import Nav from '@components/navigation/nav/nav.tsx';
import Toast from '@components/service/toast/toast.tsx';
import { Outlet } from 'react-router';
import { Suspense, useEffect } from 'react';
import NavSidebar from '@components/navigation/nav/nav-sidebar.tsx';
import { observer } from 'mobx-react-lite';
import ConditionalStrictMode from '@/conditional-strict-mode.tsx';
import usePageViewTracker from '@hooks/use-page-view-tracker.tsx';
import ClassString from '@utils/class-string.ts';
import navSidebarStore from '@store/nav-sidebar-store.ts';
import authStore from '@store/auth-store.ts';
import Modal from '@components/modal/modal.tsx';
import OptionsMenuContainer from '@components/core/options-menu/options-menu-container.tsx';
import { autorun, reaction } from 'mobx';
import cookiePromptStore from '@store/cookie-prompt-store.ts';
import CookiePrompt from '@components/core/cookie-prompt/cookie-prompt.tsx';
import SignUpCtaContainer from '@components/core/sign-up-cta/sign-up-cta-container.tsx';
import LoadingIndicator from '@components/core/loading-indicator/loading-indicator.tsx';
import modalStore from '@store/modal-store.ts';
import UserOnboardingModal from '@components/modal/user-onboarding-modal.tsx';
import hashQueryParamService from '@services/hash-query-param-service.ts';
import toastStore from '@store/toast-store.ts';
import { ToastType } from '@components/service/toast/toast-enums.ts';

const App = observer(function App() {
	usePageViewTracker();

	// Service worker update checker
	useEffect(() => {
		if ('serviceWorker' in navigator) {
			// Manual check for updates every 5 minutes
			const checkForUpdates = () => {
				void navigator.serviceWorker
					.getRegistration()
					.then((registration) => {
						if (registration) {
							void registration.update();
						}
					});
			};
			const intervalId = setInterval(checkForUpdates, 5 * 60 * 1000);

			return () => {
				clearInterval(intervalId);
			};
		}
	}, []);

	useEffect(() => {
		const disposer = autorun(async () => {
			await authStore.isAuthenticated();

			cookiePromptStore.setReadyShow();
		});

		return () => disposer();
	}, []);

	useEffect(() => {
		const disposer = reaction(
			() => [
				cookiePromptStore.readyToShow,
				cookiePromptStore.showCookiePrompt,
				authStore.user,
			],
			() => {
				if (
					!cookiePromptStore.showCookiePrompt &&
					authStore.isLoggedIn &&
					authStore.user?.needs_onboarding
				) {
					modalStore.open(<UserOnboardingModal />, {
						className: 'max-w-2xl sm:w-full',
						clickOutSideToClose: false,
					});
				}
			}
		);

		return () => disposer();
	}, []);

	useEffect(() => {
		const hashParams = hashQueryParamService.get();
		if (hashParams.verification_complete) {
			toastStore.emit(
				'Your email was verified successfully!',
				ToastType.CONFIRM
			);
		}
	}, []);

	return (
		<ConditionalStrictMode>
			{authStore.sessionRestored && (
				<div
					className={ClassString({
						static: ' relative',
						dynamic: {
							'lg:pl-sidebar':
								!navSidebarStore.hide() &&
								!navSidebarStore.minimal &&
								authStore.isLoggedIn,
							'lg:pl-24':
								navSidebarStore.minimal &&
								!navSidebarStore.hide(),
						},
					})}
				>
					<NavSidebar />
					<Nav />
					<Suspense>
						<Outlet />
					</Suspense>
					<Toast />
					<Modal />
					<OptionsMenuContainer />
					<SignUpCtaContainer />
					<CookiePrompt />
				</div>
			)}

			{!authStore.sessionRestored && (
				<div
					className={
						'w-screen h-dvh flex justify-center items-center'
					}
				>
					<LoadingIndicator />
				</div>
			)}
		</ConditionalStrictMode>
	);
});

export default App;
