import { observer } from 'mobx-react-lite';
import View from '@components/view/view.tsx';
import { useLoaderData, useLocation } from 'react-router';
import placeTagStore from '@store/place-tag-store.ts';
import ExperienceDetailsInfo from '@views/experience-details/view-components/experience-details-info.tsx';
import { ExperienceDetailsLoaderReturn } from '@views/experience-details/experience-details-loader.ts';
import PlaceStats from '@components/data/experience-stats/place-stats.tsx';
import { IconEnum } from '@components/core/icon/icon-enum.ts';
import { PlaceStatType } from '@store/place-stats-store.ts';
import ViewPanel from '@components/view/view-panel.tsx';
import LightdashDashboards from '@components/data/lightdash-dashboards/lightdash-dashboards.tsx';
import DashboardType from '@components/data/lightdash-dashboards/dashboard-type.ts';
import ExperienceLoadingTimesList from '@components/data/lightdash-dashboards/experience-loading-times-list.ts';
import { DashboardInfoType } from '@components/data/lightdash-dashboards/dashboard-info.ts';
import ExperienceDetailsRanking from '@views/experience-details/view-components/experience-details-ranking.tsx';
import ChartColors from '@components/core/chart/chart-colors.ts';
import ExperienceCcuAndVisits from '@components/data/experience-ccu-and-visits/experience-ccu-and-visits.tsx';
import ExperienceDetailsRankingTable from '@views/experience-details/view-components/experience-details-ranking-table.tsx';
import { useEffect, useMemo, useState } from 'react';
import { LocationState } from '@components/navigation/navigation-button/navigation-button.tsx';
import postHogService from '@services/posthog-service.ts';
import { PostHogEventType } from '@/enums/posthog-event.ts';
import { ExperienceStatus } from '@/schemas/experience-schema.ts';
import ViewTabPanel from '@components/view/view-tab-panel.tsx';
import { ExperienceCcuAndVisitsState } from '@components/data/experience-ccu-and-visits/experience-ccu-and-visits-state.ts';
import viewStore from '@store/view-store.ts';
import experienceRankingStore from '@store/experience-ranking-store.ts';
import ClassString from '@utils/class-string.ts';
import ExperienceDetailsInfoCompact from '@views/experience-details/view-components/experience-details-info-compact.tsx';
import ExperienceDetailsOptions from '@views/experience-details/view-components/experience-details-options.tsx';
import ExperienceDetailsCompactHeaderBanner from '@views/experience-details/view-components/experience-details-compact-header-banner.tsx';
import hashQueryParamService from '@services/hash-query-param-service.ts';
import { DataGranularity } from '@/enums/data-granularity.ts';
import AveragePlaytimeChart from '@components/data/experience-stats/average-playtime-chart.tsx';
import { autorun } from 'mobx';

const ccuTabs = [
	{
		key: ExperienceCcuAndVisitsState.CCU,
		title: 'CCU',
	},
	{
		key: ExperienceCcuAndVisitsState.VISITS,
		title: 'Visits',
	},
	{
		key: ExperienceCcuAndVisitsState.BOTH,
		title: 'CCU & Visits',
	},
];

function getDefaultCCUTab() {
	const hashParams = hashQueryParamService.get();
	if (hashParams.tab && ccuTabs.some((tab) => tab.key === hashParams.tab)) {
		return hashParams.tab as ExperienceCcuAndVisitsState;
	}

	return ExperienceCcuAndVisitsState.CCU;
}

const ExperienceDetails = observer(function ExperienceDetails() {
	const locationState = useLocation().state as LocationState;

	const { experience } = useLoaderData<ExperienceDetailsLoaderReturn>();
	const experienceArray = useMemo(() => [experience], [experience]);

	const [haveExperienceRanking, setHaveExperienceRanking] = useState(
		experienceRankingStore.experienceHaveRanking(experience.place_id)
	);

	const experienceTags = placeTagStore.getGlobalPlaceTagsByPlaceId(
		experience?.place_id
	);

	const [currentRankingsTab, setCurrentRankingsTab] = useState('');

	const [ccuChartState, setCCUChartState] = useState(getDefaultCCUTab());
	useEffect(() => {
		setCCUChartState(getDefaultCCUTab());
	}, [experience]);

	const dashboards: DashboardInfoType[] = [
		{
			type: DashboardType.GAME_SPECIFIC_OVERVIEW,
			placeId: experience.place_id,
		},
	];

	if (ExperienceLoadingTimesList.includes(experience.place_id)) {
		dashboards.push({
			type: DashboardType.EXPERIENCE_LOADING_TIMES,
			experienceCanonicalName: experience.canonical_name,
		});
	}

	useEffect(() => {
		postHogService.capture({
			type: PostHogEventType.NavigateToExperience,
			from: locationState?.from.pathname ?? 'Direct Navigation',
			experience: experience.canonical_name,
			genre: experience.genre_l1,
			sub_genre: experience.genre_l2,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (viewStore.loadingViewData && !experienceRankingStore.loading) {
			viewStore.loadingViewData = false;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [experienceRankingStore.loading]);

	useEffect(() => {
		const disposer = autorun(() => {
			const haveRanking = experienceRankingStore.experienceHaveRanking(
				experience.place_id
			);
			if (haveRanking !== haveExperienceRanking) {
				setHaveExperienceRanking(haveRanking);
			}
		});

		return () => disposer();
	}, [experience.place_id, haveExperienceRanking]);

	return (
		<View
			viewInfo={{
				title: `${experience?.name ?? 'Experience'}`,
				icon: IconEnum.PLACE,
				breadCrumbs: [{ title: 'Experiences', route: '/experiences' }],
				metaTagDescription: `Track real-time stats for ${experience?.name} on Roblox. View concurrent players, rankings, playtime, like/dislike ratios, image update history, and more.`,
				metaTagImageUrl: experience?.thumbnails?.banner
					? `https://tr.rbxcdn.com/180DAY-${experience.thumbnails.banner}/384/216/Image/Webp/noFilter`
					: undefined,
			}}
			loading={!experience}
			key={`experience-view-${experience.place_id}`}
			headerChildren={
				<>
					{!!experience && (
						<ExperienceDetailsCompactHeaderBanner
							experience={experience}
						/>
					)}
				</>
			}
			headerClass={'lg:hidden -mb-6'}
		>
			{!!experience && (
				<>
					<ViewPanel options={{ container: true }}>
						<div
							className={ClassString({
								static: 'flex justify-center items-center gap-4 h-full w-full lg:w-auto ',
								dynamic: {},
							})}
						>
							<ExperienceDetailsInfo
								experience={experience}
								tags={experienceTags}
								className={'hidden lg:flex'}
							/>
						</div>
					</ViewPanel>
					<div
						className={
							'lg:hidden w-full flex flex-col justify-center items-center gap-6'
						}
					>
						<ExperienceDetailsInfoCompact
							experience={experience}
							tags={experienceTags}
						/>

						<ExperienceDetailsOptions experience={experience} />
					</div>

					<ViewTabPanel tabs={ccuTabs} onTabChange={setCCUChartState}>
						<ExperienceCcuAndVisits
							place={experience}
							state={ccuChartState}
						/>
					</ViewTabPanel>

					<ViewPanel>
						<AveragePlaytimeChart experiences={experienceArray} />
					</ViewPanel>

					<ViewTabPanel
						tabs={[
							{
								key: 'ranking',
								title: 'Chart Rankings',
								children: (
									<ExperienceDetailsRanking
										placeId={experience.place_id}
									/>
								),
							},
							...(haveExperienceRanking
								? [
										{
											key: 'milestones',
											title: 'Chart Ranking Milestones',
											children: (
												<ExperienceDetailsRankingTable
													placeId={
														experience.place_id
													}
													isHidden={
														currentRankingsTab !==
														'milestones'
													}
												/>
											),
										},
									]
								: []),
						]}
						onTabChange={setCurrentRankingsTab}
					/>

					<ViewTabPanel
						tabs={[
							{
								key: 'likes',
								title: 'Likes',
								children: (
									<PlaceStats
										places={[experience]}
										statType={PlaceStatType.LIKES}
										title={'Likes'}
										options={{
											selectedRange:
												experience.play_status !==
												ExperienceStatus.PLAYABLE
													? 7
													: 2,
											// TODO: enable horuly when fixed in BE
											availableGranularities: [
												DataGranularity.DAY,
												DataGranularity.WEEK,
												DataGranularity.MONTH,
											],
										}}
									/>
								),
							},
							{
								key: 'like_percentage',
								title: 'Like Percentage',
								children: (
									<PlaceStats
										places={[experience]}
										statType={PlaceStatType.RATING}
										title={'Like Percentage'}
										options={{
											fill: true,
											availableGranularities: [
												DataGranularity.DAY,
											],
											yPercentage: true,
											lineColor: ChartColors()[2],
										}}
									/>
								),
							},
						]}
					/>

					<LightdashDashboards dashboards={dashboards} />
				</>
			)}
		</View>
	);
});

export default ExperienceDetails;
