import { makeAutoObservable, runInAction } from 'mobx';
import { BaseStore } from '@store/base-store.ts';

class ScrollStore implements BaseStore {
	private _scrollPosition = 0;

	reset() {
		runInAction(() => {
			this._scrollPosition = 0;
		});
	}

	constructor() {
		makeAutoObservable(this);
	}

	setScrollPosition(scrollPosition: number) {
		runInAction(() => {
			this._scrollPosition = scrollPosition;
		});
	}

	get scrollPosition() {
		return this._scrollPosition;
	}
}

const scrollStore = new ScrollStore();
export default scrollStore;
