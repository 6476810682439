import { observer } from 'mobx-react-lite';
import TopPlatformMovementType from '@views/overview/view-components/top-platform-movement-type.ts';
import TGSTable, { CustomColDef } from '@components/core/table/table.tsx';
import { CustomCellRendererProps } from 'ag-grid-react';
import NavigationButton from '@components/navigation/navigation-button/navigation-button.tsx';
import platformStatsStore from '@store/platform-stats-store.ts';
import ExperienceIcon from '@components/data/experience-icon/experience-icon.tsx';
import { SlimExperience } from '@/schemas/experience-schema.ts';
import { ExperienceIconSize } from '@components/data/experience-icon/experience-icon-constants.ts';
import { NavigationButtonEnum } from '@components/navigation/navigation-button/navigation-button-enum.ts';
import experienceStore from '@store/experience-store.ts';
import { useEffect } from 'react';
import { reaction } from 'mobx';
import thumbnailStore from '@store/thumbnail-store.ts';
import { ThumbnailType } from '@/schemas/thumbnail-schema.ts';
import { ValueFormatterParams } from 'ag-grid-community';
import Icon from '@components/core/icon/icon.tsx';
import { IconEnum } from '@components/core/icon/icon-enum.ts';
import ClassString from '@utils/class-string.ts';

interface TopCreatorListProps {
	movementType: TopPlatformMovementType;
}

interface MovementEntry {
	experience: SlimExperience;
	rank: number;
	gain: number;
	old_rank: number;
}

const TopPlatformMovement = observer(function TopPlatformMovement(
	props: TopCreatorListProps
) {
	const movement =
		props.movementType === TopPlatformMovementType.ENTRY
			? platformStatsStore.top100Entry
			: platformStatsStore.top100Exit;

	const listEntries: MovementEntry[] = movement
		.map((m) => {
			return {
				experience: experienceStore.getSlimExperienceById(m.place_id),
				rank: m.rank,
				gain: m.gain,
				old_rank: m.old_rank,
			};
		})
		.filter((entry) => !!entry.experience) as MovementEntry[];

	useEffect(() => {
		void thumbnailStore.loadThumbnailsById(
			movement.map((m) => m.place_id),
			ThumbnailType.ICON
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const getTop100Movement = () =>
			props.movementType === TopPlatformMovementType.ENTRY
				? platformStatsStore.top100Entry
				: platformStatsStore.top100Exit;

		const disposer = reaction(
			() => getTop100Movement(),
			(newMovement) => {
				void thumbnailStore.loadThumbnailsById(
					newMovement.map((m) => m.place_id),
					ThumbnailType.ICON
				);
			}
		);

		return () => disposer();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.movementType, platformStatsStore, thumbnailStore]);

	const ColumnDef: CustomColDef<MovementEntry>[] = [
		{
			minWidth: 55,
			maxWidth: 55,
			cellRenderer: (params: CustomCellRendererProps) => {
				const movementEntry = params.node.data as MovementEntry;
				return (
					<div
						className={
							'w-full h-full flex justify-center items-center'
						}
					>
						<ExperienceIcon
							experience={movementEntry.experience}
							size={ExperienceIconSize.VERY_SMALL}
							className={'rounded-md'}
						/>
					</div>
				);
			},
			cellStyle: {
				display: 'flex',
				alignItems: 'center',
				paddingRight: '0',
			},
			resizable: false,
			sortable: false,
			context: {
				priority: 2,
			},
		},
		{
			headerName: 'Experience',
			headerClass: 'ml-3 pr-0!',
			field: 'experience',
			resizable: false,
			valueFormatter: (
				params: ValueFormatterParams<MovementEntry, SlimExperience>
			) => {
				return params.value?.name ?? '';
			},
			cellRenderer: (params: CustomCellRendererProps) => {
				const movementEntry = params.node.data as MovementEntry;
				return (
					<NavigationButton
						path={`/experiences/${movementEntry.experience.place_id}`}
						direction={NavigationButtonEnum.FORWARD}
						title={movementEntry.experience.name}
						className={'pr-0 hover:pr-5'}
					/>
				);
			},
			cellStyle: {
				display: 'flex',
				alignItems: 'center',
				paddingLeft: '25px',
				paddingRight: '0',
			},
			minWidth: 140,
			context: {
				priority: 1,
			},
			flex: 1,
		},
		{
			headerName: 'Rank',
			field: 'rank',
			minWidth: 65,
			maxWidth: 65,
			headerClass: 'text-center',
			cellClass: 'text-center',
			resizable: false,
			context: {
				priority: 1,
			},
		},
		{
			headerName:
				props.movementType === TopPlatformMovementType.ENTRY
					? 'Gain (7d)'
					: 'Loss (7d)',
			headerClass: 'text-center',
			field: 'gain',
			minWidth: 100,
			maxWidth: 100,
			cellRenderer: (params: CustomCellRendererProps) => {
				const movementEntry = params.node.data as MovementEntry;
				if (!movementEntry.old_rank) {
					return (
						<div
							className={
								'w-full h-full flex justify-center items-center '
							}
						>
							<div
								className={
									'h-8 flex justify-center items-center px-2 gap-1 rounded-full bg-blue-500 text-blue-100'
								}
							>
								New
								<Icon icon={IconEnum.ROCKET} size={'1.5rem'} />
							</div>
						</div>
					);
				}

				return (
					<div
						className={
							'w-full h-full flex justify-center items-center '
						}
					>
						<div
							className={ClassString({
								static: 'h-8 flex justify-center items-center px-2 gap-1 rounded-full',
								dynamic: {
									'bg-green-400 text-green-600':
										props.movementType ===
										TopPlatformMovementType.ENTRY,
									'bg-red-300 text-red-600':
										props.movementType ===
										TopPlatformMovementType.EXIT,
								},
							})}
						>
							{movementEntry.gain}
							<Icon
								icon={
									props.movementType ===
									TopPlatformMovementType.ENTRY
										? IconEnum.TRENDING_UP
										: IconEnum.TRENDING_DOWN
								}
								size={'2rem'}
							/>
						</div>
					</div>
				);
			},
			cellStyle: {
				display: 'flex',
				alignItems: 'center',
				paddingLeft: '0',
				paddingRight: '0',
			},
			resizable: false,
			context: {
				priority: 1,
			},
		},
		{
			headerName: 'Previous Rank',
			field: 'old_rank',
			valueGetter: (params) => {
				const entry = params.node?.data;
				if (!entry?.old_rank) {
					return '—';
				}
				return entry.old_rank;
			},
			headerClass: 'text-center',
			cellClass: 'text-center',
			minWidth: 128,
			maxWidth: 128,
			resizable: false,
			context: {
				priority: 3,
			},
		},
	];

	return (
		<TGSTable
			columnDefs={ColumnDef}
			rowData={listEntries}
			tableName={`platform-movement-${props.movementType}`}
			disablePagination
			fillParent
		/>
	);
});

export default TopPlatformMovement;
