import { observer } from 'mobx-react-lite';
import logoSmall from '@assets/square_logo_white.svg';
import ExpansionPanel from '@components/core/expansion-panel/expansion-panel.tsx';
import { IconEnum } from '@components/core/icon/icon-enum.ts';
import NavSidebarLink from '@components/navigation/nav/nav-sidebar-link.tsx';
import { NavLink, useNavigate } from 'react-router';
import ClassString from '@utils/class-string.ts';
import navSidebarStore from '@store/nav-sidebar-store.ts';
import useOnClickOutside from '@hooks/use-on-click-outside.tsx';
import Icon from '@components/core/icon/icon.tsx';
import authStore from '@store/auth-store.ts';
import { UserPermission, UserRole } from '@/schemas/user-schema.ts';
import themeStore from '@store/theme-store.ts';
import { useState } from 'react';
import GlobalExperienceSearch from '@components/core/global-experience-search/global-experience-search.tsx';
import localStorageService from '@services/local-storage-service.ts';
import ThemeSelector from '@components/core/theme-selector/theme-selector.tsx';
import modalStore from '@store/modal-store.ts';
import SettingsModal from '@components/modal/settings-modal.tsx';
import ModalSignUpCta from '@components/modal/modal-sign-up-cta.tsx';
import isMobile from '@utils/is-mobile.ts';
import AppLogo from '@components/core/app-logo/app-logo.tsx';
import storeService from '@services/store-service.ts';

enum NavBarMenuKey {
	EXPERIENCES = 'experiences',
	TOOLS = 'tools',
	COMPARE = 'compare',
}

const inCompareView = () => {
	return window.location.href.includes('/compare');
};

const compareOpen = () => {
	if (inCompareView()) {
		return true;
	}

	if (!authStore.isLoggedIn && isMobile) {
		return false;
	}

	return menuOpen(NavBarMenuKey.COMPARE);
};

const inToolsView = () => {
	return (
		window.location.href.includes('/robux') ||
		window.location.href.includes('/deep-link') ||
		window.location.href.includes('/event-credentials')
	);
};

const toolsOpen = () => {
	return inToolsView() || menuOpen(NavBarMenuKey.TOOLS);
};

const storeMenuOpen = (menuKey: NavBarMenuKey, open: boolean) => {
	const user = authStore.user;
	if (!user) {
		return true;
	}

	return localStorageService.set(
		`tgs-nbm-o-${menuKey}`,
		open ? 'true' : 'false',
		user.id
	);
};

const menuOpen = (menuKey: NavBarMenuKey) => {
	const user = authStore.user;
	if (!user) {
		return true;
	}

	return localStorageService.get(`tgs-nbm-o-${menuKey}`, user.id) === 'true';
};

const NavSidebar = observer(function NavSidebar() {
	const loggedIn = authStore.isLoggedIn;
	const hideSidebar = navSidebarStore.hide();

	const { ref } = useOnClickOutside<HTMLElement>(() => {
		navSidebarStore.setOpen(false);
	});

	const navigate = useNavigate();

	const [minimizeOnMouseLeave, setMinimizeOnMouseLeave] = useState(false);

	const logoutHandler = async () => {
		navSidebarStore.setOpen(false);
		storeService.resetDataOnSignOut();
		await authStore.logOut();
		void navigate('/overview');
	};

	return (
		<aside
			className={ClassString({
				static: '-left-sidebar lg:left-0 px-4 w-sidebar bg-gray-600 fixed bottom-0 -mt-nav h-dynamic-screen flex flex-col z-nav-sidebar overflow-y-auto overflow-x-hidden',
				dynamic: {
					'left-0!': navSidebarStore.open,
					'-left-sidebar!': hideSidebar,
					'lg:w-24': navSidebarStore.minimal,
				},
			})}
			style={{
				transition:
					'left 400ms cubic-bezier(0.165, 0.84, 0.44, 1), visibility 0ms ease 400ms, width 400ms cubic-bezier(0.165, 0.84, 0.44, 1)',
			}}
			ref={ref}
			onMouseLeave={() => {
				if (minimizeOnMouseLeave) {
					navSidebarStore.setMinimal(true);
					setMinimizeOnMouseLeave(false);
				}
			}}
		>
			<div
				className={ClassString({
					static: 'mt-4 -mx-2 flex justify-center items-center',
					dynamic: { 'lg:items-start': navSidebarStore.minimal },
				})}
			>
				<NavLink to={'/overview'} draggable="false">
					<AppLogo
						color={'light'}
						className={ClassString({
							static: 'flex flex-col justify-center items-center text-white select-none',
							dynamic: {
								'lg:hidden': navSidebarStore.minimal,
							},
						})}
					/>

					<img
						src={logoSmall}
						alt={'logo-small'}
						className={ClassString({
							static: 'hidden w-16 max-w-lg',
							dynamic: {
								'lg:inline-block': navSidebarStore.minimal,
							},
						})}
						draggable="false"
					/>
				</NavLink>
			</div>

			<div className={'flex flex-col justify-between grow'}>
				<div className={'py-4 font-light'}>
					<NavSidebarLink
						title={'Overview'}
						route={'/overview'}
						icon={{ icon: IconEnum.HOME, size: '30px' }}
						className={'my-2'}
					/>

					<NavSidebarLink
						title={'Experiences'}
						route={'/experiences'}
						icon={{ icon: IconEnum.PLACE, size: '30px' }}
						className={'my-2'}
					/>

					<NavSidebarLink
						title={'Tags'}
						route={'/tags'}
						icon={{ icon: IconEnum.TAG, size: '28px' }}
						className={'my-2'}
					/>

					<NavSidebarLink
						title={'Genres'}
						route={'/genres'}
						icon={{ icon: IconEnum.GENRE, size: '28px' }}
						className={'my-2'}
					/>

					<NavSidebarLink
						title={'Creators'}
						route={'/creators'}
						icon={{ icon: IconEnum.GROUP, size: '28px' }}
						className={'my-2'}
					/>

					<NavSidebarLink
						title={'Daily Picks'}
						route={'/daily-picks'}
						icon={{ icon: IconEnum.DAILY_PICKS, size: '28px' }}
						className={'my-2'}
					/>

					<ExpansionPanel
						title={'Compare'}
						icon={{ icon: IconEnum.COMPARE, size: '28px' }}
						className={ClassString({
							static: 'text-xl text-white my-2 font-semibold',
							dynamic: {
								'lg:hidden': navSidebarStore.minimal,
							},
						})}
						colorOnHover
						selected={inCompareView()}
						titleOptions={{
							titleHeight: '42px',
							titleBorderRadius: true,
						}}
						initialState={compareOpen() ? 'expanded' : 'closed'}
						onStateChange={(open) => {
							storeMenuOpen(NavBarMenuKey.COMPARE, open);
						}}
					>
						<div
							className={ClassString({
								static: 'px-6',
								dynamic: {
									'pl-6 pr-2': !authStore.isLoggedIn,
								},
							})}
						>
							<NavSidebarLink
								title={'Compare Experiences'}
								route={'/compare/experiences'}
								locked={!authStore.isLoggedIn}
								onClick={() => {
									if (!authStore.isLoggedIn) {
										modalStore.open(<ModalSignUpCta />);
									}
								}}
								normalFontWeight
							/>

							<NavSidebarLink
								title={'Compare Tags'}
								route={'/compare/tags'}
								locked={!authStore.isLoggedIn}
								onClick={() => {
									if (!authStore.isLoggedIn) {
										modalStore.open(<ModalSignUpCta />);
									}
								}}
								normalFontWeight
							/>

							<NavSidebarLink
								title={'Compare Tag & Experiences'}
								route={'/compare/experience-tag'}
								locked={!authStore.isLoggedIn}
								onClick={() => {
									if (!authStore.isLoggedIn) {
										modalStore.open(<ModalSignUpCta />);
									}
								}}
								whiteSpaceWrap
								normalFontWeight
							/>
						</div>
					</ExpansionPanel>

					<div
						className={ClassString({
							static: 'hidden p-2 my-2 hover:bg-blue-300 rounded-md cursor-pointer w-full items-center justify-center',
							dynamic: {
								'lg:flex': navSidebarStore.minimal,
								'bg-blue-300': inCompareView(),
							},
						})}
						onClick={() => {
							navSidebarStore.setMinimal(false);
							setMinimizeOnMouseLeave(true);
						}}
					>
						<Icon
							icon={IconEnum.COMPARE}
							size={'28px'}
							className={'text-white'}
						/>
					</div>

					{authStore.haveUserRole([
						UserRole.EDITOR,
						UserRole.ADMIN,
					]) && (
						<ExpansionPanel
							title={'Tools'}
							icon={{ icon: IconEnum.TOOLS, size: '28px' }}
							className={ClassString({
								static: 'text-xl text-gray-100 my-2 font-semibold',
								dynamic: {
									'lg:hidden': navSidebarStore.minimal,
								},
							})}
							colorOnHover
							selected={inToolsView()}
							titleOptions={{
								titleHeight: '42px',
								titleBorderRadius: true,
							}}
							initialState={toolsOpen() ? 'expanded' : 'closed'}
							onStateChange={(open) => {
								storeMenuOpen(NavBarMenuKey.TOOLS, open);
							}}
						>
							<div className={'px-6'}>
								<NavSidebarLink
									title={'Robux Converter'}
									route={'/robux'}
									normalFontWeight
								/>
							</div>

							<div className={'px-6'}>
								<NavSidebarLink
									title={'Roblox Deep Link'}
									route={'/deep-link'}
									normalFontWeight
								/>
							</div>

							{authStore.havePermission(
								UserPermission.EDIT_GAME_CREDENTIALS
							) && (
								<div className={'px-6'}>
									<NavSidebarLink
										title={'Event Credentials'}
										route={'/event-credentials'}
										normalFontWeight
									/>
								</div>
							)}
						</ExpansionPanel>
					)}

					<div
						className={ClassString({
							static: 'hidden p-2 my-2 hover:bg-blue-300 rounded-md cursor-pointer w-full items-center justify-center',
							dynamic: {
								'lg:flex': navSidebarStore.minimal,
								'bg-blue-300': inToolsView(),
							},
						})}
						onClick={() => {
							navSidebarStore.setMinimal(false);
							setMinimizeOnMouseLeave(true);
						}}
					>
						<Icon
							icon={IconEnum.TOOLS}
							size={'28px'}
							className={'text-white'}
						/>
					</div>

					{authStore.havePermission(
						UserPermission.VIEW_CODE_LIBRARY
					) && (
						<NavSidebarLink
							title={'Code Library'}
							route={'/code-library'}
							icon={{
								icon: IconEnum.DESCRIPTION,
								size: '28px',
							}}
							className={'my-2'}
						/>
					)}

					{authStore.havePermission(UserPermission.LIST_USERS) && (
						<NavSidebarLink
							title={'User Management'}
							route={'/users'}
							icon={{
								icon: IconEnum.MANAGE_ACCOUNTS,
								size: '28px',
							}}
							className={'my-2'}
						/>
					)}
				</div>

				<div
					className={
						'lg:hidden py-4 mt-4 font-light border-t border-gray-500 gap-1'
					}
				>
					<div
						className={
							'flex items-center justify-between text-white text-lg px-3'
						}
					>
						{loggedIn && (
							<div className={'mr-2 flex items-center'}>
								<Icon
									icon={IconEnum.ACCOUNT}
									size={'20px'}
									className={'mr-2'}
								/>

								<div className={'mr-2'}>
									{`${authStore.user?.first_name ?? ''} ${
										authStore.user?.last_name ?? ''
									}`}
								</div>
							</div>
						)}

						{!loggedIn && (
							<NavSidebarLink
								title={'Sign In'}
								route={'/sign-in'}
								className={'w-full mr-6'}
							/>
						)}

						<ThemeSelector
							className={ClassString({
								dynamic: {
									'text-gray-600': themeStore.lightTheme,
								},
							})}
						/>
					</div>

					{!loggedIn && (
						<div className={'px-3'}>
							<NavSidebarLink
								title={'Sign Up For Free'}
								route={'/sign-up'}
								className={'w-full'}
							/>
						</div>
					)}

					{loggedIn && (
						<div
							className={
								'flex items-center flex-col text-white text-lg py-2 gap-1'
							}
						>
							<div
								className={
									'nav-sidebar-link flex items-center mr-2 px-4  py-2 w-full cursor-pointer hover:text-gray-600 hover:bg-blue-300 rounded-md'
								}
								onClick={() => {
									modalStore.open(<SettingsModal />, {
										className: 'max-w-2xl sm:w-full',
									});
									navSidebarStore.setOpen(false);
								}}
							>
								<Icon
									icon={IconEnum.SETTINGS}
									size={'20px'}
									className={'mr-2'}
								/>
								Settings
							</div>

							<div
								className={
									'nav-sidebar-link flex items-center mr-2 px-4  py-2 w-full cursor-pointer hover:text-gray-600 hover:bg-blue-300 rounded-md'
								}
								onClick={() => {
									void logoutHandler();
								}}
							>
								<Icon
									icon={IconEnum.LOGOUT}
									size={'20px'}
									className={'mr-2'}
								/>
								Sign Out
							</div>
						</div>
					)}
				</div>
			</div>

			<div
				className={ClassString({
					static: 'w-full hidden lg:flex justify-between items-center mb-4 flex-wrap gap-y-2 border-t border-gray-500 pt-2',
					dynamic: {
						'justify-start': navSidebarStore.minimal,
					},
				})}
			>
				<GlobalExperienceSearch className={'text-white'} />

				<div
					className={ClassString({
						static: 'p-1 text-white hover:text-gray-600 hover:bg-blue-300 rounded-md cursor-pointer flex justify-center items-center',
						dynamic: { 'w-40': navSidebarStore.minimal },
					})}
					onClick={() => {
						navSidebarStore.setMinimal(
							!navSidebarStore.minimal,
							authStore.user?.id
						);
					}}
				>
					<Icon
						icon={
							navSidebarStore.minimal
								? IconEnum.ARROW_FORWARD
								: IconEnum.ARROW_BACK
						}
						size={'2.25rem'}
					/>
				</div>
			</div>
		</aside>
	);
});

export default NavSidebar;
