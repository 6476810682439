import { observer } from 'mobx-react-lite';
import Highcharts from 'highcharts';
import Chart from '@components/core/chart/chart.tsx';
import { useCallback, useEffect, useState } from 'react';
import placeStatsStore, {
	CCUDataSegment,
	isValidGranularity,
} from '@store/place-stats-store.ts';
import LoadingIndicator from '@components/core/loading-indicator/loading-indicator.tsx';
import { SlimExperience } from '@/schemas/experience-schema.ts';
import { PlaceTag } from '@/schemas/plage-tag-schema.ts';
import GranularitySelector from '@components/data/granularity-selector/granularity-selector.tsx';
import hashQueryParamService from '@services/hash-query-param-service.ts';
import getGraphId from '@utils/get-graph-id.ts';
import { DataGranularity } from '@/enums/data-granularity.ts';

interface TagVsTagCcuProps {
	tags: {
		tag: PlaceTag;
		tagExperiences: SlimExperience[];
	}[];
	title: string;
	state: CCUDataSegment;
	options?: {
		defaultGranularity?: DataGranularity;
		selectedRange?: number;
	};
}

const getDefaultGranularity = (props: TagVsTagCcuProps) => {
	const hashParams = hashQueryParamService.get();
	if (
		hashParams.granularity &&
		hashParams.anchor === getGraphId(props.title) &&
		isValidGranularity(hashParams.granularity)
	) {
		return hashParams.granularity as DataGranularity;
	}

	if (props.options?.defaultGranularity) {
		return props.options.defaultGranularity;
	}

	return DataGranularity.DAY;
};

const numberFormatter = new Intl.NumberFormat(navigator.language);

const TagVsTagCcu = observer(function TagVsTagCcu(props: TagVsTagCcuProps) {
	const [granularity, setGranularity] = useState(
		getDefaultGranularity(props)
	);

	const [loading, setLoading] = useState(true);

	const data = [
		...props.tags.map((t) => {
			return {
				name: t.tag.name,
				data: placeStatsStore.averageTagCCU(
					t.tag.id,
					granularity,
					props.state
				),
			};
		}),
	];

	const noData = !loading && !data.length;

	const getData = useCallback(
		async (granularity: DataGranularity, dataSegment: CCUDataSegment) => {
			await Promise.all([
				...props.tags.map((t) => {
					return placeStatsStore.getAverageTagCCU(
						t.tag,
						t.tagExperiences,
						granularity,
						dataSegment
					);
				}),
			]);
			setLoading(false);
		},
		[props.tags]
	);

	const showColumnLabels = true;
	let series: Highcharts.SeriesOptionsType[] = [];
	series = data.map((d) => {
		return {
			name: d.name,
			type: 'line',
			showInLegend: true,
			data: d.data,
			marker: {
				enabled: false,
			},
			lineWidth: 3,
		};
	});

	let xMin = 0;
	let xMax = 0;
	data.forEach((td) => {
		if (!td) {
			return;
		}

		td.data.forEach((d) => {
			const date = d[0];

			if (xMin === 0) {
				xMin = date;
			}

			if (xMax === 0) {
				xMax = date;
			}

			if (date < xMin) {
				xMin = date;
			}

			if (date > xMax) {
				xMax = date;
			}
		});
	});

	const options: Highcharts.Options = {
		series,
		chart: {
			marginLeft: 45,
		},
		xAxis: {
			type: 'datetime',
			min: xMin,
			max: xMax,
		},
		yAxis: {
			title: {
				enabled: false,
			} as Highcharts.YAxisOptions,
			labels: {
				x: -10,
				y: 3,
				reserveSpace: false,
				style: {
					whiteSpace: 'nowrap',
				},
			},
		},
		plotOptions: {
			line: {
				marker: {
					enabled: false,
				},
			},
			column: {
				stacking: 'normal',
				dataLabels: {
					enabled: showColumnLabels,
				},
			},
		},
		navigator: {
			enabled: true,
			series: data.map((td) => {
				return {
					data: td.data,
				};
			}),
			xAxis: {
				min: xMin,
				max: xMax,
			},
		},
		scrollbar: {
			enabled: true,
			liveRedraw: true,
		},
		rangeSelector: {
			enabled: true,
			selected: props.options?.selectedRange ?? 2,
		},
		tooltip: {
			pointFormatter: function () {
				// Handle line series (single y value)
				if (this.y === undefined) {
					return '';
				}
				const value = this.y;
				const color = this.color as string;
				return `<span style="color:${color}">●</span> ${this.series.name}: <b>${numberFormatter.format(value)}</b><br/>`;
			},
			shared: true,
			split: false,
			distance: 10,
			snap: 8,
		},
	};

	const updateGranularity = (g: DataGranularity) => {
		void getData(g, props.state);
		setGranularity(g);
		setLoading(true);
	};

	useEffect(() => {
		void getData(granularity, props.state);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setLoading(true);
		void getData(granularity, props.state);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.state]);

	return (
		<div>
			<div className={'relative'}>
				<Chart
					title={props.title}
					highcharts={Highcharts}
					options={options}
					granularity={granularity}
				/>

				{loading && (
					<div
						className={
							'absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
						}
					>
						<LoadingIndicator />
					</div>
				)}
				{noData && (
					<div
						className={
							'absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
						}
					>
						No Data
					</div>
				)}
			</div>

			<div
				className={'flex items-center justify-between gap-4 flex-wrap'}
			>
				<GranularitySelector
					updateGranularity={updateGranularity}
					selectedGranularity={granularity}
				/>
			</div>
		</div>
	);
});

export default TagVsTagCcu;
