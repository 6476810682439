import BrandColors from '@styles/_brand_colors.ts';
import themeStore from '@store/theme-store.ts';

// const ChartColors = () => {
// 	if (themeStore.lightTheme) {
// 		return [
// 			BrandColors.blue500,
// 			BrandColors.red400,
// 			BrandColors.green500,
// 			BrandColors.yellow500,
// 			BrandColors.gray600,
// 			BrandColors.blue400,
// 			BrandColors.red300,
// 			BrandColors.green400,
// 			BrandColors.yellow400,
// 			BrandColors.blue300,
// 			BrandColors.pink500,
// 			BrandColors.gray500,
// 		];
// 	}
//
// 	return [
// 		BrandColors.blue400,
// 		BrandColors.red400,
// 		BrandColors.green500,
// 		BrandColors.yellow500,
// 		BrandColors.blue300,
// 		BrandColors.pink400,
// 		BrandColors.gray500,
// 		BrandColors.blue300,
// 		BrandColors.red300,
// 		BrandColors.green300,
// 		BrandColors.yellow300,
// 	];
// };

// const ChartColors = () => {
// 	if (themeStore.lightTheme) {
// 		return [
// 			BrandColors.blue400,
// 			BrandColors.red400,
// 			BrandColors.green500,
// 			'#0071A7', // Deep Blue
// 			'#009900', // Strong Green
// 			'#FF3366', // Soft Red-Pink
// 			'#6600CC', // Rich Purple
// 			'#FFCC00', // Warm Yellow
// 			'#3399FF', // Bright Blue
// 			'#CC0000', // Bold Red
// 			'#33CC99', // Teal Green
// 			'#9966FF', // Lavender
// 			'#00CCFF', // Cyan
// 			'#FF99CC', // Soft Pink
// 			'#99CC00', // Lime Green
// 			'#669999', // Muted Teal
// 			'#CC6699', // Rose Pink
// 			'#66CCFF', // Sky Blue
// 			'#D4A017', // Golden Yellow
// 			'#AD5D5D', // Earthy Brown
// 			'#808080', // Neutral Gray
// 		];
// 	}
//
// 	return [
// 		BrandColors.blue400,
// 		BrandColors.red400,
// 		BrandColors.green500,
// 		'#AA66CC', // Soft Purple
// 		'#33CC33', // Bright Green
// 		'#FFD700', // Gold Yellow
// 		'#FF4444', // Bright Red
// 		'#66B2FF', // Soft Blue
// 		'#FF6699', // Warm Pink
// 		'#33CCCC', // Aqua Teal
// 		'#9980FA', // Soft Lavender
// 		'#00E5EE', // Neon Cyan
// 		'#FFB6C1', // Light Pink
// 		'#B3D233', // Yellow-Green
// 		'#88AAAA', // Muted Cyan
// 		'#CC8899', // Warm Rose
// 		'#66D9FF', // Electric Sky Blue
// 		'#E5C100', // Deep Gold
// 		'#B57C7C', // Muted Brown
// 		'#A0A0A0', // Soft Gray
// 		'#4DB8FF', // Light Blue
// 	];
// };

const ChartColors = () => {
	if (themeStore.lightTheme) {
		return [
			BrandColors.blue300,
			BrandColors.red400,
			BrandColors.green500,
			'#f58b5e',
			'#9ecfc5',
			'#ae92e3',
			'#f0ba6b',
			'#f280a8',
			'#7cc88d',
			'#e7c44f',
			'#e88bd7',
			'#9cbff0',
			'#b889ef',
			'#f298d3',
			'#a6dfaa',
			'#82aee8',
			'#f4c87e',
			'#e6b97d',
			'#e3a9b7',
			'#c8d2dc',
		];
	}

	return [
		BrandColors.blue300,
		BrandColors.red400,
		BrandColors.green500,
		'#ff8c69',
		'#94c9b7',
		'#a084dc',
		'#f4b76e',
		'#ff80aa',
		'#7ecb8b',
		'#ffd700',
		'#de8fe8',
		'#84b8ff',
		'#c48fff',
		'#ff99c8',
		'#98e1a3',
		'#7aa9f9',
		'#ffcf99',
		'#ffc36e',
		'#e6a7be',
		'#b0b8c5',
	];
};
export default ChartColors;
