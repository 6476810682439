import { observer } from 'mobx-react-lite';
import ClassString from '@utils/class-string.ts';
import Chart from '@components/core/chart/chart.tsx';
import Highcharts from 'highcharts';
import LoadingIndicator from '@components/core/loading-indicator/loading-indicator.tsx';
import GranularitySelector from '@components/data/granularity-selector/granularity-selector.tsx';
import { DataGranularity } from '@/enums/data-granularity.ts';
import { useCallback, useEffect, useState } from 'react';
import averagePlaytimeStatsStore from '@store/experience-stats/average-playtime-stats-store.ts';
import ChartColors from '@components/core/chart/chart-colors.ts';
import * as _ from 'lodash';
import { experienceStatsSharedOptions } from '@components/data/experience-stats/experience-stats-shared-options.ts';
import formatSecondsDurationString from '@utils/format-seconds-duration-string.ts';
import { PopulatedPlaceTag } from '@/schemas/plage-tag-schema.ts';
import { getMinMaxTagX } from '@store/experience-stats/tag-stat-helpers.ts';
import BrandColors from '@styles/_brand_colors.ts';
import ThemeStore from '@store/theme-store.ts';
import hashQueryParamService from '@services/hash-query-param-service.ts';
import getGraphId from '@utils/get-graph-id.ts';
import { isValidGranularity } from '@store/place-stats-store.ts';

const graphTitle = 'Average Playtime';

const getDefaultGranularity = () => {
	const hashParams = hashQueryParamService.get();
	if (
		hashParams.granularity &&
		hashParams.anchor === getGraphId(graphTitle) &&
		isValidGranularity(hashParams.granularity)
	) {
		return hashParams.granularity as DataGranularity;
	}

	return DataGranularity.DAY;
};

interface AveragePlaytimeChartProps {
	tag: PopulatedPlaceTag;
}

const AveragePlaytimeChart = observer(function AveragePlaytimeChart(
	props: AveragePlaytimeChartProps
) {
	const [granularity, setGranularity] = useState(getDefaultGranularity());
	const [loading, setLoading] = useState(true);

	const getData = useCallback(
		async (gran: DataGranularity) => {
			await averagePlaytimeStatsStore.loadAverageTagPlaytime(
				props.tag.id,
				gran
			);
			setLoading(false);
		},
		[props.tag]
	);

	const updateGranularity = (g: DataGranularity) => {
		void getData(g);
		setGranularity(g);
		setLoading(true);
	};

	useEffect(() => {
		void getData(granularity);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.tag]);

	const data =
		averagePlaytimeStatsStore.averageTagPlaytime(
			props.tag.id,
			granularity
		) ?? [];

	const noData =
		!loading &&
		!averagePlaytimeStatsStore.averageTagPlaytime(props.tag.id, granularity)
			?.length;

	const series: Highcharts.SeriesOptionsType[] = [
		{
			name: 'Average Playtime',
			type: 'line',
			data: data.map((d) => [d[0], d[1]]),
			marker: {
				enabled: false,
			},
			lineWidth: 2,
			color: ThemeStore.lightTheme
				? BrandColors.green600
				: BrandColors.green500,
			zIndex: 2,
		},
		{
			name: '50% Spread',
			type: 'arearange',
			data: data.map((d) => [d[0], d[2], d[3]]),
			marker: {
				enabled: false,
			},
			fillOpacity: ThemeStore.lightTheme ? 0.3 : 0.15,
			lineWidth: 0,
			color: ChartColors()[2],
			zIndex: 1,
		},
	];

	const { xMin, xMax } = getMinMaxTagX(data);

	const options: Highcharts.Options = {
		chart: {
			marginLeft: 65,
		},
		series,
		yAxis: {
			labels: {
				formatter: (
					formatter: Highcharts.AxisLabelsFormatterContextObject
				) => {
					const value = formatter.value as number;
					const hours = Math.floor(value / 3600);
					const minutes = Math.floor((value % 3600) / 60);
					const seconds = value % 60;

					if (hours > 0) {
						return `${hours}h ${minutes}m`;
					} else if (minutes > 0) {
						return `${minutes}m ${seconds}s`;
					}
					return `${seconds}s`;
				},
			},
		},
		xAxis: {
			min: xMin,
			max: xMax,
		},
		navigator: {
			series,
			xAxis: {
				min: xMin,
				max: xMax,
			},
		},
		tooltip: {
			pointFormatter: function () {
				if (this.series.type === 'arearange') {
					// Handle arearange series (low/high values)
					const low = this.low;
					const high = this.high;
					if (low === undefined || high === undefined) {
						return '';
					}
					const color = this.color as string;
					const formattedLow = formatSecondsDurationString(low);
					const formattedHigh = formatSecondsDurationString(high);
					return `<span style="color:${color}">●</span> ${this.series.name}: <b>${formattedLow} - ${formattedHigh}</b><br/>`;
				} else {
					// Handle line series (single y value)
					if (this.y === undefined) {
						return '';
					}
					const value = this.y;
					const color = this.color as string;
					const formattedTime = formatSecondsDurationString(value);
					return `<span style="color:${color}">●</span> ${this.series.name}: <b>${formattedTime}</b><br/>`;
				}
			},
			shared: true,
			split: false,
			distance: 10,
			snap: 8,
		},
		rangeSelector: {
			selected: 6,
		},
		legend: {
			labelFormatter: function () {
				if (this.name === '50% Spread') {
					return this.name + ' (interquartile range)';
				}
				return this.name; // Default fallback
			},
		},
	};

	const mergedOptions: Highcharts.Options = _.merge(
		{},
		experienceStatsSharedOptions,
		options
	);

	useEffect(() => {
		void getData(granularity);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div>
			<div className={'relative'}>
				<div
					className={ClassString({
						static: 'transition-opacity',
						dynamic: { 'opacity-0': loading },
					})}
				>
					<Chart
						title={graphTitle}
						highcharts={Highcharts}
						options={mergedOptions}
						granularity={granularity}
						info={
							'Estimated amount of time players spend in the experiences that is part of this tag. Calculated from public data and may not always be accurate.'
						}
						fillPanelResponsiveWidth
					/>
				</div>

				{loading && (
					<div
						className={
							'absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
						}
					>
						<LoadingIndicator />
					</div>
				)}
				{noData && (
					<div
						className={
							'absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
						}
					>
						No Data
					</div>
				)}
			</div>

			<GranularitySelector
				updateGranularity={updateGranularity}
				selectedGranularity={granularity}
				availableGranularities={[
					DataGranularity.HOUR,
					DataGranularity.DAY,
					DataGranularity.WEEK,
				]}
			/>
		</div>
	);
});

export default AveragePlaytimeChart;
