import hashQueryParamService from '@services/hash-query-param-service.ts';
import getGraphId from '@utils/get-graph-id.ts';
import { isValidGranularity } from '@store/place-stats-store.ts';
import { DataGranularity } from '@/enums/data-granularity.ts';

export interface ExperienceData {
	experienceId: number;
	experienceName: string;
	data: number[][];
}

// TODO: delete?
export const getDefaultGranularity = (
	chartTitle: string,
	options?: {
		availableGranularities?: DataGranularity[];
		defaultGranularity?: DataGranularity;
	}
) => {
	if (
		options?.availableGranularities &&
		options.availableGranularities.length === 1
	) {
		return options.availableGranularities[0];
	}

	const hashParams = hashQueryParamService.get();
	if (
		hashParams.granularity &&
		hashParams.anchor === getGraphId(chartTitle) &&
		isValidGranularity(hashParams.granularity)
	) {
		return hashParams.granularity as DataGranularity;
	}

	if (
		options?.availableGranularities?.length &&
		options?.defaultGranularity &&
		options.availableGranularities.includes(options.defaultGranularity)
	) {
		return options.availableGranularities[0];
	}

	if (options?.defaultGranularity) {
		return options.defaultGranularity;
	}

	return DataGranularity.DAY;
};

export const getMinMaxX = (data: ExperienceData[]) => {
	let xMin = 0;
	let xMax = 0;
	data.forEach((pd) => {
		pd.data.forEach((d) => {
			const date = d[0];

			if (xMin === 0) {
				xMin = date;
			}

			if (xMax === 0) {
				xMax = date;
			}

			if (date < xMin) {
				xMin = date;
			}

			if (date > xMax) {
				xMax = date;
			}
		});
	});

	return { xMin, xMax };
};
