import { ChangeEvent, useId } from 'react';
import { observer } from 'mobx-react-lite';
import ClassString from '@utils/class-string.ts';

export interface TextAreaProps {
	label: string;
	value: string;
	onChange: (event: ChangeEvent<HTMLTextAreaElement>) => unknown;
	minHeight?: string;
	className?: string;
}

const resizeTextArea = (event: ChangeEvent<HTMLTextAreaElement>) => {
	const target = event.target as HTMLTextAreaElement;
	// Reset field height
	target.style.height = 'inherit';

	// Get the computed styles for the element
	const computed = window.getComputedStyle(target);

	// Calculate the height
	const height =
		target.scrollHeight -
		parseInt(computed.getPropertyValue('padding-top'), 10) -
		parseInt(computed.getPropertyValue('padding-bottom'), 10);
	target.style.height = `${height}px`;
};

const TextArea = observer(function TextArea(props: TextAreaProps) {
	{
		const id = useId();

		return (
			<div
				className={ClassString({
					static: 'text-area group',
					dynamic: {},
					custom: props.className,
				})}
			>
				<label
					htmlFor={id}
					className={ClassString({
						static: 'absolute top-2 group-focus-within:-top-4 left-4 text-sm tracking-wider opacity-70 group-focus-within:opacity-100 pointer-events-none group-focus-within:text-xs group-focus-within:text-blue-300 transition-all',
						dynamic: {
							'text-xs opacity-100 -top-4': !!props.value.length,
						},
					})}
				>
					{props.label}
				</label>
				<textarea
					id={id}
					value={props.value}
					style={
						props.minHeight ? { minHeight: props.minHeight } : {}
					}
					onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
						resizeTextArea(event);
						return props.onChange(event);
					}}
					className={
						'resize-none rounded-xl outline-hidden px-4 pt-1 transition-all h-fit overflow-hidden w-full bg-white dark:bg-gray-525 focus:border-blue-300 border border-gray-600 dark:border-gray-500'
					}
				/>
			</div>
		);
	}
});

export default TextArea;
