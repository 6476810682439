import { observer } from 'mobx-react-lite';
import Highcharts from 'highcharts';
import Chart from '@components/core/chart/chart.tsx';
import eventStatsStore from '@store/event-stats-store.ts';
import ClassString from '@utils/class-string.ts';

interface EventCredentialEventStatsGraphProps {
	title: string;
	experienceId?: number;
	totalEvents?: boolean;
	className?: string;
}

const EventCredentialEventStatsGraph = observer(
	function EventCredentialEventStatsGraph(
		props: EventCredentialEventStatsGraphProps
	) {
		const eventStats =
			props.experienceId && !props.totalEvents
				? eventStatsStore.getEventStatById(props.experienceId)
				: [];

		const data =
			props.experienceId && !props.totalEvents
				? eventStats.map((stat) => [
						stat.date.getTime(),
						stat.num_events,
					])
				: eventStatsStore.totalEvents;

		const options: Highcharts.Options = {
			series: [
				{
					name: 'Events',
					type: 'column',
					data,
					showInLegend: false,
				},
			],
			chart: {
				marginLeft: 45,
			},
			xAxis: {
				type: 'datetime',
			},
			yAxis: {
				title: {
					enabled: false,
				} as Highcharts.YAxisOptions,
				labels: {
					x: -5,
					y: 3,
					reserveSpace: false,
					style: {
						whiteSpace: 'nowrap',
					},
				},
			},
			plotOptions: {
				line: {
					marker: {
						enabled: false,
					},
				},
				column: {
					stacking: 'normal',
					dataLabels: {
						enabled: true,
					},
				},
			},
			navigator: {
				enabled: true,
			},
			scrollbar: {
				enabled: true,
				liveRedraw: true,
			},
			rangeSelector: {
				enabled: true,
				selected: props.totalEvents ? 2 : 7,
			},
		};

		return (
			<div
				className={ClassString({
					static: 'relative',
					dynamic: {},
					custom: props.className,
				})}
			>
				<Chart
					title={props.title}
					highcharts={Highcharts}
					options={options}
					disableShareButton
				/>
			</div>
		);
	}
);

export default EventCredentialEventStatsGraph;
