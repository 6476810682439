import { makeAutoObservable, runInAction } from 'mobx';
import httpFetch from '@services/http-fetch-service.ts';
import { ExchangeRateApiResponseSchema } from '@/schemas/exchange-rate-currency-schema.ts';
import localStorageService from '@services/local-storage-service.ts';
import { LocalStorageKey } from '@/enums/local-storage-types.ts';
import { BaseStore } from '@store/base-store.ts';

interface USDRates {
	expires: string;
	rates: {
		SEK: number;
		EUR: number;
	};
}

class CurrencyStore implements BaseStore {
	private usdRateObject: USDRates | undefined;

	reset() {
		runInAction(() => {
			this.usdRateObject = undefined;
		});
	}

	constructor() {
		makeAutoObservable(this);
		const storedUSDRates = localStorageService.get(
			LocalStorageKey.USDExchangeRates
		);
		if (storedUSDRates) {
			this.usdRateObject = JSON.parse(storedUSDRates) as USDRates;
			if (new Date() >= new Date(this.usdRateObject.expires)) {
				void this.retrieveUSDRates();
			}
		} else {
			void this.retrieveUSDRates();
		}
	}

	private async retrieveUSDRates() {
		const response = await httpFetch.GET(
			'https://open.er-api.com/v6/latest/USD',
			false
		);

		if (response.ok) {
			const responseBody = ExchangeRateApiResponseSchema.parse(
				await response.json()
			);
			this.usdRateObject = {
				expires: responseBody.time_next_update_utc,
				rates: {
					SEK: responseBody.rates.SEK,
					EUR: responseBody.rates.EUR,
				},
			};
			localStorageService.set(
				LocalStorageKey.USDExchangeRates,
				JSON.stringify(this.usdRates)
			);
		}
	}

	get usdRates() {
		return this.usdRateObject;
	}
}

const currencyStore = new CurrencyStore();
export default currencyStore;
