import { observer } from 'mobx-react-lite';
import optionsMenuStore from '@store/options-menu-store.ts';
import { CSSProperties, useEffect, useRef } from 'react';
import scrollStore from '@store/scroll-store.ts';
import ClassString from '@utils/class-string.ts';
import useWindowSize from '@hooks/use-window-size.tsx';

const defaultMenuWidth = '10rem';
const buttonClass = 'options-button';

const OptionsMenuContainer = observer(function OptionsMenuContainer() {
	// Update component when scroll position updates.
	scrollStore.scrollPosition;
	// Update component when window size updates.
	useWindowSize();

	const ref = useRef<HTMLDivElement>(null);

	const boundingClientRect =
		optionsMenuStore.options?.ref.current?.getBoundingClientRect();

	useEffect(() => {
		const handleClick = (event: MouseEvent) => {
			const target = event.target as Element;
			if (
				!target.classList.contains(buttonClass) &&
				!optionsMenuStore.isOpen(target)
			) {
				optionsMenuStore.close();
			}
		};

		const handleKeyDown = (event: KeyboardEvent) => {
			if (event.key === 'Escape') {
				optionsMenuStore.close();
			}
		};

		if (optionsMenuStore.options) {
			document.addEventListener('click', handleClick, true);
			document.addEventListener('keydown', handleKeyDown, true);
		}
		return () => {
			document.removeEventListener('click', handleClick, true);
			document.removeEventListener('keydown', handleKeyDown, true);
		};
	});

	return (
		<div
			className={
				'absolute top-0 left-0 w-screen h-dvh pointer-events-none'
			}
		>
			{!!optionsMenuStore.options && (
				<div
					ref={ref}
					className={
						'shadow-lg shadow-gray-600/50 h-0 w-40 bg-white dark:bg-gray-525 absolute overflow-hidden bottom outline-1 outline-gray-600 dark:outline-gray-500 rounded-md animate-options-menu ease-transition-curve'
					}
					style={
						{
							top: boundingClientRect
								? `calc(${boundingClientRect.bottom}px + 0.25rem)`
								: 0,
							left: boundingClientRect?.right
								? `calc(${boundingClientRect.right}px - ${optionsMenuStore.options.width ?? defaultMenuWidth})`
								: (optionsMenuStore.options.width ??
									defaultMenuWidth),
							'--button-count':
								optionsMenuStore.options.buttons.length,
							width:
								optionsMenuStore.options.width ??
								defaultMenuWidth,
						} as CSSProperties
					}
				>
					{optionsMenuStore.options.buttons.map((button) => (
						<div
							onClick={() => {
								button.onClick();
								optionsMenuStore.close();
							}}
							key={button.title}
							className={ClassString({
								static: 'pointer-events-auto h-button pl-6 pr-5 flex justify-start items-center cursor-pointer hover:text-gray-600 hover:bg-blue-300 not-last:border-b border-gray-500 dark:border-gray-500 relative',
								dynamic: {
									'justify-center':
										optionsMenuStore.options?.buttons
											.length === 1,
									'justify-start':
										optionsMenuStore.options?.buttons
											.length !== 1,
								},
								custom: buttonClass,
							})}
						>
							<div
								className={
									'absolute top-0 left-0 h-full w-4 bg-gray-600 mr-5'
								}
							></div>

							{button.title}
						</div>
					))}
				</div>
			)}
		</div>
	);
});

export default OptionsMenuContainer;
