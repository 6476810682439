import { useEffect, useState } from 'react';
import hashQueryParamService from '@services/hash-query-param-service.ts';
import getGraphId from '@utils/get-graph-id.ts';

export default function useWaitingToScrollToChart(chartName: string): boolean {
	const anchor = hashQueryParamService.get().anchor;
	const [waiting, setWaiting] = useState(
		!!anchor && anchor === getGraphId(chartName)
	);
	const [previousHeight] = useState(window.innerHeight);

	useEffect(() => {
		const checkHeightChange = () => {
			const currentHeight = window.innerHeight;
			if (currentHeight === previousHeight) {
				clearInterval(intervalId);
				setWaiting(false);
			}
		};

		// Set a 1-second interval to check for height change
		const intervalId = setInterval(checkHeightChange, 200);

		// Cleanup the interval on component unmount
		return () => clearInterval(intervalId);
	}, [previousHeight]);

	return waiting;
}
