import { observer } from 'mobx-react-lite';
import { Experience } from '@/schemas/experience-schema.ts';
import Highcharts from 'highcharts';
import Chart from '@components/core/chart/chart.tsx';

interface PlaceLikeDistributionProps {
	places: Experience[];
}

interface PlaceLikeDistributionCounts {
	'95To100': number;
	'90To94': number;
	'85To89': number;
	'80To84': number;
	'75To79': number;
	'70To74': number;
	'65To69': number;
	'60To64': number;
	'55To59': number;
	'50To54': number;
	'45To49': number;
	'40To44': number;
	'35To39': number;
	'30To34': number;
	'25To29': number;
	'20To24': number;
	'15To19': number;
	'10To14': number;
	'5To9': number;
	'0To4': number;
}

const PlaceLikeDistribution = observer(function PlaceLikeDistribution(
	props: PlaceLikeDistributionProps
) {
	const placeLikeCounts: PlaceLikeDistributionCounts = {
		'95To100': 0,
		'90To94': 0,
		'85To89': 0,
		'80To84': 0,
		'75To79': 0,
		'70To74': 0,
		'65To69': 0,
		'60To64': 0,
		'55To59': 0,
		'50To54': 0,
		'45To49': 0,
		'40To44': 0,
		'35To39': 0,
		'30To34': 0,
		'25To29': 0,
		'20To24': 0,
		'15To19': 0,
		'10To14': 0,
		'5To9': 0,
		'0To4': 0,
	};

	// Filter out places that are not actively tracked
	const filteredPlaces = props.places.filter(
		(place) => !!place.like_percentage && !!place.total_visits
	);

	for (const place of filteredPlaces) {
		const likePercentage = place.like_percentage * 100;

		if (likePercentage >= 95) {
			placeLikeCounts['95To100']++;
			continue;
		}
		if (likePercentage >= 90) {
			placeLikeCounts['90To94']++;
			continue;
		}
		if (likePercentage >= 85) {
			placeLikeCounts['85To89']++;
			continue;
		}
		if (likePercentage >= 80) {
			placeLikeCounts['80To84']++;
			continue;
		}
		if (likePercentage >= 75) {
			placeLikeCounts['75To79']++;
			continue;
		}
		if (likePercentage >= 70) {
			placeLikeCounts['70To74']++;
			continue;
		}
		if (likePercentage >= 65) {
			placeLikeCounts['65To69']++;
			continue;
		}
		if (likePercentage >= 60) {
			placeLikeCounts['60To64']++;
			continue;
		}
		if (likePercentage >= 55) {
			placeLikeCounts['55To59']++;
			continue;
		}
		if (likePercentage >= 50) {
			placeLikeCounts['50To54']++;
			continue;
		}
		if (likePercentage >= 45) {
			placeLikeCounts['45To49']++;
			continue;
		}
		if (likePercentage >= 40) {
			placeLikeCounts['40To44']++;
			continue;
		}
		if (likePercentage >= 35) {
			placeLikeCounts['35To39']++;
			continue;
		}
		if (likePercentage >= 30) {
			placeLikeCounts['30To34']++;
			continue;
		}
		if (likePercentage >= 25) {
			placeLikeCounts['25To29']++;
			continue;
		}
		if (likePercentage >= 20) {
			placeLikeCounts['20To24']++;
			continue;
		}
		if (likePercentage >= 15) {
			placeLikeCounts['15To19']++;
			continue;
		}
		if (likePercentage >= 10) {
			placeLikeCounts['10To14']++;
			continue;
		}
		if (likePercentage >= 5) {
			placeLikeCounts['5To9']++;
			continue;
		}

		placeLikeCounts['0To4']++;
	}

	const data = Object.values(placeLikeCounts);

	const options: Highcharts.Options = {
		chart: {
			height: 700,
		},
		xAxis: {
			categories: [
				'95% - 100%',
				'90% - 94%',
				'85% - 89%',
				'80% - 84%',
				'75% - 79%',
				'70% - 74%',
				'65% - 69%',
				'60% - 64%',
				'55% - 59%',
				'50% - 54%',
				'45% - 49%',
				'40% - 44%',
				'35% - 39%',
				'30% - 34%',
				'25% - 29%',
				'20% - 24%',
				'15% - 19%',
				'10% - 14%',
				'5% - 9%',
				'0% - 4%',
			],
			title: {
				text: null,
			},
			gridLineWidth: 0,
			lineWidth: 0,
		},
		series: [
			{
				name: 'Experiences',
				type: 'bar',
				data,
				pointWidth: 20,
			},
		],
		rangeSelector: {
			enabled: false,
		},
		responsive: {
			rules: [
				{
					condition: {
						maxWidth: 480,
					},
					chartOptions: {
						series: [
							{
								type: 'bar',
								pointWidth: 6,
							},
						],
					},
				},
			],
		},
	};

	return (
		<div className={'h-full'}>
			<Chart
				title={'Like Rate of Actively Tracked'}
				highcharts={Highcharts}
				options={options}
				containerClassName={'h-full'}
				fillPanelResponsiveWidth
			/>
		</div>
	);
});

export default PlaceLikeDistribution;
