import { observer } from 'mobx-react-lite';
import Highcharts from 'highcharts';
import Chart from '@components/core/chart/chart.tsx';
import { useEffect, useState } from 'react';
import LoadingIndicator from '@components/core/loading-indicator/loading-indicator.tsx';
import GranularitySelector from '@components/data/granularity-selector/granularity-selector.tsx';
import ClassString from '@utils/class-string.ts';
import { DataGranularity } from '@/enums/data-granularity.ts';
import genreCCUStore from '@store/genre-ccu-store.ts';
import CapitalizeWords from '@utils/capitalize-words.ts';
import getGraphId from '@utils/get-graph-id.ts';
import hashQueryParamService from '@services/hash-query-param-service.ts';
import { isValidGranularity } from '@store/place-stats-store.ts';

const graphTitle = 'Average Genre CCU';

const getDefaultGranularity = () => {
	const hashParams = hashQueryParamService.get();
	if (
		hashParams.granularity &&
		hashParams.anchor === getGraphId(graphTitle) &&
		isValidGranularity(hashParams.granularity)
	) {
		const hashedGranularity = hashParams.granularity as DataGranularity;
		return hashedGranularity === DataGranularity.HOUR
			? DataGranularity.DAY
			: hashedGranularity;
	}

	return DataGranularity.DAY;
};

interface GenreCCUProps {
	ccuType: 'average' | 'total';
}

const GenreCCU = observer(function GenreCCU(props: GenreCCUProps) {
	const [granularity, setGranularity] = useState<
		DataGranularity.DAY | DataGranularity.WEEK | DataGranularity.MONTH
	>(getDefaultGranularity());
	const [loading, setLoading] = useState(true);
	const [initialized, setInitialized] = useState(false);

	const data = Object.entries(genreCCUStore.genreAverageCCU(granularity))
		.map(([key, value]) => {
			return { name: key, data: value };
		})
		.sort((a, b) => a.name.localeCompare(b.name));

	const noData =
		!loading &&
		(!data.length || data.every((genreData) => !genreData.data.length));

	if (!initialized && data.length) {
		setInitialized(true);
		setLoading(false);
	}

	const yMin = undefined;
	const yMax = undefined;

	const series: Highcharts.SeriesOptionsType[] = data.map((genreCCU) => {
		return {
			name: genreCCU.name,
			type: 'line',
			showInLegend: true,
			data: genreCCU.data ?? [],
			marker: {
				enabled: false,
			},
			lineWidth: 2,
		};
	});

	let xMin = 0;
	let xMax = 0;
	data.forEach((pd) => {
		pd.data.forEach((d) => {
			const date = d[0];

			if (xMin === 0) {
				xMin = date;
			}

			if (xMax === 0) {
				xMax = date;
			}

			if (date < xMin) {
				xMin = date;
			}

			if (date > xMax) {
				xMax = date;
			}
		});
	});

	const options: Highcharts.Options = {
		series,
		xAxis: {
			type: 'datetime',
			min: xMin,
			max: xMax,
		},
		chart: {
			height: 600,
			marginLeft: 40,
		},
		yAxis: {
			title: {
				enabled: false,
			} as Highcharts.YAxisOptions,
			labels: {
				x: -10,
				y: 3,
				reserveSpace: false,
				style: {
					whiteSpace: 'nowrap',
				},
			},
			min: yMin,
			max: yMax,
		},
		plotOptions: {
			line: {
				marker: {
					enabled: false,
				},
			},
			series: {
				states: {
					hover: {
						enabled: true,
						lineWidth: 4,
					},
				},
			},
		},
		navigator: {
			enabled: true,
			xAxis: {
				min: xMin,
				max: xMax,
			},
		},
		scrollbar: {
			enabled: true,
			liveRedraw: true,
		},
		rangeSelector: {
			enabled: true,
			selected: 3,
		},
		tooltip: {
			shared: true,
			split: false,
			distance: 10,
			snap: 8,
			formatter: function () {
				if (!this.points) {
					return;
				}

				const points = this.points.slice().sort((a, b) => {
					const bY = b.y ?? 0;
					const aY = a.y ?? 0;
					return bY - aY;
				});

				const formattedDate = Highcharts.dateFormat(
					'%A, %e %b, %H:%M',
					this.x ? +this.x : 0
				);

				let tooltipHtml = `${formattedDate}<br/>`;
				points.forEach((point) => {
					tooltipHtml += `<span style="color:${point.color as string}">\u25CF</span> ${point.series.name}: <b>${point.y}</b><br/>`;
				});
				return tooltipHtml;
			},
		},
	};

	const updateGranularity = async (g: DataGranularity) => {
		if (g === DataGranularity.HOUR) {
			return;
		}

		setLoading(true);
		setGranularity(g);
		await genreCCUStore.loadGenreCCU(g);
		setLoading(false);
	};

	useEffect(() => {
		void genreCCUStore.loadGenreCCU(granularity);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div>
			<div className={'relative'}>
				<div
					className={ClassString({
						static: 'transition-opacity',
						dynamic: { 'opacity-0': loading && !initialized },
					})}
				>
					<Chart
						title={CapitalizeWords(
							`${props.ccuType} Genre CCU (Top Tracked Games)`
						)}
						highcharts={Highcharts}
						options={options}
						granularity={granularity}
						fillPanelResponsiveWidth
						overrideFullscreenHeight={700}
					/>
				</div>

				{loading && (
					<div
						className={
							'absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
						}
					>
						<LoadingIndicator />
					</div>
				)}
				{noData && (
					<div
						className={
							'absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
						}
					>
						No Data
					</div>
				)}
			</div>

			<GranularitySelector
				updateGranularity={updateGranularity}
				selectedGranularity={granularity}
				availableGranularities={[
					DataGranularity.DAY,
					DataGranularity.WEEK,
					DataGranularity.MONTH,
				]}
			/>
		</div>
	);
});

export default GenreCCU;
