import { observer } from 'mobx-react-lite';
import Button from '@components/core/button/button.tsx';
import { DataGranularity } from '@/enums/data-granularity.ts';
import useScreenBreakpoint from '@hooks/use-screen-breakpoint.tsx';
import { ScreenBreakpoint } from '@/enums/screen-breakpoints.ts';

interface GranularitySelectorPros {
	updateGranularity: (granularity: DataGranularity) => unknown;
	selectedGranularity: DataGranularity;
	availableGranularities?: DataGranularity[];
}

const GranularitySelector = observer(function GranularitySelector(
	props: GranularitySelectorPros
) {
	const screenBreakpoint = useScreenBreakpoint();

	const availableGranularities = props.availableGranularities?.length
		? props.availableGranularities
		: Object.values(DataGranularity);

	return (
		<div className={'flex items-center gap-4'}>
			{availableGranularities.includes(DataGranularity.HOUR) && (
				<Button
					title={
						screenBreakpoint <= ScreenBreakpoint.SM
							? 'H.'
							: 'Hourly'
					}
					onClick={() => {
						props.updateGranularity(DataGranularity.HOUR);
					}}
					selected={
						props.selectedGranularity === DataGranularity.HOUR
					}
					small={screenBreakpoint <= ScreenBreakpoint.SM}
				/>
			)}

			{availableGranularities.includes(DataGranularity.DAY) && (
				<Button
					title={
						screenBreakpoint <= ScreenBreakpoint.SM ? 'D.' : 'Daily'
					}
					onClick={() => {
						props.updateGranularity(DataGranularity.DAY);
					}}
					selected={props.selectedGranularity === DataGranularity.DAY}
					small={screenBreakpoint <= ScreenBreakpoint.SM}
				/>
			)}

			{availableGranularities.includes(DataGranularity.WEEK) && (
				<Button
					title={
						screenBreakpoint <= ScreenBreakpoint.SM
							? 'W.'
							: 'Weekly'
					}
					onClick={() => {
						props.updateGranularity(DataGranularity.WEEK);
					}}
					selected={
						props.selectedGranularity === DataGranularity.WEEK
					}
					small={screenBreakpoint <= ScreenBreakpoint.SM}
				/>
			)}

			{availableGranularities.includes(DataGranularity.MONTH) && (
				<Button
					title={
						screenBreakpoint <= ScreenBreakpoint.SM
							? 'M.'
							: 'Monthly'
					}
					onClick={() => {
						props.updateGranularity(DataGranularity.MONTH);
					}}
					selected={
						props.selectedGranularity === DataGranularity.MONTH
					}
					small={screenBreakpoint <= ScreenBreakpoint.SM}
				/>
			)}
		</div>
	);
});

export default GranularitySelector;
