import placeTagStore from '@store/place-tag-store.ts';
import compoundThumbnailStore from '@store/compound-thumbnail-store.ts';

export async function ExperienceTagsLoader() {
	if (!placeTagStore.initialized) {
		await placeTagStore.waitForInit();
	} else {
		void placeTagStore.loadPlaceTags();
	}

	const experienceTags = placeTagStore.placeTags;
	void compoundThumbnailStore.compoundTagThumbnails(experienceTags);

	return experienceTags;
}
