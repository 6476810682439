import { observer } from 'mobx-react-lite';
import RadioButton from '@components/core/radio-button/radio-button.tsx';
import { useEffect, useState } from 'react';

interface UserOnboardingTypeProps {
	onChange: (userType: string) => unknown;
	error?: boolean;
}

const UserOnboardingType = observer(function UserOnboardingType(
	props: UserOnboardingTypeProps
) {
	const [selectedType, setSelectedType] = useState('');

	useEffect(() => {
		props.onChange(selectedType);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedType]);

	return (
		<div
			className={
				'flex flex-col items-center gap-2 my-4 md:col-span-2 w-fit mx-auto'
			}
		>
			<div className={'text-lg font-semibold w-full'}>
				Which role describes you best?
			</div>

			<div className={'grid grid-cols-2 md:grid-cols-4 gap-x-4 gap-y-2'}>
				<div
					className={
						'flex justify-start items-center h-8 overflow-y-visible'
					}
				>
					<RadioButton
						label={'Student'}
						name={'user-type'}
						value={'student'}
						checked={selectedType === 'student'}
						onChange={setSelectedType}
						error={props.error}
					/>
				</div>

				<div
					className={
						'flex justify-start items-center h-8 overflow-y-visible'
					}
				>
					<RadioButton
						label={'Player'}
						name={'user-type'}
						value={'player'}
						checked={selectedType === 'player'}
						onChange={setSelectedType}
						error={props.error}
					/>
				</div>

				<div
					className={
						'flex justify-start items-center h-8 overflow-y-visible'
					}
				>
					<RadioButton
						label={'Developer'}
						name={'user-type'}
						value={'developer'}
						checked={selectedType === 'developer'}
						onChange={setSelectedType}
						error={props.error}
					/>
				</div>

				<div
					className={
						'flex justify-start items-center h-8 overflow-y-visible'
					}
				>
					<RadioButton
						label={'Producer'}
						name={'user-type'}
						value={'producer'}
						checked={selectedType === 'producer'}
						onChange={setSelectedType}
						error={props.error}
					/>
				</div>

				<div
					className={
						'flex justify-start items-center h-8 overflow-y-visible'
					}
				>
					<RadioButton
						label={'Data Analyst'}
						name={'user-type'}
						value={'data_analyst'}
						checked={selectedType === 'data_analyst'}
						onChange={setSelectedType}
						error={props.error}
					/>
				</div>

				<div
					className={
						'flex justify-start items-center h-8 overflow-y-visible'
					}
				>
					<RadioButton
						label={'Business Developer'}
						name={'user-type'}
						value={'business_developer'}
						checked={selectedType === 'business_developer'}
						onChange={setSelectedType}
						error={props.error}
					/>
				</div>

				<div
					className={
						'flex justify-start items-center h-8 overflow-y-visible'
					}
				>
					<RadioButton
						label={'Marketing Specialist'}
						name={'user-type'}
						value={'marketing_specialist'}
						checked={selectedType === 'marketing_specialist'}
						onChange={setSelectedType}
						error={props.error}
					/>
				</div>

				<div
					className={
						'flex justify-start items-center h-8 overflow-y-visible'
					}
				>
					<RadioButton
						label={'Journalist'}
						name={'user-type'}
						value={'journalist'}
						checked={selectedType === 'journalist'}
						onChange={setSelectedType}
						error={props.error}
					/>
				</div>
			</div>
		</div>
	);
});

export default UserOnboardingType;
