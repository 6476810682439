import { observer } from 'mobx-react-lite';
import ClassString from '@utils/class-string.ts';

interface RadioButtonProps {
	label: string;
	name: string;
	value: string;
	checked: boolean;
	onChange: (value: string) => void;
	error?: boolean;
}

const RadioButton = observer(function RadioButton(props: RadioButtonProps) {
	const handleRadioChange = () => {
		props.onChange(props.value);
	};

	return (
		<label className="flex justify-center items-center cursor-pointer select-none group">
			<input
				type="radio"
				name={props.name}
				checked={props.checked}
				onChange={handleRadioChange}
				className="peer w-0 h-0 opacity-0"
			/>
			<div
				className={ClassString({
					static: 'w-6 h-6 min-w-6 min-h-6 rounded-full mr-2 flex justify-center items-center transition-all duration-100 outline-1 outline-gray-600 dark:outline-gray-500 group-hover:outline-blue-300 peer-focus:outline-blue-300 relative',
					dynamic: {
						'outline-blue-300 dark:outline-blue-300 outline-2':
							props.checked,
						'outline-red-525 dark:outline-red-400 outline-2 ':
							props.error,
					},
				})}
			>
				<div
					className={ClassString({
						static: 'w-0 h-0 bg-transparent rounded-full transition-all absolute',
						dynamic: {
							'w-3 h-3 bg-blue-300': props.checked,
						},
					})}
				/>
			</div>
			{props.label}
		</label>
	);
});

export default RadioButton;
