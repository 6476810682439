import { observer } from 'mobx-react-lite';
import experienceRankingStore from '@store/experience-ranking-store.ts';
import Highcharts from 'highcharts';
import BrandColors from '@styles/_brand_colors.ts';
import Chart from '@components/core/chart/chart.tsx';
import { useEffect, useState } from 'react';
import LoadingIndicator from '@components/core/loading-indicator/loading-indicator.tsx';
import themeStore from '@store/theme-store.ts';
import setHashExtremes from '@components/core/chart/chart-set-hash-extremes.tsx';
import { ExperienceRanking } from '@/schemas/experience-rating.ts';
import { useNavigate } from 'react-router';
import {
	GetRankingDescriptionByTitle,
	GetRankingIndexByTitle,
	RankingCategories,
} from '@/enums/ranking-categories.ts';
import authStore from '@store/auth-store.ts';

interface CustomChart extends Highcharts.Chart {
	customTooltip: CustomTooltip;
}

interface CustomTooltip extends Highcharts.SVGElement {
	height: number;
}

interface CustomLabel extends Highcharts.SVGElement {
	textStr: string;
	xy: {
		opacity: number;
		x: number;
		y: number;
	};
}

interface RankingPoint extends Highcharts.Point {
	yCategory: string;
	start: number;
	end: number;
}

interface ExperienceDetailsRankingProps {
	placeId: number;
}

interface RankingData {
	start: number;
	end: number;
	name: string;
	y: number;
}

const chartTitle = 'Chart Rankings';

const ExperienceDetailsRanking = observer(function ExperienceDetailsRanking(
	props: ExperienceDetailsRankingProps
) {
	const [loading, setLoading] = useState(true);
	const [experienceRankings, setExperienceRankings] = useState<
		ExperienceRanking[]
	>([]);

	const navigate = useNavigate();

	useEffect(() => {
		if (experienceRankingStore.loading) {
			return;
		}

		const rankings = experienceRankingStore.experienceRating(props.placeId);

		if (rankings) {
			setExperienceRankings(rankings);
			setLoading(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.placeId, experienceRankingStore.loading]);

	useEffect(() => {
		void experienceRankingStore.loadExperienceRankings(props.placeId);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	let data;
	const seenY: number[] = [];

	if (experienceRankings) {
		experienceRankings.forEach((ranking) => {
			if (!seenY.includes(ranking.y)) {
				seenY.push(ranking.y);
			}
		});
		seenY.sort((a, b) => a - b);
		data = experienceRankings.map((ranking) => {
			return {
				...ranking,
				start: ranking.start.getTime(),
				end: ranking.end.getTime(),
				y: seenY.indexOf(ranking.y),
			};
		});
		seenY.sort((a, b) => a - b);
	}

	const categorizedData: Record<number, RankingData[]> = {};
	if (data) {
		data.forEach((d: RankingData) => {
			const yValue = d.y;
			if (!categorizedData[yValue]) {
				categorizedData[yValue] = [];
			}
			categorizedData[yValue].push(d);
		});
	}

	const series: Highcharts.SeriesOptionsType[] = Object.entries(
		categorizedData
	).map(([n, d]) => {
		const title = RankingCategories[seenY[+n]]?.title ?? 'Chart';
		return {
			name: `${title} Ranking`,
			type: 'gantt',
			data: d,
			borderWidth: 1,
			dataLabels: {
				enabled: true,
				formatter: function () {
					const chart = this.series.chart;
					if (!chart.xAxis[0]) {
						return '';
					}

					const xAxis = chart.xAxis[0] as unknown as {
						max: number;
						min: number;
					};
					const visibleRange = xAxis.max - xAxis.min; // Current zoom range in milliseconds
					const point = this.point as unknown as {
						end: number;
						start: number;
					};
					const duration = point.end - point.start;

					let threshold = 60 * 60 * 1000;

					if (visibleRange > 40 * 24 * 60 * 60 * 1000) {
						threshold = 24 * 60 * 60 * 1000;
					} else if (visibleRange > 7 * 24 * 60 * 60 * 1000) {
						threshold = 3 * 60 * 60 * 1000;
					}

					if (duration < threshold) {
						return ''; // Hide short task labels when zoomed out
					}
					return this.point.name;
				},
			},
			point: {
				events: {
					click: function (event) {
						const point = event.point as RankingPoint;

						const difference = point.end - point.start;
						const time = point.end - difference / 2;
						const rankingName = point.yCategory;
						const ranking = GetRankingIndexByTitle(rankingName);

						void navigate(
							`/rankings/${ranking}#time=${time}&exp=${props.placeId}`
						);
					},
				},
			},
			boost: {
				enabled: true,
				useGPUTranslations: true,
				pixelRatio: 0,
				// Chart-level boost when there are more than 5 series in the chart
				seriesThreshold: 5,
				usePreallocated: true,
			},
			cursor: 'pointer',
		};
	});

	const categories = seenY.map((y) => {
		return RankingCategories[y]?.title ?? 'Unknown Category';
	});

	const navigatorData: RankingData[] = [];

	Object.values(categorizedData).forEach((dataArray) => {
		dataArray.forEach((d) => {
			if (
				!navigatorData.length ||
				navigatorData[navigatorData.length - 1].y !== d.y
			) {
				navigatorData.push(d);
			}

			const dateDifference =
				d.start - navigatorData[navigatorData.length - 1].end;
			if (dateDifference < 3600000) {
				navigatorData[navigatorData.length - 1] = {
					...navigatorData[navigatorData.length - 1],
					end: d.end,
				};
			} else {
				navigatorData.push(d);
			}
		});
	});

	const options: Highcharts.Options = {
		xAxis: {
			type: 'datetime',
		},
		yAxis: {
			title: {
				text: '',
			},
			categories,
			min: 0,
			max: seenY.length - 1,
			labels: {
				useHTML: true,
				formatter: function () {
					return `<div class="gantt-custom-label" style="width: 60px; white-space: normal; word-wrap: break-word; text-align: center;">
                  		${this.value}
             		</div>`;
				},
			},
		},
		series,
		plotOptions: {
			series: {
				boostThreshold: 200,
				animation: false,
				shadow: false,
				dataLabels: {
					defer: true,
				},
			},
		},
		scrollbar: {
			enabled: true,
			liveRedraw: true,
			barBackgroundColor: BrandColors.gray400,
		},
		navigator: {
			enabled: true,
			series: {
				type: 'gantt',
				data: navigatorData,
			},
			yAxis: {
				min: 0,
				max: seenY.length - 1,
				reversed: true,
			},
			height: Math.max(30, seenY.length * 13),
		},
		chart: {
			events: {
				load: function () {
					setHashExtremes(this, chartTitle);

					const chart = this as CustomChart;
					const yTicks = chart.yAxis[0] ? chart.yAxis[0].ticks : [];

					chart.customTooltip = chart.renderer
						.label('custom tooltip', 0, 0, 'callout')
						.attr({
							zIndex: 12,
							fill: themeStore.lightTheme
								? BrandColors.white
								: BrandColors.gray525,
							'stroke-width': 1,
							stroke: themeStore.lightTheme
								? BrandColors.gray600
								: BrandColors.gray500,
							padding: 12,
							r: 6,
						})
						.css({
							color: themeStore.lightTheme
								? BrandColors.gray600
								: BrandColors.white,
							pointerEvents: 'none',
							width: window.innerWidth <= 600 ? 250 : 400,
						})
						.add()
						.hide() as CustomTooltip;

					Object.values(yTicks).forEach((tick) => {
						const label = tick.label as CustomLabel;
						if (!label) return;

						const labelElement = label.element
							.firstElementChild as HTMLDivElement;
						if (!labelElement) return;

						const labelHeight =
							labelElement.getBoundingClientRect().height;

						labelElement.addEventListener('mouseover', () => {
							const rect = labelElement.getBoundingClientRect();
							const chartPos =
								chart.pointer.getChartPosition() || {
									left: 0,
									top: 0,
								};

							const x = rect.left - chartPos.left;
							const y = rect.top - chartPos.top;

							const tooltipHeight =
								chart.customTooltip.height || 30;

							chart.customTooltip
								.attr({
									text: `${GetRankingDescriptionByTitle(labelElement.innerText)}`,
									x: x + 70, // Adjust tooltip position horizontally
									y:
										y -
										labelHeight / 4 -
										tooltipHeight / 2 +
										22, // Adjust vertically
									anchorX: x,
									anchorY: y - labelHeight / 4 + 22,
								})
								.show();
						});

						labelElement.addEventListener('mouseout', () => {
							chart.customTooltip.hide();
						});
					});
				},
			},
		},
	};

	return (
		<>
			{loading && (
				<div className={'h-32 flex justify-center items-center'}>
					<LoadingIndicator />
				</div>
			)}

			{!loading && !experienceRankings?.length && (
				<span className={'h-32 flex justify-center items-center'}>
					{`The experience has not appeared in any Roblox chart category${authStore.isLoggedIn ? '' : ' recently'}.`}
				</span>
			)}

			{!loading && !!experienceRankings?.length && (
				<Chart
					title={chartTitle}
					highcharts={Highcharts}
					constructorType={'ganttChart'}
					options={options}
					disableResponsiveHeight
					fillPanelResponsiveWidth
				/>
			)}
		</>
	);
});

export default ExperienceDetailsRanking;
