import './chart.css';
import { observer } from 'mobx-react-lite';
import Highcharts, {
	AxisSetExtremesEventObject,
	RangeSelectorButtonsOptions,
} from 'highcharts';
import HighchartsReact, {
	HighchartsReactProps,
	HighchartsReactRefObject,
} from 'highcharts-react-official';
import ChartColors from '@components/core/chart/chart-colors.ts';
import highchartsBoost from 'highcharts/modules/boost';
import highchartsGantt from 'highcharts/modules/gantt';
import highchartsStock from 'highcharts/modules/stock';
import highchartsMore from 'highcharts/highcharts-more';
import highchartsAnnotations from 'highcharts/modules/annotations';
import themeStore from '@store/theme-store.ts';
import BrandColors from '@styles/_brand_colors.ts';
import extendObject from '@utils/extend-object.ts';
import { useContext, useEffect, useRef } from 'react';
import ClassString from '@utils/class-string.ts';
import Icon from '@components/core/icon/icon.tsx';
import { IconEnum } from '@components/core/icon/icon-enum.ts';
import copyService from '@services/copy-service.ts';
import viewStore from '@store/view-store.ts';
import anchorService from '@services/anchor-service.ts';
import ChartOutline from '@components/core/chart/chart-outline.tsx';
import getGraphId from '@utils/get-graph-id.ts';
import { DataGranularity } from '@/enums/data-granularity.ts';
import setHashExtremes from '@components/core/chart/chart-set-hash-extremes.tsx';
import { HashParamMap } from '@/enums/hash-params.ts';
import modalStore from '@store/modal-store.ts';
import Tooltip from '@components/core/tooltip/tooltip.tsx';
import isMobile from '@utils/is-mobile.ts';
import useWaitingToScrollToChart from '@hooks/use-waiting-to-scroll-to-chart.tsx';
import hexToRGBA from '@utils/hex-to-rgba.ts';
import * as _ from 'lodash';
import authStore from '@store/auth-store.ts';
import { CHART_RANGE } from '@components/core/chart/chart-range.ts';
import {
	ChartRangeDefinition,
	ChartRangeDefinitionLoggedOut,
} from '@components/core/chart/chart-range-definition.tsx';
import TheGangBlackSvg from '@assets/square_logo_black.svg';
import TheGangWhiteSvg from '@assets/square_logo_white.svg';
import { ViewTabPanelContext } from '@components/view/view-tab-panel-context.tsx';
import hashQueryParamService from '@services/hash-query-param-service.ts';
import { autorun } from 'mobx';

highchartsBoost(Highcharts);
highchartsGantt(Highcharts);
highchartsStock(Highcharts);
highchartsMore(Highcharts);
highchartsAnnotations(Highcharts);

const defaultOptions: Highcharts.Options = {
	title: {
		text: undefined,
	},
	accessibility: {
		enabled: false,
	},
	credits: {
		enabled: false,
	},
	time: {
		useUTC: false,
	},
	tooltip: {
		outside: true,
		style: {
			zIndex: 100,
		},
	},
	chart: {
		style: {
			fontFamily: 'Inter',
		},
		zooming: {
			type: 'x',
			mouseWheel: false,
		},
		panning: {
			enabled: true,
			type: 'x',
		},
		panKey: 'shift',
		backgroundColor: 'rgba(0, 0, 0, 0)',
	},
	legend: {
		itemStyle: {
			fontSize: '14px',
		},
	},
	xAxis: {
		labels: {
			style: {
				color: themeStore.lightTheme
					? BrandColors.gray600
					: BrandColors.white,
			},
		},
	},
	rangeSelector: {
		enabled: true,
		selected: 2,
		buttonSpacing: 10,
		buttonTheme: {
			r: 11,
			fill: 'none',
			'stroke-width': 1,
			padding: 3,
			height: 16,
			width: 30,
			style: {
				fontSize: '12',
			},
			states: {
				hover: {
					fill: BrandColors.blue300,
					style: {
						color: BrandColors.gray600,
					},
				},
				select: {
					fill: BrandColors.gray600,
					style: {
						color: BrandColors.white,
					},
				},
			},
		},
		inputEnabled: true,
		inputStyle: {
			color: themeStore.lightTheme
				? BrandColors.gray600
				: BrandColors.white,
			backgroundColor: themeStore.lightTheme
				? BrandColors.white
				: BrandColors.gray500,
		},
		labelStyle: {
			color: themeStore.lightTheme
				? BrandColors.gray600
				: BrandColors.white,
			fontSize: '1rem',
			display: 'none',
		},
	},
};

Highcharts.setOptions(defaultOptions);

const waterMarkFadeThreshold = { min: '4%', max: '96%' };

interface ChartProps extends HighchartsReactProps {
	title: string;
	granularity?: DataGranularity;
	onLoad?: (chart: Highcharts.Chart) => unknown;
	containerClassName?: string;
	disableResponsiveHeight?: boolean;
	disableShareButton?: boolean;
	fillPanelResponsiveWidth?: boolean;
	ranges?: CHART_RANGE[];
	info?: string;
	fullHeightInFullScreen?: boolean;
	overrideFullscreenHeight?: number;
}

const Chart = observer(function Chart(props: ChartProps) {
	const xExtremes = useRef<{ min: number; max: number } | undefined>(
		undefined
	);

	const buttonGroupRef = useRef<HTMLDivElement>(null);
	const chartContainer = useRef<HTMLDivElement>(null);
	const chart = useRef<Highcharts.Chart | undefined>();

	const highchartsRef = useRef<HighchartsReactRefObject | null>(null);

	const chartOptions = {
		...props.options,
	};

	const rangeSelectorButtons: RangeSelectorButtonsOptions[] = props.ranges
		? props.ranges.map((range) => {
				return authStore.isLoggedIn
					? ChartRangeDefinition[range]
					: ChartRangeDefinitionLoggedOut[range];
			})
		: Object.values(CHART_RANGE).map((range: CHART_RANGE) => {
				return authStore.isLoggedIn
					? ChartRangeDefinition[range]
					: ChartRangeDefinitionLoggedOut[range];
			});

	extendObject(
		chartOptions,
		['chart', 'events', 'load'],
		(event: { target: Highcharts.Chart }) => {
			setHashExtremes(event.target, props.title);
		}
	);

	extendObject(
		chartOptions,
		['rangeSelector', 'buttons'],
		rangeSelectorButtons
	);

	extendObject(chartOptions, ['colors'], ChartColors());

	extendObject(
		chartOptions,
		['chart', 'selectionMarkerFill'],
		hexToRGBA(BrandColors.blue300, 0.3)
	);

	extendObject(
		chartOptions,
		['title', 'style', 'color'],
		themeStore.lightTheme ? BrandColors.gray600 : BrandColors.white
	);

	extendObject(
		chartOptions,
		['yAxis', 'labels', 'style', 'color'],
		themeStore.lightTheme ? BrandColors.gray600 : BrandColors.white
	);

	extendObject(
		chartOptions,
		['yAxis', 'gridLineColor'],
		themeStore.lightTheme ? BrandColors.gray200 : BrandColors.gray300
	);

	extendObject(
		chartOptions,
		['xAxis', 'labels', 'style', 'color'],
		themeStore.lightTheme ? BrandColors.gray600 : BrandColors.white
	);

	extendObject(
		chartOptions,
		['legend', 'itemStyle', 'color'],
		themeStore.lightTheme ? BrandColors.gray600 : BrandColors.white
	);

	extendObject(
		chartOptions,
		['legend', 'itemHoverStyle', 'color'],
		BrandColors.blue300
	);

	extendObject(
		chartOptions,
		['rangeSelector', 'buttonTheme', 'style', 'color'],
		themeStore.lightTheme ? BrandColors.gray600 : BrandColors.white
	);

	extendObject(
		chartOptions,
		['rangeSelector', 'buttonTheme', 'stroke'],
		themeStore.lightTheme ? BrandColors.gray600 : BrandColors.white
	);

	extendObject(
		chartOptions,
		[
			'rangeSelector',
			'buttonTheme',
			'states',
			'disabled',
			'style',
			'color',
		],
		themeStore.lightTheme ? BrandColors.gray400 : BrandColors.gray500
	);

	extendObject(
		chartOptions,
		['rangeSelector', 'buttonTheme', 'states', 'select', 'fill'],
		BrandColors.gray600
	);

	extendObject(
		chartOptions,
		['xAxis', 'events', 'setExtremes'],
		(extremes: AxisSetExtremesEventObject) => {
			xExtremes.current = { min: extremes.min, max: extremes.max };
		}
	);

	extendObject(chartOptions, ['chart', 'events', 'render'], (draw: Event) => {
		chart.current = draw.target as unknown as Highcharts.Chart;
	});

	if (
		props.options?.xAxis &&
		!Array.isArray(props.options?.xAxis) &&
		props.options.xAxis.type === 'datetime'
	) {
		extendObject(chartOptions, ['xAxis', 'minRange'], 86400000); // Set default min range to 1 day
	}

	const mobileResponsiveRules = {
		condition: {
			maxWidth: 480,
		},
		chartOptions: {
			chart: {
				zoomKey: 'shift',
				panKey: undefined,
			},
			legend: {
				enabled: false, // Hide the legend
			},
			navigator: {
				enabled: false,
			},
			tooltip: {
				followTouchMove: false,
			},
		},
	};

	if (!props.disableResponsiveHeight) {
		extendObject(
			mobileResponsiveRules,
			['chartOptions', 'chart', 'height'],
			300
		);
	}

	const responsiveRules: Highcharts.ResponsiveRulesOptions[] = [
		mobileResponsiveRules as Highcharts.ResponsiveRulesOptions,
	];

	if (chartOptions.responsive?.rules) {
		chartOptions.responsive.rules.push(...responsiveRules);
	} else {
		extendObject(chartOptions, ['responsive', 'rules'], responsiveRules);
	}

	useEffect(() => {
		if (chart.current && xExtremes.current) {
			chart.current.xAxis.forEach((axis) => {
				axis.setExtremes(
					xExtremes.current?.min,
					xExtremes.current?.max,
					true,
					false
				);
			});
		}
	}, [props.options]);

	const tab = useContext(ViewTabPanelContext);

	const waitingToScroll = useWaitingToScrollToChart(props.title);

	useEffect(() => {
		const disposer = autorun(() => {
			const anchor = hashQueryParamService.get().anchor;
			if (anchor !== getGraphId(props.title)) {
				return;
			}

			if (!viewStore.loadingViewData && !waitingToScroll) {
				const graphId = getGraphId(props.title);
				requestAnimationFrame(() => {
					anchorService.scrollTo(graphId);
				});
			}
		});

		return () => disposer();
	}, [props.title, waitingToScroll]);

	// Disable vertical scrolling when scrolling a graph
	useEffect(() => {
		if (!chartContainer.current) return;

		let startX = 0;
		let startY = 0;

		const handleTouchStart = (e: Event) => {
			const event = e as TouchEvent;
			startX = event.touches[0].clientX;
			startY = event.touches[0].clientY;
		};

		const handleTouchMove = (e: Event) => {
			const event = e as TouchEvent;
			const deltaX = Math.abs(event.touches[0].clientX - startX);
			const deltaY = Math.abs(event.touches[0].clientY - startY);

			// If horizontal movement is greater than vertical movement, prevent vertical scroll
			if (Math.abs(deltaX) * 1.75 > Math.abs(deltaY)) {
				event.preventDefault();
			}
		};

		chartContainer.current.addEventListener(
			'touchstart',
			handleTouchStart,
			{
				passive: false,
			}
		);
		chartContainer.current.addEventListener('touchmove', handleTouchMove, {
			passive: false,
		});

		return () => {
			chartContainer.current?.removeEventListener(
				'touchstart',
				handleTouchStart
			);
			// eslint-disable-next-line react-hooks/exhaustive-deps
			chartContainer.current?.removeEventListener(
				'touchmove',
				handleTouchMove
			);
		};
	}, []);

	const modalChart = () => {
		const type = chartOptions.series?.[0]?.type;
		const increasedHeight =
			type === 'line' ||
			type === 'area' ||
			type === 'column' ||
			type === 'bar';

		return (
			<div
				className={ClassString({
					static: 'flex flex-col justify-center items-center w-full graph-modal relative',
					dynamic: {
						'graph-increased-height':
							increasedHeight && !props.overrideFullscreenHeight,
					},
					custom: props.containerClassName,
				})}
				style={{ height: props.overrideFullscreenHeight }}
				id={getGraphId(props.title)}
			>
				<div
					className={'w-full flex justify-between items-center gap-2'}
				>
					<img
						src={
							themeStore.lightTheme
								? TheGangBlackSvg
								: TheGangWhiteSvg
						}
						alt={''}
						className={'w-12 h-12'}
					/>

					<div className={'text-base flex-1 md:truncate text-center'}>
						{props.title}
					</div>
					<div
						className={
							'flex justify-end items-center gap-2 z-in-front w-12'
						}
					>
						<Icon
							className={
								'hover:bg-blue-300 hover:text-gray-600 p-1 pointer-events-auto rounded-md cursor-pointer'
							}
							icon={IconEnum.CLEAR}
							size={'2.5rem'}
							onClick={() => {
								modalStore.close();
							}}
						/>
					</div>
				</div>

				<div
					className={
						'absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 overflow-hidden w-full h-full'
					}
					style={{
						width: props.fillPanelResponsiveWidth
							? 'calc(100% + 2rem)'
							: undefined,

						maskImage: `linear-gradient(to right, transparent, black ${waterMarkFadeThreshold.min}, black ${waterMarkFadeThreshold.max}, transparent), linear-gradient(to bottom, transparent, black ${waterMarkFadeThreshold.min}, black ${waterMarkFadeThreshold.max}, transparent)`,

						WebkitMaskImage: `linear-gradient(to right, transparent, black ${waterMarkFadeThreshold.min} , black ${waterMarkFadeThreshold.max}, transparent), linear-gradient(to bottom, transparent, black ${waterMarkFadeThreshold.min}, black ${waterMarkFadeThreshold.max}, transparent)`,

						maskComposite: 'intersect',

						WebkitMaskComposite: 'destination-in',
					}}
				>
					<div
						className={ClassString({
							static: 'w-full h-full',
							dynamic: {
								'bg-watermark-black opacity-1.5':
									themeStore.lightTheme,

								'bg-watermark-white opacity-1.5':
									themeStore.darkTheme,
							},
						})}
					></div>
				</div>

				<HighchartsReact
					containerProps={{
						className: ClassString({
							dynamic: {
								'highcharts-light': themeStore.lightTheme,
								'highcharts-dark': themeStore.darkTheme,
							},
						}),
					}}
					highcharts={{
						...Highcharts,
						...props.highcharts,
					}}
					options={
						props.fullHeightInFullScreen
							? _.merge({}, chartOptions, {
									chart: { height: window.innerHeight * 0.9 },
								})
							: chartOptions
					}
					constructorType={props.constructorType}
				/>
			</div>
		);
	};

	return (
		<>
			<div
				className={ClassString({
					static: 'w-full',
					dynamic: {},
					custom: props.containerClassName,
				})}
				id={getGraphId(props.title)}
				ref={chartContainer}
			>
				<div
					className={
						'w-full flex flex-col justify-center items-center'
					}
				>
					<div
						className={
							'w-full flex justify-between items-center gap-2'
						}
					>
						<div
							style={{
								width: buttonGroupRef.current?.offsetWidth,
							}}
						>
							<img
								src={
									themeStore.lightTheme
										? TheGangBlackSvg
										: TheGangWhiteSvg
								}
								alt={''}
								className={'w-12 h-12'}
							/>
						</div>
						<div
							className={
								'sm:text-2xl flex-1 text-center max-h-14'
							}
						>
							{props.title}
						</div>
						<div
							className={
								'flex justify-center items-center gap-2 z-in-front'
							}
							ref={buttonGroupRef}
						>
							{!!props.info && (
								<Tooltip
									text={props.info}
									direction={'left'}
									fullWidth
								>
									<Icon
										icon={IconEnum.ROUND_INFO}
										className={
											'cursor-help hover:bg-blue-300 hover:text-gray-600 p-1 pointer-events-auto rounded-md z-in-front'
										}
										size={'2.5rem'}
									/>
								</Tooltip>
							)}

							{!props.disableShareButton && (
								<>
									<Tooltip
										text={
											'Share an anchor link to the exact moment in the graph with the selected zoom and granularity.'
										}
										direction={'left'}
										fullWidth
									>
										<Icon
											className={
												'hover:bg-blue-300 hover:text-gray-600 p-2 pointer-events-auto rounded-md cursor-pointer z-in-front'
											}
											icon={IconEnum.SHARE}
											size={'2.5rem'}
											onClick={() => {
												if (chart.current) {
													const extraParams: HashParamMap =
														{};

													const extremes =
														chart.current.xAxis[0].getExtremes();

													if (
														extremes.min &&
														extremes.max
													) {
														extraParams.graphMin =
															extremes.min.toString();
														extraParams.graphMax =
															extremes.max.toString();
													}

													if (props.granularity) {
														extraParams.granularity =
															props.granularity;
													}

													if (tab) {
														extraParams.tab = tab;
													}

													copyService.anchorLink(
														getGraphId(props.title),
														extraParams
													);
												}
											}}
										/>
									</Tooltip>
								</>
							)}

							{!isMobile && (
								<Icon
									className={
										'hover:bg-blue-300 hover:text-gray-600 p-1 pointer-events-auto rounded-md cursor-pointer'
									}
									icon={IconEnum.FULLSCREEN}
									size={'2.5rem'}
									onClick={() => {
										modalStore.open(modalChart, {
											className: `sm:w-[98vw] ${props.fullHeightInFullScreen ? `sm:w-[${window.innerHeight * 0.9}]` : ''} max-w-[98vw] max-h-[98vh]`,
											darkerBackdrop: true,
											clickOutSideToClose: false,
										});
									}}
								/>
							)}
						</div>
					</div>

					<div
						className={
							'absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 overflow-hidden w-full h-full'
						}
						style={{
							width: props.fillPanelResponsiveWidth
								? 'calc(100% + 2rem)'
								: undefined,

							maskImage: `linear-gradient(to right, transparent, black ${waterMarkFadeThreshold.min}, black ${waterMarkFadeThreshold.max}, transparent), linear-gradient(to bottom, transparent, black ${waterMarkFadeThreshold.min}, black ${waterMarkFadeThreshold.max}, transparent)`,

							WebkitMaskImage: `linear-gradient(to right, transparent, black ${waterMarkFadeThreshold.min} , black ${waterMarkFadeThreshold.max}, transparent), linear-gradient(to bottom, transparent, black ${waterMarkFadeThreshold.min}, black ${waterMarkFadeThreshold.max}, transparent)`,

							maskComposite: 'intersect',

							WebkitMaskComposite: 'destination-in',
						}}
					>
						<div
							className={ClassString({
								static: 'w-full h-full',
								dynamic: {
									'bg-watermark-black opacity-1.5':
										themeStore.lightTheme,

									'bg-watermark-white opacity-1.5':
										themeStore.darkTheme,
								},
							})}
						></div>
					</div>

					<div
						className={ClassString({
							static: 'w-full',
							dynamic: {
								'-mx-4 w-graph-full lg:w-full lg:mx-0':
									props.fillPanelResponsiveWidth,
							},
						})}
					>
						<HighchartsReact
							containerProps={{
								className: `highcharts-${themeStore.lightTheme ? 'light' : 'dark'}`,
							}}
							highcharts={{
								...Highcharts,
								...props.highcharts,
							}}
							options={chartOptions}
							constructorType={props.constructorType}
							ref={highchartsRef}
						/>
					</div>
				</div>
			</div>

			<ChartOutline elementId={getGraphId(props.title)} />
		</>
	);
});

export default Chart;
