import { observer } from 'mobx-react-lite';
import ViewPanel from '@components/view/view-panel.tsx';
import Tooltip from '@components/core/tooltip/tooltip.tsx';
import { Link } from 'react-router';
import ExperienceBanner from '@components/data/experience-banner/experience-banner.tsx';
import { ExperienceBannerSize } from '@components/data/experience-banner/experience-banner-constants.ts';
import dailyPickStore from '@store/daily-pick-store.ts';
import experienceStore from '@store/experience-store.ts';

const OverviewDailyPicks = observer(function OverviewDailyPicks() {
	const dailyPicks = dailyPickStore.currentDailyPick
		? dailyPickStore.currentDailyPick.places
				.map((place) => {
					return experienceStore.getFullExperienceById(
						place.place_id
					);
				})
				.filter((exp) => !!exp)
		: [];

	if (!dailyPicks.length) {
		return <></>;
	}

	return (
		<ViewPanel options={{ container: true }}>
			<div className={'text-xl sm:text-2xl     mb-2'}>
				<Tooltip
					text={
						'A curated selection of daily highlights by Roblox staff'
					}
					direction={'bottom'}
					className={'justify-start cursor-default'}
				>
					{"Roblox's Daily Picks"}
				</Tooltip>
			</div>

			<div
				className={
					'grid grid-cols-2 xl:grid-cols-4 justify-center items-start gap-4 sm:gap-8'
				}
			>
				{dailyPicks.map((exp) => {
					return (
						<div
							className={
								'max-w-full flex flex-col justify-center items-center'
							}
							key={`daily-pick-${exp?.place_id}`}
						>
							<Link
								to={`/experiences/${exp?.place_id}`}
								className={
									'flex flex-col justify-center items-center gap-2 hover:bg-blue-300 hover:text-gray-600 w-fit p-2 rounded-lg'
								}
								draggable={false}
							>
								<ExperienceBanner
									experience={exp}
									size={ExperienceBannerSize.MEDIUM}
									className={
										'rounded-md outline-1 outline-gray-300 dark:outline-gray-500'
									}
								/>

								<div
									className={
										'w-full flex justify-center items-center text-center'
									}
								>
									{exp?.name}
								</div>
							</Link>
						</div>
					);
				})}
			</div>
		</ViewPanel>
	);
});

export default OverviewDailyPicks;
