import { observer } from 'mobx-react-lite';
import { useEffect, useRef, useState } from 'react';
import authStore from '@store/auth-store.ts';
import InputText from '@components/core/input-text/input-text.tsx';
import Button from '@components/core/button/button.tsx';
import { BUTTON_STYLE } from '@components/core/button/button-style-enum.ts';
import { Link, useLocation, useNavigate } from 'react-router';
import { INPUT_TEXT_TYPES } from '@components/core/input-text/input-text-enum.ts';
import Form from '@components/core/form/form.tsx';
import { LocationState } from '@components/navigation/navigation-button/navigation-button.tsx';
import LoadingIndicator from '@components/core/loading-indicator/loading-indicator.tsx';
import { CredentialResponse } from 'google-one-tap';
import ViewPanel from '@components/view/view-panel.tsx';
import ClassString from '@utils/class-string.ts';
import useScreenBreakpoint from '@hooks/use-screen-breakpoint.tsx';
import { ScreenBreakpoint } from '@/enums/screen-breakpoints.ts';
import navSidebarStore from '@store/nav-sidebar-store.ts';
import AppLogo from '@components/core/app-logo/app-logo.tsx';
import storeService from '@services/store-service.ts';

const SignIn = observer(function SignIn() {
	const screenBreakpoint = useScreenBreakpoint();
	const [fadeIn, setFadeIn] = useState(false);

	const [loadingGoogleSSO, setLoadingGoogleSSO] = useState(true);

	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [loading, setLoading] = useState(false);

	const navigate = useNavigate();

	const locationState = useLocation().state as LocationState;
	const navigateTo = locationState?.from
		? `${locationState.from.pathname}`
		: '/';

	const ref = useRef<HTMLDivElement>(null);

	document.title = 'Sign In | UGC Insight';

	const handleLogin = async () => {
		try {
			setLoading(true);
			storeService.resetDataOnSignIn();
			const ok = await authStore.login(email, password);
			if (ok) {
				void navigate(navigateTo);
			} else {
				setLoading(false);
				setEmail('');
				setPassword('');
			}
		} catch (_) {
			setLoading(false);
		}
	};

	const handleGoogleLogin = async (response: CredentialResponse) => {
		try {
			setLoading(true);
			storeService.resetDataOnSignIn();
			await authStore.googleLogin(response);
			void navigate(navigateTo);
		} catch (_) {
			setLoading(false);
		}
	};

	const initializeGSI = () => {
		window.google.accounts.id.initialize({
			client_id: import.meta.env.VITE_GOGGLE_OAUTH_CLIENT_ID as string,
			callback: (response: CredentialResponse) => {
				void handleGoogleLogin(response);
			},
		});

		if (ref.current) {
			window.google.accounts.id.renderButton(ref.current, {
				theme: 'outline',
				size: 'large',
				shape: 'pill',
				locale: 'en-EN',
			});

			// Wait for button to potentially populate a logged in google session
			setTimeout(() => {
				setLoadingGoogleSSO(false);
			}, 100);
		}
	};

	useEffect(() => {
		const script = document.createElement('script');
		script.src = 'https://accounts.google.com/gsi/client';
		script.onload = initializeGSI;
		script.async = true;
		document.head.appendChild(script);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setFadeIn(true);
	}, []);

	return (
		<div
			className={
				'w-screen h-dvh bg-green-100 dark:bg-gray-575 sm:bg-transparent dark:sm:bg-transparent grid place-items-center overflow-y-auto sm:bg-top-page-pattern sm:dark:bg-none bg-cover'
			}
		>
			<div
				className={
					'w-full static flex justify-start justify-items-start text-lg hover:text-blue-500 dark:hover:text-blue-300 pt-4 pl-6 self-start'
				}
			>
				<Link to={navigateTo} draggable={false}>
					{'← Back'}
				</Link>
			</div>

			<div
				className={
					'flex flex-col justify-between sm:justify-center items-center gap-4 h-full p-4'
				}
			>
				<div></div>

				<ViewPanel
					className={ClassString({
						static: 'max-w-md opacity-0 transition-opacity bg-transparent sm:bg-green-100',
						dynamic: { 'opacity-100': fadeIn },
					})}
					options={{
						container: screenBreakpoint <= ScreenBreakpoint.SM,
					}}
				>
					<div
						className={ClassString({
							static: 'flex flex-col justify-center items-center px-4',
							dynamic: {
								'opacity-0': loading,
							},
						})}
					>
						<AppLogo
							className={ClassString({
								static: 'flex flex-col justify-center items-center dark:text-white pt-0 pb-4 select-none flex-1',
								dynamic: {
									'lg:hidden': navSidebarStore.minimal,
								},
							})}
						/>

						<div className={'w-full text-2xl mb-1 font-semibold'}>
							Sign In
						</div>
						<div className={'w-full mb-6'}>
							Explore comprehensive real-time Roblox data
						</div>

						<Form
							className={
								'flex flex-col justify-center items-center w-full'
							}
							onSubmit={() => {
								void handleLogin();
							}}
						>
							<InputText
								label={'Email'}
								value={email}
								name={'email'}
								onInput={(event) =>
									setEmail(event.target.value)
								}
								type={INPUT_TEXT_TYPES.EMAIL}
								options={{ width: '100%', large: true }}
								inputClassName={'bg-transparent'}
							/>

							<InputText
								label={'Password'}
								value={password}
								name={'password'}
								onInput={(event) =>
									setPassword(event.target.value)
								}
								type={INPUT_TEXT_TYPES.PASSWORD}
								options={{ width: '100%', large: true }}
								inputClassName={'bg-transparent'}
							/>

							<Button
								title={'Sign In'}
								buttonStyle={BUTTON_STYLE.PRIMARY}
								className={
									'mt-2 w-full py-6 rounded-full max-w-80'
								}
							/>
						</Form>

						<div
							className={
								'flex justify-center items-center mt-5 mb-4 text-gray-400 dark:text-white w-full'
							}
						>
							<div
								className={
									'border-b border-x-0 border-t-0 border-gray-400 border-solid w-1/2'
								}
							></div>
							<div className={'mx-4'}>OR</div>
							<div
								className={
									'border-b border-x-0 border-t-0 border-gray-400 border-solid w-1/2'
								}
							></div>
						</div>

						<div
							ref={ref}
							className={ClassString({
								static: 'h-[44px] max-w-[180px] transition-max-width  duration-1000 flex justify-center items-center border-gray-200 rounded-full overflow-hidden',
								dynamic: { 'max-w-[364px]': !loadingGoogleSSO },
							})}
						></div>
					</div>

					<div
						className={ClassString({
							static: 'absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 pointer-events-none',
							dynamic: { 'opacity-0': !loading },
						})}
					>
						<LoadingIndicator />
					</div>
				</ViewPanel>

				<div className={'flex justify-center items-center'}>
					<span className={'mr-2'}>{'New to UGC Insight?'}</span>
					<Link
						to={'/sign-up'}
						className={'text-blue-500 dark:text-blue-300'}
					>
						Sign Up for Free
					</Link>
				</div>
			</div>

			<div></div>
		</div>
	);
});

export default SignIn;
