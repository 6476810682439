import { observer } from 'mobx-react-lite';
import Icon from '@components/core/icon/icon.tsx';
import { IconEnum } from '@components/core/icon/icon-enum.ts';
import modalStore from '@store/modal-store.ts';
import { Link, useLocation } from 'react-router';

const feature = (description: string) => {
	return (
		<div className={'flex justify-center items-center gap-2'}>
			<Icon
				icon={IconEnum.CONFIRM}
				className={'text-green-600 dark:text-green-500'}
				size={'2rem'}
			/>
			{description}
		</div>
	);
};

const ModalSignUpCta = observer(function ModalSignUpCta() {
	const location = useLocation();

	return (
		<div
			className={
				'flex flex-col justify-center items-center w-full relative'
			}
		>
			<div
				className={
					'absolute -top-2 -right-2 p-1 hover:text-gray-600 hover:bg-blue-300 rounded-full cursor-pointer flex justify-center items-center'
				}
				onClick={() => {
					modalStore.close();
				}}
			>
				<Icon icon={IconEnum.CLEAR} size={'1.5rem'} />
			</div>

			<div className={'font-bold text-center text-2xl mb-8'}>
				Sign Up for Free for FULL Access
			</div>

			<div
				className={
					'flex flex-col justify-center items-start w-full gap-4 mb-10'
				}
			>
				{feature('Access data beyond 30 days')}
				{/*{feature('Receive a monthly Trend Report')}*/}
				{feature('Create custom segments with experience tagging')}
				{feature('Do comparisons across experiences and tags')}
				{feature('Preferences remembered automatically')}
				{feature('Full access, no charge—just free!')}
			</div>

			<Link
				to={'/sign-up'}
				state={{ from: location }}
				onClick={() => {
					modalStore.close();
				}}
				className={
					'bg-gray-600 text-white mb-10 w-full max-w-96 h-14 rounded-full justify-center button relative flex items-center transition-all duration-75 px-5 py-2 whitespace-nowrap border  shadow-md dark:bg-gray-525 border-black dark:border-gray-500 dark:text-white focus-visible:outline-blue-300 focus-visible:outline-double focus-visible:outline-4 focus-visible:bg-blue-300 focus-visible:text-gray-600 focus-visible:decoration-white focus-visible:border-blue-300 hover:bg-blue-300 hover:text-gray-600 hover:border-blue-300'
				}
			>
				Sign Up for Free
			</Link>

			<div className="border-t border-gray-300 mb-4 w-full"></div>

			<div className={'flex justify-center items-center gap-2'}>
				Already got an account?{' '}
				<Link
					to={'/sign-in'}
					className={'text-blue-500 dark:text-blue-300'}
					onClick={() => {
						modalStore.close();
					}}
					state={{ from: location }}
				>
					Sign In
				</Link>
			</div>
		</div>
	);
});

export default ModalSignUpCta;
