import { observer } from 'mobx-react-lite';
import ClassString from '@utils/class-string.ts';
import modalStore from '@store/modal-store.ts';
import { useEffect } from 'react';

const Modal = observer(function Modal() {
	useEffect(() => {
		const onKeyDown = (event: KeyboardEvent) => {
			if (
				modalStore.visible &&
				modalStore.options.clickOutSideToClose &&
				event.key === 'Escape'
			) {
				modalStore.close();
			}
		};
		document.addEventListener('keydown', onKeyDown);

		return () => {
			document.removeEventListener('keydown', onKeyDown);
		};
	}, []);

	return (
		<>
			{modalStore.visible && (
				<div
					className={ClassString({
						static: 'z-modal-overlay absolute top-0 left-0 h-dvh w-screen flex justify-center items-center bg-black/50 py-4',
						dynamic: {
							'bg-black/75': modalStore.options.darkerBackdrop,
						},
					})}
					onClick={() => {
						if (modalStore.options.clickOutSideToClose) {
							modalStore.close();
						}
					}}
				>
					<div
						className={ClassString({
							static: 'flex justify-center items-start rounded-lg p-6 outline-1 outline-gray-500 w-[90vw] sm:w-3/5 max-w-lg max-h-full shadow-lg bg-white dark:bg-gray-550 overflow-y-auto',
							dynamic: {
								'overflow-y-visible':
									modalStore.options.overflow,
							},
							custom: modalStore.options.className,
						})}
						onClick={(event) => {
							event.stopPropagation();
						}}
					>
						{modalStore.content}
					</div>
				</div>
			)}
		</>
	);
});

export default Modal;
