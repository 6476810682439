import { makeAutoObservable, runInAction } from 'mobx';
import { RefObject } from 'react';
import { BaseStore } from '@store/base-store.ts';

interface OptionsMenu {
	buttons: {
		title: string;
		onClick: () => unknown;
	}[];
	ref: RefObject<HTMLDivElement>;
	width?: string;
}

class OptionsMenuStore implements BaseStore {
	private _options: OptionsMenu | undefined;

	reset() {
		runInAction(() => {
			this._options = undefined;
		});
	}

	constructor() {
		makeAutoObservable(this);
	}

	open(options: OptionsMenu) {
		runInAction(() => {
			this._options = options;
		});
	}

	close() {
		runInAction(() => {
			this._options = undefined;
		});
	}

	isOpen(element?: Element | null) {
		return (
			!!this._options?.ref.current &&
			this._options.ref.current === element
		);
	}

	get options() {
		return this._options;
	}
}

const optionsMenuStore = new OptionsMenuStore();
export default optionsMenuStore;
