import { observer } from 'mobx-react-lite';
import { Link, useLocation } from 'react-router';
import { LocationState } from '@components/navigation/navigation-button/navigation-button.tsx';

const PrivacyPolicy = observer(function PrivacyPolicy() {
	const locationState = useLocation().state as LocationState;
	const navigateTo =
		locationState?.from && locationState.from.pathname !== '/sign-up'
			? `${locationState.from.pathname}`
			: '/overview';

	return (
		<div
			className={
				'w-screen h-dvh bg-white dark:bg-gray-575 grid place-items-center overflow-y-auto'
			}
		>
			<div
				className={
					'w-full text-lg hover:text-blue-500 dark:hover:text-blue-300 p-4'
				}
			>
				<Link to={navigateTo}>{'← Back'}</Link>
			</div>

			<div className="max-w-5xl w-full p-6">
				<h1 className="text-3xl font-bold mb-4">
					Privacy and Data Protection Policy
				</h1>
				<p className="mb-6">Last updated: 28th February 2025</p>

				<h2 className="text-xl font-semibold mt-6">
					1. Who is responsible for processing your personal data?
				</h2>
				<p className="mb-4">
					Your personal data is processed by the entity The Gang
					Sweden, AB, Org. no. 559224-9691, with its registered office
					at Vasagatan 28, 111 20, Stockholm, Sweden (herein referred
					to as “The Gang”, “Company”, “we” or “us”).
				</p>

				<p className="mb-4">
					The Gang is an international game development studio
					committed to developing world-leading, immersive gaming
					experiences.s
				</p>

				<p className="mb-4">
					This privacy and data protection policy (“Privacy Policy”)
					informs you about which of your personal data is collected
					and processed by us, for which purpose we collect your data,
					how we share and protect your data, for how long is your
					data stored and your personal data rights under the GDPR.
					Please note that the protection of your personal data is of
					high importance to us.
				</p>

				<p className="mb-4">
					Any terms undefined in this Privacy Policy which are defined
					in the GDPR shall have the same meaning as in the GDPR.
				</p>

				<h2 className="text-xl font-semibold mt-6">
					2. What categories of personal data do we collect?
				</h2>
				<p className="mb-4">
					In order to provide you with our services, we may need to
					collect personal data of our users meaning any information
					that (regardless of its nature or medium), directly or in
					combination with other data, can identify a natural person
					or can be linked to them. The Gang can only collect personal
					data in compliance with applicable legislation. Please do
					not send us any of your personal data if you do not want
					them to be used in the ways described in this Privacy
					Policy.
				</p>

				<p className="mb-4">
					The information we collect about you may vary depending on
					the interactions we have with you.
				</p>

				<p className="mb-4">
					We may collect personal information about you when you
					interact with us, being that by performing registration on
					our website, contact us via our support channels, filling
					out a survey or sending us feedback, in such cases we may
					require that you provide us with certain information about
					yourself in order to be able to properly assist you, process
					your requests or improve your experience with us. The
					information requested will vary depending on the assistance
					or request being processed and may include details such as
					your first and last name, and your email address.{' '}
				</p>

				<p className="mb-4">
					In addition, when you interact with us, we may automatically
					collect technical information and usage and statistics
					information through means of tracking technologies and
					analytics services that we may use in the context of our
					activity. Such data includes information about your device
					including your IP address and geolocation information.
				</p>

				<p className="mb-4">
					Please note that if certain features related to our services
					are to be provided by third parties, those third parties may
					also collect data automatically regarding your use of the
					services through means of their own tracking technologies
					tools. These features are subject to those third parties
					‘privacy notice and policies.
				</p>

				<h2 className="text-xl font-semibold mt-6">
					3. Lawfulness of data processing
				</h2>
				<p className="mb-4">
					We process your personal data as part of the execution and
					management of our contractual relationships with you, in our
					legitimate interest to improve the quality and operational
					excellence of the services we offer you or in compliance
					with certain regulatory or legislative obligations,
					depending on the purpose of the data processing.{' '}
				</p>

				<p className="mb-4">
					Your personal data may also be processed on the basis of
					your prior consent in the event that your consent is
					requested.
				</p>

				<h2 className="text-xl font-semibold mt-6">
					4. Purpose of data processing
				</h2>
				<p className="mb-4">
					In general, we collect and process personal data for the
					purposes described below:
				</p>
				<ul className="list-disc pl-6 mb-4">
					<li className={'mb-2'}>
						Your IP address, first and last name and your email
						address to operate and provide our services to you,
						including performing registration, provide you the
						related support to respond to your inquiries;
						communicate with you about your access and use of our
						services; for troubleshooting and other technical
						support and for other customer service and support
						purpose. The legal basis for collecting and processing
						your personal data is the performance of our contractual
						obligations with you.
					</li>
					<li className={'mb-2'}>
						Your IP address to provide enhanced security and safety
						for our business: including to protect and provide
						enhanced security for our business operations and
						assets, services, network and information resources and
						technology; to investigate, prevent, detect and respond
						to fraud, cheating, unauthorized access, situations
						involving potential threats to the rights or safety of
						any person or third party, or other unauthorized
						activities or misconduct. The legal basis for collecting
						and processing your personal data is to comply with
						legal and regulatory obligations.
					</li>
					<li className={'mb-2'}>
						Your first and last name and your email address for
						promotional, advertising or marketing purposes,
						including to send or display targeted marketing to users
						and others who may be interested in our services; to
						direct you to more relevant advertisements and to
						evaluate, analyse and improve the effectiveness of our
						advertising campaigns; to send you newsletters, offers
						and other information that we think may be of interest
						to you; to contact you about our services or information
						that we think may be of interest to you. The legal basis
						for collecting and processing your personal data is your
						explicit consent or our legitimate interests in
						conducting marketing activities.
					</li>
					<li className={'mb-2'}>
						Your IP address to analyse and collect statistical data,
						including geographical distribution, website traffic, as
						well as other technical information. The legal basis for
						collecting and processing your personal data is our
						legitimate interests in maintaining and optimizing our
						platform.
					</li>
					<li className={'mb-2'}>
						Your IP address and other technical information to
						defend our rights under the law: including to manage and
						respond to potential and actual disputes and lawsuits,
						and further, to establish, defend or protect our rights
						or interests, including in the context of actual or
						anticipated litigation with users or third parties. The
						legal basis for collecting and processing your personal
						data is to comply with legal or regulatory obligations
						and our legitimate interest in protecting our business
						operations.
					</li>
					<li className={'mb-2'}>
						Your IP address and other technical information to
						comply with applicable regulatory or legal obligations,
						including as part of a judicial proceeding or as part of
						an investigation or request, whether formal or informal,
						from law enforcement or government authorities.
					</li>
				</ul>

				<h2 className="text-xl font-semibold mt-6">
					5. How we share your data
				</h2>

				<p className="mb-4">
					We may share your personal data with third parties, if: (a)
					you have consented to such disclosure; (b) we are under a
					legal obligation to do so; (c) it is necessary for the
					purpose of legal proceedings or in relation thereto, or to
					exercise or protect our rights and; (d) we are required to
					disclose your personal data to new entities or third parties
					due to organizational changes within the Company or in
					connection with the transfer of our business or any part
					thereof.
				</p>

				<p className="mb-4">
					We may also share your personal data with some reliable
					third parties in accordance with contracts entered into with
					them. They include:
				</p>

				<ul className="list-disc pl-6 mb-4">
					<li>our professional advisors and auditors;</li>
					<li>
						suppliers to whom we outsource ancillary services, such
						as the provision and management of electronic
						newsletters:
						<ul className="list-[circle] pl-6 mt-2">
							<li>
								Mailchimp and their respective Privacy Policy
								link:{' '}
								<a
									href="https://mailchimp.com/legal/privacy/"
									target={'_blank'}
									rel="noreferrer"
									className="text-blue-500 underline"
								>
									https://mailchimp.com/legal/privacy/
								</a>
							</li>
						</ul>
					</li>
					<li>
						user login services, hosting server services and
						analytics service providers:
						<ul className="list-[circle] pl-6 mt-2">
							<li>
								Google SSO (single sign on) and their respective
								Privacy Policy link:{' '}
								<a
									href="https://policies.google.com/privacy?hl=en-US"
									target={'_blank'}
									rel="noreferrer"
									className="text-blue-500 underline"
								>
									https://policies.google.com/privacy?hl=en-US
								</a>
							</li>
							<li>
								Google Cloud Platform and their respective
								Privacy Policy link:{' '}
								<a
									href="https://policies.google.com/privacy?hl=en-US"
									target={'_blank'}
									rel="noreferrer"
									className="text-blue-500 underline"
								>
									https://policies.google.com/privacy?hl=en-US
								</a>
							</li>
							<li>
								PostHog and their respective Privacy Policy
								link:{' '}
								<a
									href="https://posthog.com/privacy"
									target={'_blank'}
									rel="noreferrer"
									className="text-blue-500 underline"
								>
									https://posthog.com/privacy
								</a>
							</li>
							<li>
								Sentry and their respective Privacy Policy link:{' '}
								<a
									href="https://sentry.io/privacy/"
									target={'_blank'}
									rel="noreferrer"
									className="text-blue-500 underline"
								>
									https://sentry.io/privacy/
								</a>
							</li>
						</ul>
					</li>
				</ul>

				<h2 className="text-xl font-semibold mt-6">
					6. Do you perform international data transfers?
				</h2>

				<p className="mb-4">
					As general practice, the Company does not transfer data
					outside the European Economic Area (&#34;EEA&#34;). However,
					in an exceptional case when your personal data may need to
					be shared with a third country outside the EEA, we will
					observe all respective requirements set out in the GDPR and
					any other applicable legal provisions in order to ensure
					protection of such data, in particular as regards
					determining the suitability of such country with regard to
					data protection or by providing appropriate guarantees.
				</p>

				<h2 className="text-xl font-semibold mt-6">
					7. How long will your personal data be processed?
				</h2>

				<p className="mb-4">
					The Company processes your personal data only for the period
					strictly necessary to fulfil the purposes identified in
					section 4. Notwithstanding the foregoing, information about
					your IP address will not be retained by us for a period
					superior to thirty (30) days following the last time you
					logged in and three (3) months since the last time you
					logged in when collected and processed for visit
					statistics.{' '}
				</p>

				<p className="mb-4">
					At the end of the defined retention period, the Company
					undertakes to delete, destroy or anonymize your personal
					data.{' '}
				</p>

				<h2 className="text-xl font-semibold mt-6">
					8. How is your personal data protected?{' '}
				</h2>

				<p className="mb-4">
					The Company is committed to safeguarding personal data
					through industry-standard security practices. We employ
					robust technical and organizational measures to protect data
					from unauthorized access, disclosure, modification, or
					destruction.
				</p>

				<p className="mb-4">
					All data transmissions are encrypted using modern
					cryptographic protocols to prevent interception by
					unauthorized parties. Sensitive information, including
					passwords, is securely stored using strong hashing
					techniques in line with industry-standard security
					practices. Access to personal data is strictly controlled
					and limited to authorized personnel, following the principle
					of least privilege, and subject to rigorous security
					protocols and access controls.
				</p>

				<p className="mb-4">
					We continuously monitor, assess, and update our security
					measures to address emerging threats and maintain compliance
					with legal and regulatory requirements. While we take
					extensive precautions to protect user data, no system is
					entirely immune to security risks. We encourage users to
					adopt best practices, such as using strong passwords and
					enabling multi-factor authentication, to enhance their
					account security.
				</p>

				<h2 className="text-xl font-semibold mt-6">
					9. What are your data protection rights and how can you
					exercise them?
				</h2>

				<p className="mb-4">
					Under the terms of the applicable legislation, you may
					request the following data protection rights at any time:
				</p>

				<ul className="list-[lower-alpha] pl-6 mb-4">
					<li className={'mb-2'}>
						<b>Right of access –</b> You have the right to obtain
						confirmation as to whether your personal data is being
						processed and, if so, the right to access your personal
						data and certain information.
					</li>
					<li className={'mb-2'}>
						<b>Right of rectification –</b> You have the right to
						rectify your inaccurate personal data or request that
						incomplete personal data be completed.
					</li>
					<li className={'mb-2'}>
						<b>Right to erasure –</b> You have the right to have
						your personal data erased without undue delay if there
						are no valid grounds for retaining it, such as when it
						has to be retained to comply with a legal obligation or
						because legal proceedings are underway.
					</li>
					<li className={'mb-2'}>
						<b>Right to restriction of processing –</b> You have the
						right to request restriction of the processing of your
						personal data, in the form of suspension of processing
						or limitation of the scope of processing to certain
						categories of data or processing purposes, as set out in
						Article 18 of the GDPR.
					</li>
					<li className={'mb-2'}>
						<b>Right to data portability –</b> You have the right to
						receive personal data concerning you that you have
						provided in a structured, commonly used and
						machine-readable format and/or the right to have such
						data transmitted to another controller.
					</li>
					<li className={'mb-2'}>
						<b>Right to object –</b> You have the right to object to
						the processing of data concerning you, provided that
						there are no legitimate reasons for such processing
						which prevail over your interests, rights and freedoms,
						or for the purpose of declaring, exercising or defending
						a right in legal proceedings.
					</li>
					<li className={'mb-2'}>
						<b>Right to withdraw consent –</b> In case that your
						personal data are processed based on your consent, you
						shall have the right to withdraw such consent at any
						time in the same form and manner as the consent was
						originally given, without such revocation invalidating
						the processing of the data while the consent was in
						force.
					</li>
					<li className={'mb-2'}>
						<b>
							Right to contact the Data Protection Officer or
							Supervisory Authority –
						</b>{' '}
						You shall have the right to contact the Data Protection
						Officer of the Company at katherine@thegang.io
						<a
							href="mailto:katherine@thegang.io?subject=UGC Insight Privacy Policy"
							target={'_blank'}
							rel="noreferrer"
							className="text-blue-500 underline"
						>
							(https://edpb.europa.eu/about-edpb/board/members_en)
						</a>
						(Katherine Siano, HR Generalist) with inquiries and/or
						complaints regarding personal data processing. You shall
						also have the right to lodge a complaint with the
						Supervisory Authority, the National Office for
						Protection of Personal Data or another competent data
						protection authority, which you can find via the
						following link:
						<a
							href="https://edpb.europa.eu/about-edpb/board/members_en"
							target={'_blank'}
							rel="noreferrer"
							className="text-blue-500 underline"
						>
							(https://edpb.europa.eu/about-edpb/board/members_en)
						</a>
						.
					</li>
				</ul>

				<h2 className="text-xl font-semibold mt-6">
					10. Final provisions
				</h2>

				<h2 className="text-xl font-semibold mt-6">a. Cookies</h2>

				<p className="mb-4">
					For more information on how we use cookies and similar
					technologies, please consult our Cookie Policy{' '}
					<Link
						to={'/cookie-policy'}
						className={'text-blue-500 dark:bg-blue-300'}
					>
						through the following link
					</Link>
					.
				</p>

				<h2 className="text-xl font-semibold mt-6">b. Minors </h2>

				<p className="mb-4">
					We do not solicit or collect any personal information from
					children under the age of 13 when aware of such information.
					If you believe that we have collected personal information
					from a child under the age of 13, please contact us as
					described in Section 10, d) and we will act as necessary to
					delete that information securely.
				</p>

				<p className="mb-4">
					Without prejudice to the preceding paragraph, if you are
					under the age of 16, we will require the express consent of
					the holder of parental responsibility, for example, your
					parents or legal guardians. We shall take reasonable steps
					to verify as much as possible that consent is given or
					authorised by the holder of parental responsibility.{' '}
				</p>

				<h2 className="text-xl font-semibold mt-6">
					c. Changes and updates to the Privacy Policy
				</h2>

				<p className="mb-4">
					This Privacy Policy may be updated to reflect changes in our
					practices or legal requirements. The latest version will be
					available on our website.
				</p>

				<h2 className="text-xl font-semibold mt-6">d. Contact us</h2>

				<p className="mb-4">
					Should you have any questions regarding the processing of
					your personal data and the exercise of your rights under the
					applicable legislation and, in particular those referred to
					in this Privacy Policy, please feel free to contact us at{' '}
					<a
						href="https://www.thegang.io/contact"
						target={'_blank'}
						rel="noreferrer"
						className="text-blue-500 underline"
					>
						our contact page
					</a>
					.
				</p>
			</div>
		</div>
	);
});

export default PrivacyPolicy;
