import { observer } from 'mobx-react-lite';
import View from '@components/view/view.tsx';
import placeTagStore from '@store/place-tag-store.ts';
import Button from '@components/core/button/button.tsx';
import { IconEnum } from '@components/core/icon/icon-enum.ts';
import ExperienceTagsCreateNewModal from '@views/experience-tags/view-components/experience-tags-create-new-modal.tsx';
import { useNavigate } from 'react-router';
import SearchBar from '@components/core/search-bar/search-bar.tsx';
import { useState } from 'react';
import searchService from '@services/search-service.ts';
import ExperienceTagList from '@views/experience-tags/view-components/experience-tag-list.tsx';
import modalStore from '@store/modal-store.ts';
import ViewTabPanel from '@components/view/view-tab-panel.tsx';
import BrandColors from '@styles/_brand_colors.ts';
import authStore from '@store/auth-store.ts';
import { UserPermission } from '@/schemas/user-schema.ts';
import localStorageService from '@services/local-storage-service.ts';
import { LocalStorageKey } from '@/enums/local-storage-types.ts';
import Icon from '@components/core/icon/icon.tsx';
import ModalSignUpCta from '@components/modal/modal-sign-up-cta.tsx';
import SignUpCtaBanner from '@components/core/sign-up-cta/sign-up-cta-banner.tsx';

const ExperienceTags = observer(function ExperienceTags() {
	const navigate = useNavigate();
	const [searchTerm, setSearchTerm] = useState('');

	let experienceTags = placeTagStore.placeTags;
	if (searchTerm) {
		experienceTags = experienceTags.filter((placeTag) => {
			return searchService.tag(placeTag, searchTerm);
		});
	}

	const globalTags = experienceTags.filter((experienceTag) => {
		if (!experienceTag.global) {
			return false;
		}

		return searchService.tag(experienceTag, searchTerm);
	});

	const personal_tags = experienceTags.filter((experienceTag) => {
		if (!experienceTag.owner_id) {
			return false;
		}

		if (experienceTag.owner_id !== authStore.user?.id) {
			return false;
		}

		return searchService.tag(experienceTag, searchTerm);
	});

	const others_tags = experienceTags.filter((experienceTag) => {
		if (experienceTag.global) {
			return false;
		}

		if (
			experienceTag.owner_id &&
			experienceTag.owner_id === authStore.user?.id
		) {
			return false;
		}

		return searchService.tag(experienceTag, searchTerm);
	});

	const addNewExperienceTag = async (tagName: string, global: boolean) => {
		const newTag = await placeTagStore.addPlaceTag(tagName, global);

		if (newTag) {
			void navigate(`/tags/${newTag.id}`);
		}
	};

	const openTagCreateModal = () => {
		modalStore.open(
			<ExperienceTagsCreateNewModal onSubmit={addNewExperienceTag} />,
			{
				overflow: true,
			}
		);
	};

	const defaultTab =
		localStorageService.get(
			LocalStorageKey.ExperienceTagTabSelected,
			authStore.user?.id
		) ?? undefined;

	return (
		<View
			className={'max-w-(--breakpoint-lg)'}
			viewInfo={{ title: 'Tags', icon: IconEnum.TAG }}
			headerChildren={
				<div
					className={
						'flex justify-between items-center flex-wrap sm:flex-nowrap gap-4 w-full'
					}
				>
					<SearchBar
						onSearchUpdate={setSearchTerm}
						placeholder={'Search by name..'}
						className={'max-w-3xl'}
					/>

					<Button
						title={'New Tag'}
						icon={{
							icon: authStore.isLoggedIn
								? IconEnum.ADD
								: IconEnum.LOCK,
							placement: 'right',
							size: authStore.isLoggedIn ? '1.5rem' : '1.25rem',
						}}
						onClick={() => {
							if (authStore.isLoggedIn) {
								openTagCreateModal();
							} else {
								modalStore.open(<ModalSignUpCta />);
							}
						}}
					/>
				</div>
			}
		>
			<SignUpCtaBanner thin />

			<ViewTabPanel
				tabs={[
					{
						key: 'global_tags',
						title: 'Curated Tags',
						children: (
							<ExperienceTagList
								tags={globalTags}
								globalTags={true}
							/>
						),
					},
					{
						key: 'personal_tags',
						title: 'Your Tags',
						titleElement: (
							<div
								className={
									'flex justify-center items-center gap-2'
								}
							>
								{'Your Tags'}
								{!authStore.isLoggedIn && (
									<Icon icon={IconEnum.LOCK} size={'1.5em'} />
								)}
							</div>
						),
						children: (
							<ExperienceTagList
								tags={personal_tags}
								globalTags={false}
							/>
						),
						locked: !authStore.isLoggedIn,
						onClick: () => {
							if (authStore.isLoggedIn) {
								return;
							}

							modalStore.open(<ModalSignUpCta />);
						},
					},
					...(authStore.havePermission(UserPermission.EDIT_TAG) &&
					!!others_tags.length
						? [
								{
									key: 'others_tags',
									title: 'Other Users Tags',
									children: (
										<ExperienceTagList
											tags={others_tags}
											globalTags={false}
										/>
									),
									tooltip: (
										<div
											className={
												'w-full flex justify-center items-center text-gray-600 dark:text-white'
											}
										>
											{
												"Here you can view and edit everyone else's personal tags, this is only available for editors."
											}
										</div>
									),
								},
							]
						: []),
				]}
				className={'p-0'}
				defaultTab={defaultTab}
				tabSelectedColor={BrandColors.gray600}
				tabSelectedTextColor={BrandColors.white}
				noOutline
				onTabChange={(tabKey) => {
					localStorageService.set(
						LocalStorageKey.ExperienceTagTabSelected,
						tabKey,
						authStore.user?.id
					);
				}}
			/>
		</View>
	);
});

export default ExperienceTags;
