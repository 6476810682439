import { observer } from 'mobx-react-lite';
import {
	ExperienceStatus,
	FullExperience,
} from '@/schemas/experience-schema.ts';
import ViewPanel from '@components/view/view-panel.tsx';
import authStore from '@store/auth-store.ts';
import { UserPermission, UserRole } from '@/schemas/user-schema.ts';
import experienceStore from '@store/experience-store.ts';
import { Link, NavLink } from 'react-router';
import ExperienceCreator from '@components/data/experience-creator/experience-creator.tsx';
import { ReactNode } from 'react';
import formatNumberWithSuffix from '@utils/format-number-with-suffix.ts';
import timeAgo from '@utils/time-ago.ts';
import themeStore from '@store/theme-store.ts';
import DatePicker from '@components/core/date-picker/date-picker.tsx';
import IsValidDate from '@utils/is-valid-date.ts';
import { PlaceTag } from '@/schemas/plage-tag-schema.ts';
import ClassString from '@utils/class-string.ts';
import Icon from '@components/core/icon/icon.tsx';
import { IconEnum } from '@components/core/icon/icon-enum.ts';
import modalStore from '@store/modal-store.ts';
import ExperienceDetailsAddTagModal from '@views/experience-details/view-components/experience-details-add-tag-modal.tsx';
import IsMobile from '@utils/is-mobile.ts';
import formatSecondsDurationString from '@utils/format-seconds-duration-string.ts';

interface ExperienceDetailsInfoCompactProps {
	experience: FullExperience;
	tags: PlaceTag[];
}

const million = 1e6;

const row = (header: string, content: ReactNode | string) => {
	return (
		<div
			className={ClassString({
				static: 'grid grid-cols-5 h-6 ',
				dynamic: {
					'hover:bg-gray-100 dark:hover:bg-gray-525': !IsMobile,
				},
			})}
		>
			<div
				className={
					'text-blue-500 dark:text-blue-100 font-bold w-full col-span-2 truncate'
				}
			>
				{header}
			</div>
			<div className={'col-span-3 truncate'}>{content}</div>
		</div>
	);
};

const ageLimit = (limit?: number) => {
	if (limit === 0) {
		return 'All Ages';
	}

	if (!limit) {
		return 'Not Rated';
	}

	return `${limit}+`;
};

const status = (status: ExperienceStatus) => {
	switch (status) {
		case ExperienceStatus.UNKNOWN:
			return 'Unknown';
		case ExperienceStatus.PRIVATE:
		case ExperienceStatus.GUEST_PROHIBITED:
			return 'Private';
		case ExperienceStatus.UNAPPROVED:
			return 'Unapproved';
		case ExperienceStatus.PLAYABLE:
			return 'Playable';
		case ExperienceStatus.UNDER_REVIEW:
			return 'Under Review';
	}
};

const genreLinks = (experience: FullExperience) => {
	if (!experience.genre_l1) {
		return 'N/A';
	}

	if (!experience.genre_l2) {
		return (
			<Link
				to={`/genres/${experience.genre_l1}`}
				className={'max-w-full truncate'}
			>
				{experience.genre_l1}
			</Link>
		);
	}

	return (
		<div className={'max-w-full truncate'}>
			<Link to={`/genres/${experience.genre_l1}`}>
				{experience.genre_l1}
			</Link>
			{' - '}
			<Link to={`/genres/${experience.genre_l1}/${experience.genre_l2}`}>
				{experience.genre_l2}
			</Link>
		</div>
	);
};

const ExperienceDetailsInfoCompact = observer(
	function ExperienceDetailsInfoCompact(
		props: ExperienceDetailsInfoCompactProps
	) {
		const releaseDateSet = props.experience.released.getFullYear() >= 2;

		const handleReleaseDateChange = (value: string) => {
			if (!props.experience) {
				return;
			}

			let newDate: Date;

			if (value && IsValidDate(value)) {
				newDate = new Date(value);
			} else {
				newDate = new Date('0001-01-01T00:00:00Z');
			}

			if (newDate.getTime() !== props.experience.released.getTime()) {
				void experienceStore.updateReleaseDate(
					props.experience,
					newDate
				);
			}
		};

		const openAddTagModal = () => {
			modalStore.open(
				<ExperienceDetailsAddTagModal
					experience={props.experience}
					tags={props.tags}
				/>,
				{
					overflow: true,
				}
			);
		};

		return (
			<>
				<ViewPanel>
					{row(
						'Creator',
						<NavLink
							to={`/creators/${props.experience.creator_id}`}
							className={
								'hover:text-blue-500 dark:hover:text-blue-200 hover:underline inline-block max-w-fit'
							}
						>
							<ExperienceCreator
								creatorId={props.experience.creator_id}
							/>
						</NavLink>
					)}

					{row(
						'Players Online',
						<div
							className={'flex justify-start items-center gap-2'}
						>
							{new Intl.NumberFormat(navigator.language).format(
								props.experience.players_online
							)}

							{!!experienceStore.slimExperiences.length && (
								<div
									className={
										'flex justify-center items-center text-xs'
									}
								>
									(#
									{experienceStore.getExperienceRank(
										props.experience.place_id
									)}
									)
								</div>
							)}
						</div>
					)}

					{row(
						'Avg. Playtime (30d)',
						props.experience.average_playtime
							? formatSecondsDurationString(
									props.experience.average_playtime
								)
							: 'N/A'
					)}

					{row(
						'Visits',
						props.experience.total_visits >= million
							? `${formatNumberWithSuffix(
									props.experience.total_visits
								)}+`
							: new Intl.NumberFormat(navigator.language).format(
									props.experience.total_visits
								)
					)}

					{row(
						'Like Percent',
						`${(props.experience.like_percentage * 100).toFixed(2)}%`
					)}

					{row('Genre', genreLinks(props.experience))}

					{row('Age Limit', ageLimit(props.experience.age_limit))}

					{row('Last Updated', timeAgo(props.experience.updated))}

					{row('Status', status(props.experience.play_status))}

					{row(
						'Price',
						props.experience.price ? (
							<div
								className={
									'flex justify-start items-center gap-1'
								}
							>
								{props.experience.price}{' '}
								<img
									src={
										themeStore.lightTheme
											? '/robux-black.svg'
											: '/robux-white.svg'
									}
									alt={''}
									className={'w-6 h-6'}
								/>
							</div>
						) : (
							'Free'
						)
					)}

					{row(
						releaseDateSet ? 'Release Date' : 'Created Date',
						releaseDateSet ? (
							authStore.haveUserRole([
								UserRole.EDITOR,
								UserRole.ADMIN,
							]) ? (
								<DatePicker
									value={
										// Non set release dates are set to year 1
										releaseDateSet
											? props.experience.released.toLocaleDateString()
											: '-'
									}
									onChange={handleReleaseDateChange}
								/>
							) : releaseDateSet ? (
								props.experience.released.toLocaleDateString()
							) : (
								'-'
							)
						) : (
							props.experience.created.toLocaleDateString()
						)
					)}

					{row(
						'Curated Tags',
						<div
							className={
								'w-full flex justify-between items-center'
							}
						>
							<div className={'truncate'}>
								{props.tags.map((tag, index) => {
									return (
										<Link
											to={`/tags/${tag.id}`}
											title={tag.name}
											className={ClassString({
												static: 'h-full inline-flex items-center hover:underline',
												dynamic: {
													'mr-2':
														index <
														props.tags.length,
												},
											})}
											key={`tag-${tag.id}`}
										>
											{tag.name}
											{index < props.tags.length - 1
												? ', '
												: ''}
										</Link>
									);
								})}
							</div>

							<Icon
								icon={IconEnum.PLUS}
								size={'1.5rem'}
								className={ClassString({
									static: 'w-6 h-6 min-w-6 min-h-6 min border rounded-full hover:bg-blue-300 hover:text-gray-600 cursor-pointer flex justify-center items-center shadow-md',
									dynamic: {
										hidden: !authStore.havePermission(
											UserPermission.EDIT_PLACE
										),
									},
								})}
								onClick={openAddTagModal}
							/>
						</div>
					)}
				</ViewPanel>
			</>
		);
	}
);

export default ExperienceDetailsInfoCompact;
