import { observer } from 'mobx-react-lite';
import View from '@components/view/view.tsx';
import ExperienceTable from '@components/tables/experience-table.tsx';
import { useEffect, useState } from 'react';
import experienceStore from '@store/experience-store.ts';
import { IconEnum } from '@components/core/icon/icon-enum.ts';
import ViewPanel from '@components/view/view-panel.tsx';
import ExperienceSearch from '@views/experiences/view-components/experience-search.tsx';
import { SlimExperience } from '@/schemas/experience-schema.ts';
import { CustomCellRendererProps } from 'ag-grid-react';
import OptionsMenu from '@components/core/options-menu/options-menu.tsx';
import copyService from '@services/copy-service.ts';
import { useNavigate } from 'react-router';
import postHogService from '@services/posthog-service.ts';
import { PostHogEventType } from '@/enums/posthog-event.ts';
import httpFetch from '@services/http-fetch-service.ts';
import DownloadFileFromResponse from '@utils/download-file-from-response.ts';
import toastStore from '@store/toast-store.ts';
import { ToastType } from '@components/service/toast/toast-enums.ts';
import useHashQueryParams from '@hooks/use-hash-query-params.tsx';
import searchService from '@services/search-service.ts';
import SignUpCtaBanner from '@components/core/sign-up-cta/sign-up-cta-banner.tsx';
import modalStore from '@store/modal-store.ts';
import ModalSignUpCta from '@components/modal/modal-sign-up-cta.tsx';
import authStore from '@store/auth-store.ts';

const robloxGameUrl = 'https://www.roblox.com/games/';

const baseUrl = import.meta.env.VITE_SERVER_URL as string;

const Experiences = observer(function Experiences() {
	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useHashQueryParams();
	const [experiences, setExperiences] = useState<SlimExperience[]>([]);
	const [loading, setLoading] = useState(true);
	const initialized = experienceStore.initialized;

	useEffect(() => {
		if (initialized && loading) {
			let filteredExperiences = experienceStore.slimExperiences;
			if (searchParams.search && searchParams.search.length >= 3) {
				filteredExperiences = filteredExperiences.filter((exp) =>
					searchService.experience(exp, searchParams.search ?? '')
				);
			}

			setExperiences(filteredExperiences);
			setLoading(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [initialized, loading]);

	return (
		<View
			viewInfo={{ title: 'Experiences', icon: IconEnum.PLACE }}
			fullHeight={loading}
			loading={loading}
			headerChildren={
				<ExperienceSearch
					onSearch={(searchResult, searchTerm) => {
						setExperiences(searchResult);
						setSearchParams({
							...searchParams,
							search: searchTerm,
						});
					}}
					searchParams={searchParams.search}
				/>
			}
		>
			<SignUpCtaBanner thin />

			<ViewPanel options={{ noPadding: true, noOutline: true }}>
				<ExperienceTable
					experiences={experiences}
					contextMenu={{
						cellRenderer: (params: CustomCellRendererProps) => {
							const experience = params.node
								.data as SlimExperience;
							return (
								<OptionsMenu
									vertical
									buttons={[
										{
											title: 'View on roblox.com',
											onClick: () => {
												window.open(
													`${robloxGameUrl}${experience.place_id}`
												);
											},
										},
										{
											title: 'Compare to...',
											onClick: () => {
												if (authStore.isLoggedIn) {
													void navigate(
														`/compare/experiences#exp=${experience.place_id}`
													);
												} else {
													modalStore.open(
														<ModalSignUpCta />
													);
												}
											},
										},
										{
											title: 'Copy Experience ID',
											onClick: () => {
												copyService.experienceId(
													experience
												);
											},
										},
										{
											title: 'Copy Universe ID',
											onClick: () => {
												copyService.experienceUniverseId(
													experience
												);
											},
										},
										{
											title: 'Download Badge Stats',
											onClick: async () => {
												postHogService.capture({
													type: PostHogEventType.DownloadBadgeStats,
													experience:
														experience.canonical_name,
												});

												try {
													const res =
														await httpFetch.GET(
															`${baseUrl}/api/badges/${experience.place_id}`,
															true,
															false,
															{
																Accept: 'text/csv',
															}
														);
													await DownloadFileFromResponse(
														res,
														`${experience.canonical_name.toLowerCase().replaceAll(' ', '-')}-${experience.place_id}-badge-data`,
														'csv'
													);
												} catch (_) {
													toastStore.emit(
														'Could not download badge stats',
														ToastType.ERROR
													);
												}
											},
										},
									]}
									width={'14rem'}
								/>
							);
						},
						minWidth: 50,
						maxWidth: 50,
						resizable: false,
						sortable: false,
						cellStyle: {
							display: 'flex',
							justifyContent: 'flex-end',
							alignItems: 'center',
						},
						context: {
							priority: 1,
						},
					}}
				/>
			</ViewPanel>
		</View>
	);
});

export default Experiences;
