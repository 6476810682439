import { makeAutoObservable, runInAction } from 'mobx';
import { DataGranularity } from '@/enums/data-granularity.ts';
import {
	getExperienceStatUrl,
	getExperiencesToCollect,
	setEmptyStats,
	setExperienceStats,
} from '@store/experience-stats/experience-stat-helpers.ts';
import { ExperienceStatType } from '@/enums/experience-stat-type.ts';
import httpFetch from '@services/http-fetch-service.ts';
import {
	ExperienceStatsSchema,
	ExperienceTagStatsSchema,
} from '@/schemas/experience-stats-schema.ts';
import {
	getTagStatUrl,
	setEmptyTagStats,
} from '@store/experience-stats/tag-stat-helpers.ts';

class AveragePlaytimeStatsStore {
	private _averagePlaytimeStats: Record<
		number,
		Record<DataGranularity, number[][]>
	> = {};
	private _averageTagPlaytimeStats: Record<
		number,
		Record<DataGranularity, number[][]>
	> = {};

	constructor() {
		makeAutoObservable(this);
	}

	async loadAveragePlaytime(
		experienceIds: number[],
		granularity: DataGranularity
	) {
		const experiencesToCollect = getExperiencesToCollect(
			experienceIds,
			granularity,
			this._averagePlaytimeStats
		);

		if (!experiencesToCollect.length) {
			return;
		}

		const url = getExperienceStatUrl(
			experiencesToCollect,
			granularity,
			ExperienceStatType.PLAYTIME
		);
		const response = await httpFetch.GET(url, true, true);

		try {
			if (response.ok) {
				const data = ExperienceStatsSchema.parse(await response.json());
				const parsedData: Record<number, number[][]> = {};
				for (const experienceStat of data.series) {
					parsedData[experienceStat.place_id] = experienceStat
						? experienceStat.data.map((dataPoint) => {
								return [dataPoint[0], Math.floor(dataPoint[1])];
							})
						: [];
				}

				runInAction(() => {
					this._averagePlaytimeStats = setExperienceStats(
						parsedData,
						granularity,
						this._averagePlaytimeStats
					);
				});
				return;
			}
		} catch (_) {
			// Handle errors outside of catch
		}

		runInAction(() => {
			this._averagePlaytimeStats = setEmptyStats(
				experiencesToCollect,
				granularity,
				this._averagePlaytimeStats
			);
		});
	}

	averagePlaytime(experienceId: number, granularity: DataGranularity) {
		const data = this._averagePlaytimeStats[experienceId]?.[granularity];

		// Copy data to prevent making the inner data editable
		return data ? [...data] : undefined;
	}

	averagePlaytimes(experienceIds: number[], granularity: DataGranularity) {
		return experienceIds.map((id) => {
			return {
				place_id: id,
				data: this._averagePlaytimeStats[id]?.[granularity] ?? [],
			};
		});
	}

	async loadAveragePlaytimes(
		experienceIds: number[],
		granularity: DataGranularity
	): Promise<number[][]> {
		const url = `${getExperienceStatUrl(
			experienceIds,
			granularity,
			ExperienceStatType.PLAYTIME
		)}&aggregation=average`;
		const response = await httpFetch.GET(url, true, true);

		try {
			if (response.ok) {
				// Not a tag but the data is structured the same way
				const data = ExperienceTagStatsSchema.parse(
					await response.json()
				);
				return data.series[0]?.data ?? [];
			}
		} catch (_) {
			// Handle errors outside of catch
		}

		return [];
	}

	async loadAverageTagPlaytime(tagId: number, granularity: DataGranularity) {
		if (this._averageTagPlaytimeStats[tagId]?.[granularity]) {
			return;
		}

		const url = getTagStatUrl(
			tagId,
			granularity,
			ExperienceStatType.PLAYTIME
		);
		const response = await httpFetch.GET(url, true, true);

		try {
			if (response.ok) {
				const data = ExperienceTagStatsSchema.parse(
					await response.json()
				);

				runInAction(() => {
					const updatedTagPlaytimeStats = {
						...this._averageTagPlaytimeStats,
					};
					updatedTagPlaytimeStats[tagId][granularity] =
						data.series[0].data;

					this._averageTagPlaytimeStats = updatedTagPlaytimeStats;
				});
				return;
			}
		} catch (_) {
			// Handle errors outside of catch
		}

		runInAction(() => {
			this._averageTagPlaytimeStats = setEmptyTagStats(
				tagId,
				granularity,
				this._averageTagPlaytimeStats
			);
		});
	}

	averageTagPlaytime(tagId: number, granularity: DataGranularity) {
		const data = this._averageTagPlaytimeStats[tagId]?.[granularity];

		return data ? [...data] : undefined;
	}
}

const averagePlaytimeStatsStore = new AveragePlaytimeStatsStore();
export default averagePlaytimeStatsStore;
