import { observer } from 'mobx-react-lite';
import Highcharts from 'highcharts';
import Chart from '@components/core/chart/chart.tsx';
import { useEffect } from 'react';
import platformStatsStore from '@store/platform-stats-store.ts';
import { FullExperience } from '@/schemas/experience-schema.ts';
import ChartColors from '@components/core/chart/chart-colors.ts';
import getCycledItem from '@utils/get-cycled-item.ts';
import BrandColors from '@styles/_brand_colors.ts';
import ThemeStore from '@store/theme-store.ts';

interface PlaceGenreDistributionProps {
	title: string;
	places?: FullExperience[];
	height?: number;
}

const PlaceGenreDistribution = observer(function PlaceGenreDistribution(
	props: PlaceGenreDistributionProps
) {
	let data: Record<string, Record<string, number>> = {};

	if (props.places) {
		const groupedExperiences: Record<string, FullExperience[]> = {};
		props.places.forEach((exp) => {
			const expGenre = exp.genre_l1 ? exp.genre_l1 : 'Unlabeled';
			if (groupedExperiences[expGenre]) {
				groupedExperiences[expGenre].push(exp);
			} else {
				groupedExperiences[expGenre] = [exp];
			}
		});

		Object.entries(groupedExperiences).forEach(([genre, experiences]) => {
			const genreGroupedExperiences = experiences;
			const subGenres: Record<string, number> = {};
			genreGroupedExperiences.forEach((exp) => {
				const subGenre = exp.genre_l2 ? exp.genre_l2 : 'Unlabeled';
				if (subGenres[subGenre]) {
					subGenres[subGenre] = subGenres[subGenre] + 1;
				} else {
					subGenres[subGenre] = 1;
				}
			});

			data[genre] = subGenres;
		});
	} else {
		data = platformStatsStore.platformGenreDistribution;
	}

	const genreData: Highcharts.PointOptionsObject[] = [];
	const subGenreData: Highcharts.PointOptionsObject[] = [];

	const colors = ChartColors();
	Object.entries(data).forEach(([genre, subGenres], index) => {
		genreData.push({
			name: genre,
			y: Object.values(subGenres).reduce(
				(acc, current) => acc + current,
				0
			),
			id: genre,
			color: getCycledItem(index, colors),
		});

		Object.entries(subGenres).forEach(
			([subGenreName, subGenreValue], subGenreIndex) => {
				subGenreData.push({
					name: subGenreName,
					y: subGenreValue,
					parent: genre,
					color: Highcharts.color(getCycledItem(index, colors))
						.brighten(
							ThemeStore.lightTheme
								? -0.05 - subGenreIndex / 50
								: 0.05 + subGenreIndex / 40
						)
						.get(),
				});
			}
		);
	});

	useEffect(() => {
		if (!props.places) {
			void platformStatsStore.loadGenreDistribution();
		}
	}, [props.places]);

	const options: Highcharts.Options = {
		chart: {
			type: 'pie',
			height: props.height,
		},
		series: [
			{
				name: 'Experiences',
				data: genreData,
				type: 'pie',
				size: '45%',
			},
			{
				name: 'Sub Genres',
				data: subGenreData,
				id: 'versions',
				type: 'pie',
				size: '80%',
				innerSize: '60%',
			},
		],
		plotOptions: {
			pie: {
				shadow: false,
				center: ['50%', '50%'],
				borderColor: ThemeStore.darkTheme
					? BrandColors.gray500
					: BrandColors.white,
				states: {
					inactive: {
						opacity: 0.5,
					},
				},
			},
			series: {
				allowPointSelect: true,
				cursor: 'pointer',
				dataLabels: [
					{
						enabled: true,
						distance: 20,
						allowOverlap: true,
					} as Highcharts.DataLabelsOptions,
					{
						enabled: true,
						distance: -40,
						format: '{point.percentage:.1f}%',
						allowOverlap: true,
						style: {
							fontSize: '1.1em',
							textOutline: 'none',
							opacity: 0.7,
						},
						filter: {
							operator: '>',
							property: 'percentage',
							value: 5,
						},
					} as Highcharts.DataLabelsOptions,
				],
			},
		},
		tooltip: {
			useHTML: true,
			formatter: function () {
				const subGenreText =
					this.point.name === '—'
						? '— (no sub-genre)<br/>'
						: `${this.point.name}<br/>`;

				return `${subGenreText}
            <b style="font-size: 16px">${this.point.percentage?.toFixed(1)}%</b> <br/>
            <b>${this.y}</b> Experiences`;
			},
		},
		rangeSelector: {
			enabled: false,
		},
		responsive: {
			rules: [
				{
					condition: {
						maxWidth: 580,
					},
					chartOptions: {
						chart: {
							height: 500,
						},
					},
				},
			],
		},
	};

	return (
		<Chart
			title={props.title}
			highcharts={Highcharts}
			options={options}
			disableResponsiveHeight
			fillPanelResponsiveWidth
			fullHeightInFullScreen
		/>
	);
});

export default PlaceGenreDistribution;
