import { observer } from 'mobx-react-lite';
import SlideToggle from '@components/core/slide-toggle/slide-toggle.tsx';

interface CookieSlideTogglesProps {
	sessionTracking: {
		initialState: boolean;
		onToggle: (state: boolean) => unknown;
	};
}

const CookieSlideToggles = observer(function CookieSlideToggles({
	sessionTracking,
}: CookieSlideTogglesProps) {
	return (
		<>
			<div
				className={
					'w-full flex justify-between items-center gap-8 pl-4'
				}
			>
				<SlideToggle
					initialState={true}
					toggleBackgroundColor
					smallButton
					disabled
				/>
				<div className={'flex flex-col justify-center items-start'}>
					<div className={'text-lg'}>Necessary</div>
					Cookies that are essential for the app to function properly,
					such as maintaining your authentication when logged in and
					preserving app states.
				</div>
			</div>

			<div
				className={
					'w-full flex justify-between items-center gap-8 pl-4'
				}
			>
				<SlideToggle
					initialState={sessionTracking.initialState}
					onToggle={sessionTracking.onToggle}
					toggleBackgroundColor
					smallButton
				/>
				<div className={'flex flex-col justify-center items-start'}>
					<div className={'text-lg'}>Analytics</div>
					Cookies that store session data when collecting anonymous
					usage insights, helping us improve your experience across
					visits.
				</div>
			</div>
		</>
	);
});

export default CookieSlideToggles;
