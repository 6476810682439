import { z } from 'zod';

interface GenreCCU {
	stat: string;
	series: GenreCCUSeries[];
}

export interface GenreCCUSeries {
	name: string;
	data: number[][];
}

export const GenreCCUSeriesSchema: z.ZodType<GenreCCUSeries> = z.object({
	name: z.string(),
	data: z.array(z.array(z.number())),
});

export const GenreCCUSchema: z.ZodType<GenreCCU> = z.object({
	stat: z.string(),
	series: z.array(GenreCCUSeriesSchema),
});
