const theHaunt2024Start = new Date('2024-10-24T00:00:00.000Z');
const theHaunt2024End = new Date('2024-11-02T23:59:59.999Z');
const winterSpotlight2024Start = new Date('2024-12-17T00:00:00.000Z');
const winterSpotlight2024End = new Date('2025-01-02T23:59:59.999Z');
const theHuntStart = new Date('2025-03-14T00:00:00.000Z');
const theHuntEnd = new Date('2025-03-24T23:59:59.999Z');

const DailyPicksEventPeriods = (date: Date) => {
	const timestamp = date.getTime();
	if (
		timestamp > theHaunt2024Start.getTime() &&
		timestamp < theHaunt2024End.getTime()
	) {
		return 'No daily picks during this period because of "The Haunt" event';
	}

	if (
		timestamp > winterSpotlight2024Start.getTime() &&
		timestamp < winterSpotlight2024End.getTime()
	) {
		return 'No daily picks during this period because of "Winter Spotlight" event';
	}

	if (
		timestamp > theHuntStart.getTime() &&
		timestamp < theHuntEnd.getTime()
	) {
		return 'No daily picks during this period because of "The Hunt" event';
	}

	return 'No daily picks during this period';
};

export default DailyPicksEventPeriods;
