import { observer } from 'mobx-react-lite';
import View from '@components/view/view.tsx';
import ViewPanel from '@components/view/view-panel.tsx';
import experienceStore from '@store/experience-store.ts';
import { useState } from 'react';
import { SlimExperience } from '@/schemas/experience-schema.ts';
import { IconEnum } from '@components/core/icon/icon-enum.ts';
import Button from '@components/core/button/button.tsx';
import CopyToClipboard from '@utils/copy-to-clipboard.ts';
import toastStore from '@store/toast-store.ts';
import { ToastType } from '@components/service/toast/toast-enums.ts';
import { PlaceTag } from '@/schemas/plage-tag-schema.ts';
import TagSelector from '@components/core/tag-selector/tag-selector.tsx';
import ChartColors from '@components/core/chart/chart-colors.ts';
import DataComparisonRow from '@components/data/data-comparison-row/data-comparison-row.tsx';
import formatNumberWithSuffix from '@utils/format-number-with-suffix.ts';
import placeTagStore from '@store/place-tag-store.ts';
import TagVsTagCcu from '@components/data/tag-vs-tag-ccu/tag-vs-tag-cuu.tsx';
import { CCUDataSegment } from '@store/place-stats-store.ts';
import ViewTabPanel from '@components/view/view-tab-panel.tsx';
import TagVsTagPlaytime from '@components/data/tag-vs-tag-playtime/tag-vs-tag-playtime.tsx';

const TagComparison = observer(function TagComparison() {
	const [tag1, setTag1] = useState<PlaceTag | undefined>(undefined);
	const [tag2, setTag2] = useState<PlaceTag | undefined>(undefined);

	const [ccuChartState, setCCUChartState] = useState(CCUDataSegment.ACTIVE);

	const tagsSelected = !!tag1 && !!tag2;

	const loading =
		!experienceStore.slimExperiences.length || !placeTagStore.placeTags;

	const tag1Experiences: SlimExperience[] = tag1
		? tag1.place_ids
				.map((id) => experienceStore.getSlimExperienceById(id))
				.filter((exp) => !!exp)
		: [];
	const averageTag1PlayerCount = +(
		tag1Experiences
			.map((exp) => exp.players_online)
			.reduce((acc, curr) => acc + curr, 0) / tag1Experiences.length
	).toFixed(0);

	const averageTag1LikeRatio =
		tag1Experiences
			.map((exp) => exp.like_percentage)
			.reduce((acc, curr) => acc + curr, 0) / tag1Experiences.length;

	const averageTag1Visits = +(
		tag1Experiences
			.map((exp) => exp.total_visits)
			.reduce((acc, curr) => acc + curr, 0) / tag1Experiences.length
	).toFixed(0);

	const tag2Experiences: SlimExperience[] = tag2
		? tag2.place_ids
				.map((id) => experienceStore.getSlimExperienceById(id))
				.filter((exp) => !!exp)
		: [];
	const averageTag2PlayerCount = +(
		tag2Experiences
			.map((exp) => exp.players_online)
			.reduce((acc, curr) => acc + curr, 0) / tag2Experiences.length
	).toFixed(0);

	const averageTag2LikeRatio =
		tag2Experiences
			.map((exp) => exp.like_percentage)
			.reduce((acc, curr) => acc + curr, 0) / tag2Experiences.length;

	const averageTag2Visits = +(
		tag2Experiences
			.map((exp) => exp.total_visits)
			.reduce((acc, curr) => acc + curr, 0) / tag2Experiences.length
	).toFixed(0);

	const copyLink = () => {
		CopyToClipboard(window.location.href);
		toastStore.emit(
			'Comparison link has been copied to your clipboard.',
			ToastType.INFO
		);
	};

	return (
		<View
			viewInfo={{
				title: 'Compare Tags',
				icon: IconEnum.COMPARE,
				breadCrumbs: [{ title: 'Compare' }],
			}}
			loading={loading}
			hideHeader
		>
			<div
				className={
					'w-full flex flex-wrap justify-evenly items-center gap-6'
				}
			>
				<ViewPanel className={'w-fit'}>
					<div className={'flex flex-wrap justify-evenly'}>
						<div
							className={
								'flex flex-col justify-center items-center max-w-96 lg:h-72 m-2'
							}
						>
							<div>
								Select two tags to display comparative stats.
							</div>

							<div className={'mt-2'}>
								You can also bookmark a comparison to quickly
								return to it or share the comparison with
								someone else.
							</div>

							<div className={'mt-6'}>
								<Button
									title={'Share'}
									icon={{
										icon: IconEnum.SHARE,
										placement: 'left',
									}}
									onClick={copyLink}
								/>
							</div>
						</div>
					</div>
				</ViewPanel>

				<div
					className={
						'flex justify-evenly items-center gap-6 flex-wrap'
					}
				>
					<TagSelector
						tag={tag1}
						setTag={setTag1}
						index={1}
						color={ChartColors()[0]}
					/>

					<TagSelector
						tag={tag2}
						setTag={setTag2}
						index={2}
						color={ChartColors()[1]}
					/>
				</div>
			</div>

			{tagsSelected && (
				<>
					<ViewPanel>
						<div className={'flex flex-col justify-center'}>
							<DataComparisonRow
								label={'Name'}
								dataLeft={tag1?.name}
								dataRight={tag2?.name}
								borderBottom
							/>

							<DataComparisonRow
								label={'Player Count'}
								dataLeft={`Avg. ${averageTag1PlayerCount}`}
								dataRight={`Avg. ${averageTag2PlayerCount}`}
								dataDifference={{
									direction:
										averageTag1PlayerCount >
										averageTag2PlayerCount
											? 'left'
											: 'right',
									data:
										averageTag1PlayerCount >
										averageTag2PlayerCount
											? `${averageTag1PlayerCount - averageTag2PlayerCount}`
											: `${averageTag2PlayerCount - averageTag1PlayerCount}`,
								}}
								borderBottom
							/>

							<DataComparisonRow
								label={'Like %'}
								dataLeft={`Avg. ${(averageTag1LikeRatio * 100).toFixed(2)}%`}
								dataRight={`Avg. ${(averageTag2LikeRatio * 100).toFixed(2)}%`}
								dataDifference={{
									direction:
										averageTag1LikeRatio >
										averageTag2LikeRatio
											? 'left'
											: 'right',
									data:
										averageTag1LikeRatio >
										averageTag2LikeRatio
											? `${((averageTag1LikeRatio - averageTag2LikeRatio) * 100).toFixed(2)}%`
											: `${((averageTag2LikeRatio - averageTag1LikeRatio) * 100).toFixed(2)}%`,
								}}
								borderBottom
							/>

							<DataComparisonRow
								label={'Visits'}
								dataLeft={`Avg. ${formatNumberWithSuffix(
									averageTag1Visits
								)}`}
								dataRight={`Avg. ${formatNumberWithSuffix(
									averageTag2Visits
								)}`}
								dataDifference={{
									direction:
										averageTag1Visits > averageTag2Visits
											? 'left'
											: 'right',
									data:
										averageTag1Visits > averageTag2Visits
											? formatNumberWithSuffix(
													averageTag1Visits -
														averageTag2Visits
												)
											: formatNumberWithSuffix(
													averageTag2Visits -
														averageTag1Visits
												),
								}}
							/>
						</div>
					</ViewPanel>

					<ViewTabPanel
						tabs={[
							{
								key: CCUDataSegment.ACTIVE,
								title: 'Active',
								tooltip:
									'Only include experiences with active players at the specific timestamp.',
							},
							{
								key: CCUDataSegment.TOP_FIVE,
								title: 'Top Five',
								tooltip: (
									<div>
										{
											'Only include the top 5 experiences of the tag in terms of current player count.'
										}
										<div
											className={
												'mt-2 mb-1 font-semibold'
											}
										>
											{tag1?.name}
										</div>
										<ul className="pl-5 mt-1 list-disc">
											{tag1Experiences
												.sort(
													(a, b) =>
														b.players_online -
														a.players_online
												)
												.slice(0, 5)
												.map((exp) => {
													return (
														<li key={exp.place_id}>
															{exp.canonical_name}
														</li>
													);
												})}
										</ul>
										<div
											className={
												'mt-2 mb-1 font-semibold'
											}
										>
											{tag2?.name}
										</div>
										<ul className="pl-5 mt-1 list-disc">
											{tag2Experiences
												.sort(
													(a, b) =>
														b.players_online -
														a.players_online
												)
												.slice(0, 5)
												.map((exp) => {
													return (
														<li key={exp.place_id}>
															{exp.canonical_name}
														</li>
													);
												})}
										</ul>
									</div>
								),
							},
							{
								key: CCUDataSegment.ALL,
								title: 'All',
								tooltip:
									'Include all experiences that was created at or after each specific timestamp.',
							},
						]}
						onTabChange={(tab) => {
							setCCUChartState(tab);
						}}
					>
						<TagVsTagCcu
							tags={[
								{
									tag: tag1,
									tagExperiences: tag1Experiences,
								},
								{
									tag: tag2,
									tagExperiences: tag2Experiences,
								},
							]}
							state={ccuChartState}
							title={'Tag vs Tag Average CCU'}
						/>
					</ViewTabPanel>

					<ViewPanel>
						<TagVsTagPlaytime
							tags={[tag1, tag2]}
							title={'Tag vs Tag Average Playtime'}
						/>
					</ViewPanel>
				</>
			)}
		</View>
	);
});

export default TagComparison;
