import { z } from 'zod';

export interface PlatformMovement {
	place_id: number;
	rank: number;
	gain: number;
	old_rank: number;
}

export const PlatformMovementSchema: z.ZodType<PlatformMovement> = z.object({
	place_id: z.number(),
	rank: z.number(),
	gain: z.number(),
	old_rank: z.number(),
});

export const PlatformMovementListSchema: z.ZodType<PlatformMovement[]> =
	z.array(PlatformMovementSchema);
