import { observer } from 'mobx-react-lite';
import { Experience } from '@/schemas/experience-schema.ts';
import {
	DefaultIcons,
	ExperienceIconSize,
} from '@components/data/experience-icon/experience-icon-constants.ts';
import SeededRandom from '@utils/seeded-random.ts';
import { useEffect, useState } from 'react';
import thumbnailStore from '@store/thumbnail-store.ts';
import LoadingSpinner from '@components/core/loading-spinner/loading-spinner.tsx';

interface ExperienceIconProps {
	experience?: Experience;
	size: ExperienceIconSize;
	className?: string;
}

const ExperienceIcon = observer(function ExperienceIcon(
	props: ExperienceIconProps
) {
	let hash: string | undefined = undefined;

	if (!props.experience) {
		hash = 'default';
	} else if (props.experience.thumbnails?.icon !== undefined) {
		hash = props.experience.thumbnails.icon;
	} else if (props.experience) {
		hash = thumbnailStore.getLatestThumbnailHash(props.experience);
	}

	const defaultIcon =
		DefaultIcons[
			Math.floor(
				SeededRandom(
					props.experience ? +props.experience.place_id : 0
				) * DefaultIcons.length
			)
		];

	const [imgSrc, setImgSrc] = useState<string | undefined>(
		props.experience && !!hash && hash !== 'default'
			? `https://tr.rbxcdn.com/180DAY-${hash}/${props.size}/Image/Webp/noFilter`
			: defaultIcon
	);
	const onError = () => setImgSrc(defaultIcon);

	useEffect(() => {
		setImgSrc(
			props.experience && !!hash && hash !== 'default'
				? `https://tr.rbxcdn.com/180DAY-${hash}/${props.size}/Image/Webp/noFilter`
				: defaultIcon
		);
	}, [defaultIcon, hash, props.experience, props.size]);

	return (
		<>
			{hash && (
				<img
					src={imgSrc ? imgSrc : defaultIcon}
					alt={''}
					className={props.className}
					loading={'lazy'}
					onError={onError}
					draggable={false}
				/>
			)}

			{!hash && <LoadingSpinner className={props.className} />}
		</>
	);
});

export default ExperienceIcon;
