import { useState, useEffect } from 'react';
import IsMobile from '@utils/is-mobile.ts';

const useIsMobile = () => {
	const [isMobile, setIsMobile] = useState(false);

	useEffect(() => {
		const checkMobile = () => {
			setIsMobile(IsMobile);
		};

		checkMobile();
	}, []);

	return isMobile;
};

export default useIsMobile;
