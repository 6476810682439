import { observer } from 'mobx-react-lite';
import Highcharts from 'highcharts';
import Chart from '@components/core/chart/chart.tsx';
import { useCallback, useEffect, useState } from 'react';
import { isValidGranularity } from '@store/place-stats-store.ts';
import LoadingIndicator from '@components/core/loading-indicator/loading-indicator.tsx';
import { Experience } from '@/schemas/experience-schema.ts';
import { PlaceTag } from '@/schemas/plage-tag-schema.ts';
import GranularitySelector from '@components/data/granularity-selector/granularity-selector.tsx';
import hashQueryParamService from '@services/hash-query-param-service.ts';
import getGraphId from '@utils/get-graph-id.ts';
import { DataGranularity } from '@/enums/data-granularity.ts';
import averagePlaytimeStatsStore from '@store/experience-stats/average-playtime-stats-store.ts';
import experienceStore from '@store/experience-store.ts';
import formatSecondsDurationString from '@utils/format-seconds-duration-string.ts';

interface ExperienceVsTagPlaytimeProps {
	experiences: Experience[];
	tag: PlaceTag;
	title: string;
	options?: {
		defaultGranularity?: DataGranularity;
		selectedRange?: number;
	};
}

const getDefaultGranularity = (props: ExperienceVsTagPlaytimeProps) => {
	const hashParams = hashQueryParamService.get();
	if (
		hashParams.granularity &&
		hashParams.anchor === getGraphId(props.title) &&
		isValidGranularity(hashParams.granularity)
	) {
		return hashParams.granularity as DataGranularity;
	}

	if (props.options?.defaultGranularity) {
		return props.options.defaultGranularity;
	}

	return DataGranularity.DAY;
};

const ExperienceVsTagPlaytime = observer(function ExperienceVsTagPlaytime(
	props: ExperienceVsTagPlaytimeProps
) {
	const [granularity, setGranularity] = useState(
		getDefaultGranularity(props)
	);

	const [experiencesLoading, setExperiencesLoading] = useState(true);
	const [tagsLoading, setTagsLoading] = useState(true);
	const loading = experiencesLoading || tagsLoading;

	const [averageExperiencesData, setAverageExperiencesData] = useState<
		number[][]
	>([]);

	const getExperienceData = useCallback(async () => {
		const ids = props.experiences.map((exp) => exp.place_id);

		const [data] = await Promise.all([
			averagePlaytimeStatsStore.loadAveragePlaytimes(ids, granularity),
			averagePlaytimeStatsStore.loadAveragePlaytime(ids, granularity),
		]);

		setAverageExperiencesData(data);
		setExperiencesLoading(false);
	}, [granularity, props.experiences]);

	useEffect(() => {
		setExperiencesLoading(true);
		void getExperienceData();
	}, [getExperienceData, props.experiences]);

	const getTagData = useCallback(
		async (granularity: DataGranularity) => {
			await averagePlaytimeStatsStore.loadAverageTagPlaytime(
				props.tag.id,
				granularity
			);
			setTagsLoading(false);
		},
		[props.tag]
	);

	const experiencePlaytimeSeries: Highcharts.SeriesOptionsType[] =
		averagePlaytimeStatsStore
			.averagePlaytimes(
				props.experiences.map((exp) => exp.place_id),
				granularity
			)
			.map((playtime) => {
				return {
					name:
						experienceStore.getSlimExperienceById(playtime.place_id)
							?.name ?? 'Experience',
					type: 'line',
					showInLegend: true,
					data: playtime.data,
					marker: {
						enabled: false,
					},
					lineWidth: 3,
				};
			});

	let series: Highcharts.SeriesOptionsType[] = [];
	series = [
		{
			name: props.tag.name,
			type: 'line',
			showInLegend: true,
			data: averagePlaytimeStatsStore.averageTagPlaytime(
				props.tag.id,
				granularity
			),
			marker: {
				enabled: false,
			},
			lineWidth: 3,
		},
		...((props.experiences.length > 1
			? [
					{
						name: 'Experiences Avg.',
						type: 'line',
						showInLegend: true,
						data: averageExperiencesData,
						marker: {
							enabled: false,
						},
						lineWidth: 3,
					},
				]
			: []) as Highcharts.SeriesOptionsType[]),
		...experiencePlaytimeSeries,
	];

	let xMin = 0;
	let xMax = 0;
	series.forEach((s) => {
		const sWD = s as unknown as { data: number[][] };
		if (!sWD.data) {
			return;
		}

		sWD.data.forEach((d) => {
			if (!d) {
				return;
			}

			const date = d[0];

			if (xMin === 0) {
				xMin = date;
			}

			if (xMax === 0) {
				xMax = date;
			}

			if (date < xMin) {
				xMin = date;
			}

			if (date > xMax) {
				xMax = date;
			}
		});
	});

	const options: Highcharts.Options = {
		series,
		chart: {
			marginLeft: 65,
		},
		xAxis: {
			type: 'datetime',
			min: xMin,
			max: xMax,
		},
		yAxis: {
			title: {
				enabled: false,
			} as Highcharts.YAxisOptions,
			labels: {
				x: -10,
				y: 3,
				reserveSpace: false,
				style: {
					whiteSpace: 'nowrap',
				},
				formatter: (
					formatter: Highcharts.AxisLabelsFormatterContextObject
				) => {
					const value = formatter.value as number;
					const hours = Math.floor(value / 3600);
					const minutes = Math.floor((value % 3600) / 60);
					const seconds = value % 60;

					if (hours > 0) {
						return `${hours}h ${minutes}m`;
					} else if (minutes > 0) {
						return `${minutes}m ${seconds}s`;
					}
					return `${seconds}s`;
				},
			},
		},
		plotOptions: {
			line: {
				marker: {
					enabled: false,
				},
			},
			column: {
				stacking: 'normal',
				dataLabels: {
					enabled: true,
				},
			},
		},
		navigator: {
			enabled: true,
			series: series.map((serie) => {
				return {
					...serie,
					lineWidth: 1,
					showInLegend: false,
				};
			}),
			xAxis: {
				min: xMin,
				max: xMax,
			},
		},
		scrollbar: {
			enabled: true,
			liveRedraw: true,
		},
		rangeSelector: {
			enabled: true,
			selected: props.options?.selectedRange ?? 2,
		},
		tooltip: {
			pointFormatter: function () {
				// Handle line series (single y value)
				if (this.y === undefined) {
					return '';
				}
				const value = this.y;
				const color = this.color as string;
				const formattedTime = formatSecondsDurationString(value);
				return `<span style="color:${color}">●</span> ${this.series.name}: <b>${formattedTime}</b><br/>`;
			},
			shared: true,
			split: false,
			distance: 10,
			snap: 8,
		},
	};

	const updateGranularity = (g: DataGranularity) => {
		void getTagData(g);
		setGranularity(g);
		setTagsLoading(true);
	};

	useEffect(() => {
		void getTagData(granularity);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		void getTagData(granularity);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.tag]);

	return (
		<div>
			<div className={'relative'}>
				<Chart
					title={props.title}
					highcharts={Highcharts}
					options={options}
					granularity={granularity}
				/>

				{loading && (
					<div
						className={
							'absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
						}
					>
						<LoadingIndicator />
					</div>
				)}
				{/*{noData && (*/}
				{/*	<div*/}
				{/*		className={*/}
				{/*			'absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'*/}
				{/*		}*/}
				{/*	>*/}
				{/*		No Data*/}
				{/*	</div>*/}
				{/*)}*/}
			</div>

			<div
				className={'flex items-center justify-between gap-4 flex-wrap'}
			>
				<GranularitySelector
					updateGranularity={updateGranularity}
					selectedGranularity={granularity}
				/>
			</div>
		</div>
	);
});

export default ExperienceVsTagPlaytime;
