import { observer } from 'mobx-react-lite';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import {
	ColDef,
	FirstDataRenderedEvent,
	GridSizeChangedEvent,
	PaginationChangedEvent,
	SortChangedEvent,
	RowClassParams,
	RowStyle,
	GridApi,
	GridReadyEvent,
} from 'ag-grid-community';
import './table.css';
import themeStore from '@store/theme-store.ts';
import ClassString from '@utils/class-string.ts';
import localStorageService from '@services/local-storage-service.ts';
import authStore from '@store/auth-store.ts';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDebounce } from '@uidotdev/usehooks';

interface NewTableProps<TData> {
	columnDefs: CustomColDef<TData>[];
	rowData: TData[];
	tableName: string;
	rowHeight?: number;
	fillParent?: boolean;
	disablePagination?: boolean;
	suppressCellFocus?: boolean;
	onPaginationChanged?: (params: PaginationChangedEvent) => unknown;
	onFirstDataRendered?: (params: FirstDataRenderedEvent) => unknown;
	onSortChanged?: (params: SortChangedEvent) => unknown;
	isHidden?: boolean;
	getRowStyle?: (params: RowClassParams<TData>) => RowStyle | undefined;
}

export interface CustomColDef<TData> extends ColDef<TData> {
	context?: {
		priority?: number;
	};
}

const getPageSize = (tableName: string): number => {
	const storedPageSize = localStorageService.get(
		`${tableName}-table-size`,
		authStore.user?.id
	);
	return storedPageSize ? +storedPageSize : 20;
};

const TGSTable = observer(function TGSTable<TData>(
	props: NewTableProps<TData>
) {
	const [fade, setFade] = useState(false);

	const tableContainer = useRef<HTMLDivElement>(null);
	const tableApi = useRef<GridApi<TData> | undefined>();

	const [gridWidth, setGridWidth] = useState(0);
	const resizeDebounce = useDebounce(gridWidth, 100);

	const onGridReady = (params: GridReadyEvent) => {
		params.api.sizeColumnsToFit();
	};

	const onFirstDataRendered = (params: FirstDataRenderedEvent) => {
		if (props.onFirstDataRendered) {
			props.onFirstDataRendered(params);
		}

		// Avoid column jumping at render
		if (tableContainer.current) {
			const width = tableContainer.current.clientWidth;
			const api = params.api as GridApi<TData>;
			tableApi.current = api;
			setTimeout(() => {
				setColumns(width, api);
			}, 50);
		}
	};

	const onPaginationChanged = (params: PaginationChangedEvent) => {
		if (props.onPaginationChanged) {
			props.onPaginationChanged(params);
		}

		if (params.newPageSize) {
			localStorageService.set(
				`${props.tableName}-table-size`,
				params.api.paginationGetPageSize().toString(),
				authStore.user?.id
			);
		}
	};

	const onSortChanged = (params: SortChangedEvent) => {
		if (props.onSortChanged) {
			props.onSortChanged(params);
		}
	};

	const onGridSizeChanged = (params: GridSizeChangedEvent) => {
		setGridWidth(params.clientWidth);
	};

	const setColumns = useCallback((gridWidth: number, api: GridApi<TData>) => {
		if (api.isDestroyed()) {
			return;
		}

		// keep track of which columns to hide/show
		const columnsToShow = [];
		const columnsToHide = [];

		// iterate over all columns (visible or not) and work out
		// now many columns can fit (based on their minWidth)
		let totalColsWidth = 0;
		const allColumns = api.getColumns();

		if (allColumns) {
			allColumns.sort((a, b) => {
				const colDefA = a.getColDef() as CustomColDef<TData>;
				const colDefB = b.getColDef() as CustomColDef<TData>;
				const priorityA =
					colDefA.context?.priority ?? Number.MAX_SAFE_INTEGER;
				const priorityB =
					colDefB.context?.priority ?? Number.MAX_SAFE_INTEGER;
				return priorityA - priorityB;
			});
		}

		if (allColumns && allColumns.length > 0) {
			for (const column of allColumns) {
				totalColsWidth += column.getMinWidth();
				if (totalColsWidth > gridWidth) {
					columnsToHide.push(column.getColId());
				} else {
					columnsToShow.push(column.getColId());
				}
			}
		}

		// show/hide columns based on current grid width
		api.setColumnsVisible(columnsToShow, true);
		api.setColumnsVisible(columnsToHide, false);

		api.sizeColumnsToFit();
	}, []);

	useEffect(() => {
		if (tableContainer.current && tableApi.current) {
			setColumns(resizeDebounce, tableApi.current);
		}
	}, [resizeDebounce, setColumns]);

	const onColumnResized = () => {
		if (!fade) {
			setFade(true);
		}
	};

	const pageSize = getPageSize(props.tableName);

	useEffect(() => {
		if (tableContainer.current && tableApi.current) {
			const width = tableContainer.current.clientWidth;
			setColumns(width, tableApi.current);
		}
	}, [props.rowData, setColumns]);

	return (
		<div
			className={ClassString({
				static: 'w-full opacity-0 transition-opacity',
				dynamic: {
					'ag-theme-quartz': themeStore.lightTheme,
					'ag-theme-quartz-dark': themeStore.darkTheme,
					'opacity-100': fade,
				},
			})}
			ref={tableContainer}
		>
			<AgGridReact
				columnDefs={props.columnDefs}
				rowData={props.rowData}
				domLayout={'autoHeight'}
				paginationPageSizeSelector={true}
				pagination={!props.disablePagination}
				suppressCellFocus={props.suppressCellFocus ?? true}
				stopEditingWhenCellsLoseFocus={true}
				suppressDragLeaveHidesColumns={true}
				onGridSizeChanged={onGridSizeChanged}
				onFirstDataRendered={onFirstDataRendered}
				paginationPageSize={pageSize}
				onPaginationChanged={onPaginationChanged}
				onSortChanged={onSortChanged}
				rowHeight={props.rowHeight}
				tooltipShowDelay={100}
				onColumnResized={onColumnResized}
				onGridReady={onGridReady}
				defaultColDef={{
					flex: 1,
				}}
				className={ClassString({
					static: 'w-full',
					dynamic: {
						'[&>.ag-root-wrapper]:border-0 [&>.ag-root-wrapper]:rounded-none':
							props.fillParent,
						'[&_.ag-center-cols-viewport]:min-h-0!':
							props.rowData.length > 0,
					},
				})}
				getRowStyle={props.getRowStyle}
			/>
		</div>
	);
});

export default TGSTable;
