import { DataGranularity } from '@/enums/data-granularity.ts';

function getNearestPreviousDate(
	unit: DataGranularity,
	currentDate: Date = new Date()
): Date {
	const date = new Date(currentDate); // Create a copy of the provided date

	switch (unit) {
		case DataGranularity.HOUR:
			return getPreviousUTCHour(date);
		case DataGranularity.DAY:
			return getPreviousUTCDay(date);
		case DataGranularity.WEEK:
			return getPreviousUTCWeek(date);
		case DataGranularity.MONTH:
			return getPreviousUTCMonth(date);
	}
}

function getPreviousUTCHour(date: Date): Date {
	date.setUTCMinutes(1, 0, 0); // Reset minutes, seconds, milliseconds in UTC
	return date;
}

function getPreviousUTCDay(date: Date): Date {
	date.setUTCHours(1, 0, 0, 0); // Reset hours, minutes, seconds, milliseconds in UTC
	return date;
}

function getPreviousUTCWeek(date: Date): Date {
	const dayOfWeek = date.getUTCDay(); // Get current day of the week (0 = Sunday) in UTC
	const diffToMonday = (dayOfWeek + 6) % 7; // Calculate how many days ago Monday was
	date.setUTCDate(date.getUTCDate() - diffToMonday); // Set date to previous Monday in UTC
	date.setUTCHours(1, 0, 0, 0); // Reset hours, minutes, seconds, milliseconds in UTC
	return date;
}

function getPreviousUTCMonth(date: Date): Date {
	date.setUTCDate(1); // Set date to the 1st of the month in UTC
	date.setUTCHours(1, 0, 0, 0); // Reset hours, minutes, seconds, milliseconds in UTC
	return date;
}

export default getNearestPreviousDate;
