import { observer } from 'mobx-react-lite';
import ModalBase from '@components/modal/modal-base.tsx';
import TagExpansionInput from '@components/data/tag-expansion-input/tag-expansion-input.tsx';
import { useState } from 'react';
import { PlaceTag } from '@/schemas/plage-tag-schema.ts';
import placeTagStore from '@store/place-tag-store.ts';
import { FullExperience } from '@/schemas/experience-schema.ts';

interface ExperienceDetailsAddTagModalProps {
	experience: FullExperience;
	tags: PlaceTag[];
}

const ExperienceDetailsAddTagModal = observer(
	function ExperienceDetailsAddTagModal(
		props: ExperienceDetailsAddTagModalProps
	) {
		const [selectedTag, setSelectedTag] = useState<PlaceTag | undefined>(
			undefined
		);
		const [searchTerm, setSearchTerm] = useState('');

		const addTag = () => {
			if (selectedTag) {
				void placeTagStore.addPlaceIdToTag(
					selectedTag.id,
					props.experience.place_id
				);
				setSelectedTag(undefined);
				setSearchTerm('');
			}
		};

		return (
			<ModalBase
				title={`Add Curated Tag`}
				content={{
					children: (
						<div
							className={
								'flex flex-col justify-between items-start'
							}
						>
							<div
								className={
									'flex flex-col lg:flex-row justify-center items-center gap-4 w-full flex-1 flex-wrap'
								}
							>
								<TagExpansionInput
									searchTerm={searchTerm}
									setSearchTerm={setSearchTerm}
									tag={selectedTag}
									setTag={setSelectedTag}
									filteredTagIds={props.tags.map(
										(tag) => tag.id
									)}
									excludeNonGlobal
									scroll
								/>
							</div>

							<div></div>
						</div>
					),
				}}
				onConfirm={addTag}
				options={{
					confirmButtonTitle: 'Add Tag',
					confirmButtonDisabled: !selectedTag,
				}}
			/>
		);
	}
);

export default ExperienceDetailsAddTagModal;
