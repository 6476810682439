import { observer } from 'mobx-react-lite';
import ClassString from '@utils/class-string.ts';
import navSidebarStore from '@store/nav-sidebar-store.ts';
import AppLogo from '@components/core/app-logo/app-logo.tsx';

interface SignUpHeaderProps {
	className?: string;
}

const SignUpHeader = observer(function SignUpHeader(props: SignUpHeaderProps) {
	return (
		<div
			className={ClassString({
				static: 'inline-flex flex-col md:flex-row-reverse justify-center md:justify-between items-center w-full gap-x-4',
				dynamic: {},
				custom: props.className,
			})}
		>
			<AppLogo
				className={ClassString({
					static: 'flex flex-col justify-center items-center dark:text-white px-4 pt-0 pb-8 select-none flex-1 w-full',
					dynamic: {
						'lg:hidden': navSidebarStore.minimal,
					},
				})}
			/>

			<div className={'w-fit md:max-w-1/2'}>
				<div className={' text-2xl mb-1 font-semibold'}>
					Sign Up for Free
				</div>
				<div className={'mb-6'}>
					Sign up for free to access additional features and
					historical data
				</div>
			</div>
		</div>
	);
});

export default SignUpHeader;
