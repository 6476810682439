import { observer } from 'mobx-react-lite';
import View from '@components/view/view.tsx';
import { Outlet, useNavigation } from 'react-router';

const LoadingView = observer(function LoadingView() {
	const { state } = useNavigation();

	return (
		<>
			{state === 'loading' && (
				<View loading={true} hideHeader hideFooter center>
					<></>
				</View>
			)}

			{state !== 'loading' && <Outlet />}
		</>
	);
});

export default LoadingView;
