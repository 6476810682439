import { observer } from 'mobx-react-lite';
import { Link, useLocation } from 'react-router';
import { LocationState } from '@components/navigation/navigation-button/navigation-button.tsx';

const CookiePolicy = observer(function CookiePolicy() {
	const locationState = useLocation().state as LocationState;
	const navigateTo =
		locationState?.from && locationState.from.pathname !== '/sign-up'
			? `${locationState.from.pathname}`
			: '/overview';

	return (
		<div
			className={
				'w-screen h-dvh bg-white dark:bg-gray-575 grid place-items-center overflow-y-auto'
			}
		>
			<div
				className={
					'w-full text-lg hover:text-blue-500 dark:hover:text-blue-300 p-4'
				}
			>
				<Link to={navigateTo}>{'← Back'}</Link>
			</div>

			<div className="max-w-5xl w-full p-6">
				<h1 className="text-3xl font-bold mb-4">Cookie Policy</h1>

				<h2 className="text-xl font-semibold mt-6">Introduction</h2>
				<p className="mb-4">
					We have prepared this Cookie Policy to inform users of our
					digital platform that we use cookies to enhance their
					browsing experience, making it more efficient and
					personalized. This policy explains what cookies are, the
					types we use, their purposes, how long we retain them, and
					how users can manage their preferences.
				</p>
				<p className="mb-4">
					By accessing our digital platform and using our services, we
					encourage you to read this Cookie Policy carefully. This
					Cookie Policy is part of our{' '}
					<Link
						to={'/privacy-policy'}
						className={'text-blue-500 dark:text-blue-300'}
					>
						Privacy Policy
					</Link>
					.
				</p>

				<h2 className="text-xl font-semibold mt-6">
					What are cookies?
				</h2>
				<p className="mb-4">
					Cookies are small text files placed on your computer or
					mobile device through your internet browser when you visit a
					website. They serve to store, retrieve, or update data.
					Cookies help ensure the website functions properly, enhance
					security, improve user experience, and provide insights into
					website performance.
				</p>
				<p className="mb-4">
					Additionally, cookies enable the website to remember user
					preferences, such as their name, language, and display
					settings, so users don&#39;t have to reconfigure them with
					each visit.
				</p>

				<h2 className="text-xl font-semibold mt-6">
					How do we use cookies?
				</h2>
				<p className="mb-4">
					Our website uses different types of cookies. Regarding their
					duration and origin, we use the following cookies:
				</p>
				<ul className="list-disc pl-6 mb-4">
					<li>
						<strong>Persistent cookies</strong> – These cookies
						remain stored in the user’s browser and are used when
						they return to our website, allowing them to stay logged
						in.
					</li>
					<li>
						<strong>First-party cookies</strong> – The persistent
						cookies we use are also first-party cookies, meaning
						they are set by us without relying on external services.
					</li>
					<li>
						<strong>Session cookies</strong> – These cookies are
						temporary and remain active only while the user is on
						our website. Once the user leaves, these cookies are
						deleted.
					</li>
					<li>
						<strong>Third-party cookies</strong> – These session
						cookies are administered by third parties to help ensure
						website efficiency and analytics tracking.
					</li>
				</ul>

				<h2 className="text-xl font-semibold mt-6">
					What types of cookies do we use?
				</h2>
				<h3 className="text-lg font-medium mt-4">Necessary Cookies</h3>
				<p className="mb-4">
					These cookies are essential for the website to function
					properly, enabling core functionalities and security
					features anonymously.
				</p>
				<table className="w-full border-collapse border border-gray-300 mb-4">
					<thead>
						<tr className="bg-gray-100 dark:bg-gray-525">
							<th className="border border-gray-300 p-2">
								Cookie
							</th>
							<th className="border border-gray-300 p-2">
								Responsible Entity
							</th>
							<th className="border border-gray-300 p-2">
								Duration
							</th>
							<th className="border border-gray-300 p-2">
								Purpose
							</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td className="border border-gray-300 p-2">
								session
							</td>
							<td className="border border-gray-300 p-2">
								The Gang
							</td>
							<td className="border border-gray-300 p-2">
								1 year
							</td>
							<td className="border border-gray-300 p-2">
								Keeps user signed-in
							</td>
						</tr>
						<tr>
							<td className="border border-gray-300 p-2">
								g_state
							</td>
							<td className="border border-gray-300 p-2">
								Google
							</td>
							<td className="border border-gray-300 p-2">
								1 week
							</td>
							<td className="border border-gray-300 p-2">
								Helps facilitate a single sign-on with a Google
								account
							</td>
						</tr>
					</tbody>
				</table>

				<h3 className="text-lg font-medium mt-4">Analytics Cookies</h3>
				<p className="mb-4">
					These cookies track how visitors interact with the website,
					providing insights into metrics such as visitor numbers,
					bounce rates, and traffic sources.
				</p>
				<table className="w-full border-collapse border border-gray-300 mb-4">
					<thead>
						<tr className="bg-gray-100 dark:bg-gray-525">
							<th className="border border-gray-300 p-2">
								Cookie
							</th>
							<th className="border border-gray-300 p-2">
								Responsible Entity
							</th>
							<th className="border border-gray-300 p-2">
								Duration
							</th>
							<th className="border border-gray-300 p-2">
								Purpose
							</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td className="border border-gray-300 p-2 break-all">
								ph_phc_GaCBL1yz3q9ccKnJjLmZRUpWe5ZhewczMYJauha0FY3_posthog
							</td>
							<td className="border border-gray-300 p-2">
								PostHog
							</td>
							<td className="border border-gray-300 p-2">
								1 year
							</td>
							<td className="border border-gray-300 p-2">
								Session data when collecting anonymous usage
								insights
							</td>
						</tr>
					</tbody>
				</table>

				<h2 className="text-xl font-semibold mt-6">
					How can I control the cookie preferences?
				</h2>
				<p className="mb-4">
					You can manage your cookie preferences at any time by
					clicking on the &#34;Settings&#34; button. This allows you
					to revisit the cookie consent banner, modify your
					preferences, or withdraw your consent.
				</p>
				<p className="mb-4">
					Additionally, most web browsers offer options to block or
					delete cookies. You can adjust your browser settings to
					manage cookies according to your preferences. Below are
					links to support documents for managing and deleting cookies
					from the major web browsers.
				</p>
				<ul className="list-disc pl-6 mb-4">
					<li>
						<a
							href="https://support.google.com/accounts/answer/32050"
							target="_blank"
							rel="noreferrer"
							className="text-blue-500 dark:text-blue-300 underline"
						>
							Google Chrome
						</a>
					</li>
					<li>
						<a
							href="https://support.apple.com/en-in/guide/safari/sfri11471/mac"
							target="_blank"
							rel="noreferrer"
							className="text-blue-500 dark:text-blue-300 underline"
						>
							Apple Safari
						</a>
					</li>
					<li>
						<a
							href="https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox"
							target="_blank"
							rel="noreferrer"
							className="text-blue-500 dark:text-blue-300 underline"
						>
							Mozilla Firefox
						</a>
					</li>
					<li>
						<a
							href="https://support.microsoft.com/en-us/windows/manage-cookies-in-microsoft-edge"
							target="_blank"
							rel="noreferrer"
							className="text-blue-500 dark:text-blue-300 underline"
						>
							Microsoft Edge
						</a>
					</li>
				</ul>

				<h2 className="text-xl font-semibold mt-6">
					Changes to this Policy
				</h2>
				<p className="mb-4">
					The Gang reserves the right to review this Cookie Policy and
					make changes whenever deemed necessary or whenever required.
					Therefore, we strongly encourage our users to review our
					Cookie Policy periodically.
				</p>

				<h2 className="text-xl font-semibold mt-6">Contact</h2>
				<p className="mb-4">
					If you have any further questions regarding our Cookie
					Policy, please contact us through the following support
					channels:{' '}
					<a
						href="https://www.thegang.io/contact"
						target="_blank"
						rel="noreferrer"
						className="text-blue-500 dark:text-blue-300 underline"
					>
						https://www.thegang.io/contact
					</a>
				</p>
			</div>
		</div>
	);
});

export default CookiePolicy;
