import experienceStore from '@store/experience-store.ts';
import platformStatsStore from '@store/platform-stats-store.ts';
import dailyPickStore from '@store/daily-pick-store.ts';

const loadDailyPicks = async () => {
	await dailyPickStore.loadCurrentDailyPicks();
	if (dailyPickStore.currentDailyPick?.places) {
		dailyPickStore.currentDailyPick.places.forEach((place) => {
			void experienceStore.getFullExperience(place.place_id.toString());
		});
	}
};

export function OverviewLoader() {
	void platformStatsStore.loadPlatformCCU();
	void platformStatsStore.loadPlatformMovement();
	void loadDailyPicks();

	return 'ok';
}
