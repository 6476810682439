import { makeAutoObservable, runInAction } from 'mobx';
import localStorageService from '@services/local-storage-service.ts';
import {
	CookiePreferences,
	LocalStorageKey,
} from '@/enums/local-storage-types.ts';
import postHogService from '@services/posthog-service.ts';
import { BaseStore } from '@store/base-store.ts';

class CookiePromptStore implements BaseStore {
	private _readyToShow = false;
	private _showCookiePrompt = false;

	reset() {
		runInAction(() => {
			this._readyToShow = false;
			this._showCookiePrompt = false;
		});
	}

	private checkCookiePreferenceStatus() {
		const cookiePreferencesString = localStorageService.get(
			LocalStorageKey.CookiePreferences
		);

		runInAction(() => {
			this._showCookiePrompt = !cookiePreferencesString;

			if (cookiePreferencesString) {
				try {
					const cookiePreferences = JSON.parse(
						cookiePreferencesString
					) as CookiePreferences;
					if (cookiePreferences.st) {
						postHogService.enablePersistentTracking();
					} else {
						postHogService.disablePersistentTracking();
						this.deleteSessionTrackingCookie();
					}
				} catch (_) {
					// Do nothing
				}
			}
		});
	}

	constructor() {
		makeAutoObservable(this);

		this.checkCookiePreferenceStatus();
	}

	setReadyShow() {
		runInAction(() => {
			this._readyToShow = true;
		});

		this.checkCookiePreferenceStatus();
	}

	setCookiePreferences(preferences: CookiePreferences) {
		localStorageService.set(
			LocalStorageKey.CookiePreferences,
			JSON.stringify(preferences)
		);

		runInAction(() => {
			this._showCookiePrompt = false;
		});
	}

	deleteSessionTrackingCookie() {
		document.cookie = `ph_phc_GaCBL1yz3q9ccKnJjLmZRUpWe5ZhewczMYJauha0FY3_posthog=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
	}

	get showCookiePrompt() {
		return this._readyToShow && this._showCookiePrompt;
	}

	get readyToShow() {
		return this._readyToShow;
	}
}

const cookiePromptStore = new CookiePromptStore();
export default cookiePromptStore;
