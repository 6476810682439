import { observer } from 'mobx-react-lite';
import SignUpCta from '@components/core/sign-up-cta/sign-up-cta.tsx';

const SignUpCtaContainer = observer(function SignUpCtaContainer() {
	return (
		<div
			className={
				'absolute top-0 left-0 w-screen h-dvh pointer-events-none flex flex-col justify-end items-center sm:items-end p-2 py-4 sm:p-8'
			}
		>
			<SignUpCta />
		</div>
	);
});

export default SignUpCtaContainer;
