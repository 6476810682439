import { DataGranularity } from '@/enums/data-granularity.ts';

const anHour = 60 * 60 * 1000;
const halfHour = anHour / 2;
const aDay = 24 * anHour;
const halfDay = aDay / 2;
const aWeek = 7 * aDay;
const halfWeek = aWeek / 2;
const thirtyDays = 30 * aDay;
const fifteenDays = thirtyDays / 2;

const doesNextTimestampExist = (
	currentTimestamp: number,
	nextTimestamp: number,
	granularity: DataGranularity
) => {
	switch (granularity) {
		case DataGranularity.HOUR:
			return (
				nextTimestamp > currentTimestamp + halfHour &&
				nextTimestamp < currentTimestamp + anHour + halfHour
			);
		case DataGranularity.DAY:
			return (
				nextTimestamp > currentTimestamp + halfHour &&
				nextTimestamp < currentTimestamp + aDay + halfDay
			);
		case DataGranularity.WEEK:
			return (
				nextTimestamp > currentTimestamp + halfWeek &&
				nextTimestamp < currentTimestamp + aWeek + halfWeek
			);
		case DataGranularity.MONTH:
			return (
				nextTimestamp > currentTimestamp + fifteenDays &&
				nextTimestamp < currentTimestamp + thirtyDays + fifteenDays
			);
	}
};

export const fillMissingTimestamps = (
	data: number[][], // Input data: [[timestamp, value]]
	interval: DataGranularity // Interval range
): (number | null)[][] => {
	if (data.length === 0) return [];

	// Sort the data by the timestamp
	data.sort(([a], [b]) => a - b);

	// Fill in missing timestamps
	const filledData: (number | null)[][] = [];
	data.forEach(([timestamp, value], index) => {
		filledData.push([timestamp, value]);

		if (
			data[index + 1] &&
			!doesNextTimestampExist(timestamp, data[index + 1][0], interval)
		) {
			filledData.push([timestamp, null]);
		}
	});

	// Get the first timestamp and current timestamp
	const startTimestamp = data[data.length - 1][0];
	const endTimestamp = new Date().getTime();

	// Determine interval in milliseconds
	const intervalMs = (() => {
		switch (interval) {
			case DataGranularity.HOUR:
				return anHour;
			case DataGranularity.DAY:
				return aDay;
			case DataGranularity.WEEK:
				return aWeek;
			case DataGranularity.MONTH:
				return thirtyDays;
		}
	})();

	// Fill in any missing data from last data point until now
	for (
		let timestamp = startTimestamp;
		timestamp <= endTimestamp;
		timestamp += intervalMs
	) {
		filledData.push([timestamp, null]);
	}

	filledData.sort(([a], [b]) => {
		const aNum = a ?? 0;
		const bNum = b ?? 0;
		return aNum - bNum;
	});

	return filledData;
};

export const getSurroundingPointsForNulls = (
	data: number[][], // Input data: [[timestamp, value]]
	granularity: DataGranularity // Interval range
): number[][] => {
	if (data.length === 0) return [];

	const filledData = fillMissingTimestamps(data, granularity);

	// Keep track of the surrounding points for null values
	const surroundingPoints: number[][] = [];
	let previousValidPoint: number[] | null = null;

	filledData.forEach(([timestamp, value], index) => {
		// Should always be true except for last index
		const [nextTimestamp] = filledData[index + 1] ?? [null];

		// If we are finishing a null sequence, capture the first valid point after
		if (timestamp && nextTimestamp && value !== null) {
			if (previousValidPoint && surroundingPoints.length > 0) {
				// Add the current valid point (end of null range)
				surroundingPoints.push([timestamp, value]);
			}

			previousValidPoint = [timestamp, value];
		} else {
			// Start a new null range if not already tracking
			if (previousValidPoint && surroundingPoints.length === 0) {
				// Add the last valid point before the null range
				surroundingPoints.push(previousValidPoint);
			}
		}
	});

	const lastTimestamp = filledData[filledData.length - 1][0];
	// If the array ends with nulls, add an end point
	if (surroundingPoints.length > 0 && previousValidPoint && lastTimestamp) {
		surroundingPoints.push([lastTimestamp, previousValidPoint[1]]);
	}

	surroundingPoints.sort(([a], [b]) => a - b);

	return surroundingPoints;
};

export const findNearestDataPoint = (data: number[][], timestamp: number) => {
	let nearestDataPoint: { timestamp: number; value: number } = {
		timestamp: Number.MAX_SAFE_INTEGER,
		value: Number.MAX_SAFE_INTEGER,
	};

	for (const [ts, value] of data) {
		const difference = timestamp > ts ? timestamp - ts : ts - timestamp;
		const newDifference =
			timestamp > nearestDataPoint.timestamp
				? timestamp - nearestDataPoint.timestamp
				: nearestDataPoint.timestamp - timestamp;
		if (difference < newDifference) {
			nearestDataPoint = { timestamp: ts, value };
		}
	}

	return nearestDataPoint;
};
