import { observer } from 'mobx-react-lite';
import View from '@components/view/view.tsx';
import ViewPanel from '@components/view/view-panel.tsx';
import { IconEnum } from '@components/core/icon/icon-enum.ts';
import SearchBar from '@components/core/search-bar/search-bar.tsx';
import { useState } from 'react';
import creatorStore from '@store/creator-store.ts';
import CreatorTable from '@components/tables/creator-table.tsx';
import SignUpCtaBanner from '@components/core/sign-up-cta/sign-up-cta-banner.tsx';

const Creators = observer(function Creators() {
	const [searchTerm, setSearchTerm] = useState('');

	const creators =
		searchTerm.length >= 3
			? creatorStore.creators.filter((creator) => {
					return creator.name
						.toLowerCase()
						.includes(searchTerm.toLowerCase());
				})
			: creatorStore.creators;

	return (
		<View
			viewInfo={{
				title: 'Creators',
				icon: IconEnum.GROUP,
			}}
			loading={!creatorStore.initialized}
			headerChildren={
				<>
					<SearchBar
						onSearchUpdate={setSearchTerm}
						placeholder={'Search by name..'}
					/>
				</>
			}
		>
			<SignUpCtaBanner thin />

			<ViewPanel options={{ noPadding: true, noOutline: true }}>
				<CreatorTable creators={creators} />
			</ViewPanel>
		</View>
	);
});

export default Creators;
