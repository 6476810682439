import { observer } from 'mobx-react-lite';
import ExperienceIcon from '@components/data/experience-icon/experience-icon.tsx';
import { ExperienceIconSize } from '@components/data/experience-icon/experience-icon-constants.ts';
import ExpansionInput from '@components/core/expansion-input/expansion-input.tsx';
import { useEffect, useMemo, useState } from 'react';
import experienceStore from '@store/experience-store.ts';
import { SlimExperience } from '@/schemas/experience-schema.ts';
import Icon from '@components/core/icon/icon.tsx';
import { IconEnum } from '@components/core/icon/icon-enum.ts';
import ClassString from '@utils/class-string.ts';
import thumbnailStore from '@store/thumbnail-store.ts';
import { useDebounce } from '@uidotdev/usehooks';
import LoadingSpinner from '@components/core/loading-spinner/loading-spinner.tsx';
import searchService from '@services/search-service.ts';
import GetPlatform, { Platform } from '@utils/get-platform.ts';
import { useLocation, useNavigate } from 'react-router';
import { ThumbnailType } from '@/schemas/thumbnail-schema.ts';
import useIsMobile from '@hooks/use-is-mobile.tsx';

interface NavigateToExperienceProps {
	onNavigate?: () => unknown;
	focusOnRender?: boolean;
}

const inputLimit = 9;

const NavigateToExperience = observer(function NavigateToExperience(
	props: NavigateToExperienceProps
) {
	const isMobile = useIsMobile();

	const [searchTerm, setSearchTerm] = useState('');
	const debouncedSearchTerm = useDebounce(searchTerm, 500);

	const location = useLocation();
	const navigate = useNavigate();

	const matchingPlaces = useMemo(() => {
		const searchFilter = (place: SlimExperience): boolean => {
			return searchService.experience(place, searchTerm);
		};

		return searchTerm.length > 2
			? experienceStore.slimExperiences
					.filter((place) => searchFilter(place))
					.sort((a, b) => {
						if (a.players_online === b.players_online) {
							return a.name.localeCompare(b.name);
						}
						return b.players_online - a.players_online;
					})
			: [];
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchTerm, experienceStore.slimExperiences]);

	useEffect(() => {
		const loadThumbnails = () => {
			void thumbnailStore.loadThumbnails(
				matchingPlaces.slice(0, inputLimit),
				ThumbnailType.ICON
			);
		};

		if (debouncedSearchTerm) {
			loadThumbnails();
		}
	}, [debouncedSearchTerm, matchingPlaces]);

	return (
		<div className={'relative group'}>
			{!!experienceStore.slimExperiences.length && (
				<Icon
					className={
						'absolute left-3 top-1/2 -translate-y-1/2 rounded-full pointer-events-none z-in-front-4'
					}
					icon={IconEnum.SEARCH}
					size={'2em'}
				/>
			)}

			{!experienceStore.slimExperiences.length && (
				<div
					className={
						'absolute left-3 top-1/2 -translate-y-1/2 z-in-front-4'
					}
				>
					<LoadingSpinner className={'w-7 h-7'} />
				</div>
			)}

			{!isMobile && (
				<div
					className={ClassString({
						static: 'absolute right-5 top-1/2 -translate-y-1/2 text-gray-400 z-in-front-4',
						dynamic: {
							'right-12': !!searchTerm,
						},
					})}
				>
					{GetPlatform() === Platform.MAC ? '⌘+F' : 'Ctrl+F'}
				</div>
			)}

			{!!searchTerm && (
				<Icon
					icon={IconEnum.CLEAR}
					size={'2rem'}
					onClick={() => {
						setSearchTerm('');
					}}
					className={
						'absolute right-2 top-1/2 -translate-y-1/2 rounded-full hover:bg-blue-300 cursor-pointer z-in-front-4 hover:text-gray-600 p-1'
					}
				/>
			)}

			<ExpansionInput
				className={'group'}
				inputClassName={
					'py-2! pl-12! pr-12! text-lg! rounded-full! transition-all! w-full! h-12!'
				}
				suggestionClassName={ClassString({
					dynamic: {
						'top-2!': matchingPlaces.length <= 0,
						'group-focus-within:top-4!': matchingPlaces.length > 0,
					},
				})}
				label={''}
				value={searchTerm}
				placeholder={'Find Experience..'}
				onInput={(event) => setSearchTerm(event.target.value)}
				onEnterKey={(value: string) => {
					if (value.length >= 3) {
						void navigate(`/experiences#search=${value}`, {
							state: { from: location },
						});
						if (props.onNavigate) {
							props.onNavigate();
						}
					}
				}}
				seeAllResults={(value: string) => {
					if (value.length >= 3) {
						void navigate(`/experiences#search=${value}`, {
							state: { from: location },
						});
					}
				}}
				suggestionLimit={inputLimit}
				focusOnSearchShortcut
				suggestions={matchingPlaces.map((place) => {
					return {
						value: (
							<div className={'flex items-center'}>
								<div className={'h-7 w-7 mr-2'}>
									<ExperienceIcon
										experience={place}
										size={ExperienceIconSize.VERY_SMALL}
										className={'h-7 w-7 min-h-7 min-w-7'}
									/>
								</div>

								<div className={'truncate pr-2'}>
									{place.name}
								</div>

								<div className={'mr-4'}>
									<Icon icon={IconEnum.ARROW_FORWARD} />
								</div>
							</div>
						),
						title: place.name,
						onClick: () => {
							void navigate(`/experiences/${place.place_id}`, {
								state: { from: location },
							});
							if (props.onNavigate) {
								props.onNavigate();
							}
						},
					};
				})}
				focusOnRender={props.focusOnRender}
			/>
		</div>
	);
});

export default NavigateToExperience;
