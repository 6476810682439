import { observer } from 'mobx-react-lite';
import NavigationButton from '@components/navigation/navigation-button/navigation-button.tsx';
import { NavigationButtonEnum } from '@components/navigation/navigation-button/navigation-button-enum.ts';
import {
	DashboardInfo,
	DashboardInfoType,
} from '@components/data/lightdash-dashboards/dashboard-info.ts';
import ClassString from '@utils/class-string.ts';
import posthogService from '@services/posthog-service.ts';
import { PostHogEventType } from '@/enums/posthog-event.ts';
import ViewPanel from '@components/view/view-panel.tsx';
import authStore from '@store/auth-store.ts';
import { UserRole } from '@/schemas/user-schema.ts';
import useIsMobile from '@hooks/use-is-mobile.tsx';

interface LightdashDashboardsProps {
	dashboards: DashboardInfoType[];
	title?: string;
}

const LightdashDashboards = observer(function LightdashDashboards(
	props: LightdashDashboardsProps
) {
	const isMobile = useIsMobile();

	if (
		!authStore.isLoggedIn ||
		authStore.haveUserRole(UserRole.VIEWER) ||
		isMobile
	) {
		return <></>;
	}

	return (
		<ViewPanel>
			<div className={'text-2xl mb-4'}>
				{props.title ?? 'Lightdash Dashboards'}
			</div>

			{props.dashboards.map((dashboard, index) => {
				return (
					<div
						key={DashboardInfo(dashboard).title}
						className={ClassString({
							static: 'flex items-center sm:flex-row flex-col',
							dynamic: {
								'sm:mb-4': index + 1 < props.dashboards.length,
							},
						})}
					>
						<div
							className={
								'min-w-72 h-auto flex justify-center sm:justify-start mb-4 sm:mb-0 sm:mr-4'
							}
						>
							<NavigationButton
								title={DashboardInfo(dashboard).title}
								path={DashboardInfo(dashboard).url}
								direction={NavigationButtonEnum.EXTERNAL}
								external
								outline
								background
								onClick={() => {
									posthogService.capture({
										type: PostHogEventType.NavigateToLightdash,
										dashboard: dashboard.type,
									});
								}}
							/>
						</div>

						<div>
							{DashboardInfo(dashboard).description}
							<span className={'font-semibold'}>
								{DashboardInfo(dashboard).disclaimer}
							</span>
						</div>
					</div>
				);
			})}
		</ViewPanel>
	);
});

export default LightdashDashboards;
