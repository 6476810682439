import { RangeSelectorButtonsOptions } from 'highcharts';
import modalStore from '@store/modal-store.ts';
import ModalSignUpCta from '@components/modal/modal-sign-up-cta.tsx';
import { CHART_RANGE } from '@components/core/chart/chart-range.ts';

export const ChartRangeDefinition: Record<
	CHART_RANGE,
	RangeSelectorButtonsOptions
> = {
	[CHART_RANGE.DAY]: {
		type: 'day',
		count: 1,
		text: '1d',
		title: 'View 1 day',
	},
	[CHART_RANGE.WEEK]: {
		type: 'day',
		count: 7,
		text: '7d',
		title: 'View 7 days',
	},
	[CHART_RANGE.MONTH]: {
		type: 'month',
		count: 1,
		text: '1m',
		title: 'View 1 month',
	},
	[CHART_RANGE.THREE_MONTHS]: {
		type: 'month',
		count: 3,
		text: '3m',
		title: 'View 3 months',
	},
	[CHART_RANGE.SIX_MONTHS]: {
		type: 'month',
		count: 6,
		text: '6m',
		title: 'View 6 months',
	},
	[CHART_RANGE.YEAR_TO_DATE]: {
		type: 'ytd',
		text: 'YTD',
		title: 'View year to date',
	},
	[CHART_RANGE.YEAR]: {
		type: 'year',
		count: 1,
		text: '1y',
		title: 'View 1 year',
	},
	[CHART_RANGE.ALL]: {
		type: 'all',
		text: 'All',
		title: 'View all',
	},
};

export const ChartRangeDefinitionLoggedOut: Record<
	CHART_RANGE,
	RangeSelectorButtonsOptions
> = {
	[CHART_RANGE.DAY]: ChartRangeDefinition[CHART_RANGE.DAY],
	[CHART_RANGE.WEEK]: ChartRangeDefinition[CHART_RANGE.WEEK],
	[CHART_RANGE.MONTH]: ChartRangeDefinition[CHART_RANGE.MONTH],
	[CHART_RANGE.THREE_MONTHS]: {
		text: '3m',
		events: {
			click: function () {
				modalStore.open(<ModalSignUpCta />);
				return false;
			},
		},
	},
	[CHART_RANGE.SIX_MONTHS]: {
		text: '6m',
		events: {
			click: function () {
				modalStore.open(<ModalSignUpCta />);
				return false;
			},
		},
	},
	[CHART_RANGE.YEAR_TO_DATE]: {
		text: 'YTD',
		events: {
			click: function () {
				modalStore.open(<ModalSignUpCta />);
				return false;
			},
		},
	},
	[CHART_RANGE.YEAR]: {
		text: '1y',
		events: {
			click: function () {
				modalStore.open(<ModalSignUpCta />);
				return false;
			},
		},
	},
	[CHART_RANGE.ALL]: {
		text: 'All',
		events: {
			click: function () {
				modalStore.open(<ModalSignUpCta />);
				return false;
			},
		},
	},
};
