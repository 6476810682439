import { observer } from 'mobx-react-lite';
import modalStore from '@store/modal-store.ts';
import ModalSignUpCta from '@components/modal/modal-sign-up-cta.tsx';
import Icon from '@components/core/icon/icon.tsx';
import { IconEnum } from '@components/core/icon/icon-enum.ts';
import LogoAnimatedBlack from '@assets/logo_animated_black.webp';
import { useEffect, useState } from 'react';
import ClassString from '@utils/class-string.ts';
import authStore from '@store/auth-store.ts';
import { useLocation } from 'react-router';
import localStorageService from '@services/local-storage-service.ts';
import { LocalStorageKey } from '@/enums/local-storage-types.ts';
import { reaction } from 'mobx';
import cookiePromptStore from '@store/cookie-prompt-store.ts';

const loginScreen = (pathname: string) => {
	return pathname === '/sign-in' || pathname === '/sign-up';
};

const dismissTimeLimit = 30 * 24 * 60 * 60 * 1000; // 30 Days

const isDismissedPreviously = () => {
	const dismissDateTime = localStorageService.get(
		LocalStorageKey.SignUpCallout
	);
	if (!dismissDateTime) {
		return false;
	}

	const dismissDate = new Date(+dismissDateTime);
	const now = new Date();

	return dismissTimeLimit > now.getTime() - dismissDate.getTime();
};

const SignUpCta = observer(function SignUpCta() {
	const [isLoaded, setIsLoaded] = useState(false);
	const [isShaking, setIsShaking] = useState(false);
	const [dismissed, setDismissed] = useState(authStore.isLoggedIn);
	const dismissedPreviously = isDismissedPreviously();
	const location = useLocation();

	const dismiss = () => {
		setDismissed(true);
		// Allow component to fade out
		setTimeout(() => {
			localStorageService.set(
				LocalStorageKey.SignUpCallout,
				`${new Date().getTime()}`
			);
		}, 500);
	};

	useEffect(() => {
		const interval = setInterval(() => {
			setIsShaking(true);
			setTimeout(() => setIsShaking(false), 820); // Shake lasts for 0.82s
		}, 5000);

		return () => clearInterval(interval);
	}, []);

	useEffect(() => {
		const disposer = reaction(
			() => [
				cookiePromptStore.readyToShow,
				cookiePromptStore.showCookiePrompt,
			],
			() => {
				if (!cookiePromptStore.showCookiePrompt) {
					setTimeout(() => {
						setIsLoaded(true);
					}, 3000);
				}
			}
		);

		return () => disposer();
	}, []);

	if (
		authStore.isLoggedIn ||
		loginScreen(location.pathname) ||
		dismissedPreviously
	) {
		return <></>;
	}

	return (
		<div
			className={ClassString({
				static: 'group w-fit max-w-(--breakpoint-sm) bg-red-200 text-gray-600 rounded-full outline outline-gray-600  px-8 py-4 flex flex-row-reverse justify-center items-center gap-8 cursor-pointer transition-opacity opacity-0 shadow-lg',
				dynamic: {
					'opacity-100 pointer-events-auto': isLoaded,
					'opacity-0 pointer-events-none': dismissed,
					'animate-shake': isShaking,
				},
			})}
			onClick={() => {
				modalStore.open(<ModalSignUpCta />);
			}}
		>
			<div
				className={
					'peer child rounded-full hover:text-gray-600 hover:bg-blue-300 hover:underline p-1 cursor-pointer flex justify-center items-center pointer-events-auto'
				}
				onClick={(event) => {
					event.stopPropagation();
					dismiss();
				}}
			>
				<Icon icon={IconEnum.CLEAR} size={'2rem'} />
			</div>

			<div
				className={
					'cursor-pointer group-hover:underline peer-hover:text-gray-600'
				}
			>
				{'Psst! Sign up for free to unlock full access!'}
			</div>

			<img
				src={LogoAnimatedBlack}
				alt={''}
				loading={'lazy'}
				className="w-16 h-16 object-cover"
				draggable={false}
			/>
		</div>
	);
});

export default SignUpCta;
