import { observer } from 'mobx-react-lite';
import PlaceExpansionInput from '@components/data/place-expansion-input/place-expansion-input.tsx';
import useHashQueryParams from '@hooks/use-hash-query-params.tsx';
import experienceStore from '@store/experience-store.ts';
import { useEffect, useState } from 'react';
import { Experience } from '@/schemas/experience-schema.ts';
import ExperienceIcon from '@components/data/experience-icon/experience-icon.tsx';
import { ExperienceIconSize } from '@components/data/experience-icon/experience-icon-constants.ts';
import Button from '@components/core/button/button.tsx';
import thumbnailStore from '@store/thumbnail-store.ts';
import ClassString from '@utils/class-string.ts';
import Icon from '@components/core/icon/icon.tsx';
import { IconEnum } from '@components/core/icon/icon-enum.ts';
import getCycledItem from '@utils/get-cycled-item.ts';
import { ThumbnailType } from '@/schemas/thumbnail-schema.ts';

interface MultipleExperienceSelectorProps {
	experiences: Experience[];
	onAddExperience: (experiences: Experience[]) => unknown;
	onRemoveExperience: (experience: Experience) => unknown;
	colors: string[];
	fullSizedPreviews?: number;
	className?: string;
}

const MultipleExperienceSelector = observer(
	(props: MultipleExperienceSelectorProps) => {
		const [searchParams, setSearchParams] = useHashQueryParams();

		const [searchTerm, setSearchTerm] = useState('');
		const [experience, setExperience] = useState<Experience | undefined>();

		const alreadyAdded =
			!!experience &&
			!!props.experiences.find(
				(exp) => exp.place_id === experience.place_id
			);

		const maxAmount = props.experiences.length >= 6;
		const fullSizedPreviews = props.fullSizedPreviews ?? 1;

		const addExperience = (experience: Experience | undefined) => {
			setSearchParams({
				...searchParams,
				[`exp`]: experience
					? [...props.experiences, experience]
							.map((exp) => exp.place_id)
							.toString()
					: '',
			});
			if (experience) {
				props.onAddExperience([experience]);
			}
		};

		useEffect(() => {
			const experienceIdString = searchParams.exp;

			if (experienceIdString) {
				const ids = experienceIdString
					.split(',')
					.map((id) => +id)
					.slice(0, 6);
				const experiences = ids
					.map((id) => experienceStore.getSlimExperienceById(id))
					.filter((exp) => !!exp);
				void thumbnailStore.loadThumbnailsById(ids, ThumbnailType.ICON);
				props.onAddExperience(experiences);
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, []);

		return (
			<div
				className={ClassString({
					static: 'flex flex-col m-2 gap-4',
					dynamic: {},
					custom: props.className,
				})}
			>
				<div className={'flex items-center gap-4'}>
					<div className={'flex-1'}>
						<PlaceExpansionInput
							searchTerm={searchTerm}
							setSearchTerm={setSearchTerm}
							place={experience}
							setPlace={(exp) => {
								setExperience(exp);
							}}
							fullWidth
						/>
					</div>

					<Button
						title={'Compare'}
						onClick={() => {
							if (experience) {
								addExperience(experience);
							}
							setExperience(undefined);
							setSearchTerm('');
						}}
						disabled={!experience || alreadyAdded || maxAmount}
					/>
				</div>

				<div
					className={
						'border dark:border-gray-500 p-6 rounded-xl flex justify-evenly items-center flex-1 flex-wrap text-center max-w-full sm:max-w-xl gap-4 min-h-64'
					}
				>
					{props.experiences.map((exp, index) => {
						return (
							<div
								key={exp.place_id}
								className={ClassString({
									static: 'w-32 sm:w-40 flex flex-col items-center gap-2 h-28 justify-between',
									dynamic: {
										'h-full justify-center':
											props.experiences.length <=
											fullSizedPreviews,
									},
								})}
								draggable={false}
							>
								<div
									className={'border-4 rounded-md relative'}
									style={{
										borderColor: getCycledItem(
											index,
											props.colors
										),
									}}
									onClick={() => {
										props.onRemoveExperience(exp);
										const filteredExp =
											props.experiences.filter(
												(experience) =>
													experience.place_id !==
													exp.place_id
											);
										setSearchParams({
											...searchParams,
											[`exp`]: filteredExp.length
												? filteredExp
														.map(
															(exp) =>
																exp.place_id
														)
														.toString()
												: '',
										});
									}}
								>
									<ExperienceIcon
										experience={exp}
										size={ExperienceIconSize.LARGE}
										className={ClassString({
											static: 'max-w-16',
											dynamic: {
												'sm:max-w-40':
													props.experiences.length <=
													fullSizedPreviews,
											},
										})}
									/>

									<div
										className={ClassString({
											static: 'absolute top-0 left-0 w-16 h-16 bg-blue-300/75 opacity-0 hover:opacity-100 cursor-pointer',
											dynamic: {
												'sm:w-40 sm:h-40':
													props.experiences.length <=
													fullSizedPreviews,
											},
										})}
									>
										<Icon
											icon={IconEnum.CLEAR}
											size={'4rem'}
											className={
												'absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-gray-600'
											}
										/>
									</div>
								</div>

								<div
									className={ClassString({
										static: 'truncate w-full',
										dynamic: {
											'flex-none':
												props.experiences.length <=
												fullSizedPreviews,
										},
									})}
								>
									{exp.canonical_name}
								</div>
							</div>
						);
					})}
				</div>
			</div>
		);
	}
);

export default MultipleExperienceSelector;
