import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef, useState } from 'react';
import LoadingIndicator from '@components/core/loading-indicator/loading-indicator.tsx';
import viewStore, { ViewInfo } from '@store/view-store.ts';
import ClassString from '@utils/class-string.ts';
import { Link, useNavigation } from 'react-router';
import ScrollProvider from '@components/providers/scroll-provider.tsx';
import ViewFooterSocials from '@components/view/view-footer-socials.tsx';
import Icon from '@components/core/icon/icon.tsx';
import { IconEnum } from '@components/core/icon/icon-enum.ts';

interface ViewProps {
	children: React.ReactNode;
	viewInfo?: ViewInfo;
	hideHeader?: boolean;
	headerChildren?: React.ReactNode;
	headerClass?: string;
	className?: string;
	fullHeight?: boolean;
	loading?: boolean;
	center?: boolean;
	disableFadeIn?: boolean;
	hideFooter?: boolean;
}

const defaultMetaTagTitle = 'UGC Insight by The Gang';
const defaultMetaTagDescription =
	'Track real-time Roblox data with ease. Search, group, and compare games by concurrent users, playtime, rankings, and more.';
const defaultMetaTagImageUrl = 'https://ugcinsight.com/the_gang_logo.png';

const View = observer(function View(props: ViewProps) {
	const { state } = useNavigation();

	const [fade, setFade] = useState(!!props.disableFadeIn);
	const [fadeFooter, setFadeFooter] = useState(false);

	useEffect(() => {
		setFade(true);
	}, []);

	useEffect(() => {
		const title = props.viewInfo?.title
			? `${props.viewInfo.title} | UGC Insight`
			: defaultMetaTagTitle;
		const description = props.viewInfo?.metaTagDescription
			? props.viewInfo.metaTagDescription
			: defaultMetaTagDescription;
		const imageUrl = props.viewInfo?.metaTagImageUrl
			? props.viewInfo.metaTagImageUrl
			: defaultMetaTagImageUrl;

		document.title = title;

		document
			.querySelector('meta[name="title"]')
			?.setAttribute('content', title);
		document
			.querySelector('meta[name="og:title"]')
			?.setAttribute('content', title);
		document
			.querySelector('meta[name="twitter:title"]')
			?.setAttribute('content', title);

		document
			.querySelector('meta[name="description"]')
			?.setAttribute('content', description);
		document
			.querySelector('meta[name="og:description"]')
			?.setAttribute('content', description);
		document
			.querySelector('meta[name="twitter:description"]')
			?.setAttribute('content', description);

		document
			.querySelector('meta[property="og:image"]')
			?.setAttribute('content', imageUrl);
		document
			.querySelector('meta[name="twitter:image"]')
			?.setAttribute('content', imageUrl);

		document
			.querySelector('meta[name="robots"]')
			?.setAttribute(
				'content',
				viewStore.viewInfo?.robotNoIndex
					? 'noindex,nofollow'
					: 'index,follow'
			);

		document
			.querySelector('link[rel="canonical"]')
			?.setAttribute(
				'href',
				`https://ugcinsight.com${window.location.pathname}`
			);

		viewStore.setViewInfo(props.viewInfo);
	}, [props.viewInfo]);

	const scrollContainer = useRef<HTMLDivElement>(null);
	const viewContainer = useRef<HTMLDivElement>(null);
	const footerContainer = useRef<HTMLDivElement>(null);

	const [hasScrollbar, setHasScrollbar] = useState(false);

	useEffect(() => {
		if (props.loading) {
			return;
		}

		const checkScrollbar = () => {
			if (
				scrollContainer.current &&
				viewContainer.current &&
				footerContainer.current
			) {
				const hasScroll =
					scrollContainer.current.scrollHeight >
					scrollContainer.current.clientHeight;
				const viewPadding = 42;
				const fullHeight =
					scrollContainer.current.clientHeight -
						viewContainer.current.clientHeight -
						viewPadding <
					footerContainer.current.clientHeight;
				setHasScrollbar(hasScroll || fullHeight);
				if (!fadeFooter && !props.loading) {
					setFadeFooter(true);
				}
			}
		};

		setTimeout(checkScrollbar, 0);

		const resizeObserver = new ResizeObserver(() => checkScrollbar());
		if (scrollContainer.current) {
			resizeObserver.observe(scrollContainer.current);
		}

		if (viewContainer.current) {
			resizeObserver.observe(viewContainer.current);
		}

		return () => {
			resizeObserver.disconnect();
		};
	}, [fadeFooter, props.loading]);

	return (
		<ScrollProvider scrollRef={scrollContainer}>
			<div
				className={ClassString({
					static: 'mx-auto max-w-(--breakpoint-2xl) px-2 lg:px-6',
					dynamic: {
						'h-full': props.loading,
					},
					custom: props.className,
				})}
				style={{
					height:
						hasScrollbar && !props.loading
							? undefined
							: `calc(100% - ${footerContainer.current?.clientHeight ?? 0}px)`,
				}}
			>
				{!props.loading && (
					<div
						className={ClassString({
							static: 'flex flex-wrap justify-center gap-6 w-full opacity-0 transition-opacity duration-100',
							dynamic: {
								'h-full flex-col': props.fullHeight,
								'flex-row items-center': state === 'loading',
								'items-center': props.center,
								'opacity-100': fade,
							},
						})}
						ref={viewContainer}
					>
						{!props.hideHeader && (
							<div
								className={ClassString({
									static: 'flex justify-between flex-wrap items-center w-full -my-2',
									dynamic: {},
									custom: props.headerClass,
								})}
							>
								{props.headerChildren}
							</div>
						)}

						<>{props.children}</>
					</div>
				)}

				{props.loading && (
					<div
						className={
							'inline-flex justify-center items-center w-full h-full'
						}
					>
						<LoadingIndicator />
					</div>
				)}
			</div>

			{!props.loading && (
				<div
					className={'inline-flex justify-center items-center w-full'}
				>
					<div
						className={ClassString({
							static: 'w-full max-w-(--breakpoint-2xl) grid grid-cols-2 xl:grid-cols-4 gap-x-8 gap-y-4 bottom-0 mt-4 opacity-0 transition-opacity flex-wrap px-8 select-none',
							dynamic: {
								hidden: props.hideFooter,
								'absolute mb-4': !hasScrollbar,
								'opacity-100': fadeFooter,
							},
						})}
						ref={footerContainer}
					>
						<ViewFooterSocials className={'hidden xl:flex'} />

						<div
							className={
								'flex justify-center items-center gap-x-8 gap-y-2 flex-wrap col-span-2'
							}
						>
							<Link
								to={'/privacy-policy'}
								className={
									'hover:text-blue-500 dark:hover:text-blue-300'
								}
							>
								Privacy Policy
							</Link>

							<Link
								to={'/terms-of-use'}
								className={
									'hover:text-blue-500 dark:hover:text-blue-300'
								}
							>
								Terms of Use
							</Link>

							<Link
								to={'/cookie-policy'}
								className={
									'hover:text-blue-500 dark:hover:text-blue-300'
								}
							>
								Cookie Policy
							</Link>
						</div>

						<ViewFooterSocials
							className={'xl:hidden col-span-2 sm:col-span-1'}
						/>

						<div
							className={
								'flex justify-center sm:justify-end items-center w-full col-span-2 sm:col-span-1'
							}
						>
							<a
								href={
									'mailto:ugcinsight@thegang.io?subject=Feedback'
								}
								className={
									'hover:text-blue-500 dark:hover:text-blue-300 justify-self-end flex justify-center items-center gap-1 text-center'
								}
							>
								We’d love to hear from you!
								<Icon icon={IconEnum.EMAIL} size={'1.5em'} />
							</a>
						</div>
					</div>
				</div>
			)}
		</ScrollProvider>
	);
});

export default View;
