import { makeAutoObservable, runInAction } from 'mobx';
import {
	Thumbnail,
	ThumbnailListSchema,
	ThumbnailType,
} from '@/schemas/thumbnail-schema.ts';
import { Experience } from '@/schemas/experience-schema.ts';
import httpFetch from '@services/http-fetch-service.ts';
import { BaseStore } from '@store/base-store.ts';

export const thumbnailApi = `${import.meta.env.VITE_SERVER_URL}/api/thumbnail`;

class ThumbnailStore implements BaseStore {
	private _latestIcons: Record<number, Thumbnail> = {};
	private _latestBanners: Record<number, Thumbnail> = {};
	private _allThumbnails: Record<number, Thumbnail[]> = {};

	reset() {
		runInAction(() => {
			this._latestIcons = {};
			this._latestBanners = {};
			this._allThumbnails = {};
		});
	}

	constructor() {
		makeAutoObservable(this);
	}

	async loadThumbnailsById(experienceIds: number[], type: ThumbnailType) {
		const filteredExperiences =
			type === ThumbnailType.ICON
				? experienceIds.filter((id) => !this._latestIcons[id])
				: experienceIds.filter((id) => !this._latestBanners[id]);

		if (!filteredExperiences.length) {
			return;
		}

		const searchParams = new URLSearchParams();
		searchParams.append('placeIds', filteredExperiences.join(','));
		searchParams.append('type', type);

		const response = await httpFetch.GET(
			`${thumbnailApi}/bulk?${searchParams.toString()}`
		);

		if (response.ok) {
			const thumbnails = ThumbnailListSchema.parse(await response.json());
			const loadedThumbnails: Record<number, Thumbnail> = {};

			// Some experiences do not have thumbnails, register them as empty
			filteredExperiences.forEach((id) => {
				loadedThumbnails[id] = {
					place_id: id,
					hash: 'default',
					type,
					time: '',
				};
			});

			thumbnails.forEach((t) => {
				if (t.place_id) {
					loadedThumbnails[t.place_id] = t;
				}
			});

			runInAction(() => {
				if (type === ThumbnailType.ICON) {
					this._latestIcons = {
						...this._latestIcons,
						...loadedThumbnails,
					};
				} else {
					this._latestBanners = {
						...this._latestBanners,
						...loadedThumbnails,
					};
				}
			});
		}
	}

	async loadThumbnails(experiences: Experience[], type: ThumbnailType) {
		const filteredExperiences =
			type === ThumbnailType.ICON
				? experiences.filter((exp) => !this._latestIcons[exp.place_id])
				: experiences.filter(
						(exp) => !this._latestBanners[exp.place_id]
					);
		if (!filteredExperiences.length) {
			return;
		}

		const searchParams = new URLSearchParams();
		searchParams.append(
			'placeIds',
			filteredExperiences.map((exp) => exp.place_id).join(',')
		);
		searchParams.append('type', type);

		const response = await httpFetch.GET(
			`${thumbnailApi}/bulk?${searchParams.toString()}`
		);

		if (response.ok) {
			const thumbnails = ThumbnailListSchema.parse(await response.json());
			const loadedThumbnails: Record<number, Thumbnail> = {};

			// Some experiences do not have thumbnails, register them as empty
			filteredExperiences.forEach((exp) => {
				loadedThumbnails[exp.place_id] = {
					place_id: exp.place_id,
					hash: 'default',
					type,
					time: '',
				};
			});

			thumbnails.forEach((t) => {
				if (t.place_id) {
					loadedThumbnails[t.place_id] = t;
				}
			});

			runInAction(() => {
				if (type === ThumbnailType.ICON) {
					this._latestIcons = {
						...this._latestIcons,
						...loadedThumbnails,
					};
				} else {
					this._latestBanners = {
						...this._latestBanners,
						...loadedThumbnails,
					};
				}
			});
		}
	}

	async loadAllThumbnails(experienceId: number) {
		if (this._allThumbnails[experienceId]) {
			return;
		}

		const searchParams = new URLSearchParams();
		searchParams.append('placeId', experienceId.toString());
		searchParams.append('startTime', new Date('2022-01-01').toISOString());

		const response = await httpFetch.GET(
			`${thumbnailApi}?${searchParams.toString()}`
		);

		if (response.ok) {
			const thumbnails = ThumbnailListSchema.parse(await response.json());
			runInAction(() => {
				this._allThumbnails = {
					...this._allThumbnails,
					[experienceId]: thumbnails,
				};

				if (
					!thumbnails.some(
						(thumbnail) => thumbnail.type === ThumbnailType.ICON
					)
				) {
					this._latestIcons = {
						...this._latestIcons,
						[experienceId]: {
							place_id: experienceId,
							hash: 'default',
							type: ThumbnailType.ICON,
							time: '',
						},
					};
				}

				if (
					!thumbnails.some(
						(thumbnail) => thumbnail.type === ThumbnailType.BANNER
					)
				) {
					this._latestBanners = {
						...this._latestIcons,
						[experienceId]: {
							place_id: experienceId,
							hash: 'default',
							type: ThumbnailType.BANNER,
							time: '',
						},
					};
				}
			});
		}
	}

	getLatestThumbnailHash(experience: Experience) {
		const thumbnail = this._latestIcons[experience.place_id];

		return thumbnail ? thumbnail.hash : undefined;
	}

	getLatestThumbnailHashById(experienceId: number) {
		const thumbnail = this._latestIcons[experienceId];

		return thumbnail ? thumbnail.hash : undefined;
	}

	getLatestBannerHash(experience: Experience) {
		const thumbnail = this._latestBanners[experience.place_id];

		return thumbnail ? thumbnail.hash : undefined;
	}

	getAllThumbnails(experience: Experience) {
		return this._allThumbnails[experience.place_id];
	}
}

const thumbnailStore = new ThumbnailStore();
export default thumbnailStore;
