import { observer } from 'mobx-react-lite';
import Highcharts from 'highcharts';
import Chart from '@components/core/chart/chart.tsx';
import BrandColors from '@styles/_brand_colors.ts';
import platformStatsStore from '@store/platform-stats-store.ts';
import { CHART_RANGE } from '@components/core/chart/chart-range.ts';

const PlatformCCUChart = observer(function PlatformCCUChart() {
	const data = platformStatsStore.platformCCUChartData;
	const options: Highcharts.Options = {
		series: [
			{
				type: 'area',
				name: 'Daily CCU',
				data,
				fillOpacity: 0.6,
				showInNavigator: true,
				cropThreshold: 5000,
				showInLegend: false,
			},
		],
		chart: {
			marginLeft: 45,
		},
		xAxis: {
			type: 'datetime',
			minRange: 2592000000, // Minimum zoom value is 1 month (in ms)
			min: data[0][0],
			max: data[data.length - 1][0],
		},
		yAxis: {
			title: {
				enabled: false,
			} as Highcharts.YAxisOptions,
			labels: {
				x: -10,
				y: 3,
				reserveSpace: false,
				style: {
					whiteSpace: 'nowrap',
				},
			},
			tickInterval: 2000000,
			min: 0,
			softMax: 8000000,
		},
		plotOptions: {
			area: {
				marker: {
					enabled: false,
				},
			},
		},
		rangeSelector: {
			enabled: true,
			inputEnabled: false,
			buttonPosition: {
				x: -20,
			},
			buttonTheme: {
				r: 10,
				style: {
					borderWidth: '1px solid black',
				},
				fill: 'none',
				states: {
					hover: {
						fill: BrandColors.blue300,
						style: {
							color: BrandColors.gray600,
						},
					},
					select: {
						fill: BrandColors.gray600,
						style: {
							color: BrandColors.white,
						},
					},
				},
			},
			labelStyle: {
				visibility: 'hidden',
			},
		},
		scrollbar: {
			enabled: true,
			barBackgroundColor: BrandColors.gray400,
		},
		navigator: {
			enabled: true,
			xAxis: {
				min: data[0][0],
				max: data[data.length - 1][0],
			},
		},
		responsive: {
			rules: [
				{
					condition: {
						maxWidth: 480,
					},
					chartOptions: {
						rangeSelector: {
							selected: 1,
						},
					},
				},
			],
		},
	};

	return (
		<Chart
			title={'Tracked Games CCU'}
			highcharts={Highcharts}
			options={options}
			fillPanelResponsiveWidth
			ranges={[
				CHART_RANGE.MONTH,
				CHART_RANGE.THREE_MONTHS,
				CHART_RANGE.SIX_MONTHS,
				CHART_RANGE.YEAR_TO_DATE,
				CHART_RANGE.YEAR,
				CHART_RANGE.ALL,
			]}
			info={
				'Computed from the daily average CCU of all games we are currently tracking on Roblox.'
			}
		/>
	);
});

export default PlatformCCUChart;
