import { observer } from 'mobx-react-lite';
import ClassString from '@utils/class-string.ts';
import Chart from '@components/core/chart/chart.tsx';
import Highcharts from 'highcharts';
import LoadingIndicator from '@components/core/loading-indicator/loading-indicator.tsx';
import GranularitySelector from '@components/data/granularity-selector/granularity-selector.tsx';
import { Experience } from '@/schemas/experience-schema.ts';
import { DataGranularity } from '@/enums/data-granularity.ts';
import { useCallback, useEffect, useState } from 'react';
import averagePlaytimeStatsStore from '@store/experience-stats/average-playtime-stats-store.ts';
import hexToRGBA from '@utils/hex-to-rgba.ts';
import {
	ExperienceData,
	getMinMaxX,
} from '@components/data/experience-stats/experience-stats-chart-helpers.ts';
import ChartColors from '@components/core/chart/chart-colors.ts';
import * as _ from 'lodash';
import { experienceStatsSharedOptions } from '@components/data/experience-stats/experience-stats-shared-options.ts';
import formatSecondsDurationString from '@utils/format-seconds-duration-string.ts';

interface AveragePlaytimeChartProps {
	experiences: Experience[];
}

const AveragePlaytimeChart = observer(function AveragePlaytimeChart(
	props: AveragePlaytimeChartProps
) {
	const [granularity, setGranularity] = useState(DataGranularity.HOUR);
	const [loading, setLoading] = useState(true);

	const mappedData = props.experiences.map((experience) => {
		return {
			experienceId: experience.place_id,
			experienceName: experience.name,
			data: averagePlaytimeStatsStore.averagePlaytime(
				experience.place_id,
				granularity
			),
		};
	});
	const data = mappedData.filter(
		(loadedData) => !!loadedData.data
	) as ExperienceData[];

	const noData =
		!loading &&
		(!data.length || data.every((placeData) => !placeData.data.length));

	const getData = useCallback(
		async (gran: DataGranularity) => {
			await averagePlaytimeStatsStore.loadAveragePlaytime(
				props.experiences.map((experience) => experience.place_id),
				gran
			);
			setLoading(false);
		},
		[props.experiences]
	);

	const updateGranularity = (g: DataGranularity) => {
		void getData(g);
		setGranularity(g);
		setLoading(true);
	};

	useEffect(() => {
		void getData(granularity);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.experiences]);

	const series: Highcharts.SeriesOptionsType[] = data.map(
		(averagePlaytimeData) => {
			return {
				name: 'Average Playtime',
				type: 'area',
				showInLegend: false,
				data: averagePlaytimeData.data ?? [],
				marker: {
					enabled: false,
				},
				fillOpacity: 0.6,
				lineWidth: 2,
				lineColor: ChartColors()[2],
				fillColor: hexToRGBA(ChartColors()[2], 0.6),
			};
		}
	);

	const { xMin, xMax } = getMinMaxX(data);

	const options: Highcharts.Options = {
		chart: {
			marginLeft: 65,
		},
		series,
		yAxis: {
			labels: {
				formatter: (
					formatter: Highcharts.AxisLabelsFormatterContextObject
				) => {
					const value = formatter.value as number;
					const hours = Math.floor(value / 3600);
					const minutes = Math.floor((value % 3600) / 60);
					const seconds = value % 60;

					if (hours > 0) {
						return `${hours}h ${minutes}m`;
					} else if (minutes > 0) {
						return `${minutes}m ${seconds}s`;
					}
					return `${seconds}s`;
				},
			},
		},
		navigator: {
			series,
			xAxis: {
				min: xMin,
				max: xMax,
			},
		},
		tooltip: {
			pointFormatter: function () {
				if (!this.y) {
					return '';
				}
				const value = this.y;
				const color = this.color as string;
				const formattedTime = formatSecondsDurationString(value);
				return `<span style="color:${color}">●</span> ${this.series.name}: <b>${formattedTime}</b><br/>`;
			},
		},
	};

	const mergedOptions: Highcharts.Options = _.merge(
		{},
		experienceStatsSharedOptions,
		options
	);

	return (
		<div>
			<div className={'relative'}>
				<div
					className={ClassString({
						static: 'transition-opacity',
						dynamic: { 'opacity-0': loading },
					})}
				>
					<Chart
						title={'Average Playtime'}
						highcharts={Highcharts}
						options={mergedOptions}
						granularity={granularity}
						info={
							'Estimated amount of time players spend in this experience. Calculated from public data and may not always be accurate.'
						}
						fillPanelResponsiveWidth
					/>
				</div>

				{loading && (
					<div
						className={
							'absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
						}
					>
						<LoadingIndicator />
					</div>
				)}
				{noData && (
					<div
						className={
							'absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
						}
					>
						No Data
					</div>
				)}
			</div>

			<GranularitySelector
				updateGranularity={updateGranularity}
				selectedGranularity={granularity}
				availableGranularities={[
					DataGranularity.HOUR,
					DataGranularity.DAY,
					DataGranularity.WEEK,
				]}
			/>
		</div>
	);
});

export default AveragePlaytimeChart;
