const formatMillisDurationString = (durationMillis: number) => {
	const millisecondsInASecond = 1000;
	const millisecondsInAMinute = millisecondsInASecond * 60;
	const millisecondsInAnHour = millisecondsInAMinute * 60;
	const millisecondsInADay = millisecondsInAnHour * 24;

	const totalDays = Math.floor(durationMillis / millisecondsInADay);
	const remainingMillisecondsAfterDays = durationMillis % millisecondsInADay;

	const totalHours = Math.floor(
		remainingMillisecondsAfterDays / millisecondsInAnHour
	);
	const remainingMillisecondsAfterHours =
		remainingMillisecondsAfterDays % millisecondsInAnHour;

	const totalMinutes = Math.floor(
		remainingMillisecondsAfterHours / millisecondsInAMinute
	);
	const remainingMillisecondsAfterMinutes =
		remainingMillisecondsAfterHours % millisecondsInAMinute;

	const totalSeconds = Math.floor(
		remainingMillisecondsAfterMinutes / millisecondsInASecond
	);

	let durationString = '';

	if (totalDays > 0) {
		durationString = `${totalDays} day${totalDays === 1 ? '' : 's'}`;
	}

	if (totalHours > 0) {
		durationString = `${durationString}${durationString ? ', ' : ''}${totalHours} hour${totalHours === 1 ? '' : 's'}`;
	}

	if (totalMinutes > 0) {
		durationString = `${durationString}${durationString ? ', ' : ''}${totalMinutes} min`;
	}

	if (totalSeconds > 0) {
		durationString = `${durationString}${durationString ? ', ' : ''}${totalSeconds} sec`;
	}

	return durationString;
};

export default formatMillisDurationString;
