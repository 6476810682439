import { observer } from 'mobx-react-lite';
import { Experience } from '@/schemas/experience-schema.ts';
import SeededRandom from '@utils/seeded-random.ts';
import { useEffect, useState } from 'react';
import LoadingSpinner from '@components/core/loading-spinner/loading-spinner.tsx';
import {
	DefaultBanners,
	ExperienceBannerSize,
} from '@components/data/experience-banner/experience-banner-constants.ts';
import thumbnailStore from '@store/thumbnail-store.ts';

interface ExperienceBannerProps {
	experience?: Experience;
	size: ExperienceBannerSize;
	className?: string;
}

const ExperienceBanner = observer(function ExperienceBanner(
	props: ExperienceBannerProps
) {
	let hash: string | undefined = undefined;

	if (!props.experience) {
		hash = 'default';
	} else if (props.experience.thumbnails?.banner !== undefined) {
		hash = props.experience.thumbnails.banner;
	} else if (props.experience) {
		hash = thumbnailStore.getLatestBannerHash(props.experience);
	}

	const defaultThumbnail =
		DefaultBanners[
			Math.floor(
				SeededRandom(
					props.experience ? +props.experience.place_id : 0
				) * DefaultBanners.length
			)
		];

	const [imgSrc, setImgSrc] = useState<string | undefined>(
		props.experience && !!hash && hash !== 'default'
			? `https://tr.rbxcdn.com/180DAY-${hash}/${props.size}/Image/Webp/noFilter`
			: defaultThumbnail
	);
	const onError = () => setImgSrc(defaultThumbnail);

	useEffect(() => {
		setImgSrc(
			props.experience && !!hash && hash !== 'default'
				? `https://tr.rbxcdn.com/180DAY-${hash}/${props.size}/Image/Webp/noFilter`
				: defaultThumbnail
		);
	}, [defaultThumbnail, hash, props.experience, props.size]);

	return (
		<>
			{hash && (
				<img
					src={imgSrc ? imgSrc : defaultThumbnail}
					alt={''}
					className={props.className}
					loading={'lazy'}
					onError={onError}
					draggable={false}
				/>
			)}

			{!hash && <LoadingSpinner className={props.className} />}
		</>
	);
});

export default ExperienceBanner;
