import { makeAutoObservable, runInAction } from 'mobx';
import httpFetch from '@services/http-fetch-service.ts';
import {
	PlatformCCU,
	PlatformCCUListResponseSchema,
} from '@/schemas/platform-ccu.ts';
import {
	PlatformMovement,
	PlatformMovementListSchema,
} from '@/schemas/platform-movement-schema.ts';
import { BaseStore } from '@store/base-store.ts';

const platformCCUApi = `${import.meta.env.VITE_SERVER_URL}/api/platform/ccu`;
const platformGenreApi = `${import.meta.env.VITE_SERVER_URL}/api/platform/genre?new`;
const platformMovementApi = `${import.meta.env.VITE_SERVER_URL}/api/platform/movement`;

class PlatformStatsStore implements BaseStore {
	private _platformCCU: PlatformCCU[] = [];
	private _platformCCUChartData: number[][] = [];
	private _top100Entry: PlatformMovement[] = [];
	private _top100Exit: PlatformMovement[] = [];

	private _platformGenreDistribution: Record<string, Record<string, number>> =
		{};

	private _loading = true;

	reset() {
		runInAction(() => {
			this._platformCCU = [];
			this._platformCCUChartData = [];
			this._top100Entry = [];
			this._top100Exit = [];

			this._platformGenreDistribution = {};
			this._loading = true;
		});
	}

	constructor() {
		makeAutoObservable(this);
	}

	async loadPlatformCCU() {
		const response = await httpFetch.GET(
			`${platformCCUApi}?granularity=day`
		);

		if (!this._loading) {
			runInAction(() => {
				this._loading = true;
			});
		}

		if (response.ok) {
			const platformCCUResponse = PlatformCCUListResponseSchema.parse(
				await response.json()
			);

			runInAction(() => {
				this._platformCCU = platformCCUResponse
					.map((data) => {
						return { ...data, time: new Date(data.time) };
					})
					.sort((a, b) => a.time.getTime() - b.time.getTime());

				this._platformCCUChartData = this._platformCCU.map((data) => {
					return [data.time.getTime(), data.ccu];
				});
			});
		}

		runInAction(() => {
			this._loading = false;
		});
	}

	async loadGenreDistribution() {
		if (Object.entries(this._platformGenreDistribution).length) {
			// Data already loaded
			return;
		}

		const response = await httpFetch.GET(platformGenreApi);

		if (response.ok) {
			const platformDis = (await response.json()) as Record<
				string,
				Record<string, number>
			>;
			runInAction(() => {
				this._platformGenreDistribution =
					this.renameGenreKeys(platformDis);
			});
		}
	}

	async loadPlatformMovement() {
		const [entryResponse, exitResponse] = await Promise.all([
			httpFetch.GET(`${platformMovementApi}?type=top100entry`),
			httpFetch.GET(`${platformMovementApi}?type=top100exit`),
		]);

		let top100Entry: PlatformMovement[] | undefined;
		let top100Exit: PlatformMovement[] | undefined;

		if (entryResponse.ok) {
			top100Entry = PlatformMovementListSchema.parse(
				await entryResponse.json()
			);
		}

		if (exitResponse.ok) {
			top100Exit = PlatformMovementListSchema.parse(
				await exitResponse.json()
			);
		}

		runInAction(() => {
			if (top100Entry) {
				this._top100Entry = top100Entry;
			}

			if (top100Exit) {
				this._top100Exit = top100Exit;
			}
		});
	}

	get loading() {
		return this._loading;
	}

	get platformCCU() {
		return this._platformCCU;
	}

	get platformCCUChartData() {
		return this._platformCCUChartData;
	}

	get platformGenreDistribution() {
		return this._platformGenreDistribution;
	}

	get top100Entry() {
		return this._top100Entry;
	}

	get top100Exit() {
		return this._top100Exit;
	}

	private renameGenreKeys(
		data: Record<string, Record<string, number>>
	): Record<string, Record<string, number>> {
		const newData: Record<string, Record<string, number>> = {};

		for (const outerKey in data) {
			const newOuterKey = outerKey === 'Unlabeled' ? '—' : outerKey;
			const innerRecord = data[outerKey];
			const newInnerRecord: Record<string, number> = {};

			for (const innerKey in innerRecord) {
				const newInnerKey = innerKey === 'Unlabeled' ? '—' : innerKey;
				newInnerRecord[newInnerKey] = innerRecord[innerKey];
			}

			newData[newOuterKey] = newInnerRecord;
		}

		return newData;
	}
}

const platformStatsStore = new PlatformStatsStore();
export default platformStatsStore;
