import { makeAutoObservable, runInAction } from 'mobx';
import httpFetch from '@services/http-fetch-service.ts';
import {
	DailyPicks,
	DailyPicksResponseArraySchema,
} from '@/schemas/daily-picks-schema.ts';
import { BaseStore } from '@store/base-store.ts';

const baseUrl = import.meta.env.VITE_SERVER_URL as string;

const dailyPickUrl = `${baseUrl}/api/platform/picks`;

class DailyPickStore implements BaseStore {
	private _currentDailyPick: DailyPicks | undefined;
	private _dailyPicks: Record<string, DailyPicks> = {};

	reset() {
		runInAction(() => {
			this._currentDailyPick = undefined;
			this._dailyPicks = {};
		});
	}

	constructor() {
		makeAutoObservable(this);
	}

	async loadCurrentDailyPicks() {
		const response = await httpFetch.GET(dailyPickUrl);

		if (response.ok) {
			const dailyPicksResponse = DailyPicksResponseArraySchema.parse(
				await response.json()
			);

			if (dailyPicksResponse.length) {
				const dailyPicks = {
					...dailyPicksResponse[0],
					date: new Date(dailyPicksResponse[0].date),
				};

				runInAction(() => {
					this._currentDailyPick = dailyPicks;
				});
			}
		}
	}

	async loadDailyPicks(startDate: Date, endDate: Date) {
		const dateRange = this.getDateRange(startDate, endDate);

		if (
			dateRange.every(
				(date) => !!this._dailyPicks[this.getDailyPickKey(date)]
			)
		) {
			return;
		}

		const response = await httpFetch.GET(
			`${dailyPickUrl}?startTime=${startDate.toISOString()}&endTime=${endDate.toISOString()}`
		);

		if (response.ok) {
			const dailyPicksResponse = DailyPicksResponseArraySchema.parse(
				await response.json()
			);

			const updatedDailyPicks = { ...this._dailyPicks };

			for (const date of dateRange) {
				const key = this.getDailyPickKey(date);
				if (!updatedDailyPicks[key]) {
					updatedDailyPicks[key] = {
						places: [],
						date,
					};
				}
			}

			for (const dp of dailyPicksResponse) {
				const dailyPicks = {
					...dp,
					date: new Date(dp.date),
				};

				const key = this.getDailyPickKey(dailyPicks.date);

				updatedDailyPicks[key] = dailyPicks;
			}
			runInAction(() => {
				this._dailyPicks = updatedDailyPicks;
			});
		}
	}

	get currentDailyPick() {
		return this._currentDailyPick;
	}

	getDailyPicks(startDate: Date, endDate: Date) {
		const dates = this.getDateRange(startDate, endDate);
		const dailyPicks = [];
		for (const date of dates) {
			const dailyPick = this._dailyPicks[this.getDailyPickKey(date)];
			if (dailyPick) {
				dailyPicks.push(dailyPick);
			} else {
				dailyPicks.push({
					places: [],
					date,
				});
			}
		}

		return dailyPicks;
	}

	private getDailyPickKey(date?: Date) {
		const d = date ?? new Date();

		return `${d.getUTCFullYear()}-${d.getUTCMonth() + 1}-${d.getUTCDate()}`;
	}

	private getDateRange(startDate: Date, endDate: Date): Date[] {
		const dates: Date[] = [];
		const currentDate = new Date(startDate);

		// Loop until the current date reaches the end date
		while (currentDate <= endDate) {
			dates.push(new Date(currentDate)); // Add a new Date instance to avoid reference issues
			currentDate.setDate(currentDate.getDate() + 1); // Move to the next day
		}

		return dates;
	}
}

const dailyPickStore = new DailyPickStore();
export default dailyPickStore;
