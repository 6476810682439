import { observer } from 'mobx-react-lite';
import {
	DefaultIcons,
	ExperienceIconSize,
} from '@components/data/experience-icon/experience-icon-constants.ts';
import SeededRandom from '@utils/seeded-random.ts';
import ClassString from '@utils/class-string.ts';
import compoundThumbnailStore from '@store/compound-thumbnail-store.ts';
import CompoundExperienceIconFallbackImage from '@components/data/experience-icon/compound-experience-icon-fallback-image.tsx';
import LoadingSpinner from '@components/core/loading-spinner/loading-spinner.tsx';

interface CompoundExperienceIconProps {
	tagId?: number;
	className?: string;
	iconSize?: ExperienceIconSize;
}

const CompoundExperienceIcon = observer(function CompoundExperienceIcon(
	props: CompoundExperienceIconProps
) {
	const sources =
		props.tagId && compoundThumbnailStore.tagThumbnailsLoaded(props.tagId)
			? compoundThumbnailStore
					.getTagThumbnail(props.tagId)
					.map((hash) => {
						return !hash || hash === 'default'
							? DefaultIcons[
									Math.floor(
										SeededRandom(+hash) *
											DefaultIcons.length
									)
								]
							: `https://tr.rbxcdn.com/180DAY-${hash}/${props.iconSize ?? ExperienceIconSize.VERY_SMALL}/Image/Webp/noFilter`;
					})
			: [];

	const loading =
		!!props.tagId &&
		!compoundThumbnailStore.tagThumbnailsLoaded(props.tagId);

	const increaseColSpan = (index: number) => {
		if (index === 0 && sources.length === 3) {
			return true;
		}

		return sources.length < 3;
	};

	return (
		<div
			className={ClassString({
				static: 'w-20 h-20',
				dynamic: {},
				custom: props.className,
			})}
		>
			{loading && (
				<div
					className={'flex justify-center items-center w-full h-full'}
				>
					<LoadingSpinner />
				</div>
			)}

			<div
				className={
					'grid grid-cols-2 w-full h-full overflow-hidden gap-[1px] p-[1px]'
				}
			>
				{sources.map((src, idx) => (
					<div
						key={idx}
						className={ClassString({
							static: 'w-full h-full overflow-hidden',
							dynamic: {
								'col-span-2': increaseColSpan(idx),
								// hidden: !allLoaded || hashesLoading,
								hidden: loading,
							},
						})}
					>
						<CompoundExperienceIconFallbackImage
							src={src}
							alt={''}
							className={'object-cover w-full h-full rounded-md'}
						/>
					</div>
				))}
			</div>
		</div>
	);
});

export default CompoundExperienceIcon;
