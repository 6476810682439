import { observer } from 'mobx-react-lite';
import Highcharts, { SeriesOptionsType } from 'highcharts';
import Chart from '@components/core/chart/chart.tsx';
import { useCallback, useEffect, useState } from 'react';
import placeStatsStore, {
	isValidGranularity,
	PlaceStatType,
} from '@store/place-stats-store.ts';
import LoadingIndicator from '@components/core/loading-indicator/loading-indicator.tsx';
import {
	ExperienceStatus,
	FullExperience,
} from '@/schemas/experience-schema.ts';
import hexToRGBA from '@utils/hex-to-rgba.ts';
import ChartColors from '@components/core/chart/chart-colors.ts';
import themeStore from '@store/theme-store.ts';
import ThemeStore from '@store/theme-store.ts';
import BrandColors from '@styles/_brand_colors.ts';
import thumbnailStore from '@store/thumbnail-store.ts';
import { ExperienceIconSize } from '@components/data/experience-icon/experience-icon-constants.ts';
import { ThumbnailType } from '@/schemas/thumbnail-schema.ts';
import { ExperienceBannerSize } from '@components/data/experience-banner/experience-banner-constants.ts';
import ClassString from '@utils/class-string.ts';
import { ExperienceCcuAndVisitsState } from '@components/data/experience-ccu-and-visits/experience-ccu-and-visits-state.ts';
import GranularitySelector from '@components/data/granularity-selector/granularity-selector.tsx';
import modalStore from '@store/modal-store.ts';
import hashQueryParamService from '@services/hash-query-param-service.ts';
import getGraphId from '@utils/get-graph-id.ts';
import SlideToggle from '@components/core/slide-toggle/slide-toggle.tsx';
import { IconEnum } from '@components/core/icon/icon-enum.ts';
import {
	fillMissingTimestamps,
	findNearestDataPoint,
	getSurroundingPointsForNulls,
} from '@utils/chart-data.ts';
import localStorageService from '@services/local-storage-service.ts';
import authStore from '@store/auth-store.ts';
import { LocalStorageKey } from '@/enums/local-storage-types.ts';
import robuxPriceHistoryStore from '@store/robux-price-history-store.ts';
import { DataGranularity } from '@/enums/data-granularity.ts';

interface ExperienceCcuAndVisitsProps {
	place: FullExperience;
	state: ExperienceCcuAndVisitsState;
}

const ChartTitles: Record<ExperienceCcuAndVisitsState, string> = {
	[ExperienceCcuAndVisitsState.CCU]: 'Concurrent Users',
	[ExperienceCcuAndVisitsState.VISITS]: 'New Visits',
	[ExperienceCcuAndVisitsState.BOTH]: 'Concurrent Users and New Visits',
};

enum ToggleType {
	DAILY_PICKS = 'daily_picks',
	ICONS = 'icons',
	BANNERS = 'banners',
	ROBUX_HISTORY = 'robux_history',
}

const getDefaultGranularity = (title: string) => {
	const hashParams = hashQueryParamService.get();
	if (
		hashParams.granularity &&
		hashParams.anchor === getGraphId(title) &&
		isValidGranularity(hashParams.granularity)
	) {
		return hashParams.granularity as DataGranularity;
	}

	return DataGranularity.DAY;
};

const ExperienceCcuAndVisits = observer(function ExperienceCcuAndVisits(
	props: ExperienceCcuAndVisitsProps
) {
	const [granularity, setGranularity] = useState(
		getDefaultGranularity(ChartTitles[props.state])
	);
	const [loading, setLoading] = useState(true);
	const [initialized, setInitialized] = useState(false);

	const [showRobuxHistory, setShowRobuxHistory] = useState(
		localStorageService.get(
			`${LocalStorageKey.CCUToggleState}-${ToggleType.ROBUX_HISTORY}`,
			authStore.user?.id
		) !== 'false'
	);
	const [showDailyPicks, setShowDailyPicks] = useState(
		localStorageService.get(
			`${LocalStorageKey.CCUToggleState}-${ToggleType.DAILY_PICKS}`,
			authStore.user?.id
		) !== 'false'
	);
	const [showIcons, setShowIcons] = useState(
		localStorageService.get(
			`${LocalStorageKey.CCUToggleState}-${ToggleType.ICONS}`,
			authStore.user?.id
		) !== 'false'
	);
	const [showBanners, setshowBanners] = useState(
		localStorageService.get(
			`${LocalStorageKey.CCUToggleState}-${ToggleType.BANNERS}`,
			authStore.user?.id
		) !== 'false'
	);

	const updateToggle = (state: boolean, type: ToggleType) => {
		localStorageService.set(
			`${LocalStorageKey.CCUToggleState}-${type}`,
			`${state}`,
			authStore.user?.id
		);

		switch (type) {
			case ToggleType.ROBUX_HISTORY:
				setShowRobuxHistory(state);
				break;
			case ToggleType.DAILY_PICKS:
				setShowDailyPicks(state);
				break;
			case ToggleType.ICONS:
				setShowIcons(state);
				break;
			case ToggleType.BANNERS:
				setshowBanners(state);
				break;
		}
	};

	const robuxPriceHistory = showRobuxHistory
		? robuxPriceHistoryStore.experienceRobuxPriceHistory(
				props.place.place_id
			)
		: [];

	const thumbnails = thumbnailStore.getAllThumbnails(props.place)
		? thumbnailStore.getAllThumbnails(props.place).filter((thumbnail) => {
				if (showIcons && showBanners) {
					return true;
				}

				if (!showIcons && thumbnail.type === ThumbnailType.ICON) {
					return false;
				}

				if (!showBanners && thumbnail.type === ThumbnailType.BANNER) {
					return false;
				}

				return true;
			})
		: [];

	const series: {
		name: string;
		color: string;
		data: (number | null)[][] | undefined;
	}[] = [];

	const missingDataSeries: {
		name: string;
		color: string;
		data: (number | null)[][] | undefined;
	}[] = [];

	if (
		props.state === ExperienceCcuAndVisitsState.VISITS ||
		props.state === ExperienceCcuAndVisitsState.BOTH
	) {
		series.push({
			name: 'New Visits',
			color: ChartColors()[1],
			data: fillMissingTimestamps(
				placeStatsStore.placeStat(
					props.place.place_id,
					PlaceStatType.VISITS,
					granularity
				) ?? [],
				granularity
			),
		});

		missingDataSeries.push({
			name: 'New Visits',
			color: ChartColors()[1],
			data: getSurroundingPointsForNulls(
				placeStatsStore.placeStat(
					props.place.place_id,
					PlaceStatType.VISITS,
					granularity
				) ?? [],
				granularity
			),
		});
	}

	if (
		props.state === ExperienceCcuAndVisitsState.CCU ||
		props.state === ExperienceCcuAndVisitsState.BOTH
	) {
		series.push({
			name: 'Concurrent Users',
			color: ChartColors()[0],
			data: fillMissingTimestamps(
				placeStatsStore.placeStat(
					props.place.place_id,
					PlaceStatType.CCUS,
					granularity
				) ?? [],
				granularity
			),
		});

		missingDataSeries.push({
			name: 'Concurrent Users',
			color: ChartColors()[0],
			data: getSurroundingPointsForNulls(
				placeStatsStore.placeStat(
					props.place.place_id,
					PlaceStatType.CCUS,
					granularity
				) ?? [],
				granularity
			),
		});
	}

	const noData = !loading && !series.some((series) => !!series.data?.length);

	const getData = useCallback(
		async (tf: DataGranularity) => {
			await Promise.all([
				placeStatsStore.getPlaceStats(
					[props.place.place_id],
					PlaceStatType.VISITS,
					tf
				),
				placeStatsStore.getPlaceStats(
					[props.place.place_id],
					PlaceStatType.CCUS,
					tf
				),
			]);

			setLoading(false);
			if (!initialized) {
				setInitialized(true);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[props.place]
	);

	let xMin = 0;
	let xMax = 0;
	series.forEach((pd) => {
		if (pd.data) {
			pd.data.forEach((d) => {
				const date = d[0];

				if (date) {
					if (xMin === 0) {
						xMin = date;
					}

					if (xMax === 0) {
						xMax = date;
					}

					if (date < xMin) {
						xMin = date;
					}

					if (date > xMax) {
						xMax = date;
					}
				}
			});
		}
	});

	const mappedSeries = [
		...series.map((series): SeriesOptionsType => {
			return {
				name: series.name,
				type: 'area',
				showInLegend: false,
				data: series.data ?? [],
				marker: {
					enabled: false,
				},
				color: series.color,
				lineWidth: 3,
				lineColor: series.color,
				fillColor: hexToRGBA(series.color, 0.6),
			};
		}),
		...missingDataSeries.map((series): SeriesOptionsType => {
			return {
				name: series.name,
				type: 'line',
				showInLegend: false,
				data: series.data ?? [],
				marker: {
					enabled: false,
				},
				lineWidth: 3,
				color: series.color,
				dashStyle: 'Dash',
				enableMouseTracking: false,
			};
		}),
	];

	const dailyPickDates = showDailyPicks ? props.place.daily_pick_dates : [];

	const navigatorSeries: SeriesOptionsType[] = [
		...series.map((series): SeriesOptionsType => {
			return {
				type: 'area',
				data: series.data ?? [],
				color: series.color,
				lineWidth: 1,
				lineColor: series.color,
				fillColor: hexToRGBA(series.color, 0.6),
			};
		}),
		...missingDataSeries.map((series): SeriesOptionsType => {
			return {
				type: 'line',
				data: series.data ?? [],
				marker: {
					enabled: false,
				},
				lineWidth: 1,
				color: series.color,
				dashStyle: 'Dash',
			};
		}),
	];

	const options: Highcharts.Options = {
		series: mappedSeries,
		annotations: [
			{
				draggable: '',
				labels: [
					...thumbnails.map((thumbnail) => {
						const cutoffDate = new Date(`2024-10-03`);
						const thumbnailDate = new Date(thumbnail.time);

						const hashPrefix =
							thumbnailDate >= cutoffDate ? '180DAY-' : '';

						const imgUrl = `https://tr.rbxcdn.com/${hashPrefix}${thumbnail.hash}/${thumbnail.type === ThumbnailType.ICON ? ExperienceIconSize.SMALL : ExperienceBannerSize.SMALL}/Image/Webp/noFilter`;

						return {
							useHTML: true,
							allowOverlap: true,
							point: {
								xAxis: 0,
								yAxis: 400,
								x: new Date(thumbnail.time).getTime(),
								y: 0,
							},
							y:
								thumbnail.type === ThumbnailType.ICON ||
								!showIcons
									? -16
									: 60,
							backgroundColor: 'transparent',
							borderWidth: 0,
							text: `<div id="${hashPrefix}${thumbnail.hash}-${thumbnail.type}" class="group w-fit flex flex-col justify-center items-center gap-2 dark:text-white bg-white dark:bg-gray-525 rounded-xs p-1 outline-1 outline-gray-300 dark:outline-gray-500 cursor-pointer hover:absolute hover:z-in-front">
										<div class="${ClassString({
											static: 'flex group-touch-hover:hidden flex-col justify-center items-center gap-2 pointer-events-none',
											dynamic: {
												'min-h-8 min-w-8':
													thumbnail.type ===
													ThumbnailType.ICON,
												'min-h-6 min-w-9':
													thumbnail.type ===
													ThumbnailType.BANNER,
											},
										})}">
							                  <img src="${imgUrl}" alt="" class="${ClassString({
													static: 'pointer-events-none',
													dynamic: {
														'h-8 w-8':
															thumbnail.type ===
															ThumbnailType.ICON,
														'h-5 w-9':
															thumbnail.type ===
															ThumbnailType.BANNER,
													},
												})}">
										</div>
										
										<div class="hidden group-touch-hover:flex flex-col justify-center items-center gap-2 pointer-events-none">
							                <img src="${imgUrl}" alt="" class="${ClassString({
												static: 'pointer-events-none',
												dynamic: {
													'h-16 w-16':
														thumbnail.type ===
														ThumbnailType.ICON,
													'h-11 w-16':
														thumbnail.type ===
														ThumbnailType.BANNER,
												},
											})}">
							                <div>
							                	${thumbnail.type === ThumbnailType.ICON ? 'Icon' : 'Thumbnail'}
											</div>
											<div>
							                	${new Date(thumbnail.time).toLocaleDateString()}
							                </div>
							                <div>
							                	${new Date(thumbnail.time).toLocaleTimeString()}
							                </div>
										</div>
					               </div>`,
						};
					}),
					...dailyPickDates.map((date) => {
						return {
							useHTML: true,
							allowOverlap: true,
							point: {
								xAxis: 0,
								yAxis: 0,
								x: date.getTime(),
								y: 0,
							},
							y: 0,
							backgroundColor: 'transparent',
							borderWidth: 0,
							text: `<div id="daily-pick-${date.getTime()}" class="relative">
										<div class="group flex flex-col justify-center items-center gap-2 dark:text-white bg-white dark:bg-gray-525 rounded-xs  outline-dashed outline-1 outline-gray-600 dark:outline-white h-10 w-10 absolute top-0 left-1/2 transform -translate-x-1/2 translate-y-2">
											<img src="${themeStore.lightTheme ? '/daily-picks-black.svg' : '/daily-picks-white.svg'}" alt="" />
											<div class="absolute hidden group-touch-hover:flex justify-center items-center top-[150%] left-1/2 transform -translate-x-1/2 -translate-y-1/2 py-1 px-2 text-sm dark:text-white bg-white dark:bg-gray-525 rounded-xs outline-1 outline-gray-600 dark:outline-white">
												Daily Pick
											</div>
										</div>
									</div>`,
						};
					}),
					...robuxPriceHistory.map((history) => {
						return {
							useHTML: true,
							allowOverlap: true,
							point: {
								xAxis: 0,
								yAxis: 400,
								x: history.time.getTime(),
								y: 0,
							},
							backgroundColor: 'transparent',
							borderWidth: 0,
							text: `<div id="robux-price-history-${history.time.getTime()}" class="${ClassString({ static: 'group flex flex-col justify-center items-center gap-2 dark:text-white bg-white dark:bg-gray-525 rounded-xs outline-1 outline-gray-600 dark:outline-white h-8 w-12 relative group z-in-front', dynamic: { 'w-14': history.price > 99 } })}">
										<div class="flex justify-center items-center gap-1 text-sm">
											${history.price}
											<img src="${themeStore.lightTheme ? '/robux-black.svg' : '/robux-white.svg'}" alt="" class="h-5 w-5" />
										</div>
										<div class="absolute hidden group-touch-hover:flex justify-center items-center gap-1 top-[175%] left-1/2 transform -translate-x-1/2 -translate-y-1/2 py-1 px-4 text-xs dark:text-white bg-white dark:bg-gray-525 rounded-xs outline-1 outline-gray-600 dark:outline-white">
											Price Change:
											${history.change > 0 ? `+${history.change}` : history.change}
											<img src="${themeStore.lightTheme ? '/robux-black.svg' : '/robux-white.svg'}" alt="" class="h-5 w-5" />
										</div>
									</div>`,
						};
					}),
					...(props.place.released.getFullYear() > 1
						? [
								{
									useHTML: true,
									allowOverlap: true,
									point: {
										xAxis: 0,
										yAxis: 0,
										x: props.place.released.getTime(),
										y: 0,
									},
									y: 0,
									backgroundColor: 'transparent',
									borderWidth: 0,
									text: `<div id="release-date-${props.place.released.getTime()}" class="relative">
										<div class="group flex flex-col justify-center items-center gap-2 dark:text-white bg-white dark:bg-gray-525 rounded-xs  outline-dashed outline-1 outline-gray-600 dark:outline-white absolute top-0 left-1/2 transform -translate-x-1/2 translate-y-2 px-2 py-1 text-sm">
											Release
										</div>
								</div>`,
								},
							]
						: []),
				],
				shapes: [
					...thumbnails.map((thumbnail) => {
						return {
							type: 'path',
							points: [
								{
									xAxis: 0,
									yAxis: 400,
									x: new Date(thumbnail.time).getTime(),
									y: 0,
								},
								{
									xAxis: 0,
									yAxis: 0,
									x: new Date(thumbnail.time).getTime(),
									y: 0,
								},
							],
							strokeWidth: 1, // Line thickness
							stroke: ThemeStore.lightTheme
								? BrandColors.gray600
								: BrandColors.white, // Line color (semi-transparent black)
						};
					}),
					...dailyPickDates.map((date) => {
						const data =
							placeStatsStore.placeStat(
								props.place.place_id,
								props.state === ExperienceCcuAndVisitsState.CCU
									? PlaceStatType.CCUS
									: PlaceStatType.VISITS,
								granularity
							) ?? [];
						const nearestDataPoint = findNearestDataPoint(
							data,
							date.getTime()
						);

						return {
							type: 'path',
							dashStyle: 'Dash',
							points: [
								{
									xAxis: 0,
									yAxis: 0,
									x: date.getTime(),
									y: nearestDataPoint.value,
								},
								{
									xAxis: 0,
									yAxis: 0,
									x: date.getTime(),
									y: 0,
								},
							],
							strokeWidth: 1, // Line thickness
							stroke: ThemeStore.lightTheme
								? BrandColors.gray600
								: BrandColors.white, // Line color (semi-transparent black)
						};
					}),
					...robuxPriceHistory.map((history) => {
						return {
							type: 'path',
							points: [
								{
									xAxis: 0,
									yAxis: 400,
									x: history.time.getTime(),
									y: 0,
								},
								{
									xAxis: 0,
									yAxis: 0,
									x: history.time.getTime(),
									y: 0,
								},
							],
							strokeWidth: 1, // Line thickness
							stroke: ThemeStore.lightTheme
								? BrandColors.gray600
								: BrandColors.white, // Line color (semi-transparent black)
						};
					}),
					...(props.place.released.getFullYear() > 1
						? [props.place.released].map((date) => {
								const data =
									placeStatsStore.placeStat(
										props.place.place_id,
										props.state ===
											ExperienceCcuAndVisitsState.CCU
											? PlaceStatType.CCUS
											: PlaceStatType.VISITS,
										granularity
									) ?? [];
								const nearestDataPoint = findNearestDataPoint(
									data,
									date.getTime()
								);

								return {
									type: 'path',
									dashStyle: 'Dash',
									points: [
										{
											xAxis: 0,
											yAxis: 0,
											x: date.getTime(),
											y: nearestDataPoint.value,
										},
										{
											xAxis: 0,
											yAxis: 0,
											x: date.getTime(),
											y: 0,
										},
									],
									strokeWidth: 1, // Line thickness
									stroke: ThemeStore.lightTheme
										? BrandColors.gray600
										: BrandColors.white, // Line color (semi-transparent black)
								};
							})
						: []),
				],
				events: {
					click: (clickEvent) => {
						if (clickEvent) {
							const label = clickEvent.target as HTMLDivElement;
							if (
								!label.id.includes('Icon') &&
								!label.id.includes('Banner')
							) {
								return;
							}
							const splitId = label.id.split('-');
							const prefix =
								splitId.length < 3
									? ''
									: `${label.id.split('-')[0]}-`;
							const hash =
								splitId.length < 3 ? splitId[0] : splitId[1];
							const type =
								splitId.length < 3 ? splitId[1] : splitId[2];
							const typeEnum =
								type === 'Icon'
									? ThumbnailType.ICON
									: ThumbnailType.BANNER;
							const imgUrl = `https://tr.rbxcdn.com/${prefix}${hash}/${typeEnum === ThumbnailType.ICON ? ExperienceIconSize.VERY_LARGE : ExperienceBannerSize.VERY_LARGE}/Image/Webp/noFilter`;

							modalStore.open(<img src={imgUrl} alt={''} />);
						}
					},
				},
			},
		],
		chart: {
			marginLeft: 45,
		},
		xAxis: {
			type: 'datetime',
			title: {
				y: 5,
				style: {
					color: themeStore.lightTheme
						? BrandColors.gray600
						: BrandColors.white,
					fontStyle: 'italic',
				},
			},
		},
		yAxis: {
			title: {
				enabled: false,
			} as Highcharts.YAxisOptions,
			labels: {
				x: -10,
				y: 3,
				reserveSpace: false,
				style: {
					whiteSpace: 'nowrap',
				},
			},
			tickPositioner: function (this: Highcharts.Axis): number[] {
				const axis = this as Highcharts.Axis & {
					dataMax: number | undefined;
					tickInterval: number | undefined;
				};
				const dataMax = axis.dataMax ?? 0;

				// Ensure the max value is at least 1
				const max = Math.max(dataMax, 1);
				const min = this.min ?? 0;

				// Use tickInterval if defined, otherwise fallback to a calculated interval
				const tickInterval = axis.tickInterval ?? (max - min) / 5; // Default fallback
				const tickPositions = this.getLinearTickPositions(
					tickInterval,
					min,
					max
				);

				// If max is 1, enforce ticks at [0, 1]
				return max === 1 ? [0, 1] : tickPositions;
			},
		},
		plotOptions: {
			line: {
				marker: {
					enabled: false,
				},
			},
		},
		navigator: {
			enabled: true,
			series: navigatorSeries,
			xAxis: {
				min: xMin,
				max: xMax,
			},
		},
		scrollbar: {
			enabled: true,
			liveRedraw: true,
		},
		rangeSelector: {
			selected:
				props.place.play_status !== ExperienceStatus.PLAYABLE ? 7 : 2,
		},
	};

	const updateTimeFrame = (timeframe: DataGranularity) => {
		void getData(timeframe);
		setGranularity(timeframe);
		setLoading(true);
	};

	useEffect(() => {
		void getData(granularity);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className={'w-full'}>
			<div className={'w-full relative'}>
				<div
					className={ClassString({
						static: 'transition-opacity',
						dynamic: { 'opacity-0': loading && !initialized },
					})}
				>
					<Chart
						title={ChartTitles[props.state]}
						highcharts={Highcharts}
						options={options}
						granularity={granularity}
						fillPanelResponsiveWidth
					/>
				</div>

				{loading && (
					<LoadingIndicator
						className={
							'absolute! top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
						}
					/>
				)}
				{noData && (
					<div
						className={
							'absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
						}
					>
						No Data
					</div>
				)}
			</div>

			<div
				className={
					'w-full flex items-center justify-between flex-wrap gap-4'
				}
			>
				<GranularitySelector
					updateGranularity={updateTimeFrame}
					selectedGranularity={granularity}
				/>

				<div
					className={
						'flex justify-center items-center gap-4 select-none'
					}
				>
					{!!robuxPriceHistoryStore.experienceRobuxPriceHistory(
						props.place.place_id
					).length && (
						<div
							className={
								'flex flex-col justify-center items-center gap-2 w-16 text-center overflow-x-visible'
							}
						>
							<div className={'text-xs whitespace-nowrap'}>
								{'Robux Price'}
							</div>
							<SlideToggle
								initialState={showRobuxHistory}
								smallButton
								icons={{
									on: IconEnum.CONFIRM,
									onSize: '1.5rem',
									off: IconEnum.CLEAR,
									offSize: '1.5rem',
								}}
								onToggle={(show) => {
									updateToggle(
										show,
										ToggleType.ROBUX_HISTORY
									);
								}}
								toggleBackgroundColor
							/>
						</div>
					)}

					{!!props.place.daily_pick_dates.length && (
						<div
							className={
								'flex flex-col justify-center items-center gap-2 w-16'
							}
						>
							<div className={'text-xs'}>{'Daily Picks'}</div>
							<SlideToggle
								initialState={showDailyPicks}
								smallButton
								icons={{
									on: IconEnum.CONFIRM,
									onSize: '1.5rem',
									off: IconEnum.CLEAR,
									offSize: '1.5rem',
								}}
								onToggle={(show) => {
									updateToggle(show, ToggleType.DAILY_PICKS);
								}}
								toggleBackgroundColor
							/>
						</div>
					)}

					<div
						className={
							'flex flex-col justify-center items-center gap-2 w-16'
						}
					>
						<div className={'text-xs'}>{'Icons'}</div>
						<SlideToggle
							initialState={showIcons}
							smallButton
							icons={{
								on: IconEnum.CONFIRM,
								onSize: '1.5rem',
								off: IconEnum.CLEAR,
								offSize: '1.5rem',
							}}
							onToggle={(show) => {
								updateToggle(show, ToggleType.ICONS);
							}}
							toggleBackgroundColor
						/>
					</div>

					<div
						className={
							'flex flex-col justify-center items-center gap-2 w-16'
						}
					>
						<div className={'text-xs'}>{'Thumbnails'}</div>
						<SlideToggle
							initialState={showBanners}
							smallButton
							icons={{
								on: IconEnum.CONFIRM,
								onSize: '1.5rem',
								off: IconEnum.CLEAR,
								offSize: '1.5rem',
							}}
							onToggle={(show) => {
								updateToggle(show, ToggleType.BANNERS);
							}}
							toggleBackgroundColor
						/>
					</div>
				</div>
			</div>
		</div>
	);
});

export default ExperienceCcuAndVisits;
