import { ChangeEvent, RefObject, useId } from 'react';
import { INPUT_TEXT_TYPES } from '@components/core/input-text/input-text-enum.ts';
import ClassString from '@utils/class-string.ts';
import { observer } from 'mobx-react-lite';

export interface InputTextProps {
	label: string;
	value: string;
	name?: string;
	onInput?: (event: ChangeEvent<HTMLInputElement>) => unknown;
	onBlur?: () => unknown;
	onFocus?: () => unknown;
	className?: string;
	inputClassName?: string;
	type?: INPUT_TEXT_TYPES;
	options?: {
		noMarginBottom?: boolean;
		noMargin?: boolean;
		width?: string;
		noAutocomplete?: boolean;
		large?: boolean;
	};
	innerRef?: RefObject<HTMLInputElement>;
	placeholder?: string;
	disabled?: boolean;
}

const InputText = observer(function InputText(props: InputTextProps) {
	const id = useId();

	return (
		<div
			className={ClassString({
				static: 'relative my-3 group w-52',
				dynamic: {
					'mb-0': props.options?.noMarginBottom,
					'my-0': props.options?.noMargin,
				},
				custom: props.className,
			})}
			style={{
				width: props.options?.width,
			}}
		>
			<label
				htmlFor={id}
				className={ClassString({
					static: 'absolute transition-all select-none left-4 top-2 text-sm opacity-70 cursor-text group-focus-within:cursor-default group-focus-within:-top-4 group-focus-within:text-xs group-focus-within:opacity-100',
					dynamic: {
						'text-base top-3': props.options?.large,
						'cursor-default -top-4 text-xs opacity-100':
							!!props.value.length,
					},
				})}
			>
				{props.label}
			</label>
			<input
				id={id}
				type={`${props.type ? props.type : INPUT_TEXT_TYPES.TEXT}`}
				value={props.value}
				name={props.name}
				onInput={props.onInput}
				onBlur={props.onBlur}
				onFocus={props.onFocus}
				className={ClassString({
					static: 'w-full h-9 text-ellipsis transition-all rounded-full outline-1 px-4 bg-white placeholder:text-gray-400 dark:placeholder:text-gray-300 dark:bg-gray-525 outline-gray-600 dark:outline-gray-500 focus:outline-blue-300 focus:outline-2',
					dynamic: {
						'h-12 text-base': props.options?.large,
					},
					custom: props.inputClassName,
				})}
				autoComplete={props.options?.noAutocomplete ? 'off' : 'on'}
				ref={props.innerRef}
				placeholder={props.placeholder}
				disabled={props.disabled}
			/>
		</div>
	);
});

export default InputText;
