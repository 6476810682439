import View from '@components/view/view.tsx';
import experienceStore from '@store/experience-store.ts';
import { observer } from 'mobx-react-lite';
import PlaceGenreDistribution from '@components/data/place-genre-distribution/place-genre-distribution.tsx';
import PlaceLikeDistribution from '@components/data/place-like-distribution/place-like-distribution.tsx';
import TopPlatformMovement from '@views/overview/view-components/top-platform-movement.tsx';
import TopCreatorsListTypeEnum from '@views/overview/view-components/top-platform-movement-type.ts';
import QuickData from '@components/data/quick-data/quick-data.tsx';
import ViewPanel from '@components/view/view-panel.tsx';
import { IconEnum } from '@components/core/icon/icon-enum.ts';
import platformStatsStore from '@store/platform-stats-store.ts';
import PlatformCCUChart from '@views/overview/view-components/platform-ccu-chart.tsx';
import calculateAverage from '@utils/calculate-average.ts';
import NavigateToExperience from '@components/navigation/navigate-to-experience/navigate-to-experience.tsx';
import OverviewDailyPicks from '@views/overview/view-components/overview-daily-picks.tsx';
import { useEffect, useState } from 'react';
import { reaction } from 'mobx';
import Tooltip from '@components/core/tooltip/tooltip.tsx';
import GenreCCU from '@components/data/genre-ccu/genre-ccu.tsx';
import SignUpCtaBanner from '@components/core/sign-up-cta/sign-up-cta-banner.tsx';
import authStore from '@store/auth-store.ts';

const Overview = observer(function Overview() {
	const [loading, setLoading] = useState(!experienceStore.initialized);
	useEffect(() => {
		const disposer = reaction(
			() => experienceStore.initialized,
			() => {
				if (loading && experienceStore.initialized) {
					setLoading(false);
				}
			}
		);

		return () => disposer();
	}, [loading]);

	const places = experienceStore.slimExperiences;
	const totalPlayersOnline = places.reduce(
		(sum, experience) => sum + (experience.players_online || 0),
		0
	);

	const dailyCCU = [...platformStatsStore.platformCCU].sort(
		(a, b) => a.ccu - b.ccu
	);
	const highestCCU = dailyCCU.length
		? dailyCCU[dailyCCU.length - 1]
		: { ccu: 0, time: 0 };

	const lastMonthAverageCCU = () => {
		const now = new Date();
		const firstDayOfCurrentMonth = new Date(
			now.getFullYear(),
			now.getMonth(),
			1
		);
		const lastDayOfPreviousMonth = new Date(firstDayOfCurrentMonth);
		lastDayOfPreviousMonth.setDate(lastDayOfPreviousMonth.getDate() - 1);
		lastDayOfPreviousMonth.setHours(23, 59, 59, 999);
		const firstDayOfPreviousMonth = new Date(
			lastDayOfPreviousMonth.getFullYear(),
			lastDayOfPreviousMonth.getMonth(),
			1
		);

		const ccu = +calculateAverage(
			dailyCCU
				.filter((ccu) => {
					const time = ccu.time.getTime();
					return (
						time > firstDayOfPreviousMonth.getTime() &&
						time < lastDayOfPreviousMonth.getTime()
					);
				})
				.map((ccu) => ccu.ccu)
		);
		return ccu.toFixed(0);
	};

	return (
		<View
			viewInfo={{
				title: 'Overview',
				icon: IconEnum.HOME,
				iconSize: '30px',
			}}
			loading={
				!experienceStore.slimExperiences.length ||
				platformStatsStore.loading
			}
			hideHeader
		>
			<div className={'w-full px-2'}>
				<NavigateToExperience />
			</div>

			<ViewPanel>
				{!!platformStatsStore.platformCCUChartData.length && (
					<>
						<PlatformCCUChart />

						<div
							className={
								'w-full flex justify-evenly items-center flex-wrap mt-2 gap-2'
							}
						>
							<QuickData
								data={
									authStore.isLoggedIn
										? new Intl.NumberFormat(
												navigator.language
											).format(+lastMonthAverageCCU())
										: 'Sign in to view'
								}
								title={'Average Daily CCU Last Month'}
								subTitle={'(Top Tracked Games)'}
								className={'m-0!'}
								size={'small'}
							/>

							<QuickData
								data={`~${new Intl.NumberFormat(
									navigator.language
								).format(totalPlayersOnline)}`}
								title={'Players Online'}
								subTitle={'(Top Tracked Games)'}
								className={'m-0!'}
								size={'small'}
							/>

							<div className={'flex flex-col items-center'}>
								{authStore.isLoggedIn && (
									<Tooltip
										text={new Date(
											highestCCU.time
										).toLocaleDateString()}
										direction={'bottom'}
									>
										<QuickData
											data={new Intl.NumberFormat(
												navigator.language
											).format(highestCCU.ccu)}
											title={'Highest Daily CCU'}
											subTitle={'(Top Tracked Games)'}
											className={'m-0!'}
											size={'small'}
										/>
									</Tooltip>
								)}

								{!authStore.isLoggedIn && (
									<QuickData
										data={'Sign in to view'}
										title={'Highest Daily CCU'}
										subTitle={'(Top Tracked Games)'}
										className={'m-0!'}
										size={'small'}
									/>
								)}
							</div>
						</div>
					</>
				)}

				{!platformStatsStore.platformCCUChartData.length && (
					<div className={'w-full flex justify-center'}>
						No Roblox CCU data
					</div>
				)}
			</ViewPanel>

			<SignUpCtaBanner />

			<OverviewDailyPicks />

			<ViewPanel options={{ container: true }}>
				<div className={'text-xl sm:text-2xl mb-2'}>
					<Tooltip
						text={
							'Games entering and exiting the Top 100 (by CCU) compared to their ranks 7-days ago'
						}
						direction={'bottom'}
						className={'justify-start cursor-default'}
					>
						{'Top 100: New Entries & Dropouts'}
					</Tooltip>
				</div>

				<div
					className={
						'w-full flex justify-between items-start gap-6 flex-col 2xl:flex-row px-2'
					}
				>
					<ViewPanel
						title={'Entry into Top 100'}
						loading={loading}
						className={'h-fit px-0 pb-0'}
						options={{ hideOverflow: true }}
					>
						<TopPlatformMovement
							movementType={TopCreatorsListTypeEnum.ENTRY}
						/>
					</ViewPanel>

					<ViewPanel
						title={'Exit from Top 100'}
						loading={loading}
						className={'h-fit px-0 pb-0'}
						options={{ hideOverflow: true }}
					>
						<TopPlatformMovement
							movementType={TopCreatorsListTypeEnum.EXIT}
						/>
					</ViewPanel>
				</div>
			</ViewPanel>

			<ViewPanel loading={loading}>
				<GenreCCU ccuType={'average'} />
			</ViewPanel>

			<div className={'w-full grid 2xl:grid-cols-2 gap-6'}>
				<ViewPanel loading={loading}>
					<PlaceGenreDistribution
						title={'Platform Genre Distribution'}
						height={700}
					/>
				</ViewPanel>

				<ViewPanel loading={loading} options={{ fullHeight: true }}>
					<PlaceLikeDistribution places={places} />
				</ViewPanel>
			</div>
		</View>
	);
});

export default Overview;
