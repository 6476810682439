import { observer } from 'mobx-react-lite';
import ClassString from '@utils/class-string.ts';

interface QuickDataProps {
	data: string | number;
	title: string;
	subTitle?: string;
	className?: string;
	size?: 'small';
}

const QuickData = observer(function QuickData(props: QuickDataProps) {
	return (
		<div className={`m-4 ${props.className}`}>
			<div
				className={ClassString({
					static: 'text-2xl lg:text-5xl font-semibold text-center break-words',
					dynamic: { 'lg:text-2xl': props.size === 'small' },
				})}
			>
				{props.data}
			</div>
			<div
				className={ClassString({
					static: 'text-lg lg:text-2xl font-light text-center',
					dynamic: { 'lg:text-lg': props.size === 'small' },
				})}
			>
				{props.title}
			</div>
			<div
				className={ClassString({
					static: 'text-base lg:text-lg font-light text-center',
					dynamic: {
						'text-xs lg:text-sm': props.size === 'small',
						hidden: !props.subTitle,
					},
				})}
			>
				{props.subTitle}
			</div>
		</div>
	);
});

export default QuickData;
