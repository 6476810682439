import { observer } from 'mobx-react-lite';
import CookieSlideToggles from '@components/core/cookie-prompt/cookie-slide-toggles.tsx';
import { useState } from 'react';
import Button from '@components/core/button/button.tsx';
import {
	CookiePreferences,
	LocalStorageKey,
} from '@/enums/local-storage-types.ts';
import cookiePromptStore from '@store/cookie-prompt-store.ts';
import localStorageService from '@services/local-storage-service.ts';
import modalStore from '@store/modal-store.ts';
import postHogService from '@services/posthog-service.ts';

const sessionTrackingEnabled = () => {
	const cookiePreferencesString = localStorageService.get(
		LocalStorageKey.CookiePreferences
	);

	if (cookiePreferencesString) {
		try {
			const cookiePreferences = JSON.parse(
				cookiePreferencesString
			) as CookiePreferences;

			return cookiePreferences.st;
		} catch (_) {
			// Do nothing
		}
	}

	return true;
};

const SettingsModal = observer(function SettingsModal() {
	const [sessionTracking, setSessionTracking] = useState(
		sessionTrackingEnabled()
	);

	const save = () => {
		const cookiePreferences: CookiePreferences = {
			st: sessionTracking,
		};

		cookiePromptStore.setCookiePreferences(cookiePreferences);

		if (cookiePreferences.st) {
			postHogService.enablePersistentTracking();
		} else {
			postHogService.disablePersistentTracking();
			cookiePromptStore.deleteSessionTrackingCookie();
		}

		modalStore.close();
	};

	return (
		<div className={'w-full flex flex-col justify-center items-center'}>
			<div className={'text-2xl text-center mb-4'}>Settings</div>

			<div
				className={
					'w-full flex justify-center items-start flex-col gap-4 mb-6'
				}
			>
				<div className={'text-xl'}>Cookie Preferences</div>

				<div
					className={
						'w-full flex justify-center items-center flex-col gap-4'
					}
				>
					<CookieSlideToggles
						sessionTracking={{
							initialState: sessionTracking,
							onToggle: setSessionTracking,
						}}
					/>
				</div>
			</div>

			<Button title={'Save Settings'} onClick={save} />
		</div>
	);
});

export default SettingsModal;
