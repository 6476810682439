import Highcharts from 'highcharts';
import hashQueryParamService from '@services/hash-query-param-service.ts';
import getGraphId from '@utils/get-graph-id.ts';
import authStore from '@store/auth-store.ts';
import modalStore from '@store/modal-store.ts';
import ModalBase from '@components/modal/modal-base.tsx';
import { Link } from 'react-router';

const THIRTY_DAYS_MS = 29 * 24 * 60 * 60 * 1000; // 30 days in milliseconds
const outsideGuestRange = (timestamp: number): boolean => {
	const now = Date.now(); // Current timestamp in milliseconds
	return now - timestamp > THIRTY_DAYS_MS;
};

interface ExtendedAxis extends Highcharts.Axis {
	dataMin: number;
	dataMax: number;
}

const setHashExtremes = (chart: Highcharts.Chart, title: string) => {
	const hashParams = hashQueryParamService.get();
	if (
		hashParams.anchor === getGraphId(title) &&
		hashParams.graphMin &&
		hashParams.graphMax
	) {
		const now = new Date();

		let min = +hashParams.graphMin;
		const max = +hashParams.graphMax;
		const outsideRange = outsideGuestRange(min);
		if (!authStore.isLoggedIn && outsideRange) {
			modalStore.open(
				<ModalBase
					title={'Sign In to See More'}
					content={{
						children: (
							<>
								<div
									className={
										'flex flex-col justify-center items-center gap-2'
									}
								>
									<span>
										<Link
											to={'/sign-in'}
											className={
												'text-blue-300 dark:blue-300 font-semibold'
											}
											onClick={() => {
												modalStore.close();
											}}
										>
											Sign in
										</Link>

										{
											' to see all the linked data. Without signing in, only the last 30 days of data are available.'
										}
									</span>

									<span>
										{"Don't have an account? "}
										<Link
											to={'/sign-up'}
											className={
												'text-blue-300 dark:blue-300 font-semibold'
											}
											onClick={() => {
												modalStore.close();
											}}
										>
											Create one
										</Link>
										{' for free!'}
									</span>
								</div>
							</>
						),
					}}
					onConfirm={() => {
						modalStore.close();
					}}
					options={{
						confirmButtonTitle: 'Got It!',
						removeCancelButton: true,
					}}
				/>,
				{
					clickOutSideToClose: false,
					graphRangeWarning: true,
				}
			);
			min = now.getTime() - THIRTY_DAYS_MS;
		}

		chart.xAxis.forEach((axis) => {
			const extendedAxis = axis as ExtendedAxis;
			extendedAxis.setExtremes(
				Math.max(extendedAxis.dataMin, min),
				Math.min(extendedAxis.dataMax, max),
				true,
				false
			);
		});
	}
};

export default setHashExtremes;
