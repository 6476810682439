import { observer } from 'mobx-react-lite';
import Icon from '@components/core/icon/icon.tsx';
import { IconEnum } from '@components/core/icon/icon-enum.ts';
import { useCallback, useEffect, useState } from 'react';
import GetPlatform, { Platform } from '@utils/get-platform.ts';
import ClassString from '@utils/class-string.ts';
import useIsMobile from '@hooks/use-is-mobile.tsx';

interface SearchBarProps {
	onSearchUpdate: (search: string) => unknown;
	placeholder: string;
	className?: string;
	initialSearchTerm?: string;
}

const SearchBar = observer(function SearchBar(props: SearchBarProps) {
	const isMobile = useIsMobile();

	const [inputElement, setInputElement] = useState<HTMLDivElement | null>();
	const [searchTerm, setSearchTerm] = useState(props.initialSearchTerm ?? '');

	const focusSearchInput = useCallback(() => {
		if (inputElement) {
			inputElement.focus();
		}
	}, [inputElement]);

	const searchHasFocus = useCallback(() => {
		return inputElement && document.activeElement === inputElement;
	}, [inputElement]);

	useEffect(() => {
		const onKeyDown = (event: KeyboardEvent) => {
			// Start Search
			if (GetPlatform() === Platform.MAC) {
				if (
					event.getModifierState &&
					event.getModifierState('Meta') &&
					event.key === 'f'
				) {
					if (!searchHasFocus()) {
						event.preventDefault();
						focusSearchInput();
					}
				}
			} else {
				if (
					event.getModifierState &&
					event.getModifierState('Control') &&
					event.key === 'f'
				) {
					if (!searchHasFocus()) {
						event.preventDefault();
						focusSearchInput();
					}
				}
			}
		};
		document.addEventListener('keydown', onKeyDown);

		return () => {
			document.removeEventListener('keydown', onKeyDown);
		};
	}, [focusSearchInput, searchHasFocus]);

	return (
		<div
			className={ClassString({
				static: 'w-full relative group',
				dynamic: {},
				custom: props.className,
			})}
		>
			<Icon
				icon={IconEnum.SEARCH}
				size={'2em'}
				className={
					'absolute left-3 top-1/2 -translate-y-1/2 rounded-full pointer-events-none'
				}
			/>

			<input
				className={
					'py-2 pl-14 rounded-full w-full text-lg outline-1 px-4 placeholder:text-gray-400 dark:placeholder:text-gray-300 bg-white dark:bg-gray-525 outline-gray-600 dark:outline-gray-500 focus:outline-blue-300 focus:outline-2'
				}
				value={searchTerm}
				name={'search'}
				onChange={(event) => {
					setSearchTerm(event.target.value);
					props.onSearchUpdate(event.target.value);
				}}
				placeholder={props.placeholder}
				ref={(ref) => setInputElement(ref)}
			/>

			{!isMobile && (
				<div
					className={ClassString({
						static: 'absolute right-5 top-1/2 -translate-y-1/2 text-gray-400',
						dynamic: {
							'right-11': !!searchTerm,
						},
					})}
				>
					{GetPlatform() === Platform.MAC ? '⌘+F' : 'Ctrl+F'}
				</div>
			)}

			{!!searchTerm.length && (
				<Icon
					icon={IconEnum.CLEAR}
					size={'2em'}
					className={
						'absolute right-2 top-1/2 -translate-y-1/2 cursor-pointer hover:bg-blue-300 hover:text-gray-600 rounded-full p-1'
					}
					onClick={() => {
						setSearchTerm('');
						props.onSearchUpdate('');
					}}
				/>
			)}
		</div>
	);
});

export default SearchBar;
