import { observer } from 'mobx-react-lite';
import UserOnboardingInterests from '@components/core/user-onboarding/user-onboarding-interests.tsx';
import UserOnboardingType from '@components/core/user-onboarding/user-onboarding-type.tsx';
import { useState } from 'react';
import { UserInterests } from '@/enums/user-interests.ts';
import Button from '@components/core/button/button.tsx';
import modalStore from '@store/modal-store.ts';
import authStore from '@store/auth-store.ts';

const UserOnboardingModal = observer(function UserOnboardingModal() {
	const [userInterests, setUserInterests] = useState<UserInterests[]>([]);
	const [userType, setUserType] = useState('');

	const incomplete = !userInterests.length || !userType.length;

	const [showIncomplete, setShowIncomplete] = useState(false);

	return (
		<div
			className={'w-full flex flex-col justify-center items-center px-4'}
		>
			<div className={'text-2xl text-center mb-4'}>
				Complete Your User Registration
			</div>

			<div className={' mb-8'}>
				To proceed, please complete your user registration. We need a
				few additional details to personalize your experience.
			</div>

			<UserOnboardingInterests
				onChange={setUserInterests}
				error={showIncomplete && !userInterests.length}
				fullWidth
			/>

			<UserOnboardingType
				onChange={setUserType}
				error={showIncomplete && !userType.length}
			/>

			<Button
				title={'Complete'}
				className={'mt-8 w-full py-6 rounded-full max-w-80'}
				onClick={() => {
					if (incomplete) {
						setShowIncomplete(true);
						return;
					}

					void authStore.update(userInterests, userType);
					modalStore.close();
				}}
			/>
		</div>
	);
});

export default UserOnboardingModal;
