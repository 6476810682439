import { observer } from 'mobx-react-lite';
import { FullExperience } from '@/schemas/experience-schema.ts';
import ExperienceBanner from '@components/data/experience-banner/experience-banner.tsx';
import { ExperienceBannerSize } from '@components/data/experience-banner/experience-banner-constants.ts';
import EditableText from '@components/core/editable-text/editable-text.tsx';
import authStore from '@store/auth-store.ts';
import { UserPermission } from '@/schemas/user-schema.ts';
import experienceStore from '@store/experience-store.ts';

interface ExperienceDetailsCompactHeaderBannerProps {
	experience: FullExperience;
}

const ExperienceDetailsCompactHeaderBanner = observer(
	function ExperienceDetailsCompactHeaderBanner(
		props: ExperienceDetailsCompactHeaderBannerProps
	) {
		const canEdit = authStore.havePermission(UserPermission.EDIT_PLACE);

		const handleCanonicalNameChance = (newName: string) => {
			if (props.experience.canonical_name !== newName) {
				void experienceStore.updateCanonicalName(
					props.experience,
					newName
				);
			}
		};

		return (
			<div
				className={
					'w-full flex flex-col gap-3 justify-center items-center'
				}
			>
				<div className={'w-full text-2xl px-2'}>
					{canEdit ? (
						<EditableText
							text={props.experience.canonical_name}
							onTextChange={handleCanonicalNameChance}
							iconSize={'1.25rem'}
							truncate
							noLeftPadding
						/>
					) : (
						props.experience.canonical_name
					)}
				</div>

				<ExperienceBanner
					experience={props.experience}
					size={ExperienceBannerSize.LARGE}
					className={
						'inline-block w-full max-w-(--breakpoint-sm) border border-gray-600 dark:border-gray-500 rounded-lg'
					}
				/>
			</div>
		);
	}
);

export default ExperienceDetailsCompactHeaderBanner;
