const formatSecondsDurationString = (durationSeconds: number) => {
	const secondsInAMinute = 60;
	const secondsInAnHour = secondsInAMinute * 60;
	const secondsInADay = secondsInAnHour * 24;

	const totalDays = Math.floor(durationSeconds / secondsInADay);
	const remainingSecondsAfterDays = durationSeconds % secondsInADay;

	const totalHours = Math.floor(remainingSecondsAfterDays / secondsInAnHour);
	const remainingSecondsAfterHours =
		remainingSecondsAfterDays % secondsInAnHour;

	const totalMinutes = Math.floor(
		remainingSecondsAfterHours / secondsInAMinute
	);
	const remainingSecondsAfterMinutes =
		remainingSecondsAfterHours % secondsInAMinute;

	const totalSeconds = Math.floor(remainingSecondsAfterMinutes);

	let durationString = '';

	if (totalDays > 0) {
		durationString = `${totalDays} day${totalDays === 1 ? '' : 's'}`;
	}

	if (totalHours > 0) {
		durationString = `${durationString}${durationString ? ', ' : ''}${totalHours} hour${totalHours === 1 ? '' : 's'}`;
	}

	if (totalMinutes > 0) {
		durationString = `${durationString}${durationString ? ', ' : ''}${totalMinutes} min`;
	}

	if (totalSeconds > 0) {
		durationString = `${durationString}${durationString ? ', ' : ''}${totalSeconds} sec`;
	}

	return durationString;
};

export default formatSecondsDurationString;
