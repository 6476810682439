import { observer } from 'mobx-react-lite';
import React, { ReactNode, useState } from 'react';
import LoadingIndicator from '@components/core/loading-indicator/loading-indicator.tsx';
import ClassString from '@utils/class-string.ts';
import Tooltip from '@components/core/tooltip/tooltip.tsx';
import hashQueryParamService from '@services/hash-query-param-service.ts';
import { ViewTabPanelContext } from '@components/view/view-tab-panel-context.tsx';

interface ViewTabPanelProps<T> {
	children?: React.ReactNode;
	title?: string;
	loading?: boolean;
	className?: string;
	tabs: {
		key: T;
		title: string;
		titleElement?: ReactNode;
		children?: React.ReactNode;
		tooltip?: React.ReactNode;
		locked?: boolean;
		onClick?: () => unknown;
	}[];
	onTabChange?: (tab: T) => unknown;
	noOutline?: boolean;
	tabSelectedColor?: string;
	tabSelectedTextColor?: string;
	defaultTab?: string;
}

function defaultTabKey<T>(props: ViewTabPanelProps<T>) {
	const hashParams = hashQueryParamService.get();
	if (
		hashParams.tab &&
		props.tabs.some((tab) => tab.key === hashParams.tab)
	) {
		return hashParams.tab as T;
	}

	if (props.defaultTab) {
		return props.defaultTab as T;
	}

	return props.tabs?.length ? props.tabs[0].key : undefined;
}

const ViewTabPanel = observer(function ViewTabPanel<T>(
	props: ViewTabPanelProps<T>
) {
	const [selectedTab, setSelectedTab] = useState<T | undefined>(
		defaultTabKey<T>(props)
	);

	const onTabChange = (tab: T) => {
		setSelectedTab(tab);

		if (props.onTabChange) {
			props.onTabChange(tab);
		}
	};

	return (
		<ViewTabPanelContext.Provider
			value={selectedTab ? selectedTab.toString() : ''}
		>
			<div
				className={ClassString({
					static: 'p-4 rounded-lg shadow-md w-full bg-white dark:bg-gray-550 outline-1 outline-gray-300 dark:outline-gray-500 relative mt-10',
					dynamic: { 'outline-0': props.noOutline },
					custom: props.className,
				})}
			>
				{!props.loading && (
					<div
						className={ClassString({
							static: 'absolute -top-[36px] left-5 flex items-center gap-4 pr-5',
							dynamic: { '-top-[35px]': props.noOutline },
						})}
					>
						{props.tabs.map((tab, index) => {
							return (
								<div
									className={ClassString({
										static: ' h-[34px] bg-gray-100 dark:bg-gray-575 flex justify-center items-center rounded-t-md text-sm border border-b-0 border-gray-300 dark:border-gray-500 select-none relative',
										dynamic: {
											'bg-white dark:bg-gray-550 z-in-front h-[36px]':
												tab.key === selectedTab,
											'hover:bg-blue-300 hover:text-gray-600 cursor-pointer':
												tab.key !== selectedTab,
											'p-4': !tab.tooltip,
										},
									})}
									style={{
										backgroundColor:
											props.tabSelectedColor &&
											tab.key === selectedTab
												? props.tabSelectedColor
												: undefined,
										color:
											props.tabSelectedColor &&
											tab.key === selectedTab
												? props.tabSelectedTextColor
												: undefined,
									}}
									key={`tab-${index}`}
									onClick={() => {
										if (
											tab.title !== selectedTab &&
											!tab.locked
										) {
											onTabChange(tab.key);
										}

										if (tab.onClick) {
											tab.onClick();
										}
									}}
								>
									{!!tab.tooltip && (
										<Tooltip
											text={tab.tooltip}
											direction={'right'}
											className={'w-full h-full p-4'}
											widthClass={'w-72'}
										>
											{!tab.titleElement && (
												<>{tab.title}</>
											)}

											{tab.titleElement && (
												<>{tab.titleElement}</>
											)}
										</Tooltip>
									)}

									{!tab.tooltip && !tab.titleElement && (
										<>{tab.title}</>
									)}

									{!tab.tooltip && tab.titleElement && (
										<>{tab.titleElement}</>
									)}
								</div>
							);
						})}
					</div>
				)}

				{!props.loading && !!props.tabs?.length && (
					<>
						{props.tabs.map((tab, index) => {
							return (
								<div
									key={`tab-content-${index}`}
									className={ClassString({
										static: 'transition-[opacity] duration-[400ms]',
										dynamic: {
											'h-0 opacity-0 overflow-hidden pointer-events-none animate-hide-tab':
												selectedTab !== tab.key,
											'opacity-100':
												selectedTab === tab.key,
										},
									})}
								>
									{tab.children}
								</div>
							);
						})}
					</>
				)}

				{!props.loading && !!props.children && (
					<>
						{!!props.title && (
							<div
								className={
									'text-2xl mb-4 flex justify-center items-center'
								}
							>
								{props.title}
							</div>
						)}
						{props.children}
					</>
				)}

				{props.loading && (
					<div className={'flex justify-center items-center'}>
						<LoadingIndicator />
					</div>
				)}
			</div>
		</ViewTabPanelContext.Provider>
	);
});

export default ViewTabPanel;
