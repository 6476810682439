import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import LoadingIndicator from '@components/core/loading-indicator/loading-indicator.tsx';
import ClassString from '@utils/class-string.ts';
import ExpansionPanel from '@components/core/expansion-panel/expansion-panel.tsx';

interface ViewExpansionPanelProps {
	children?: React.ReactNode;
	title: string;
	startExpanded?: boolean;
	loading?: boolean;
	className?: string;
}

const ViewExpansionPanel = observer(function ViewExpansionPanel(
	props: ViewExpansionPanelProps
) {
	const [expended, setExpanded] = useState(!!props.startExpanded);

	return (
		<div
			className={ClassString({
				static: 'rounded-lg shadow-md w-full bg-white dark:bg-gray-550 outline-1 outline-gray-300 dark:outline-gray-500 relative overflow-hidden',
				dynamic: {},
				custom: props.className,
			})}
		>
			{!props.loading && (
				<>
					<div
						className={
							'absolute w-full h-20 cursor-pointer top-0 left-0'
						}
						onClick={() => {
							setExpanded(!expended);
						}}
					></div>

					<ExpansionPanel
						title={props.title}
						viewPanel
						overflowVisible
						state={expended ? 'expanded' : 'closed'}
						titleOptions={{
							padding: true,
							titleHeight: '62px',
						}}
					>
						{props.children}
					</ExpansionPanel>
				</>
			)}

			{props.loading && (
				<div className={'flex justify-center items-center'}>
					<LoadingIndicator />
				</div>
			)}
		</div>
	);
});

export default ViewExpansionPanel;
