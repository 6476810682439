import { observer } from 'mobx-react-lite';
import { PlaceTag } from '@/schemas/plage-tag-schema.ts';
import LoadingSpinner from '@components/core/loading-spinner/loading-spinner.tsx';
import Icon from '@components/core/icon/icon.tsx';
import { IconEnum } from '@components/core/icon/icon-enum.ts';
import ClassString from '@utils/class-string.ts';
import authStore from '@store/auth-store.ts';
import { UserPermission } from '@/schemas/user-schema.ts';
import modalStore from '@store/modal-store.ts';
import ExperienceDetailsAddTagModal from '@views/experience-details/view-components/experience-details-add-tag-modal.tsx';
import { FullExperience } from '@/schemas/experience-schema.ts';
import { Link } from 'react-router';

interface ExperienceDetailsInfoTagsPanelProps {
	experience: FullExperience;
	tags: PlaceTag[];
	loading: boolean;
	className?: string;
}

const ExperienceDetailsInfoTagsPanel = observer(
	function ExperienceDetailsInfoTagsPanel(
		props: ExperienceDetailsInfoTagsPanelProps
	) {
		const openModal = () => {
			modalStore.open(
				<ExperienceDetailsAddTagModal
					experience={props.experience}
					tags={props.tags}
				/>,
				{
					overflow: true,
				}
			);
		};

		return (
			<div
				className={ClassString({
					static: 'flex flex-col justify-center items-start bg-white dark:bg-gray-550 p-3 border border-gray-300 dark:border-gray-500 rounded-lg h-20 shadow-lg',
					dynamic: {},
					custom: props.className,
				})}
			>
				<div
					className={
						'text-lg font-semibold dark:font-normal text-blue-500 dark:text-blue-100'
					}
				>
					Curated Tags
				</div>

				{props.loading && (
					<div
						className={
							'w-full flex-1 flex justify-center items-center'
						}
					>
						<LoadingSpinner />
					</div>
				)}

				{!props.loading && (
					<div
						className={
							'w-full flex-1 max-w-full flex justify-between items-center gap-4'
						}
					>
						{!props.tags.length && (
							<div
								className={
									'w-full h-10 flex items-center justify-center'
								}
							>
								No tags
							</div>
						)}

						{!!props.tags.length && (
							<div className={'truncate'}>
								{props.tags.map((tag, index) => {
									return (
										<Link
											to={`/tags/${tag.id}`}
											title={tag.name}
											className={ClassString({
												static: 'h-full inline-flex items-center hover:bg-blue-300 hover:text-gray-600 border border-gray-600 dark:border-gray-500 hover:border-blue-300 dark:hover:border-blue-300 rounded-full px-3 py-1',
												dynamic: {
													'mr-3':
														index <
														props.tags.length - 1,
												},
											})}
											key={`tag-${tag.id}`}
										>
											{tag.name}
										</Link>
									);
								})}
							</div>
						)}

						<div
							className={ClassString({
								static: 'relative w-8 h-10',
								dynamic: {
									hidden: !authStore.havePermission(
										UserPermission.EDIT_PLACE
									),
								},
							})}
						>
							<div
								className={
									'absolute -top-4 right-0 w-12 h-12 border rounded-full hover:bg-blue-300 hover:text-gray-600 cursor-pointer flex justify-center items-center shadow-md'
								}
								onClick={openModal}
							>
								<Icon icon={IconEnum.PLUS} size={'2rem'} />
							</div>
						</div>
					</div>
				)}
			</div>
		);
	}
);

export default ExperienceDetailsInfoTagsPanel;
