import { makeAutoObservable, runInAction } from 'mobx';
import { DataGranularity } from '@/enums/data-granularity.ts';
import httpFetch from '@services/http-fetch-service.ts';
import { GenreCCUSchema } from '@/schemas/genre-ccu-schema.ts';
import { BaseStore } from '@store/base-store.ts';

const genreCCUApi = `${import.meta.env.VITE_SERVER_URL}/api/stats/genres`;

type SupportedGranularities =
	| DataGranularity.DAY
	| DataGranularity.WEEK
	| DataGranularity.MONTH;

class GenreCCUUStore implements BaseStore {
	private _genreCCU: Record<
		SupportedGranularities,
		Record<string, number[][]>
	> = {
		[DataGranularity.DAY]: {},
		[DataGranularity.WEEK]: {},
		[DataGranularity.MONTH]: {},
	};

	reset() {
		runInAction(() => {
			this._genreCCU = {
				[DataGranularity.DAY]: {},
				[DataGranularity.WEEK]: {},
				[DataGranularity.MONTH]: {},
			};
		});
	}

	constructor() {
		makeAutoObservable(this);
	}

	async loadGenreCCU(granularity: SupportedGranularities) {
		if (Object.keys(this._genreCCU[granularity] ?? {}).length > 0) {
			return;
		}

		const response = await httpFetch.GET(
			`${genreCCUApi}?granularity=${granularity}&startTime=2022-01-01T01:00:00Z`
		);

		if (response.ok) {
			const parsedGenreCCU = GenreCCUSchema.parse(await response.json());

			const newGenreCCU: Record<string, number[][]> = {};
			parsedGenreCCU.series.forEach((genreCCUSeries) => {
				newGenreCCU[genreCCUSeries.name] = genreCCUSeries.data.map(
					(arr) => arr.map((num) => Math.floor(num))
				);
			});

			runInAction(() => {
				this._genreCCU = {
					...this._genreCCU,
					[granularity]: newGenreCCU,
				};
			});
		}
	}

	genreAverageCCU(
		granularity: SupportedGranularities
	): Record<string, number[][]> {
		const filteredGenreCCU: Record<string, number[][]> = {};
		Object.entries(this._genreCCU[granularity]).forEach(([key, value]) => {
			filteredGenreCCU[key] = value.map((arr) =>
				arr.filter((_, index) => index !== 1)
			);
		});

		return filteredGenreCCU;
	}

	genreTotalCCU(
		granularity: SupportedGranularities
	): Record<string, number[][]> {
		const filteredGenreCCU: Record<string, number[][]> = {};
		Object.entries(this._genreCCU[granularity]).forEach(([key, value]) => {
			filteredGenreCCU[key] = value.map((arr) =>
				arr.filter((_, index) => index !== 2)
			);
		});

		return filteredGenreCCU;
	}
}

const genreCCUStore = new GenreCCUUStore();
export default genreCCUStore;
