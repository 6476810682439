import { DataGranularity } from '@/enums/data-granularity.ts';
import getNearestPreviousDate from '@utils/get-nearest-previous-date.ts';
import previousEvenTenMinuteDate from '@utils/previous-even-ten-minutes-date.ts';
import { ExperienceStatType } from '@/enums/experience-stat-type.ts';

const concurrentUsersApi = `${import.meta.env.VITE_SERVER_URL}/api/stats`;

export const getTagStatUrl = (
	tagId: number,
	granularity: DataGranularity,
	statType: ExperienceStatType
) => {
	const endDate = getNearestPreviousDate(granularity);
	return `${concurrentUsersApi}/${statType}?tagId=${tagId.toString()}&granularity=${granularity}&startTime=2022-01-01T01:00:00Z&endTime=${previousEvenTenMinuteDate(endDate).toISOString()}&withPercentiles=true&aggregation=average`;
};

export const setTagStats = (
	parsedData: Record<number, number[][]>,
	granularity: DataGranularity,
	stats: Record<number, Record<DataGranularity, number[][]>>
) => {
	const appendedStats = { ...stats };
	for (const [experienceId, experienceStat] of Object.entries(parsedData)) {
		appendedStats[+experienceId] = {
			...appendedStats[+experienceId],
			[granularity]: experienceStat,
		};
	}

	return appendedStats;
};

export const setEmptyTagStats = (
	tagId: number,
	granularity: DataGranularity,
	stats: Record<number, Record<DataGranularity, number[][]>>
) => {
	const appendedStats = { ...stats };
	appendedStats[+tagId] = {
		...appendedStats[+tagId],
		[granularity]: [],
	};

	return appendedStats;
};

export const getMinMaxTagX = (data: number[][]) => {
	let xMin = 0;
	let xMax = 0;
	data.forEach((d) => {
		const date = d[0];

		if (xMin === 0) {
			xMin = date;
		}

		if (xMax === 0) {
			xMax = date;
		}

		if (date < xMin) {
			xMin = date;
		}

		if (date > xMax) {
			xMax = date;
		}
	});

	return { xMin, xMax };
};
