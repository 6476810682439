import { BaseStore } from '@store/base-store.ts';
import { makeAutoObservable, runInAction } from 'mobx';
import { PlaceTag } from '@/schemas/plage-tag-schema.ts';
import thumbnailStore from '@store/thumbnail-store.ts';
import { ThumbnailType } from '@/schemas/thumbnail-schema.ts';
import experienceStore from '@store/experience-store.ts';

class CompoundThumbnailStore implements BaseStore {
	private _tagThumbnails: Record<number, string[]> = {};

	reset() {
		runInAction(() => {
			this._tagThumbnails = {};
		});
	}

	constructor() {
		makeAutoObservable(this);
	}

	async compoundTagThumbnails(tags: PlaceTag[]) {
		const filteredTags = tags.filter((tag) => !this._tagThumbnails[tag.id]);

		if (!filteredTags.length) {
			return;
		}

		const experienceIds: number[] = [];
		const topTagExperienceIds: Record<number, number[]> = {};

		await experienceStore.waitForInitialized();

		filteredTags.forEach((tag) => {
			const ids = this.getTopExperiences(tag.place_ids);
			experienceIds.push(...ids);
			topTagExperienceIds[tag.id] = ids;
		});

		await thumbnailStore.loadThumbnailsById(
			experienceIds,
			ThumbnailType.ICON
		);

		const tagHashes: Record<number, string[]> = {};

		Object.entries(topTagExperienceIds).forEach(([tagId, expIds]) => {
			tagHashes[+tagId] = expIds
				.map((expId) =>
					thumbnailStore.getLatestThumbnailHashById(expId)
				)
				.filter((hash) => !!hash) as string[];
		});

		runInAction(() => {
			this._tagThumbnails = {
				...this._tagThumbnails,
				...tagHashes,
			};
		});
	}

	getTagThumbnail(tagId: number) {
		return this._tagThumbnails[tagId] ?? [];
	}

	tagThumbnailsLoaded(tagId: number) {
		return !!this._tagThumbnails[tagId];
	}

	private getTopExperiences(experienceIds: number[]) {
		if (experienceIds.length <= 4) {
			return experienceIds;
		}

		return [...experienceIds]
			.sort((a, b) => {
				const rankA = experienceStore.getExperienceRank(a) ?? Infinity;
				const rankB = experienceStore.getExperienceRank(b) ?? Infinity;

				return rankA - rankB;
			})
			.slice(0, Math.min(experienceIds.length - 1, 4));
	}
}

const compoundThumbnailStore = new CompoundThumbnailStore();
export default compoundThumbnailStore;
