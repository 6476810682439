import { observer } from 'mobx-react-lite';
import { User, UserRole } from '@/schemas/user-schema.ts';
import TGSTable from '@components/core/table/table.tsx';
import {
	ISelectCellEditorParams,
	ITooltipParams,
	NewValueParams,
	ValueFormatterParams,
} from 'ag-grid-community';
import userStore from '@store/user-store.ts';
import CapitalizeWords from '@utils/capitalize-words.ts';
import { CustomCellRendererProps } from 'ag-grid-react';
import Icon from '@components/core/icon/icon.tsx';
import { IconEnum } from '@components/core/icon/icon-enum.ts';
import ClassString from '@utils/class-string.ts';

interface UsersTableProps {
	users: User[];
}

const tooltipFormatter = (params: ITooltipParams<User, string>) => {
	if (!params.value) {
		return '-';
	}

	const date = new Date(params.value);
	return date.getFullYear() < 2
		? '-'
		: `${date.toLocaleDateString()} - ${date.toLocaleTimeString()}`;
};

const dateFormatter = (params: ValueFormatterParams<User, string>) => {
	if (!params.value) {
		return '-';
	}

	const date = new Date(params.value);
	return date.getFullYear() < 2 ? '-' : date.toLocaleDateString();
};

const UsersTable = observer(function UsersTable(props: UsersTableProps) {
	return (
		<>
			<TGSTable<User>
				columnDefs={[
					{
						headerName: 'First Name',
						field: 'first_name',
						minWidth: 150,
						context: {
							priority: 3,
						},
						resizable: false,
						flex: 1,
						valueFormatter: (
							params: ValueFormatterParams<User, string>
						) => `${CapitalizeWords(params.value ?? '')}`,
					},
					{
						headerName: 'Last Name',
						field: 'last_name',
						minWidth: 150,
						context: {
							priority: 3,
						},
						resizable: false,
						flex: 1,
						valueFormatter: (
							params: ValueFormatterParams<User, string>
						) => `${CapitalizeWords(params.value ?? '')}`,
					},
					{
						field: 'email',
						minWidth: 250,
						cellRenderer: (params: CustomCellRendererProps) => {
							const user = params.node.data as User;
							return (
								<div className={'flex items-center gap-1'}>
									{user.email}
									<div
										className={ClassString({
											static: 'h-4 w-4',
											dynamic: {
												hidden: !user.email_verified,
											},
										})}
									>
										<Icon icon={IconEnum.ROUND_CHECK} />
									</div>
								</div>
							);
						},
						context: {
							priority: 1,
						},
						resizable: false,
						flex: 1,
					},
					{
						field: 'role',
						minWidth: 100,
						context: {
							priority: 2,
						},
						resizable: false,
						cellEditor: 'agSelectCellEditor',
						singleClickEdit: true,
						cellEditorParams: {
							values: Object.values(UserRole),
						} as ISelectCellEditorParams,
						editable: true,
						onCellValueChanged: (
							event: NewValueParams<User, UserRole>
						) => {
							if (!event.node?.data || !event.newValue) {
								return;
							}

							const user = event.node.data;

							void userStore.updateRole(user, event.newValue);
						},
						cellStyle: {
							cursor: 'pointer',
						},
						valueFormatter: (
							params: ValueFormatterParams<User, string>
						) => {
							return params.value
								? `${params.value.charAt(0).toUpperCase()}${params.value.slice(1)}`
								: '?';
						},
						flex: 1,
					},
					{
						headerName: 'Last Active',
						field: 'last_active',
						tooltipValueGetter: tooltipFormatter,
						minWidth: 120,
						context: {
							priority: 1,
						},
						sort: 'desc',
						resizable: false,
						valueFormatter: dateFormatter,
						flex: 1,
					},
					{
						headerName: 'Joined',
						field: 'created',
						minWidth: 120,
						context: {
							priority: 3,
						},
						resizable: false,
						valueFormatter: dateFormatter,
						flex: 1,
					},
				]}
				rowData={props.users}
				tableName={'Users'}
				suppressCellFocus={false}
			/>
		</>
	);
});

export default UsersTable;
