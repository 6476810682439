import { DataGranularity } from '@/enums/data-granularity.ts';
import getNearestPreviousDate from '@utils/get-nearest-previous-date.ts';
import previousEvenTenMinuteDate from '@utils/previous-even-ten-minutes-date.ts';
import { FullExperience } from '@/schemas/experience-schema.ts';
import experienceStore from '@store/experience-store.ts';
import { ExperienceStatType } from '@/enums/experience-stat-type.ts';

const concurrentUsersApi = `${import.meta.env.VITE_SERVER_URL}/api/stats`;

const getStartDate = (placeIds: number[]): Date => {
	const date = new Date();
	date.setUTCHours(0, 0, 0, 0);

	let oldestPlace: FullExperience | undefined;
	for (const placeId of placeIds) {
		const p = experienceStore.getFullExperienceById(placeId);
		if (!p) {
			continue;
		}

		if (!oldestPlace || p.created < oldestPlace.created) {
			oldestPlace = p;
		}
	}

	date.setUTCDate(new Date().getDate() - 5000);
	return oldestPlace?.created ?? date;
};

export const getExperiencesToCollect = (
	experienceIds: number[],
	granularity: DataGranularity,
	stats: Record<number, Record<DataGranularity, number[][]>>
) => {
	return experienceIds.filter((id) => {
		return !stats[id]?.[granularity]?.length;
	});
};

export const getExperienceStatUrl = (
	experienceIds: number[],
	granularity: DataGranularity,
	statType: ExperienceStatType
) => {
	const startDate = getStartDate(experienceIds);
	const endDate = getNearestPreviousDate(granularity);
	return `${concurrentUsersApi}/${statType}?placeIds=${experienceIds.toString()}&granularity=${granularity}&startTime=${startDate.toISOString()}&endTime=${previousEvenTenMinuteDate(endDate).toISOString()}`;
};

export const setExperienceStats = (
	parsedData: Record<number, number[][]>,
	granularity: DataGranularity,
	stats: Record<number, Record<DataGranularity, number[][]>>
) => {
	const appendedStats = { ...stats };
	for (const [experienceId, experienceStat] of Object.entries(parsedData)) {
		appendedStats[+experienceId] = {
			...appendedStats[+experienceId],
			[granularity]: experienceStat,
		};
	}

	return appendedStats;
};

export const setEmptyStats = (
	experienceIds: number[],
	granularity: DataGranularity,
	stats: Record<number, Record<DataGranularity, number[][]>>
) => {
	const appendedStats = { ...stats };
	for (const experienceId of experienceIds) {
		appendedStats[+experienceIds] = {
			...appendedStats[+experienceId],
			[granularity]: [],
		};
	}

	return appendedStats;
};
