import { ReactElement } from 'react';
import { makeAutoObservable, runInAction } from 'mobx';
import { BaseStore } from '@store/base-store.ts';
interface ModalOptions {
	overflow: boolean;
	clickOutSideToClose: boolean;
	className: string;
	darkerBackdrop: boolean;
	graphRangeWarning: boolean;
}

type OptionalModalOptions = Partial<ModalOptions>;

class ModalStore implements BaseStore {
	private defaultOptions: ModalOptions = {
		overflow: false,
		clickOutSideToClose: true,
		className: '',
		darkerBackdrop: false,
		graphRangeWarning: false,
	};

	// Store more complex elements like a Highcharts graph as a function
	private activeModal: (() => ReactElement) | ReactElement | undefined;
	private activeOptions: ModalOptions = { ...this.defaultOptions };
	private _graphRangeWarningShown = false;

	reset() {
		runInAction(() => {
			this.activeModal = undefined;
			this.activeOptions = { ...this.defaultOptions };
			this._graphRangeWarningShown = false;
		});
	}

	constructor() {
		makeAutoObservable(this);
	}

	private resetOptions() {
		runInAction(() => {
			this.activeOptions = { ...this.defaultOptions };
		});
	}

	open(
		content: (() => ReactElement) | ReactElement,
		options?: OptionalModalOptions
	) {
		if (options?.graphRangeWarning && this._graphRangeWarningShown) {
			return;
		}

		runInAction(() => {
			this.activeModal = content;
			this.activeOptions = { ...this.activeOptions, ...options };
			if (options?.graphRangeWarning) {
				this._graphRangeWarningShown = true;
			}
		});
	}

	close() {
		runInAction(() => {
			this.activeModal = undefined;
		});
		this.resetOptions();
	}

	get content() {
		return typeof this.activeModal === 'function'
			? this.activeModal()
			: this.activeModal;
	}

	get visible() {
		return !!this.activeModal;
	}

	get options() {
		return this.activeOptions;
	}
}

const modalStore = new ModalStore();
export default modalStore;
