import { observer } from 'mobx-react-lite';
import { IconEnum } from '@components/core/icon/icon-enum.ts';
import { useRef } from 'react';
import optionsMenuStore from '@store/options-menu-store.ts';
import Icon from '@components/core/icon/icon.tsx';
import ClassString from '@utils/class-string.ts';

interface OptionsMenuProps {
	buttons: {
		title: string;
		onClick: () => unknown;
	}[];
	width?: string;
	vertical?: boolean;
}

const OptionsMenu = observer(function OptionsMenu(props: OptionsMenuProps) {
	const menuButton = useRef<HTMLDivElement>(null);

	const onClick = () => {
		if (menuButton) {
			const options = { ...props, ref: menuButton };
			optionsMenuStore.isOpen(menuButton.current)
				? optionsMenuStore.close()
				: optionsMenuStore.open(options);
		}
	};

	return (
		<div
			className={ClassString({
				static: 'group cursor-pointer relative hover:bg-blue-300  rounded-full hover:text-gray-600',
				dynamic: { 'rounded-lg': props.vertical },
			})}
			ref={menuButton}
			onClick={onClick}
		>
			{!!props.vertical && (
				<div
					className={
						'hover:bg-blue-300 w-8 grid justify-center overflow-visible rounded-lg py-1 pointer-events-none'
					}
				>
					<Icon
						icon={IconEnum.MORE_VERT}
						size={'2rem'}
						className={'pointer-events-none'}
					/>
				</div>
			)}

			{!props.vertical && (
				<div
					className={
						'hover:bg-blue-300 grid justify-center items-center overflow-visible rounded-button pointer-events-none border group-hover:border-none border-gray-600 dark:border-gray-500 h-button w-button'
					}
				>
					<Icon
						icon={IconEnum.MORE}
						size={'2rem'}
						className={'pointer-events-none'}
					/>
				</div>
			)}
		</div>
	);
});

export default OptionsMenu;
