import { observer } from 'mobx-react-lite';
import Highcharts from 'highcharts';
import Chart from '@components/core/chart/chart.tsx';
import { useCallback, useEffect, useState } from 'react';
import placeStatsStore, {
	CCUDataSegment,
	isValidGranularity,
	PlaceStatType,
} from '@store/place-stats-store.ts';
import LoadingIndicator from '@components/core/loading-indicator/loading-indicator.tsx';
import { Experience, SlimExperience } from '@/schemas/experience-schema.ts';
import { PlaceTag } from '@/schemas/plage-tag-schema.ts';
import GranularitySelector from '@components/data/granularity-selector/granularity-selector.tsx';
import hashQueryParamService from '@services/hash-query-param-service.ts';
import getGraphId from '@utils/get-graph-id.ts';
import { DataGranularity } from '@/enums/data-granularity.ts';

interface ExperienceVsTagCcuProps {
	experiences: Experience[];
	tag: PlaceTag;
	tagExperiences: SlimExperience[];
	state: CCUDataSegment;
	title: string;
	options?: {
		defaultGranularity?: DataGranularity;
		selectedRange?: number;
	};
}

const numberFormatter = new Intl.NumberFormat(navigator.language);

const getDefaultGranularity = (props: ExperienceVsTagCcuProps) => {
	const hashParams = hashQueryParamService.get();
	if (
		hashParams.granularity &&
		hashParams.anchor === getGraphId(props.title) &&
		isValidGranularity(hashParams.granularity)
	) {
		return hashParams.granularity as DataGranularity;
	}

	if (props.options?.defaultGranularity) {
		return props.options.defaultGranularity;
	}

	return DataGranularity.DAY;
};

const ExperienceVsTagCcu = observer(function ExperienceVsTagCcu(
	props: ExperienceVsTagCcuProps
) {
	const [granularity, setGranularity] = useState(
		getDefaultGranularity(props)
	);

	const [experiencesLoading, setExperiencesLoading] = useState(true);
	const [tagsLoading, setTagsLoading] = useState(true);
	const loading = experiencesLoading || tagsLoading;

	const [experienceData, setExperienceDataData] = useState<number[][]>([]);

	let data = [
		placeStatsStore.averageTagCCU(props.tag.id, granularity, props.state),
		experienceData,
		...props.experiences.map((exp) =>
			placeStatsStore.placeStat(
				exp.place_id,
				PlaceStatType.CCUS,
				granularity
			)
		),
	];

	if (props.experiences.length === 1) {
		data = [data[0], data[2]];
	}

	const seriesName = [
		props.tag.name,
		props.experiences.length === 1
			? props.experiences[0].canonical_name
			: 'Experiences Avg.',
		...props.experiences.map((exp) => exp.canonical_name),
	];

	const noData = !loading && !data.length;

	const getExperienceData = useCallback(async () => {
		void placeStatsStore.getPlaceStats(
			props.experiences.map((exp) => exp.place_id),
			PlaceStatType.CCUS,
			granularity
		);
		const data = await placeStatsStore.getAverageCCU(
			props.experiences.map((exp) => exp.place_id),
			granularity,
			props.state
		);

		setExperienceDataData(data);
		setExperiencesLoading(false);
	}, [granularity, props.experiences, props.state]);

	useEffect(() => {
		setExperiencesLoading(true);
		void getExperienceData();
	}, [getExperienceData]);

	const getTagData = useCallback(
		async (granularity: DataGranularity, dataSegment: CCUDataSegment) => {
			await placeStatsStore.getAverageTagCCU(
				props.tag,
				props.tagExperiences,
				granularity,
				dataSegment
			);
			setTagsLoading(false);
		},
		[props.tag, props.tagExperiences]
	);

	const showColumnLabels = true;
	let series: Highcharts.SeriesOptionsType[] = [];
	series = data.map((d, index) => {
		return {
			name: seriesName[index],
			type: 'line',
			showInLegend: true,
			data: d,
			marker: {
				enabled: false,
			},
			lineWidth: 3,
		};
	});

	let xMin = 0;
	let xMax = 0;
	data.forEach((td) => {
		if (!td) {
			return;
		}

		td.forEach((d) => {
			const date = d[0];

			if (xMin === 0) {
				xMin = date;
			}

			if (xMax === 0) {
				xMax = date;
			}

			if (date < xMin) {
				xMin = date;
			}

			if (date > xMax) {
				xMax = date;
			}
		});
	});

	const options: Highcharts.Options = {
		series,
		chart: {
			marginLeft: 45,
		},
		xAxis: {
			type: 'datetime',
			min: xMin,
			max: xMax,
		},
		yAxis: {
			title: {
				enabled: false,
			} as Highcharts.YAxisOptions,
			labels: {
				x: -10,
				y: 3,
				reserveSpace: false,
				style: {
					whiteSpace: 'nowrap',
				},
			},
		},
		plotOptions: {
			line: {
				marker: {
					enabled: false,
				},
			},
			column: {
				stacking: 'normal',
				dataLabels: {
					enabled: showColumnLabels,
				},
			},
		},
		navigator: {
			enabled: true,
			series: data.map((td) => {
				return {
					data: td,
				};
			}),
			xAxis: {
				min: xMin,
				max: xMax,
			},
		},
		scrollbar: {
			enabled: true,
			liveRedraw: true,
		},
		rangeSelector: {
			enabled: true,
			selected: props.options?.selectedRange ?? 2,
		},
		tooltip: {
			pointFormatter: function () {
				// Handle line series (single y value)
				if (this.y === undefined) {
					return '';
				}
				const value = this.y;
				const color = this.color as string;
				return `<span style="color:${color}">●</span> ${this.series.name}: <b>${numberFormatter.format(value)}</b><br/>`;
			},
			shared: true,
			split: false,
			distance: 10,
			snap: 8,
		},
	};

	const updateGranularity = (g: DataGranularity) => {
		void getTagData(g, props.state);
		setGranularity(g);
		setTagsLoading(true);
	};

	useEffect(() => {
		void getTagData(granularity, props.state);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setTagsLoading(true);
		void getTagData(granularity, props.state);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.state]);

	return (
		<div>
			<div className={'relative'}>
				<Chart
					title={props.title}
					highcharts={Highcharts}
					options={options}
					granularity={granularity}
				/>

				{loading && (
					<div
						className={
							'absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
						}
					>
						<LoadingIndicator />
					</div>
				)}
				{noData && (
					<div
						className={
							'absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
						}
					>
						No Data
					</div>
				)}
			</div>

			<div
				className={'flex items-center justify-between gap-4 flex-wrap'}
			>
				<GranularitySelector
					updateGranularity={updateGranularity}
					selectedGranularity={granularity}
				/>
			</div>
		</div>
	);
});

export default ExperienceVsTagCcu;
