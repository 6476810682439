import { observer } from 'mobx-react-lite';
import TGSTable, { CustomColDef } from '@components/core/table/table.tsx';
import { PlaceTag } from '@/schemas/plage-tag-schema.ts';
import { CustomCellRendererProps } from 'ag-grid-react';
import NavigationButton from '@components/navigation/navigation-button/navigation-button.tsx';
import { NavigationButtonEnum } from '@components/navigation/navigation-button/navigation-button-enum.ts';
import authStore from '@store/auth-store.ts';
import { UserPermission } from '@/schemas/user-schema.ts';
import OptionsMenu from '@components/core/options-menu/options-menu.tsx';
import modalStore from '@store/modal-store.ts';
import ModalBase from '@components/modal/modal-base.tsx';
import placeTagStore from '@store/place-tag-store.ts';
import toastStore from '@store/toast-store.ts';
import { ToastType } from '@components/service/toast/toast-enums.ts';
import { BUTTON_STYLE } from '@components/core/button/button-style-enum.ts';
import ExperienceTagsRenameModal from '@views/experience-tags/view-components/experience-tags-rename-modal.tsx';
import Icon from '@components/core/icon/icon.tsx';
import { IconEnum } from '@components/core/icon/icon-enum.ts';
import CompoundExperienceIcon from '@components/data/experience-icon/compound-experience-icon.tsx';

interface ExperienceTagListProps {
	tags: PlaceTag[];
	globalTags: boolean;
}

const ColumnDefs: CustomColDef<PlaceTag>[] = [
	{
		minWidth: 70,
		maxWidth: 70,
		cellRenderer: (params: CustomCellRendererProps) => {
			const tag = params.data as PlaceTag;
			return (
				<div
					className={'w-full h-full flex justify-center items-center'}
				>
					<CompoundExperienceIcon
						tagId={tag.id}
						className={'w-full h-full'}
					/>
				</div>
			);
		},
		cellStyle: {
			display: 'flex',
			alignItems: 'center',
			paddingRight: '0',
		},
		resizable: false,
		sortable: false,
		context: {
			priority: 1,
		},
	},
	{
		headerName: 'Name',
		field: 'name',
		cellRenderer: (params: CustomCellRendererProps) => {
			const tag = params.node.data as PlaceTag;
			return (
				<NavigationButton
					path={`/tags/${tag.id}`}
					direction={NavigationButtonEnum.FORWARD}
					title={tag.name}
				/>
			);
		},
		headerClass: 'ml-3',
		cellStyle: {
			display: 'flex',
			alignItems: 'center',
			paddingLeft: '2em',
		},
		resizable: false,
		minWidth: 250,
		sort: 'asc',
		context: {
			priority: 1,
		},
		flex: 1,
	},
	{
		headerName: 'Experiences',
		field: 'place_ids',
		valueFormatter: (params) => {
			const placeIds = params.value as string[];
			return placeIds.length.toString();
		},
		comparator: (a: string[], b: string[]) => {
			return a.length - b.length;
		},
		cellStyle: {
			display: 'flex',
			alignItems: 'center',
		},
		resizable: false,
		minWidth: 150,
		maxWidth: 150,
		context: {
			priority: 2,
		},
	},
];

const ExperienceTagList = observer(function ExperienceTagList(
	props: ExperienceTagListProps
) {
	const columns = [...ColumnDefs];

	if (!props.globalTags) {
		columns.push({
			headerName: 'Curated',
			field: 'global',
			cellRenderer: (params: CustomCellRendererProps) => {
				const creator = params.node.data as PlaceTag;
				return (
					<>
						{creator.global && (
							<Icon icon={IconEnum.CURATED} size={'2rem'} />
						)}
					</>
				);
			},
			resizable: false,
			cellStyle: {
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
			},
			headerClass: 'text-center',
			minWidth: 100,
			maxWidth: 100,
			context: {
				priority: 2,
			},
			flex: 0,
		});
	}

	if (
		!props.globalTags ||
		authStore.havePermission(UserPermission.EDIT_TAG)
	) {
		columns.push({
			cellRenderer: (params: CustomCellRendererProps) => {
				const tag = params.node.data as PlaceTag;
				return (
					<OptionsMenu
						vertical
						buttons={[
							{
								title: 'Delete Tag',
								onClick: () => {
									modalStore.open(
										<ModalBase
											title={`Delete "${tag.name}"`}
											content={{
												children: (
													<>
														<div className={'mb-3'}>
															{`Are you sure you want to delete the tag "${tag.name}"?`}
														</div>
														<div
															className={
																'text-xs italic'
															}
														>
															{
																'This action is irreversible, and the tag cannot be restored once deleted.'
															}
														</div>
													</>
												),
											}}
											onConfirm={async () => {
												const res =
													await placeTagStore.deletePlaceTag(
														tag.id
													);

												if (res.ok) {
													toastStore.emit(
														`Successfully deleted tag ${tag.name}`,
														ToastType.CONFIRM
													);
												}
											}}
											options={{
												confirmButtonTitle:
													'Delete Tag',
												confirmButtonStyle:
													BUTTON_STYLE.WARNING,
											}}
										/>
									);
								},
							},
							{
								title: 'Rename Tag',
								onClick: () => {
									modalStore.open(
										<ExperienceTagsRenameModal
											placeTag={tag}
										/>
									);
								},
							},
						]}
						width={'9rem'}
					/>
				);
			},
			minWidth: 50,
			maxWidth: 50,
			resizable: false,
			sortable: false,
			cellStyle: {
				display: 'flex',
				justifyContent: 'flex-end',
				alignItems: 'center',
			},
			context: {
				priority: 1,
			},
			flex: 0,
		});
	}
	return (
		<TGSTable
			columnDefs={columns}
			rowData={props.tags}
			tableName={'experience-tags-table'}
			rowHeight={60}
		/>
	);
});

export default ExperienceTagList;
