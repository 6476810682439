import { makeAutoObservable, runInAction } from 'mobx';
import {
	EventStats,
	EventStatsListSchema,
} from '@/schemas/event-stats-schema.ts';
import httpFetch from '@services/http-fetch-service.ts';
import { BaseStore } from '@store/base-store.ts';

const eventStatsApi = `${import.meta.env.VITE_SERVER_URL}/api/stats/events`;

class EventStatsStore implements BaseStore {
	private _eventStats: Record<number, EventStats[]> = {};
	private _eventStatsTotal: number[][] = [];

	reset() {
		runInAction(() => {
			this._eventStats = {};
		});
	}

	constructor() {
		makeAutoObservable(this);
	}

	async getEventStats() {
		const startTime = new Date(2025, 0, 23);
		const res = await httpFetch.GET(
			`${eventStatsApi}?granularity=day&startTime=${startTime.toISOString()}&endTime=${new Date().toISOString()}`
		);

		if (res.ok) {
			const rankingResponse = EventStatsListSchema.parse(
				await res.json()
			);
			const eventStats = rankingResponse.map((stats) => {
				return {
					...stats,
					date: new Date(stats.date),
				};
			});

			eventStats.sort((a, b) => a.date.getTime() - b.date.getTime());

			const eventStatsMap: Record<number, EventStats[]> = {};
			const totalEventStats: Record<number, number> = {};

			for (const stat of eventStats) {
				if (eventStatsMap[stat.place_id]) {
					eventStatsMap[stat.place_id].push(stat);
				} else {
					eventStatsMap[stat.place_id] = [stat];
				}

				const timestamp = stat.date.getTime();
				if (totalEventStats[timestamp]) {
					totalEventStats[timestamp] =
						totalEventStats[timestamp] + stat.num_events;
				} else {
					totalEventStats[timestamp] = stat.num_events;
				}
			}

			runInAction(() => {
				this._eventStats = eventStatsMap;
				this._eventStatsTotal = Object.entries(totalEventStats).map(
					([time, event_count]) => {
						return [+time, event_count];
					}
				);
			});
		}
	}

	getEventStatById(experienceId: number) {
		return this._eventStats[experienceId] ?? [];
	}

	get totalEvents() {
		return this._eventStatsTotal;
	}
}

const eventStatsStore = new EventStatsStore();

export default eventStatsStore;
