import { observer } from 'mobx-react-lite';
import { UserInterests, UserInterestsArray } from '@/enums/user-interests.ts';
import Checkbox from '@components/core/checkbox/checkbox.tsx';
import { useEffect, useState } from 'react';
import ClassString from '@utils/class-string.ts';

interface UserOnboardingInterestsProps {
	onChange: (userInterests: UserInterests[]) => unknown;
	fullWidth?: boolean;
	error?: boolean;
}

const UserOnboardingInterests = observer(function UserOnboardingInterests(
	props: UserOnboardingInterestsProps
) {
	const [selectedInterests, setSelectedInterests] = useState<UserInterests[]>(
		[]
	);

	useEffect(() => {
		props.onChange(selectedInterests);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedInterests]);

	return (
		<div
			className={ClassString({
				static: 'flex flex-col justify-start items-center gap-2',
				dynamic: { 'w-full': props.fullWidth },
			})}
		>
			<div className={'text-lg font-semibold w-full'}>
				Which types of games interest you?
			</div>

			<div
				className={ClassString({
					static: 'grid grid-cols-2 gap-x-4 gap-y-2 md:gap-y-4 lg:gap-y-2',
					dynamic: { 'w-full': props.fullWidth },
				})}
			>
				{UserInterestsArray.map((interest) => {
					return (
						<div
							key={`interest_${interest.value}`}
							className={
								'flex justify-start items-center h-8 overflow-y-visible'
							}
						>
							<Checkbox
								label={interest.label}
								onChange={(selected) => {
									if (selected) {
										setSelectedInterests([
											...selectedInterests,
											interest.value,
										]);
									} else {
										const removed = [
											...selectedInterests,
										].filter((i) => i !== interest.value);
										setSelectedInterests(removed);
									}
								}}
								error={props.error}
							/>
						</div>
					);
				})}
			</div>
		</div>
	);
});

export default UserOnboardingInterests;
