import { observer } from 'mobx-react-lite';
import { Link } from 'react-router';
import ClassString from '@utils/class-string.ts';
import authStore from '@store/auth-store.ts';
import { useState } from 'react';

interface SignUpCtaBanner {
	thin?: boolean;
}

const CallToActions = [
	'Sign up for free to gain deeper insights through experience and tag comparisons and more',
	'Sign up for free to access data beyond 30 days and more',
	'Sign up for free to craft unique game segments with personalized tags and more',
	'Sign up for free to track trends and compare experiences across different tags and more',
	// 'Sign up for free to receive a monthly Trend Report and more',
	'Sign up for free to access additional features and historical data',
	'Sign up for free to build personalized game segments using your own tags and more',
];

const getCallToAction = () => {
	return CallToActions[Math.floor(Math.random() * CallToActions.length)];
};

const SignUpCtaBanner = observer(function SignUpCtaBanner(
	props: SignUpCtaBanner
) {
	const [callToActionText] = useState(getCallToAction());

	if (!authStore.sessionRestored || authStore.isLoggedIn) {
		return <></>;
	}

	return (
		<Link
			to={'/sign-up'}
			className={ClassString({
				static: 'group w-full flex flex-wrap justify-evenly items-center bg-red-200 text-gray-600 rounded-lg outline-1 outline-gray-300 dark:outline-gray-500 p-8 pr-12 transition-all font-medium text-center gap-y-4 gap-x-12',
				dynamic: { 'py-3': props.thin },
			})}
		>
			<div
				className={ClassString({
					static: 'text-lg group-hover:underline',
					dynamic: { 'text-base': props.thin },
				})}
			>
				{callToActionText}
			</div>

			{!props.thin && (
				<div
					className={
						'bg-gray-600 text-white hover:bg-blue-300 hover:text-gray-600 rounded-full px-5 py-2 '
					}
				>
					{'Sign up for FREE'}
				</div>
			)}
		</Link>
	);
});

export default SignUpCtaBanner;
