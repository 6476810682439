import { useState } from 'react';
import ClassString from '@utils/class-string.ts';
import { observer } from 'mobx-react-lite';
import { IconEnum } from '@components/core/icon/icon-enum.ts';
import Icon from '@components/core/icon/icon.tsx';

interface SlideToggleProps {
	initialState?: boolean;
	state?: boolean;
	onToggle?: (state: boolean) => void;
	className?: string;
	icons?: {
		on: IconEnum;
		onSize?: string;
		off: IconEnum;
		offSize?: string;
	};
	smallButton?: boolean;
	toggleBackgroundColor?: boolean;
	disabled?: boolean;
}

const SlideToggle = observer(function SlideToggle(props: SlideToggleProps) {
	const [isOn, setIsOn] = useState(props.initialState);

	const on = props.state ?? isOn;

	const toggle = () => {
		if (props.disabled) {
			return;
		}

		setIsOn(!on);
		if (props.onToggle) {
			props.onToggle(!on);
		}
	};

	return (
		<div
			className={ClassString({
				static: 'w-14 h-4 flex items-center bg-gray-300 dark:bg-gray-500 rounded-full p-1 cursor-pointer',
				dynamic: {
					'w-12': props.smallButton,
				},
				custom: props.className,
			})}
			onClick={toggle}
		>
			<div
				className={ClassString({
					static: ' bg-gray-600 border border-gray-500 w-10 h-10 rounded-full shadow-md transform transition-transform duration-300 relative',
					dynamic: {
						'w-8 h-8': props.smallButton,
						'translate-x-4': on,
						'-translate-x-2 ': !on,
						'bg-white dark:bg-gray-600 border-gray-600 dark:border-gray-500':
							!props.toggleBackgroundColor,
						'text-white': props.toggleBackgroundColor,
						'bg-gray-500 dark:bg-gray-575':
							props.toggleBackgroundColor && !on,
						'bg-gray-300 cursor-default': props.disabled,
					},
				})}
			>
				{!!props.icons && (
					<Icon
						icon={on ? props.icons.on : props.icons.off}
						size={
							on
								? (props.icons.onSize ?? '1.8rem')
								: (props.icons.offSize ?? '1.8rem')
						}
						className={
							'absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
						}
					/>
				)}
			</div>
		</div>
	);
});

export default SlideToggle;
