import dailyPickStore from '@store/daily-pick-store.ts';
import experienceRankingStore from '@store/experience-ranking-store.ts';
import genreCCUStore from '@store/genre-ccu-store.ts';
import placeStatsStore from '@store/place-stats-store.ts';
import platformStatsStore from '@store/platform-stats-store.ts';
import rankingStore from '@store/ranking-store.ts';
import eventCredentialStore from '@store/event-credential-store.ts';
import eventStatsStore from '@store/event-stats-store.ts';
import userStore from '@store/user-store.ts';

class StoreService {
	resetDataOnSignIn() {
		dailyPickStore.reset();
		experienceRankingStore.reset();
		genreCCUStore.reset();
		placeStatsStore.reset();
		platformStatsStore.reset();
		rankingStore.reset();
	}

	resetDataOnSignOut() {
		this.resetDataOnSignIn();
		eventCredentialStore.reset();
		eventStatsStore.reset();
		userStore.reset();
	}
}

const storeService = new StoreService();
export default storeService;
