import { observer } from 'mobx-react-lite';
import { ChangeEvent, FormEvent, useState } from 'react';
import Icon from '@components/core/icon/icon.tsx';
import { IconEnum } from '@components/core/icon/icon-enum.ts';
import ClassString from '@utils/class-string.ts';

interface EditTextProps {
	text: string;
	onTextChange: (text: string) => unknown;
	className?: string;
	hideIcon?: boolean;
	resetOnEmpty?: boolean;
	iconSize?: string;
	truncate?: boolean;
	noLeftPadding?: boolean;
}

const EditableText = observer(function EditableText(props: EditTextProps) {
	const [inputSpan, setInputContainer] = useState<HTMLSpanElement | null>();

	const handleTextChange = (event: ChangeEvent<HTMLSpanElement>) => {
		if (event.target.innerText === props.text) {
			return;
		}

		if (!event.target.innerText && props.resetOnEmpty) {
			event.target.innerText = props.text;
		} else {
			props.onTextChange(event.target.innerText);
		}
	};

	const keyDownEvent = (event: KeyboardEvent) => {
		if (event.key === 'Enter' || event.key === 'Escape') {
			inputSpan?.blur();
		}
	};

	const attachKeyListener = () => {
		if (inputSpan) {
			inputSpan.addEventListener('keydown', keyDownEvent);
		}
	};

	const removeKeyListener = () => {
		if (inputSpan) {
			inputSpan.removeEventListener('keydown', keyDownEvent);
		}
	};

	const handleEditToggle = () => {
		removeKeyListener();

		if (inputSpan) {
			inputSpan.focus();
			const range = document.createRange();
			range.selectNodeContents(inputSpan);
			range.collapse(false);
			const sel = window.getSelection();
			sel?.removeAllRanges();
			sel?.addRange(range);
		}
	};

	const onInput = (event: FormEvent<HTMLSpanElement>) => {
		const dataTransfer = (event.nativeEvent as InputEvent).dataTransfer;

		if (!dataTransfer) {
			return;
		}

		if (dataTransfer.types.find((value) => value.includes('html'))) {
			(event.target as HTMLSpanElement).innerText = props.text;
			(event.target as HTMLSpanElement).blur();
		}
	};

	return (
		<div
			className={`flex items-center ${
				props.className ? props.className : ''
			}`}
		>
			<span
				role={'input'}
				ref={(ref) => setInputContainer(ref)}
				onFocus={attachKeyListener}
				onBlur={handleTextChange}
				onInput={onInput}
				contentEditable
				suppressContentEditableWarning={true}
				className={ClassString({
					static: 'px-2 rounded-md hover:outline-2 outline-blue-300',
					dynamic: {
						truncate: props.truncate,
						'pl-0!': props.noLeftPadding,
					},
				})}
			>
				{props.text}
			</span>
			{!props.hideIcon && (
				<Icon
					icon={IconEnum.EDIT}
					onClick={handleEditToggle}
					size={props.iconSize}
					className={'cursor-pointer'}
				/>
			)}
		</div>
	);
});

export default EditableText;
