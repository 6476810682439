import { observer } from 'mobx-react-lite';
import Highcharts from 'highcharts';
import Chart from '@components/core/chart/chart.tsx';
import { useCallback, useEffect, useState } from 'react';
import placeStatsStore, {
	CCUDataSegment,
	isValidGranularity,
} from '@store/place-stats-store.ts';
import LoadingIndicator from '@components/core/loading-indicator/loading-indicator.tsx';
import hexToRGBA from '@utils/hex-to-rgba.ts';
import ChartColors from '@components/core/chart/chart-colors.ts';
import themeStore from '@store/theme-store.ts';
import BrandColors from '@styles/_brand_colors.ts';
import { Experience } from '@/schemas/experience-schema.ts';
import { Creator } from '@/schemas/creator-schema.ts';
import GranularitySelector from '@components/data/granularity-selector/granularity-selector.tsx';
import hashQueryParamService from '@services/hash-query-param-service.ts';
import getGraphId from '@utils/get-graph-id.ts';
import { DataGranularity } from '@/enums/data-granularity.ts';

interface AverageTagCCUProps {
	creator: Creator;
	creatorExperiences: Experience[];
	state: CCUDataSegment;
}

const graphTitle = 'Average CCU';

const getDefaultGranularity = () => {
	const hashParams = hashQueryParamService.get();
	if (
		hashParams.granularity &&
		hashParams.anchor === getGraphId(graphTitle) &&
		isValidGranularity(hashParams.granularity)
	) {
		return hashParams.granularity as DataGranularity;
	}

	return DataGranularity.WEEK;
};

const AverageCreatorCCU = observer(function AverageCreatorCCU(
	props: AverageTagCCUProps
) {
	const [granularity, setGranularity] = useState(getDefaultGranularity());
	const [loading, setLoading] = useState(true);

	const data = placeStatsStore.averageCreatorCCU(
		props.creator.id,
		granularity,
		props.state
	);

	const noData = !loading && data && !data.length;

	const getData = useCallback(
		async (gran: DataGranularity, seg: CCUDataSegment) => {
			await placeStatsStore.getAverageCreatorCCU(
				props.creator,
				props.creatorExperiences,
				gran,
				seg
			);
			setLoading(false);
		},
		[props.creator, props.creatorExperiences]
	);

	const options: Highcharts.Options = {
		series: [
			{
				name: 'Average',
				type: 'area',
				showInLegend: false,
				data: data && !loading ? data : [],
				marker: {
					enabled: false,
				},
				lineWidth: 3,
				lineColor: ChartColors()[0],
				fillColor: hexToRGBA(ChartColors()[0], 0.6),
			},
		],
		chart: {
			marginLeft: 45,
		},
		xAxis: {
			type: 'datetime',
			title: {
				y: 5,
				style: {
					color: themeStore.lightTheme
						? BrandColors.gray600
						: BrandColors.white,
					fontStyle: 'italic',
				},
			},
			min: data?.length ? data[0][0] : undefined,
			max: data?.length ? data[data.length - 1][0] : undefined,
		},
		yAxis: {
			title: {
				enabled: false,
			} as Highcharts.YAxisOptions,
			labels: {
				x: -10,
				y: 3,
				reserveSpace: false,
				style: {
					whiteSpace: 'nowrap',
				},
			},
		},
		plotOptions: {
			line: {
				marker: {
					enabled: false,
				},
			},
		},
		navigator: {
			enabled: true,
			xAxis: {
				min: data?.length ? data[0][0] : undefined,
				max: data?.length ? data[data.length - 1][0] : undefined,
			},
		},
		scrollbar: {
			enabled: true,
			liveRedraw: true,
		},
		rangeSelector: {
			selected: 7,
		},
	};

	const updateGranularity = (g: DataGranularity) => {
		setLoading(true);
		setGranularity(g);
		void getData(g, props.state);
	};

	useEffect(() => {
		void getData(granularity, props.state);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setLoading(true);
		void getData(granularity, props.state);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.state]);

	return (
		<div className={'w-full'}>
			<div className={'w-full relative'}>
				<Chart
					title={graphTitle}
					highcharts={Highcharts}
					options={options}
					granularity={granularity}
				/>

				{loading && (
					<LoadingIndicator
						className={
							'absolute! top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
						}
					/>
				)}
				{noData && (
					<div
						className={
							'absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
						}
					>
						No Data
					</div>
				)}
			</div>

			<div
				className={'flex items-center justify-between gap-4 flex-wrap'}
			>
				<GranularitySelector
					updateGranularity={updateGranularity}
					selectedGranularity={granularity}
				/>
			</div>
		</div>
	);
});

export default AverageCreatorCCU;
