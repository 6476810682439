import { z } from 'zod';

export interface ExperienceStatsSeries {
	place_id: number;
	name: string;
	data: number[][];
}

export interface ExperienceTagStatsSeries {
	data: number[][];
}

interface ExperienceStats {
	stat: string;
	series: ExperienceStatsSeries[];
}

interface ExperienceTagStats {
	stat: string;
	series: ExperienceTagStatsSeries[];
}

export const ExperienceStatsSeriesSchema: z.ZodType<ExperienceStatsSeries> =
	z.object({
		place_id: z.number(),
		name: z.string(),
		data: z.array(z.array(z.number())),
	});

export const ExperienceStatsSchema: z.ZodType<ExperienceStats> = z.object({
	stat: z.string(),
	series: z.array(ExperienceStatsSeriesSchema),
});

export const ExperienceTagStatsSeriesSchema: z.ZodType<ExperienceTagStatsSeries> =
	z.object({
		data: z.array(z.array(z.number())),
	});

export const ExperienceTagStatsSchema: z.ZodType<ExperienceTagStats> = z.object(
	{
		stat: z.string(),
		series: z.array(ExperienceTagStatsSeriesSchema),
	}
);
