import { observer } from 'mobx-react-lite';
import { Link, useLocation } from 'react-router';
import { LocationState } from '@components/navigation/navigation-button/navigation-button.tsx';

const TermsOfUse = observer(function TermsOfUse() {
	const locationState = useLocation().state as LocationState;
	const navigateTo =
		locationState?.from && locationState.from.pathname !== '/sign-up'
			? `${locationState.from.pathname}`
			: '/overview';

	return (
		<div
			className={
				'w-screen h-dvh bg-white dark:bg-gray-575 grid place-items-center overflow-y-auto'
			}
		>
			<div
				className={
					'w-full text-lg hover:text-blue-500 dark:hover:text-blue-300 p-4'
				}
			>
				<Link to={navigateTo}>{'← Back'}</Link>
			</div>

			<div className="max-w-5xl w-full p-6">
				<h1 className="text-3xl font-bold mb-4">TERMS OF USE</h1>
				<p className="mb-6">Effective Date: 2025-03-12</p>

				<p className="mb-4">
					These Terms of Use (&#34;Terms&#34;) govern the use of UGC
					Insight provided by The Gang S.A., a company registered in
					Portugal, with co-ownership by The Gang Studio AB, a company
					registered in Sweden (collectively, &#34;Company,&#34;
					&#34;we,&#34; &#34;us,&#34; or &#34;our&#34;). By accessing
					or using UGC Insight, you agree to be bound by these Terms.
				</p>

				<h2 className="text-xl font-semibold mt-6">
					1. Acceptance of Terms
				</h2>
				<p className="mb-4">
					By accessing or using UGC Insight, you acknowledge that you
					have read, understood, and agreed to these Terms. If you do
					not agree, you must not use UGC Insight.
				</p>

				<h2 className="text-xl font-semibold mt-6">
					2. Description of UGC Insight
				</h2>
				<p className="mb-4">
					UGC Insight provides{' '}
					<i>
						real-time analytics, statistical insights, and data
						visualization
					</i>{' '}
					regarding the Roblox platform. It enables users to analyze{' '}
					<i>trends, performance metrics, and other insights</i>{' '}
					related to games within Roblox.
				</p>

				<h2 className="text-xl font-semibold mt-6">
					3. Eligibility & Access Restrictions
				</h2>
				<ul className="list-[upper-roman] pl-6 mb-4">
					<li>UGC Insight is available to all users worldwide.</li>
					<li>
						Non-registered users have access to a 30-day data
						history limit.
					</li>
					<li>
						Registered users gain access to an extended data
						history.
					</li>
					<li>
						UGC Insight is not directed at minors; however, it is
						not restricted from their use.
					</li>
				</ul>

				<h2 className="text-xl font-semibold mt-6">
					4. Account Creation & User Responsibilities
				</h2>
				<ul className="list-[upper-roman] pl-6 mb-4">
					<li>Users may register to access additional features.</li>
					<li>
						You are responsible for maintaining the confidentiality
						of your login credentials. You must not share or
						disclose them to unauthorized individuals.
					</li>
					<li>
						You must provide accurate and up-to-date information
						during registration. False or misleading information may
						result in immediate account suspension.
					</li>
					<li>
						Prohibited Activities:
						<ul className="list-[lower-alpha] pl-6 mt-2">
							<li>
								Scraping, automated data extraction, or any
								method of accessing data beyond the provided
								features.
							</li>
							<li>
								Reverse engineering, decompiling, or modifying
								any part of UGC Insight.
							</li>
							<li>
								Circumventing security measures, accessing
								unauthorized data, or attempting to breach the
								system.
							</li>
							<li>
								Using UGC Insight in violation of applicable
								laws or Roblox’s terms of service.
							</li>
							<li>
								Engaging in excessive usage that negatively
								impacts the platform’s performance.
							</li>
						</ul>
					</li>
				</ul>

				<h2 className="text-xl font-semibold mt-6">
					5. Enforcement & Consequences of Misuse
				</h2>
				<p className="mb-4">
					If a user violates these Terms, we may take one or more of
					the following actions:
				</p>
				<ul className="list-[upper-roman] pl-6 mb-4">
					<li>
						Immediate account suspension or termination: We may
						suspend or permanently terminate access without prior
						notice.
					</li>
					<li>
						IP Address blocking: We may ban the user’s IP to prevent
						further access.
					</li>
					<li>
						Financial liability: If the user’s actions cause
						financial harm (e.g., excessive server load,
						unauthorized data extraction), we reserve the right to
						seek compensation for damages.
					</li>
					<li>
						Legal action: If violations involve hacking, data theft,
						or breach of intellectual property, we may pursue legal
						action to recover damages.
					</li>
					<li>
						Reporting to authorities: If applicable, violations
						involving fraud, hacking, or data breaches may be
						reported to law enforcement agencies.
					</li>
				</ul>

				<h2 className="text-xl font-semibold mt-6">
					6. Intellectual Property & Data Ownership
				</h2>
				<ul className="list-[upper-roman] pl-6 mb-4">
					<li>
						The Company retains all intellectual property rights
						over UGC Insight.
					</li>
					<li>
						UGC Insight does not own the underlying Roblox data but
						merely displays publicly available insights.
					</li>
					<li>
						Users may not copy, distribute, modify, or resell any
						part of UGC Insight or its insights without prior
						written consent.
					</li>
					<li>
						Any feedback or suggestions provided by users regarding
						UGC Insight may be used by the Company without
						obligation.
					</li>
				</ul>

				<h2 className="text-xl font-semibold mt-6">
					7. Permitted & Prohibited Uses
				</h2>
				<p className="mb-4">Permitted Uses:</p>
				<ul className="list-[upper-roman] pl-6 mb-4">
					<li>
						Personal or business analysis of game trends on Roblox.
					</li>
					<li>
						Internal decision-making based on analytics provided.
					</li>
				</ul>

				<p className="mb-4">Prohibited Uses:</p>
				<ul className="list-[upper-roman] pl-6 mb-4">
					<li>
						Scraping, automated extraction, or unauthorized
						reproduction of data.
					</li>
					<li>
						Reverse engineering, decompiling, or modifying UGC
						Insight.
					</li>
					<li>Using UGC Insight to develop competing services.</li>
					<li>
						Any activity that violates applicable laws or Roblox’s
						terms.
					</li>
					<li>
						Any use that negatively impacts UGC Insight’s
						performance or security.
					</li>
				</ul>

				<h2 className="text-xl font-semibold mt-6">
					8. Privacy & Data Handling
				</h2>
				<p className="mb-4">
					Our Privacy Policy governs the collection, storage, and
					processing of personal data. By using UGC Insight, you agree
					to the data practices outlined in our{' '}
					<Link
						to={'/privacy-policy'}
						className={'text-blue-500 dark:text-blue-300'}
					>
						Privacy Policy
					</Link>
					.
				</p>

				<ul className="list-[upper-roman] pl-6 mb-4">
					<li>
						Collected Data: IP address, and if registered, first
						name, last name, and email.
					</li>
					<li>
						Purpose: Security logging, visit statistics, and
						internal communications.
					</li>
					<li>Retention:</li>

					<ul className="list-[lower-alpha] pl-6 mb-4">
						<li>IP addresses deleted after 30 days.</li>
						<li>
							User data retained indefinitely unless deletion is
							requested.
						</li>
						<li>Visit statistics stored for 3 months.</li>
					</ul>
				</ul>

				<ul className="list-disc pl-6 mb-4">
					<li>
						Data Security: Encrypted transmissions, hashed password
						storage, and restricted database access.
					</li>
					<li>Cookies:</li>

					<ul className="list-[lower-alpha] pl-6 mb-4">
						<li>
							Persistent authorization cookies to keep users
							signed in (1st- party).
						</li>
						<li>
							Session tracking cookies from PostHog (3rd-party)
							for analytics purposes.
						</li>
						<li>
							Cookies expire 1 year after last access, unless
							cleared by the user.
						</li>
						<li>
							Data transfers: No personal data is transferred
							outside the European Economic Area (EEA).
						</li>
					</ul>
				</ul>

				<h2 className="text-xl font-semibold mt-6">
					9. User Support Policy
				</h2>

				<ul className="list-[upper-roman] pl-6 mb-4">
					<li>
						The Company does not guarantee technical support but may
						provide it at its discretion.
					</li>
					<li>
						Support inquiries may be addressed through the provided
						contact email.
					</li>
				</ul>

				<h2 className="text-xl font-semibold mt-6">
					10. Payment & Subscription Terms
				</h2>
				<ul className="list-[upper-roman] pl-6 mb-4">
					<li>
						Payment details, including pricing and billing cycles,
						will be disclosed prior to purchase.
					</li>
					<li>
						Subscription fees are non-refundable except where
						required by law.
					</li>
					<li>
						Failure to make timely payments may result in account
						suspension or termination.
					</li>
					<li>
						Users will be notified in advance of any changes to the
						pricing model.
					</li>
				</ul>

				<h2 className="text-xl font-semibold mt-6">
					11. Disclaimers & Limitation of Liability
				</h2>
				<ul className="list-[upper-roman] pl-6 mb-4">
					<li>
						UGC Insight is provided &#34;as is&#34; without
						warranties of any kind.
					</li>
					<li>
						We do not guarantee the accuracy, completeness, or
						uninterrupted availability of UGC Insight.
					</li>
					<li>
						To the maximum extent permitted by law, the Company is
						not liable for indirect, incidental, or consequential
						damages, including lost profits or data.
					</li>
					<li>
						In no event shall our total liability exceed €100 or the
						total amount paid by you in the past 12 months
						(whichever is lower).
					</li>
					<li>
						The Company is not responsible for external services
						(e.g., PostHog) or issues arising from reliance on the
						data provided by UGC Insight.
					</li>
				</ul>

				<h2 className="text-xl font-semibold mt-6">
					12. Termination of Access
				</h2>
				<ul className="list-[upper-roman] pl-6 mb-4">
					<li>
						We reserve the right to suspend or terminate any user’s
						access at any time, without notice, for violations of
						these Terms.
					</li>
					<li>
						Users may discontinue use at any time by ceasing access
						to UGC Insight.
					</li>
					<li>
						The Company may terminate UGC Insight’s availability at
						any time, without liability to users.
					</li>
				</ul>

				<h2 className="text-xl font-semibold mt-6">
					13. Governing Law & Dispute Resolution
				</h2>
				<ul className="list-[upper-roman] pl-6 mb-4">
					<li>
						These Terms shall be governed by the laws of Portugal.
					</li>
					<li>
						In the event of a dispute, the parties shall first
						attempt arbitration.
					</li>
					<li>
						If unresolved, disputes shall be subject to the
						exclusive jurisdiction of the courts in Portugal.
					</li>
				</ul>

				<h2 className="text-xl font-semibold mt-6">
					14. Modification of Terms
				</h2>
				<ul className="list-[upper-roman] pl-6 mb-4">
					<li>
						We reserve the right to modify these Terms at any time.
						Updates will be posted with a revised &#34;Effective
						Date&#34;.
					</li>
					<li>
						Continued use after modifications constitutes acceptance
						of the new Terms.
					</li>
				</ul>

				<h2 className="text-xl font-semibold mt-6">
					15. Contact Information
				</h2>
				<p className="mb-4">
					For any inquiries or concerns regarding these Terms, please
					contact us at:
				</p>

				<p>
					<a
						href={
							'mailto:henry@thegang.io?subject=UGC Insight Terms of Use'
						}
						className={'text-blue-500 dark:text-blue-300'}
					>
						henry@thegang.io
					</a>
				</p>

				<p>
					<a
						href={
							'mailto:robin@thegang.io?subject=UGC Insight Terms of Use'
						}
						className={'text-blue-500 dark:text-blue-300'}
					>
						robin@thegang.io
					</a>
				</p>

				<p>
					<a
						href={
							'mailto:lenita@thegang.io?subject=UGC Insight Terms of Use'
						}
						className={'text-blue-500 dark:text-blue-300'}
					>
						lenita@thegang.io
					</a>
				</p>
			</div>
		</div>
	);
});

export default TermsOfUse;
