export enum UserInterests {
	INCREMENTAL_SIMULATORS = 'incremental_simulators',
	RNG_SIMULATORS = 'rng_simulators',
	TYCOONS = 'tycoons',
	ROLEPLAY_AND_HANGOUT = 'roleplay_and_hangout',
	SHOOTERS = 'shooters',
	FIGHTING_MELEE_COMBAT = 'fighting_melee_combat',
	HORROR = 'horror',
	TOWER_DEFENSE = 'tower_defense',
	ARCADE = 'arcade',
	OBBY = 'obby',
	ACTION_RPGS_AND_MMOS = 'action_rpgs_and_mmos',
	SANDBOX_AND_CREATIVE = 'sandbox_and_creative',
	SPORTS = 'sports',
	RACING = 'racing',
	FASHION = 'fashion',
	BRANDED = 'branded',
}

export const UserInterestDisplayNames: Record<UserInterests, string> = {
	[UserInterests.INCREMENTAL_SIMULATORS]: 'Incremental Simulators',
	[UserInterests.RNG_SIMULATORS]: 'RNG Simulators',
	[UserInterests.TYCOONS]: 'Tycoons',
	[UserInterests.ROLEPLAY_AND_HANGOUT]: 'Roleplay & Hangout',
	[UserInterests.SHOOTERS]: 'Shooters',
	[UserInterests.FIGHTING_MELEE_COMBAT]: 'Fighting/Melee Combat',
	[UserInterests.HORROR]: 'Horror',
	[UserInterests.TOWER_DEFENSE]: 'Tower Defense',
	[UserInterests.ARCADE]: 'Arcade',
	[UserInterests.OBBY]: 'Obby',
	[UserInterests.ACTION_RPGS_AND_MMOS]: 'Action RPGs & MMOs',
	[UserInterests.SANDBOX_AND_CREATIVE]: 'Sandbox & Creative',
	[UserInterests.SPORTS]: 'Sports',
	[UserInterests.RACING]: 'Racing',
	[UserInterests.FASHION]: 'Fashion',
	[UserInterests.BRANDED]: 'Branded',
};

export const UserInterestsArray = Object.entries(UserInterests).map(
	([key, value]) => ({
		key, // Enum key (e.g., "INCREMENTAL_SIMULATORS")
		value, // Enum value (e.g., "incremental_simulators")
		label: UserInterestDisplayNames[value as UserInterests],
	})
);
