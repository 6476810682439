import { observer } from 'mobx-react-lite';
import Icon from '@components/core/icon/icon.tsx';
import { IconEnum } from '@components/core/icon/icon-enum.ts';
import Button from '@components/core/button/button.tsx';
import { useState } from 'react';
import { BUTTON_STYLE } from '@components/core/button/button-style-enum.ts';
import { CookiePreferences } from '@/enums/local-storage-types.ts';
import cookiePromptStore from '@store/cookie-prompt-store.ts';
import CookieSlideToggles from '@components/core/cookie-prompt/cookie-slide-toggles.tsx';

const CookiePrompt = observer(function CookiePrompt() {
	const [customizeMode, setCustomizeMode] = useState(false);
	const [allowSessionTracking, setAllowSessionTracking] = useState(true);

	const allowAll = () => {
		const cookiePreferences: CookiePreferences = {
			st: true,
		};

		cookiePromptStore.setCookiePreferences(cookiePreferences);
	};

	const allowSelection = () => {
		const cookiePreferences: CookiePreferences = {
			st: allowSessionTracking,
		};

		cookiePromptStore.setCookiePreferences(cookiePreferences);
	};

	if (!cookiePromptStore.showCookiePrompt) {
		return <></>;
	}

	return (
		<div
			className={
				'w-screen h-dvh absolute top-0 left-0 pointer-events-none flex flex-col justify-end items-center p-4 pb-12 lg:pb-8 z-50'
			}
		>
			<div
				className={
					'flex justify-center items-center rounded-lg p-6 outline-1 outline-gray-500 w-full max-w-2xl max-h-full shadow-lg bg-white dark:bg-gray-550 overflow-y-auto pointer-events-auto'
				}
			>
				<div
					className={
						'w-full flex flex-col justify-center items-center gap-4'
					}
				>
					<div className={'flex justify-center items-center gap-6'}>
						<Icon icon={IconEnum.COOKIE} size={'5rem'} />
						<div className={'text-xl'}>
							We use cookies to make your experience better—always
							anonymously.
						</div>
					</div>

					{!customizeMode && (
						<>
							<div>
								Some cookies help the site function properly,
								while others let us understand how you use it so
								we can improve. Your privacy matters, so you can
								choose what works for you.
							</div>

							<div
								className={
									'flex justify-evenly items-center gap-4 w-full'
								}
							>
								<Button
									title={'Customize Settings'}
									onClick={() => {
										setCustomizeMode(true);
									}}
								/>
								<Button
									title={'Allow All'}
									buttonStyle={BUTTON_STYLE.PRIMARY}
									onClick={allowAll}
								/>
							</div>
						</>
					)}

					{customizeMode && (
						<>
							<CookieSlideToggles
								sessionTracking={{
									initialState: true,
									onToggle: setAllowSessionTracking,
								}}
							/>

							<Button
								title={'Allow Selection'}
								onClick={allowSelection}
							/>
						</>
					)}
				</div>
			</div>
		</div>
	);
});

export default CookiePrompt;
