import { useState, useRef, useEffect, ReactNode } from 'react';
import { observer } from 'mobx-react-lite';
import ClassString from '@utils/class-string.ts';

interface TooltipProps {
	text: string | ReactNode;
	children: ReactNode;
	direction?: 'right' | 'bottom' | 'left' | 'top';
	className?: string;
	widthClass?: string;
	fullWidth?: boolean;
}

const defaultWidth = 250;

const Tooltip = observer(function Tooltip(props: TooltipProps) {
	const [visible, setVisible] = useState(false);
	const [beenVisible, setBeenVisible] = useState(false);
	const [adjustedDirection, setAdjustedDirection] = useState(
		props.direction ?? 'right'
	);
	const [width, setWidth] = useState<number>(defaultWidth);
	const tooltipRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (visible && tooltipRef.current) {
			const tooltipRect = tooltipRef.current.getBoundingClientRect();
			const viewportWidth = window.innerWidth;
			const viewportHeight = window.innerHeight;

			let newWidth: number = defaultWidth;

			if (adjustedDirection === 'right') {
				const difference = tooltipRect.right - viewportWidth + 20;
				newWidth = Math.max(
					Math.min(tooltipRect.width - difference, defaultWidth),
					0
				);

				if (newWidth < 50) {
					newWidth = defaultWidth;
					setAdjustedDirection('left');
				}

				setWidth(newWidth);
				return;
			}

			if (adjustedDirection === 'left') {
				const difference = tooltipRect.left - 10;
				newWidth = Math.max(
					Math.min(tooltipRect.width + difference, defaultWidth),
					0
				);
				if (newWidth < 50) {
					newWidth = defaultWidth;
					setAdjustedDirection('right');
				}

				setWidth(newWidth);
				return;
			}

			if (adjustedDirection === 'bottom') {
				if (tooltipRect.bottom < 0) {
					setAdjustedDirection('top');
					return;
				}
			}

			if (adjustedDirection === 'top') {
				if (tooltipRect.top > viewportHeight) {
					setAdjustedDirection('bottom');
					return;
				}
			}
		}
	}, [visible, props.direction, adjustedDirection]);

	return (
		<div
			className={ClassString({
				static: 'relative flex items-center justify-center',
				dynamic: {},
				custom: props.className,
			})}
		>
			<div
				onMouseEnter={() => {
					if (!beenVisible) {
						setBeenVisible(true);
					}
					setVisible(true);
				}}
				onMouseLeave={() => setVisible(false)}
			>
				{props.children}
			</div>
			<div
				ref={tooltipRef}
				className={ClassString({
					static: 'absolute w-fit p-2 bg-gray-700 text-sm rounded-xl shadow-lg z-10 bg-white dark:bg-gray-550 border border-gray-600 dark:border-gray-400 pointer-events-none opacity-0',
					dynamic: {
						'w-screen': props.fullWidth,
						'left-full ml-3': adjustedDirection === 'right',
						'top-full mt-3': adjustedDirection === 'bottom',
						'right-full mr-3': adjustedDirection === 'left',
						'bottom-full mb-3': adjustedDirection === 'top',
						'animate-tooltip-show': visible,
						'animate-tooltip-hide': beenVisible && !visible,
					},
					custom: props.widthClass,
				})}
				style={{ maxWidth: width }}
			>
				<div className="relative text-gray-600 dark:text-white">
					<div
						className={ClassString({
							static: 'absolute transform w-0 h-0',
							dynamic: {
								'-left-4 top-1/2 -translate-y-1/2 border-t-[6px] border-t-transparent border-r-[7px] border-r-gray-600 dark:border-r-gray-400 border-b-[6px] border-b-transparent':
									adjustedDirection === 'right',
								'-top-4 left-1/2 -translate-x-1/2 border-l-[6px] border-l-transparent border-b-[7px] border-b-gray-600 dark:border-b-gray-400 border-r-[6px] border-r-transparent':
									adjustedDirection === 'bottom',
								'-right-4 top-1/2 -translate-y-1/2 border-t-[6px] border-t-transparent border-l-[7px] border-l-gray-600 dark:border-l-gray-400 border-b-[6px] border-b-transparent':
									adjustedDirection === 'left',
								'-bottom-4 left-1/2 -translate-x-1/2 border-l-[6px] border-l-transparent border-t-[7px] border-t-gray-600 dark:border-t-gray-400 border-r-[6px] border-r-transparent':
									adjustedDirection === 'top',
							},
						})}
					></div>
					{props.text}
				</div>
			</div>
		</div>
	);
});

export default Tooltip;
