import { observer } from 'mobx-react-lite';
import ClassString from '@utils/class-string.ts';
import { useEffect, useState } from 'react';
import anchorService from '@services/anchor-service.ts';

interface ChartOutlineProps {
	elementId: string;
}

const ChartOutline = observer(function ChartOutline(props: ChartOutlineProps) {
	const [showOutline, setShowOutline] = useState(
		anchorService.isAnchored(props.elementId)
	);

	useEffect(() => {
		if (anchorService.isAnchored(props.elementId)) {
			setShowOutline(true);

			const disableOutline = () => {
				setShowOutline(false);
				window.removeEventListener('click', disableOutline);
			};

			window.addEventListener('click', disableOutline);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div
			className={ClassString({
				static: 'absolute top-0 left-0 w-full h-full outline outline-transparent transition-all shadow-transparent z-in-front pointer-events-none',
				dynamic: {
					'outline-blue-300 rounded-md shadow-anchor dark:shadow-anchor-dark':
						showOutline,
				},
			})}
		></div>
	);
});

export default ChartOutline;
