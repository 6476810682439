/// <reference types="vite-plugin-svgr/client" />

import React from 'react';
import { observer } from 'mobx-react-lite';
import { IconEnum } from '@components/core/icon/icon-enum.ts';
import ClassString from '@utils/class-string.ts';
import DeleteSvg from '@material-design-icons/svg/outlined/delete.svg?react';
import ArrowBackSvg from '@material-design-icons/svg/round/arrow_back.svg?react';
import ArrowForwardSvg from '@material-design-icons/svg/round/arrow_forward.svg?react';
import ExternalLinkSvg from '@material-design-icons/svg/round/open_in_new.svg?react';
import EditSvg from '@material-design-icons/svg/outlined/edit.svg?react';
import SearchSvg from '@material-design-icons/svg/round/search.svg?react';
import ChevronLeftSvg from '@material-design-icons/svg/round/chevron_left.svg?react';
import ChevronRightSvg from '@material-design-icons/svg/round/chevron_right.svg?react';
import ChevronUpSvg from '@material-design-icons/svg/round/expand_less.svg?react';
import ChevronDownSvg from '@material-design-icons/svg/round/expand_more.svg?react';
import CalendarMothSvg from '@material-design-icons/svg/round/calendar_month.svg?react';
import ClearSvg from '@material-design-icons/svg/round/clear.svg?react';
import ConfirmSvg from '@material-design-icons/svg/round/check.svg?react';
import MenuSvg from '@material-design-icons/svg/round/menu.svg?react';
import BulkAddSvg from '@material-design-icons/svg/outlined/library_add.svg?react';
import TagSvg from '@material-design-icons/svg/round/bookmark_border.svg?react';
import PlaceSvg from '@material-design-icons/svg/outlined/sports_esports.svg?react';
import RobuxSvg from '@material-design-icons/svg/outlined/paid.svg?react';
import BadgeSvg from '@material-design-icons/svg/outlined/local_police.svg?react';
import DeepLinkSvg from '@material-design-icons/svg/outlined/link.svg?react';
import AccountSvg from '@material-design-icons/svg/outlined/account_circle.svg?react';
import HandSvg from '@material-design-icons/svg/outlined/front_hand.svg?react';
import LogoutSvg from '@material-design-icons/svg/round/logout.svg?react';
import SadSvg from '@material-design-icons/svg/round/sentiment_dissatisfied.svg?react';
import GroupSvg from '@material-design-icons/svg/round/groups.svg?react';
import UserSvg from '@material-design-icons/svg/round/person.svg?react';
import MoreSvg from '@material-design-icons/svg/round/more_horiz.svg?react';
import MoreVertSvg from '@material-design-icons/svg/round/more_vert.svg?react';
import ToolsSvg from '@material-design-icons/svg/outlined/handyman.svg?react';
import TheGangBlackSvg from '@assets/square_logo_black.svg?react';
import TheGangWhiteSvg from '@assets/square_logo_white.svg?react';
import OverviewSvg from '@material-design-icons/svg/round/query_stats.svg?react';
import BreadcrumbArrowSvg from '@material-design-icons/svg/round/east.svg?react';
import AddSvg from '@material-design-icons/svg/round/add_circle_outline.svg?react';
import ShareSvg from '@material-design-icons/svg/round/share.svg?react';
import RoundCheckSvg from '@material-design-icons/svg/round/check_circle.svg?react';
import RoundInfoSvg from '@material-design-icons/svg/outlined/info.svg?react';
import RoundWarningSvg from '@material-design-icons/svg/round/error.svg?react';
import RoundErrorSvg from '@material-design-icons/svg/round/cancel.svg?react';
import CopySvg from '@material-design-icons/svg/outlined/content_copy.svg?react';
import LightModeSvg from '@material-design-icons/svg/round/light_mode.svg?react';
import DarkModeSvg from '@material-design-icons/svg/round/dark_mode.svg?react';
import PlaySvg from '@material-design-icons/svg/round/play_circle_outline.svg?react';
import Description from '@material-design-icons/svg/outlined/description.svg?react';
import Visibility from '@material-design-icons/svg/outlined/visibility.svg?react';
import VisibilityOff from '@material-design-icons/svg/outlined/visibility_off.svg?react';
import Disabled from '@material-design-icons/svg/outlined/do_disturb_on.svg?react';
import Compare from '@material-design-icons/svg/round/compare_arrows.svg?react';
import ManageAccounts from '@material-design-icons/svg/round/manage_accounts.svg?react';
import CheckCircleOutline from '@material-design-icons/svg/round/task_alt.svg?react';
import Home from '@material-design-icons/svg/round/home.svg?react';
import Curated from '@material-design-icons/svg/round/workspace_premium.svg?react';
import DailyPicks from '@material-design-icons/svg/round/auto_awesome.svg?react';
import Video from '@material-design-icons/svg/outlined/smart_display.svg?react';
import Fullscreen from '@material-design-icons/svg/round/fullscreen.svg?react';
import Ranking from '@material-design-icons/svg/round/emoji_events.svg?react';
import Genre from '@material-design-icons/svg/outlined/casino.svg?react';
import Plus from '@material-design-icons/svg/round/add.svg?react';
import Cookie from '@material-design-icons/svg/outlined/cookie.svg?react';
import TrendingUp from '@material-design-icons/svg/round/trending_up.svg?react';
import TrendingDown from '@material-design-icons/svg/round/trending_down.svg?react';
import Settings from '@material-design-icons/svg/outlined/settings.svg?react';
import Rocket from '@material-design-icons/svg/round/rocket_launch.svg?react';
import Lock from '@material-design-icons/svg/outlined/lock.svg?react';
import Global from '@material-design-icons/svg/outlined/public.svg?react';
import Email from '@material-design-icons/svg/outlined/email.svg?react';

interface IconProps {
	icon: IconEnum;
	className?: string;
	onClick?: () => unknown;
	size?: string;
}

const icons: Record<
	IconEnum,
	React.FunctionComponent<React.SVGProps<SVGSVGElement>>
> = {
	[IconEnum.DELETE]: DeleteSvg,
	[IconEnum.ARROW_BACK]: ArrowBackSvg,
	[IconEnum.ARROW_FORWARD]: ArrowForwardSvg,
	[IconEnum.EXTERNAL_LINK]: ExternalLinkSvg,
	[IconEnum.EDIT]: EditSvg,
	[IconEnum.SEARCH]: SearchSvg,
	[IconEnum.CHEVRON_LEFT]: ChevronLeftSvg,
	[IconEnum.CHEVRON_RIGHT]: ChevronRightSvg,
	[IconEnum.CHEVRON_UP]: ChevronUpSvg,
	[IconEnum.CHEVRON_DOWN]: ChevronDownSvg,
	[IconEnum.CALENDAR]: CalendarMothSvg,
	[IconEnum.CLEAR]: ClearSvg,
	[IconEnum.CONFIRM]: ConfirmSvg,
	[IconEnum.MENU]: MenuSvg,
	[IconEnum.BULK_ADD]: BulkAddSvg,
	[IconEnum.TAG]: TagSvg,
	[IconEnum.PLACE]: PlaceSvg,
	[IconEnum.ROBUX]: RobuxSvg,
	[IconEnum.BADGE]: BadgeSvg,
	[IconEnum.DEEP_LINK]: DeepLinkSvg,
	[IconEnum.ACCOUNT]: AccountSvg,
	[IconEnum.HAND]: HandSvg,
	[IconEnum.LOGOUT]: LogoutSvg,
	[IconEnum.SAD]: SadSvg,
	[IconEnum.GROUP]: GroupSvg,
	[IconEnum.USER]: UserSvg,
	[IconEnum.MORE]: MoreSvg,
	[IconEnum.MORE_VERT]: MoreVertSvg,
	[IconEnum.TOOLS]: ToolsSvg,
	[IconEnum.THE_GANG_BLACK]: TheGangBlackSvg,
	[IconEnum.THE_GANG_WHITE]: TheGangWhiteSvg,
	[IconEnum.OVERVIEW]: OverviewSvg,
	[IconEnum.BREADCRUMB_ARROW]: BreadcrumbArrowSvg,
	[IconEnum.ADD]: AddSvg,
	[IconEnum.SHARE]: ShareSvg,
	[IconEnum.ROUND_CHECK]: RoundCheckSvg,
	[IconEnum.ROUND_INFO]: RoundInfoSvg,
	[IconEnum.ROUND_WARNING]: RoundWarningSvg,
	[IconEnum.ROUND_ERROR]: RoundErrorSvg,
	[IconEnum.COPY]: CopySvg,
	[IconEnum.LIGHT_MODE]: LightModeSvg,
	[IconEnum.DARK_MODE]: DarkModeSvg,
	[IconEnum.PLAY]: PlaySvg,
	[IconEnum.DESCRIPTION]: Description,
	[IconEnum.VISIBILITY]: Visibility,
	[IconEnum.VISIBILITY_OFF]: VisibilityOff,
	[IconEnum.DISABLED]: Disabled,
	[IconEnum.COMPARE]: Compare,
	[IconEnum.MANAGE_ACCOUNTS]: ManageAccounts,
	[IconEnum.CHECK_CIRCLE_OUTLINE]: CheckCircleOutline,
	[IconEnum.HOME]: Home,
	[IconEnum.CURATED]: Curated,
	[IconEnum.DAILY_PICKS]: DailyPicks,
	[IconEnum.VIDEO]: Video,
	[IconEnum.FULLSCREEN]: Fullscreen,
	[IconEnum.RANKING]: Ranking,
	[IconEnum.GENRE]: Genre,
	[IconEnum.PLUS]: Plus,
	[IconEnum.COOKIE]: Cookie,
	[IconEnum.TRENDING_UP]: TrendingUp,
	[IconEnum.TRENDING_DOWN]: TrendingDown,
	[IconEnum.SETTINGS]: Settings,
	[IconEnum.ROCKET]: Rocket,
	[IconEnum.LOCK]: Lock,
	[IconEnum.GLOBAL]: Global,
	[IconEnum.EMAIL]: Email,
};

const Icon = observer(function Icon(props: IconProps) {
	const SvgIcon = icons[props.icon];

	return (
		<>
			{SvgIcon && (
				<SvgIcon
					className={ClassString({
						dynamic: {},
						custom: props.className,
					})}
					onClick={props.onClick}
					style={{
						fontSize: props.size,
						width: '1em',
						height: '1em',
						fill: 'currentColor',
					}}
				/>
			)}
		</>
	);
});

export default Icon;
