export const experienceStatsSharedOptions: Highcharts.Options = {
	chart: {
		marginLeft: 45,
	},
	xAxis: {
		type: 'datetime',
	},
	yAxis: {
		title: {
			enabled: false,
		} as Highcharts.YAxisOptions,
		labels: {
			x: -10,
			y: 3,
			reserveSpace: false,
			style: {
				whiteSpace: 'nowrap',
			},
		},
	},
	plotOptions: {
		line: {
			marker: {
				enabled: false,
			},
		},
		column: {
			stacking: 'normal',
		},
	},
	navigator: {
		enabled: true,
	},
	scrollbar: {
		enabled: true,
		liveRedraw: true,
	},
	rangeSelector: {
		enabled: true,
		selected: 2,
	},
};
