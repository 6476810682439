import { useEffect } from 'react';
import { useLocation } from 'react-router';
import postHogService from '@services/posthog-service.ts';
import { PostHogEventType } from '@/enums/posthog-event.ts';

const usePageViewTracker = () => {
	const location = useLocation();

	useEffect(() => {
		const sendPageView = () => {
			postHogService.capture({
				type: PostHogEventType.PageView,
				$current_url: location.pathname,
			});
		};

		sendPageView();
	}, [location]);
};

export default usePageViewTracker;
