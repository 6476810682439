import type { Config } from 'tailwindcss';

const config: Config = {
	theme: {
		extend: {
			backgroundImage: {
				'watermark-black': 'url("assets/watermark-black.webp")',
				'watermark-white': 'url("assets/watermark-white.webp")',
				'top-page-pattern': 'url("assets/top-page-pattern.svg")',
			},
			borderWidth: {
				'12': '12px',
			},
			boxShadow: {
				outline: '0 1px #252525',
				'outline-dark': '0 1px #676767',
				focus: 'inset 0 0 0 2px #73ccff',
				'focus-dark': 'inset 0 0 0 2px #b8e5ff',
				anchor: '0 0 15px 5px #73ccff',
				'anchor-dark': '0 0 15px 5px #6d74fd',
			},
			margin: {
				'toast-fade-out': '-400px',
				nav: '70px',
			},
			transitionProperty: {
				'toast-fade-out': 'margin',
				select: 'max-height, opacity',
				height: 'height',
				'max-width': 'max-width',
			},
			borderRadius: {
				button: '17px', // Half button height
			},
			height: {
				button: '34px',
				'expansion-input-open': 'calc(34px * var(--button-count))',
				'expansion-button-open':
					'min(calc(34px * var(--button-count) + 34px), calc(34px * 10 + 34px))',
				'expansion-panel-open':
					'calc(var(--panel-height) + var(--title-height))',
				nav: '70px',
				'scroll-container': 'calc(100dvh - 70px)', // Full height minus nav bar
				'dynamic-screen': '100dvh',
			},
			minHeight: {
				'expansion-panel-title': 'var(--title-height)',
			},
			inset: {
				sidebar: '290px',
			},
			width: {
				button: '34px',
				sidebar: '290px',
				nav: 'calc(100vw - 290px)',
				'scroll-container': 'calc(100vw - 84px)',
				'scroll-container-expanded': 'calc(100vw - 290px)',
				'minimal-nav': 'calc(100vw - 6rem)',
				'experience-thumbnail': '280px',
				'panel-1/2': 'calc(50% - 1rem)',
				'panel-1/3': 'calc(33.333333% - 1rem)',
				'tools-logo': 'var(--tools-logo-width)',
				'graph-full': 'calc(100% + 2rem)',
			},
			opacity: {
				1: '0.01',
				1.5: '0.015',
				2: '0.02',
				3: '0.03',
			},
			padding: {
				sidebar: '290px',
				nav: '70px',
			},
			minWidth: {
				'experience-thumbnail': '280px',
			},
			maxWidth: {
				'experience-thumbnail': '280px',
			},
			outlineWidth: {
				3: '3px',
			},
			animation: {
				'options-menu': 'open-options-menu 100ms forwards',
				'hide-tab': 'hide-tab 400ms forwards',
				'tooltip-show': 'tooltip-show 100ms forwards',
				'tooltip-hide': 'tooltip-hide 200ms forwards',
				shake: 'shake 0.82s cubic-bezier(.36,.07,.19,.97) both',
			},
			keyframes: {
				'open-options-menu': {
					'0%': { height: '0' },
					'100%': { height: 'calc(var(--button-count) * 34px)' },
				},
				'hide-tab': {
					'0%': { visibility: 'visible' },
					'99%': { visibility: 'visible' },
					'100%': { visibility: 'hidden' },
				},
				'tooltip-show': {
					'0%': { opacity: '0' },
					'100%': { opacity: '1' },
				},
				'tooltip-hide': {
					'0%': { opacity: '1' },
					'50%': { opacity: '1' },
					'100%': { opacity: '0' },
				},
				shake: {
					'10%, 90%': {
						transform: 'translate3d(-1px, 0, 0)',
					},
					'20%, 80%': {
						transform: 'translate3d(2px, 0, 0)',
					},
					'30%, 50%, 70%': {
						transform: 'translate3d(-4px, 0, 0)',
					},
					'40%, 60%': {
						transform: 'translate3d(4px, 0, 0)',
					},
				},
			},
			animationTimingFunction: {
				'transition-curve': 'cubic-bezier(0.165, 0.84, 0.44, 1)',
			},
			transitionTimingFunction: {
				'transition-curve': 'cubic-bezier(0.165, 0.84, 0.44, 1)',
			},
			zIndex: {
				'in-front': '2',
				'in-front-2': '3',
				'in-front-3': '4',
				'in-front-4': '5',
				'nav-menu': '8',
				nav: '9',
				'nav-sidebar': '10',
				'modal-overlay': '51',
				modal: '52',
				toast: '53',
			},
		},
	},
};
export default config;
