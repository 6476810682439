import { observer } from 'mobx-react-lite';
import { IconEnum } from '@components/core/icon/icon-enum.ts';
import { NavLink, useLocation } from 'react-router';
import Icon from '@components/core/icon/icon.tsx';
import ClassString from '@utils/class-string.ts';
import { useEffect, useState } from 'react';
import navSidebarStore from '@store/nav-sidebar-store.ts';

interface NavSidebarLinkProps {
	title: string;
	route: string;
	icon?: NavSidebarLinkIcon;
	className?: string;
	whiteSpaceWrap?: boolean;
	normalFontWeight?: boolean;
	locked?: boolean;
	onClick?: () => unknown;
}

export interface NavSidebarLinkIcon {
	icon: IconEnum;
	size: string;
}

const NavSidebarLink = observer(function NavSidebarLink(
	props: NavSidebarLinkProps
) {
	const location = useLocation();
	const [routeActive, setRouteActive] = useState(false);

	useEffect(() => {
		const isActive = location.pathname.startsWith(props.route);

		setRouteActive(isActive);
	}, [location, props.route]);

	return (
		<div
			className={ClassString({
				static: 'group rounded-md  hover:bg-blue-300 my-1 w-full',
				dynamic: {
					'bg-blue-300': routeActive,
				},
				custom: props.className,
			})}
		>
			<NavLink
				to={props.route}
				draggable="false"
				className={ClassString({
					static: 'no-underline',
					dynamic: { active: routeActive },
				})}
				onClick={(event) => {
					if (props.locked) {
						event.preventDefault();
					} else {
						navSidebarStore.setOpen(false);
					}

					if (props.onClick) {
						props.onClick();
					}
				}}
			>
				<div
					className={ClassString({
						static: 'relative flex items-center text-white group-hover:text-gray-600 text-lg font-semibold px-4 py-2',
						dynamic: {
							'lg:justify-center': navSidebarStore.minimal,
							'font-normal': props.normalFontWeight,
							'text-gray-600': routeActive,
						},
					})}
				>
					{props.icon && (
						<div
							className={ClassString({
								static: 'mr-2 flex items-center',
								dynamic: {
									'lg:mr-0': navSidebarStore.minimal,
								},
							})}
						>
							<Icon
								icon={props.icon.icon}
								size={props.icon.size}
							/>
						</div>
					)}
					<div
						className={ClassString({
							static: 'whitespace-nowrap flex-1',
							dynamic: {
								'lg:hidden': navSidebarStore.minimal,
								'whitespace-normal': props.whiteSpaceWrap,
							},
						})}
					>
						{props.title}
					</div>

					{props.locked && (
						<div className={'flex justify-end items-center'}>
							<Icon icon={IconEnum.LOCK} size={'2rem'} />
						</div>
					)}

					{/*{props.locked && (*/}
					{/*	<Icon*/}
					{/*		icon={IconEnum.LOCK}*/}
					{/*		size={'2rem'}*/}
					{/*		className={ClassString({*/}
					{/*			static: 'absolute top-1/2 right-0 transform -translate-y-1/2 transition-all duration-1000',*/}
					{/*			dynamic: {*/}
					{/*				'opacity-0 text-gray-500':*/}
					{/*					navSidebarStore.minimal,*/}
					{/*			},*/}
					{/*		})}*/}
					{/*	/>*/}
					{/*)}*/}
				</div>
			</NavLink>
		</div>
	);
});

export default NavSidebarLink;
