import { observer } from 'mobx-react-lite';
import View from '@components/view/view.tsx';
import ViewPanel from '@components/view/view-panel.tsx';
import eventCredentialStore from '@store/event-credential-store.ts';
import EventCredentialInfo from '@views/event-credentials/view-components/event-credential-info.tsx';
import { IconEnum } from '@components/core/icon/icon-enum.ts';
import { useEffect, useState } from 'react';
import experienceStore from '@store/experience-store.ts';
import EventCredentialCreate from '@views/event-credentials/view-components/event-credential-create.tsx';
import SearchBar from '@components/core/search-bar/search-bar.tsx';
import searchService from '@services/search-service.ts';
import thumbnailStore from '@store/thumbnail-store.ts';
import { ThumbnailType } from '@/schemas/thumbnail-schema.ts';
import ViewExpansionPanel from '@components/view/view-expansion-panel.tsx';
import EventCredentialEventStatsGraph from '@views/event-credentials/view-components/event-credential-event-stats-graph.tsx';

const EventCredentials = observer(function EventCredentials() {
	const [searchTerm, setSearchTerm] = useState('');
	const [visibleSecret, setVisibleSecret] = useState('');

	const credentials = searchTerm.length
		? eventCredentialStore.eventCredentials.filter((cred) =>
				searchService.eventCredential(cred, searchTerm)
			)
		: eventCredentialStore.eventCredentials;

	useEffect(() => {
		void thumbnailStore.loadThumbnailsById(
			credentials.map((c) => c.place_id),
			ThumbnailType.ICON
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<View
			viewInfo={{
				title: 'Event Credentials',
				icon: IconEnum.TOOLS,
				iconSize: '28px',
				breadCrumbs: [{ title: 'Tools' }],
			}}
			hideHeader
			loading={!experienceStore.slimExperiences.length}
		>
			<div
				className={
					'max-w-3xl flex flex-col justify-center items-center gap-6'
				}
			>
				<ViewPanel className={'max-w-3xl'}>
					<EventCredentialCreate />
				</ViewPanel>

				<ViewExpansionPanel title={'Readme'} className={'max-w-3xl'}>
					<div
						className={
							'flex flex-col justify-center items-center gap-4 p-4 pt-0'
						}
					>
						<p>
							To send custom game events from your game to the
							TGSAnalytics backend to be visible in our Lightdash
							tool, you will need to generate a game key and
							secret key pair from the form above. You can check
							if there is already a pair by searching as well.
						</p>
						<p>
							You can either paste the ID of the game from
							Roblox.com, or you can try searching for it. If the
							game has not yet launched, you will need to use its
							ID as we will have not cataloged the game in our
							database yet. Please give it a name descriptive
							name. If the game&#39;s code name, please include
							what the live project name will be for easy
							reference later. E.g.: &quot;Shoreditch / IKEA&quot;
						</p>
						<p className={'max-w-2xl'}>
							<em>Note:</em> Only events sent from this game will
							be accepted if the game is not played through
							Studio! If you want to do a live test outside of the
							Studio engine, please create a new pair of keys{' '}
							<em>specifically</em> for that place. Do not change
							the ones that will be released to production!
						</p>
						<p>
							Once you&#39;ve generated a new pair of keys, you
							will need to create two new{' '}
							<code
								className={'bg-gray-200 dark:bg-gray-575 px-1'}
							>
								StringValue
							</code>{' '}
							objects in{' '}
							<code
								className={'bg-gray-200 dark:bg-gray-575 px-1'}
							>
								ServerStorage
							</code>{' '}
							of your game. For the game key, please create a{' '}
							<code
								className={'bg-gray-200 dark:bg-gray-575 px-1'}
							>
								TGSAnalyticsGameKey
							</code>{' '}
							and for the secret key, create{' '}
							<code
								className={'bg-gray-200 dark:bg-gray-575 px-1'}
							>
								TGSAnalyticsSecretKey
							</code>
							. Copy the keys from here and paste into the value
							fields for each entry.
						</p>
						<p>
							Your game should now be able to send events! You can
							check the logs for this by setting the{' '}
							<code
								className={'bg-gray-200 dark:bg-gray-575 px-1'}
							>
								enableInfoLog
							</code>{' '}
							to{' '}
							<code
								className={'bg-gray-200 dark:bg-gray-575 px-1'}
							>
								true
							</code>{' '}
							in{' '}
							<code
								className={'bg-gray-200 dark:bg-gray-575 px-1'}
							>
								GACustomOptions
							</code>
							, also located in{' '}
							<code
								className={'bg-gray-200 dark:bg-gray-575 px-1'}
							>
								ServerStorage
							</code>
							. If this file does not exist, you can copy one from
							the Code Storage project in Studio.
						</p>
						<p>
							Please reach out to{' '}
							<a
								href="https://thegang-dev.slack.com/archives/D02MTGNELG0"
								target={'_blank'}
								className={'text-blue-500 dark:text-blue-300'}
								rel="noreferrer"
							>
								Henry Henderson
							</a>{' '}
							or{' '}
							<a
								href="https://thegang-dev.slack.com/archives/D06FRQXF0RG"
								target={'_blank'}
								className={'text-blue-500 dark:text-blue-300'}
								rel="noreferrer"
							>
								Filip Jansson
							</a>{' '}
							if you have questions or feedback with this process!
						</p>
					</div>
				</ViewExpansionPanel>
			</div>

			<div
				className={
					'w-full max-w-(--breakpoint-lg) flex flex-col justify-center items-center gap-6'
				}
			>
				<ViewExpansionPanel title={'Total Events'} startExpanded>
					<EventCredentialEventStatsGraph
						title={`Total Events`}
						className={'px-4'}
						totalEvents
					/>
				</ViewExpansionPanel>

				<div className="border-t border-gray-500 w-full"></div>

				<SearchBar
					onSearchUpdate={setSearchTerm}
					placeholder={'Search..'}
				/>

				{credentials.map((cred) => {
					return (
						<ViewPanel key={cred.game_key}>
							<EventCredentialInfo
								credential={cred}
								secretVisible={visibleSecret === cred.game_key}
								setSecretVisible={(visible) => {
									setVisibleSecret(
										visible ? cred.game_key : ''
									);
								}}
							/>
						</ViewPanel>
					);
				})}
			</div>
		</View>
	);
});

export default EventCredentials;
