export enum CHART_RANGE {
	DAY = 'day',
	WEEK = 'week',
	MONTH = 'month',
	THREE_MONTHS = 'three_months',
	SIX_MONTHS = 'six_months',
	YEAR_TO_DATE = 'year_to_date',
	YEAR = 'year',
	ALL = 'all',
}
