import { BUTTON_STYLE } from '@components/core/button/button-style-enum.ts';
import { IconEnum } from '@components/core/icon/icon-enum.ts';
import Icon from '@components/core/icon/icon.tsx';
import { LegacyRef } from 'react';
import { observer } from 'mobx-react-lite';
import ClassString from '@utils/class-string.ts';
import LoadingSpinner from '@components/core/loading-spinner/loading-spinner.tsx';

export interface ButtonProps {
	title: string;
	onClick?: () => unknown;
	disabled?: boolean;
	buttonStyle?: BUTTON_STYLE;
	className?: string;
	selected?: boolean;
	icon?: ButtonIcon;
	round?: boolean;
	noBorder?: boolean;
	innerRef?: LegacyRef<HTMLButtonElement>;
	transparent?: boolean;
	loading?: boolean;
	small?: boolean;
}

export interface ButtonIcon {
	icon: IconEnum;
	placement: 'left' | 'right';
	size?: string;
}

const Button = observer(function Button(props: ButtonProps) {
	const buttonStyle = props.buttonStyle
		? props.buttonStyle
		: BUTTON_STYLE.DEFAULT;

	return (
		<button
			className={ClassString({
				static: `button relative flex items-center justify-center rounded-button h-button w-fit transition-all duration-75 px-5 py-2 whitespace-nowrap border disabled:bg-gray-200 dark:disabled:bg-gray-500 disabled:border-gray-200 dark:disabled:border-gray-500 disabled:text-gray-500 dark:disabled:text-gray-400 text-gray-600 decoration-gray-600 dark:decoration-white shadow-md disabled:shadow-none bg-white dark:bg-gray-525 border-gray-600 dark:border-gray-500 dark:text-white focus-visible:outline-blue-300 focus-visible:outline-double focus-visible:outline-4 focus-visible:bg-blue-300 focus-visible:text-gray-600 focus-visible:decoration-white focus-visible:border-blue-300 hover:text-gray-600 hover:bg-blue-300 hover:decoration-white hover:border-blue-300 cursor-pointer`,
				dynamic: {
					'text-white border-black bg-gray-600 dark:bg-white dark:border-gray-600 dark:text-gray-600':
						buttonStyle === BUTTON_STYLE.PRIMARY,
					'text-white border-red-500 bg-red-500 dark:border-red-500 dark:bg-red-500':
						buttonStyle === BUTTON_STYLE.WARNING,
					'p-0 w-button': props.round,
					'text-white border-white dark:border-white bg-gray-600 dark:bg-gray-600 underline decoration-white':
						props.selected,
					'[&:not(:hover)]:bg-transparent!': props.transparent,
					'px-3 py-1 h-8 text-sm': props.small,
				},
				custom: props.className,
			})}
			onClick={() => !!props.onClick && props.onClick()}
			disabled={!!props.disabled || !!props.loading}
			ref={props.innerRef}
		>
			{!!props.icon && (
				<>
					{props.icon.placement === 'left' && (
						<Icon
							icon={props.icon.icon}
							size={props.icon.size ?? '18px'}
							className={ClassString({
								dynamic: {
									'text-transparent': !!props.loading,
								},
							})}
						/>
					)}
				</>
			)}
			{!!props.title && (
				<div
					style={{
						paddingLeft:
							props.icon?.placement === 'left' ? '0.5em' : '0',
						paddingRight:
							props.icon?.placement === 'right' ? '0.5em' : '0',
					}}
					className={ClassString({
						dynamic: { 'text-transparent': !!props.loading },
					})}
				>
					{props.title}
				</div>
			)}
			{!!props.icon && (
				<>
					{props.icon.placement === 'right' && (
						<Icon
							icon={props.icon.icon}
							size={props.icon.size ?? '18px'}
							className={ClassString({
								dynamic: {
									'text-transparent': !!props.loading,
								},
							})}
						/>
					)}
				</>
			)}

			{props.loading && (
				<div
					className={
						'absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
					}
				>
					<LoadingSpinner />
				</div>
			)}
		</button>
	);
});

export default Button;
