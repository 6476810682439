import { makeAutoObservable, runInAction } from 'mobx';
import httpFetch from '@services/http-fetch-service.ts';
import { Creator, CreatorListSchema } from '@/schemas/creator-schema.ts';
import { BaseStore } from '@store/base-store.ts';

const baseUrl = import.meta.env.VITE_SERVER_URL as string;

const creatorUrl = `${baseUrl}/api/creator`;

class CreatorStore implements BaseStore {
	private _creators: Creator[] = [];
	private _initialized = false;

	reset() {
		this._creators = [];
		this._initialized = false;
	}

	constructor() {
		makeAutoObservable(this);
	}

	async loadCreators() {
		const response = await httpFetch.GET(creatorUrl);

		if (response.ok) {
			const creators = CreatorListSchema.parse(await response.json());
			runInAction(() => {
				this._creators = creators;
				this._initialized = true;
			});
		}
	}

	getCreatorById(id: number) {
		return this._creators.find((creator) => creator.id === id);
	}

	get creators() {
		return this._creators;
	}

	get initialized() {
		return this._initialized;
	}

	async waitForInit(): Promise<void> {
		if (this._initialized) {
			return;
		}

		await new Promise((resolve) => setTimeout(resolve, 250));
		return this.waitForInit();
	}
}

const creatorStore = new CreatorStore();
export default creatorStore;
