import { observer } from 'mobx-react-lite';
import useHashQueryParams from '@hooks/use-hash-query-params.tsx';
import { useEffect, useState } from 'react';
import TagExpansionInput from '@components/data/tag-expansion-input/tag-expansion-input.tsx';
import { PlaceTag } from '@/schemas/plage-tag-schema.ts';
import placeTagStore from '@store/place-tag-store.ts';
import ClassString from '@utils/class-string.ts';
import CompoundExperienceIcon from '@components/data/experience-icon/compound-experience-icon.tsx';
import { ExperienceIconSize } from '@components/data/experience-icon/experience-icon-constants.ts';

interface ExperienceComparisonSelectorProps {
	tag: PlaceTag | undefined;
	setTag: (tag: PlaceTag | undefined) => unknown;
	color: string;
	index: number;
}

const TagSelector = observer((props: ExperienceComparisonSelectorProps) => {
	const [searchParams, setSearchParams] = useHashQueryParams();
	const tagIdString = searchParams[`tag-${props.index}`];
	const tag = placeTagStore.getPlaceTagById(tagIdString ? +tagIdString : 0);

	useEffect(() => {
		props.setTag(tag);
	}, [tag, props]);

	const [searchTerm, setSearchTerm] = useState(tag ? tag.name : '');

	const setTag = (tag?: PlaceTag) => {
		setSearchParams({
			...searchParams,
			[`tag-${props.index}`]: tag ? tag.id.toString() : '',
		});
	};

	useEffect(() => {
		if (props.tag && searchTerm !== props.tag.name) {
			setSearchTerm(props.tag.name);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.tag]);

	return (
		<div
			className={
				'border dark:border-gray-500 p-6 rounded-xl flex flex-col items-center m-2'
			}
		>
			<TagExpansionInput
				searchTerm={searchTerm}
				setSearchTerm={setSearchTerm}
				tag={props.tag}
				setTag={setTag}
			/>

			<div
				className={'flex flex-col items-center justify-between h-full'}
			>
				<div
					className={ClassString({
						static: `w-48 h-48 mt-4 rounded-lg overflow-hidden shadow-lg border-4`,
						dynamic: {
							'bg-gray-200 dark:bg-gray-500': !props.tag,
						},
					})}
					style={{ borderColor: props.color }}
				>
					{props.tag && (
						<div
							className={
								'flex flex-col justify-center items-center w-full h-full gap-2'
							}
						>
							<CompoundExperienceIcon
								tagId={props.tag.id}
								iconSize={ExperienceIconSize.SMALL}
							/>
							<div className={'text-center'}>
								{`Tag includes ${props.tag.place_ids.length} experiences.`}
							</div>
						</div>
					)}
				</div>

				<div
					className={
						'w-64 text-center break-words font-semibold mt-2'
					}
				>
					{props.tag?.name ?? '. . .'}
				</div>
			</div>
		</div>
	);
});

export default TagSelector;
