import { observer } from 'mobx-react-lite';
import { ChangeEvent, useState } from 'react';
import ClassString from '@utils/class-string.ts';

interface CheckboxProps {
	label: string;
	name?: string;
	onChange?: (checked: boolean) => void;
	initialState?: boolean;
	error?: boolean;
}

const Checkbox = observer(function Checkbox(props: CheckboxProps) {
	const [isChecked, setIsChecked] = useState<boolean>(!!props.initialState);

	const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
		setIsChecked(event.target.checked);
		if (props.onChange) {
			props.onChange(event.target.checked);
		}
	};

	return (
		<label
			className={
				'flex justify-center items-center cursor-pointer select-none group'
			}
		>
			<input
				type={'checkbox'}
				name={props.name}
				checked={isChecked}
				onChange={handleCheckboxChange}
				className={'peer w-0 h-0 opacity-0'}
			/>
			<div
				className={ClassString({
					static: 'w-6 h-6 min-w-6 min-h-6 rounded-md mr-2 flex justify-center items-center transition-all duration-100 outline-1 outline-gray-600 dark:outline-gray-500 group-hover:outline-blue-300 peer-focus:outline-blue-300 relative',
					dynamic: {
						'outline-blue-300 dark:outline-blue-300  outline-2':
							isChecked,
						'outline-red-525 dark:outline-red-400 outline-2':
							props.error,
					},
				})}
			>
				<svg
					className={ClassString({
						static: 'w-0 h-0 text-transparent transition-all absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2',
						dynamic: {
							'text-blue-300  w-6 h-6': isChecked,
						},
					})}
					fill={'none'}
					stroke={'currentColor'}
					strokeWidth={'3'}
					viewBox={'0 0 24 24'}
					xmlns={'http://www.w3.org/2000/svg'}
				>
					<path
						strokeLinecap={'round'}
						strokeLinejoin={'round'}
						d={'M5 13l4 4L19 7'}
					></path>
				</svg>
			</div>
			{props.label}
		</label>
	);
});

export default Checkbox;
