import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import authStore from '@store/auth-store.ts';
import InputText from '@components/core/input-text/input-text.tsx';
import Button from '@components/core/button/button.tsx';
import { BUTTON_STYLE } from '@components/core/button/button-style-enum.ts';
import { Link, useLocation } from 'react-router';
import { INPUT_TEXT_TYPES } from '@components/core/input-text/input-text-enum.ts';
import Form from '@components/core/form/form.tsx';
import LoadingIndicator from '@components/core/loading-indicator/loading-indicator.tsx';
import ViewPanel from '@components/view/view-panel.tsx';
import ClassString from '@utils/class-string.ts';
import useScreenBreakpoint from '@hooks/use-screen-breakpoint.tsx';
import { ScreenBreakpoint } from '@/enums/screen-breakpoints.ts';
import validationService from '@services/validation-service.ts';
import SignUpHeader from '@views/sign-up/sign-up-header.tsx';
import { UserInterests } from '@/enums/user-interests.ts';
import { LocationState } from '@components/navigation/navigation-button/navigation-button.tsx';
import UserOnboardingInterests from '@components/core/user-onboarding/user-onboarding-interests.tsx';
import UserOnboardingType from '@components/core/user-onboarding/user-onboarding-type.tsx';

const SignUp = observer(function SignUp() {
	const screenBreakpoint = useScreenBreakpoint();

	const [fadeIn, setFadeIn] = useState(false);

	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');

	const [userInterests, setUserInterests] = useState<UserInterests[]>([]);
	const [userType, setUserType] = useState('');

	const [showIncomplete, setShowIncomplete] = useState(false);
	const [loading, setLoading] = useState(false);

	const [accountCreated, setAccountCreated] = useState(false);

	const locationState = useLocation().state as LocationState;
	const navigateTo =
		locationState?.from && locationState.from.pathname !== '/sign-up'
			? `${locationState.from.pathname}`
			: '/overview';

	document.title = 'Sign Up for Free | UGC Insight';

	const passwordsNotMatching =
		!!password.length &&
		!!confirmPassword.length &&
		password !== confirmPassword;

	const incomplete =
		!firstName.length ||
		!lastName.length ||
		!validationService.email(email) ||
		password.length < 8 ||
		confirmPassword.length < 8 ||
		passwordsNotMatching ||
		!userInterests.length ||
		!userType.length;

	const handleSignUp = async () => {
		if (incomplete) {
			setShowIncomplete(true);
			return;
		}

		try {
			setLoading(true);
			const success = await authStore.register(
				firstName,
				lastName,
				email,
				password,
				userInterests,
				userType
			);
			setLoading(false);

			if (success) {
				setAccountCreated(true);
			}
		} catch (_) {
			setLoading(false);
		}
	};

	useEffect(() => {
		setFadeIn(true);
	}, []);

	return (
		<div
			className={
				'w-screen h-dvh bg-green-100 dark:bg-gray-575 md:bg-transparent dark:md:bg-transparent grid place-items-center overflow-y-auto md:bg-top-page-pattern md:dark:bg-none bg-cover'
			}
		>
			<div
				className={
					'w-full static flex justify-start items-center text-lg hover:text-blue-500 dark:hover:text-blue-300 pt-4 pl-6 self-start'
				}
			>
				<Link to={navigateTo} draggable={false}>
					{'← Back'}
				</Link>
			</div>

			<div
				className={
					'flex flex-col justify-between md:justify-center items-center md:gap-4 h-full p-4 pt-0'
				}
			>
				<div></div>

				<ViewPanel
					className={ClassString({
						static: 'w-fit opacity-0 transition-opacity bg-transparent md:bg-green-100',
						dynamic: {
							'opacity-100': fadeIn,
						},
					})}
					options={{
						container: screenBreakpoint <= ScreenBreakpoint.MD,
					}}
				>
					<div
						className={ClassString({
							static: 'inline-flex flex-col justify-center items-center p-4 h-full',
							dynamic: {
								'opacity-0': loading,
								hidden: accountCreated,
							},
						})}
					>
						<SignUpHeader />

						<Form
							className={
								'inline-flex flex-col justify-center items-center w-full pt-4'
							}
							onSubmit={() => {
								void handleSignUp();
							}}
						>
							<div
								className={
									'grid md:grid-cols-2 gap-y-4 md:gap-y-0 gap-x-12 mb-8 md:mb-0'
								}
							>
								<div
									className={
										'max-w-lg flex flex-col justify-center items-center place-self-start'
									}
								>
									<div
										className={
											'text-lg font-semibold w-full'
										}
									>
										Account Information
									</div>

									<div className={'grid grid-cols-2 gap-4'}>
										<InputText
											label={'First Name'}
											value={firstName}
											name={'first_name'}
											onInput={(event) =>
												setFirstName(event.target.value)
											}
											type={INPUT_TEXT_TYPES.TEXT}
											options={{
												width: '100%',
												large: true,
											}}
											inputClassName={ClassString({
												static: 'bg-transparent',
												dynamic: {
													'outline-red-525':
														showIncomplete &&
														!firstName.length,
												},
											})}
										/>

										<InputText
											label={'Last Name'}
											value={lastName}
											name={'last_name'}
											onInput={(event) =>
												setLastName(event.target.value)
											}
											type={INPUT_TEXT_TYPES.TEXT}
											options={{
												width: '100%',
												large: true,
											}}
											inputClassName={ClassString({
												static: 'bg-transparent',
												dynamic: {
													'outline-red-525':
														showIncomplete &&
														!lastName.length,
												},
											})}
										/>
									</div>

									<InputText
										label={'Email'}
										value={email}
										name={'email'}
										onInput={(event) =>
											setEmail(event.target.value)
										}
										type={INPUT_TEXT_TYPES.EMAIL}
										options={{ width: '100%', large: true }}
										inputClassName={ClassString({
											static: 'bg-transparent',
											dynamic: {
												'outline-red-525':
													showIncomplete &&
													!validationService.email(
														email
													),
											},
										})}
									/>

									<div className={'relative w-full'}>
										<div
											className={ClassString({
												static: 'absolute -top-2 right-4 text-sm text-red-525 hidden',
												dynamic: {
													block:
														showIncomplete &&
														password.length < 8,
												},
											})}
										>
											{'Password needs to be longer'}
										</div>

										<InputText
											label={'Password'}
											value={password}
											name={'password'}
											onInput={(event) =>
												setPassword(event.target.value)
											}
											type={INPUT_TEXT_TYPES.PASSWORD}
											options={{
												width: '100%',
												large: true,
											}}
											inputClassName={ClassString({
												static: 'bg-transparent',
												dynamic: {
													'outline-red-525':
														passwordsNotMatching ||
														(showIncomplete &&
															password.length <
																8),
												},
											})}
										/>
									</div>

									<div className={'relative w-full'}>
										<div
											className={ClassString({
												static: 'absolute -top-2 right-4 text-sm text-red-525 hidden',
												dynamic: {
													block: passwordsNotMatching,
												},
											})}
										>
											{'Password does not match'}
										</div>

										<InputText
											label={'Confirm Password'}
											value={confirmPassword}
											name={'confirm_email'}
											onInput={(event) =>
												setConfirmPassword(
													event.target.value
												)
											}
											type={INPUT_TEXT_TYPES.PASSWORD}
											options={{
												width: '100%',
												large: true,
											}}
											inputClassName={ClassString({
												static: 'bg-transparent',
												dynamic: {
													'outline-red-525':
														passwordsNotMatching ||
														(showIncomplete &&
															confirmPassword.length <
																8),
												},
											})}
										/>
									</div>
								</div>

								<UserOnboardingInterests
									onChange={setUserInterests}
									error={
										showIncomplete && !userInterests.length
									}
								/>

								<UserOnboardingType
									onChange={setUserType}
									error={showIncomplete && !userType.length}
								/>
							</div>

							<Button
								title={'Sign Up for Free'}
								buttonStyle={BUTTON_STYLE.PRIMARY}
								className={
									'mt-2 w-full py-6 rounded-full max-w-80'
								}
							/>
						</Form>
					</div>

					<div
						className={ClassString({
							static: 'inline-flex flex-col justify-center items-center p-4',
							dynamic: {
								'opacity-0': loading,
								hidden: !accountCreated,
							},
						})}
					>
						<div className={'w-full mb-6 text-center font-bold'}>
							Account successfully created!
						</div>

						<div className={'w-full mb-6 text-center max-w-md'}>
							Please check your email for a verification message
							and complete the verification process within 7 days.
						</div>

						<div className={'w-full mb-6 text-center max-w-md'}>
							If you don’t see the email, please check your spam
							folder.
						</div>

						<Link
							to={'/sign-in'}
							className={
								'mt-2 w-full py-6 rounded-full max-w-80 button relative flex items-center justify-center h-button transition-all duration-75 px-5 whitespace-nowrap border  text-white shadow-md bg-gray-600  border-gray-500  focus-visible:outline-blue-300 focus-visible:outline-double focus-visible:outline-4 focus-visible:bg-blue-300 focus-visible:text-gray-600 focus-visible:decoration-white focus-visible:border-blue-300 hover:bg-blue-300 hover:text-gray-600 hover:decoration-white hover:border-blue-300'
							}
						>
							Sign In
						</Link>
					</div>

					<div
						className={ClassString({
							static: 'absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 pointer-events-none',
							dynamic: { 'opacity-0': !loading },
						})}
					>
						<LoadingIndicator />
					</div>
				</ViewPanel>

				<div
					className={ClassString({
						static: 'flex justify-center items-center',
						dynamic: { hidden: accountCreated },
					})}
				>
					<span className={'mr-2'}>{'Already got an account?'}</span>
					<Link
						to={'/sign-in'}
						className={'text-blue-500 dark:text-blue-300'}
					>
						Sign In
					</Link>
				</div>
			</div>

			<div></div>
		</div>
	);
});

export default SignUp;
