import { observer } from 'mobx-react-lite';
import themeStore from '@store/theme-store.ts';

interface AppLogoProps {
	color?: 'light' | 'dark';
	className?: string;
}

const AppLogo = observer(function AppLogo(props: AppLogoProps) {
	let imgSrc = themeStore.lightTheme
		? '/app-logo-black.png'
		: '/app-logo-white.png';

	if (props.color === 'light') {
		imgSrc = '/app-logo-white.png';
	}

	if (props.color === 'dark') {
		imgSrc = '/app-logo-black.png';
	}

	return (
		<div className={props.className}>
			<img
				src={imgSrc}
				alt={'logo-full'}
				className={'w-full max-w-md'}
				draggable="false"
			/>
		</div>
	);
});

export default AppLogo;
