import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { DefaultIcons } from '@components/data/experience-icon/experience-icon-constants.ts';
import SeededRandom from '@utils/seeded-random.ts';

interface CompoundExperienceIconFallbackImageProps {
	src: string;
	alt: string;
	className?: string;
}

const CompoundExperienceIconFallbackImage = observer(
	function CompoundExperienceIconFallbackImage(
		props: CompoundExperienceIconFallbackImageProps
	) {
		const [imgSrc, setImgSrc] = useState(props.src);

		const fallbackSrc =
			DefaultIcons[
				Math.floor(
					SeededRandom(new Date().getTime()) * DefaultIcons.length
				)
			];

		return (
			<img
				src={imgSrc}
				alt={props.alt}
				onError={() => setImgSrc(fallbackSrc)}
				className={props.className}
			/>
		);
	}
);

export default CompoundExperienceIconFallbackImage;
