import { observer } from 'mobx-react-lite';
import LinkedIn from '@assets/socials-linked-in.svg?react';
import Roblox from '@assets/socials-roblox.svg?react';
import YouTube from '@assets/socials-youtube.svg?react';
import TikTok from '@assets/socials-tiktok.svg?react';
import Twitter from '@assets/socials-twitter.svg?react';
import Instagram from '@assets/socials-instagram.svg?react';
import Discord from '@assets/socials-discord.svg?react';
import ClassString from '@utils/class-string.ts';

interface ViewFooterSocialsProps {
	className?: string;
}

const iconClassName = 'w-6 h-6';

const ViewFooterSocials = observer(function ViewFooterSocials(
	props: ViewFooterSocialsProps
) {
	return (
		<div
			className={ClassString({
				static: 'flex flex-col justify-center items-center sm:items-start w-full gap-1',
				dynamic: {},
				custom: props.className,
			})}
		>
			<a
				href={'https://www.thegang.io'}
				target="_blank"
				rel="noreferrer"
				className={
					'text-sm hover:text-blue-500 dark:hover:text-blue-300'
				}
			>
				UGC Insight by THE GANG
			</a>

			<div
				className={
					'flex justify-center sm:justify-start items-center flex-wrap gap-2 w-full '
				}
			>
				<a
					href={'https://www.linkedin.com/company/the-gang-sweden/'}
					target="_blank"
					rel="noreferrer"
					className={'hover:text-blue-500 dark:hover:text-blue-300'}
				>
					<LinkedIn className={iconClassName} />
				</a>

				<a
					href={
						'https://www.roblox.com/groups/5273516/The-Gang-Stockholm#!/about'
					}
					target="_blank"
					rel="noreferrer"
					className={'hover:text-blue-500 dark:hover:text-blue-300'}
				>
					<Roblox className={iconClassName} />
				</a>

				<a
					href={
						'https://www.youtube.com/channel/UC69OuylCokfIbCsfAKhAMbw'
					}
					target="_blank"
					rel="noreferrer"
					className={'hover:text-blue-500 dark:hover:text-blue-300'}
				>
					<YouTube className={iconClassName} />
				</a>

				<a
					href={'https://www.tiktok.com/@the.gang.gaming'}
					target="_blank"
					rel="noreferrer"
					className={'hover:text-blue-500 dark:hover:text-blue-300'}
				>
					<TikTok className={iconClassName} />
				</a>

				<a
					href={'https://twitter.com/TheGang_Gaming'}
					target="_blank"
					rel="noreferrer"
					className={'hover:text-blue-500 dark:hover:text-blue-300'}
				>
					<Twitter className={iconClassName} />
				</a>

				<a
					href={'https://www.instagram.com/the.gang.gaming/'}
					target="_blank"
					rel="noreferrer"
					className={'hover:text-blue-500 dark:hover:text-blue-300'}
				>
					<Instagram className={iconClassName} />
				</a>

				<a
					href={'https://discord.com/invite/TzNrVny2mZ'}
					target="_blank"
					rel="noreferrer"
					className={'hover:text-blue-500 dark:hover:text-blue-300'}
				>
					<Discord className={iconClassName} />
				</a>
			</div>
		</div>
	);
});

export default ViewFooterSocials;
